<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      @click="dismiss()"
      style="z-index: 20"
    >
      <div
        class="container m-auto max-w-3xl border border-white-100 mt-16 bg-white rounded-lg form-container flex flex-col py-8"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div
          class="flex-none flex items-start justify-between pb-4 border-b border-gray px-4"
        >
          <div class="text-3xl">
            Invoice <span class="font-bold" v-if="data">#{{ data.id }}</span>
          </div>
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div class="overflow-y-scroll py-4 mb-4" v-if="data">
          <div class="grid grid-cols-2 gap-2 px-4 text-sm mb-4">
            <div class="">
              <span class="font-extrabold">Invoiced To :</span>
              <br /><br />{{
                this.userdata.customfields1
                  ? this.userdata.customfields1.toUpperCase()
                  : "Personal"
              }}
              <br />{{ this.userdata.fullname }}<br />{{
                this.userdata.address1
                  ? this.userdata.address1 +
                    ", " +
                    (this.userdata.countryname ? this.userdata.countryname : "")
                  : this.userdata.address2
                  ? this.userdata.address2 +
                    ", " +
                    (this.userdata.countryname ? this.userdata.countryname : "")
                  : this.userdata.countryname
                  ? this.userdata.countryname
                  : ""
              }}
              <br />NPWP:
              {{ this.userdata.tax_id ? this.userdata.tax_id : "-" }}
            </div>
            <div class="">
              <span class="font-extrabold">Pay To : </span><br /><br />PT Deneva
              <br />YAP Square C-5, Jl. C. Simanjuntak No. 2 Yogyakarta 55523<br />D.
              I. Yogyakarta, Indonesia <br />NPWP: 90.872.685.5.8-343.000
            </div>
          </div>
          <!-- <div class="text-sm px-4 mb-6" v-if="data.status == 'Unpaid'">
            <div class="p-2 bg-greenBg border border-gray font-bold">
              Apply Credit
            </div>
            <div class="px-2 py-2 border-l border-r border-b border-gray">
              <div class="mb-2">
                Your credit balance is
                <span class="font-bold">Rp.402.000</span>, this can be applied
                to the invoice using the form below. Enter the amount to apply.
              </div>
              <div class="mx-auto max-w-xs grid grid-cols-2 gap-2 items-center">
                <input
                  type="number"
                  class="px-2 py-1 border border-gray outline-none"
                />
                <button class="bg-green px-2 py-1 border border-green">
                  Apply Credit
                </button>
              </div>
            </div>
          </div> -->
          <div
            class="px-4 my-4 flex items-center justify-end"
            v-if="data.status === 'Unpaid'"
          >
            <div class="text-right mr-4">Payment Methods:</div>
            <div
              class="w-60 py-1 px-3 border border-gray rounded-lg flex items-center relative cursor-pointer"
              @click="selectPM = !selectPM"
              tabindex="0"
              @blur="selectPM = false"
            >
              <div class="flex-1">
                {{
                  payment.method ? payment.name : "--Select Payment Method--"
                }}
              </div>
              <div class="">
                <div class="text-xs text-gray mt-1">
                  <i
                    :class="
                      selectPM ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
                    "
                  ></i>
                </div>
              </div>
              <div class="absolute left-0 right-0 top-10" v-if="selectPM">
                <div
                  class="border border-gray p-3 bg-white rounded-lg max-h-60 overflow-y-scroll"
                >
                  <div
                    class="border-b border-gray cursor-pointer hover:text-orange py-1 last:border-none"
                    v-for="pm in paymentMethodList"
                    :key="pm.method"
                    @click="changePayment(pm.method, pm.name)"
                  >
                    {{ pm.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-sm px-4">
            <div
              class="p-2 border-t border-l border-r border-gray font-bold flex items-center justify-between rounded-t-lg"
            >
              <div class="">Invoice Items</div>
              <div class="">
                Status :
                <span
                  class="text-xl font-bold"
                  :class="data.status === 'Paid' ? 'text-green' : 'text-red'"
                  >{{ data.status }}</span
                >
              </div>
            </div>

            <div class="px-2 border border-gray py-2 rounded-b-lg">
              <div class="border-b border-gray grid grid-cols-7 py-2 gap-3">
                <div class="col-span-5 font-bold">Description</div>
                <div class="col-span-2 font-bold">Amount</div>
              </div>
              <div
                class="border-b border-gray grid grid-cols-7 py-1 items-center gap-3"
                v-for="dat in data.items"
                :key="dat.relid"
              >
                <div class="col-span-5">
                  {{ dat.description }}
                </div>
                <div class="col-span-2">Rp. {{ dat.amout }}</div>
              </div>

              <div
                class="border-b border-gray grid grid-cols-7 py-1 items-center gap-3"
              >
                <div class="col-span-5 text-right font-bold">Sub Total</div>
                <div class="col-span-2">Rp. {{ data.subtotal }}</div>
              </div>
              <div
                class="border-b border-gray grid grid-cols-7 py-1 items-center gap-3"
              >
                <div class="col-span-5 text-right font-bold">10% PPN</div>
                <div class="col-span-2">Rp. {{ data.tax }}</div>
              </div>
              <div
                class="border-b border-gray grid grid-cols-7 py-1 items-center gap-3"
              >
                <div class="col-span-5 text-right font-bold">Credit</div>
                <div class="col-span-2">Rp. {{ data.credit }}</div>
              </div>
              <div class="grid grid-cols-7 py-1 items-center gap-3">
                <div class="col-span-5 text-right font-bold">Total</div>
                <div class="col-span-2">Rp. {{ data.total }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4" v-if="data && data.status == 'Unpaid'">
          <button
            class="py-3 bg-orange text-white w-full rounded-lg"
            @click.prevent="proceedPayment()"
            v-if="!onSubmit && payment.method !== null"
          >
            Proceed to payment
          </button>
          <button class="py-3 bg-gray text-white w-full rounded-lg" v-else>
            Proceed to payment
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "invoiceDetail",

  props: {
    will_dismiss: {
      type: Boolean,
      default: false
    },
    data: null,
    snap: null
  },
  mounted: function() {
    this.g_loading_destroy();
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", process.env.VUE_APP_MIDTRANS_SITE);
    recaptchaScript.setAttribute(
      "data-client-key",
      process.env.VUE_APP_MIDTRANS_DATA_CLIENT_KEY
    );
    recaptchaScript.setAttribute("type", "text/javascript");
    document.head.appendChild(recaptchaScript);
  },
  computed: {
    ...mapState(["userdata", "payment"])
  },

  data: function() {
    return {
      selectPM: false,
      onSubmit: false,
      paymentMethodList: [
        {
          name: "BCA Virtual Account",
          method: "bca_va"
        },
        {
          name: "BRI Virtual Account",
          method: "bri_va"
        },
        {
          name: "Gopay",
          method: "gopay"
        },
        {
          name: "Shopee Pay",
          method: "shopeepay"
        },
        {
          name: "Permata Virtual Account",
          method: "permata_va"
        },
        {
          name: "BNI Virtual Account",
          method: "bni_va"
        },
        {
          name: "Danamon Online",
          method: "danamon_online"
        },
        {
          name: "Other Virtual Account",
          method: "other_va"
        },
        {
          name: "BCA Klik Pay",
          method: "bca_klikpay"
        },
        {
          name: "Alfamart",
          method: "alfamart"
        }
      ]
    };
  },
  methods: {
    // ...mapActions(["postMe", "login"]),
    proceedPayment() {
      window.snap.pay(this.snap, {
        onSuccess: function(result) {
          /* You may add your own implementation here */
          alert("payment success!");
          console.log(result);
        },
        onPending: function(result) {
          /* You may add your own implementation here */
          alert("wating your payment!");
          console.log(result);
        },
        onError: function(result) {
          /* You may add your own implementation here */
          alert("payment failed!");
          console.log(result);
        },
        onClose: function() {
          /* You may add your own implementation here */
          alert("you closed the popup without finishing the payment");
        }
      });
    },
    changePayment(method, name) {
      this.$store.commit("changePayment", { method: method, name: name });
      this.$emit("changePayment");
    },
    dismiss() {
      this.$store.commit("resetPayment");
      this.$emit("dismiss");
    },
    succesClose() {
      this.$emit("dismiss");
      this.$emit("get");
    },
    closeNotif() {
      this.notif.show = false;
      this.succesClose();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
  height: 700px;
  overflow: hidden;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
