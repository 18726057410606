<template>
  <div class="">
    <div class="grid grid-cols-2 items-center justify-center" v-if="!iframe">
      <div
        class="flex justify-center min-h-screen text-white bg-orange items-center"
      >
        <div class="w-64">
          <img src="@/assets/logo/logo-white.png" alt="" class="w-full" />
        </div>
        <!-- <div class="flex font-bold relative">
          <h1 class="text-2xl font-nunito">cloudora</h1>
          <span
            class="absolute top-0 font-nunito"
            style="font-size: 8px; right: -6px"
            >TM</span
          >
        </div> -->
      </div>
      <div class="min-h-screen">
        <div
          class="max-w-sm min-h-screen mx-auto flex items-center justify-center"
        >
          <div class="w-full">
            <ErrorMessage
              class="mb-4"
              v-if="em"
              :message="emMessage"
              @dismiss="em = false"
            />
            <h1 class="text-2xl mb-6 font-nunito font-bold">Login</h1>
            <form @submit.prevent="logincheck" class="mb-6">
              <div
                class="flex px-2 py-3 mb-2 bg-red-10 border border-red-20 items-center justify-between text-red rounded-md"
                v-if="loginData.email.message"
              >
                <div class="">
                  {{ loginData.email.message }}
                </div>
                <div class="" @click="loginData.email.message = null">
                  <i class="far fa-times-circle cursor-pointer"></i>
                </div>
              </div>
              <div
                class="bg-grayForm p-3 rounded-md mb-2 border"
                :class="{
                  'border-orange': loginData.email.active,
                  'border-grayForm': !loginData.email.active,
                  'border-red':
                    loginData.email.message && !loginData.email.active
                }"
              >
                <div class="flex">
                  <div class="mr-2">
                    <img
                      src="@/assets/icon/email_orange@2x.png"
                      alt=""
                      class="w-full"
                    />
                  </div>
                  <input
                    type="email"
                    v-model="loginData.email.data"
                    @focus="loginData.email.active = true"
                    @blur="loginData.email.active = false"
                    class="w-full text-sm placeholder-grayText bg-grayForm outline-none"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div
                class="flex bg-grayForm p-3 border rounded-md mb-4"
                :class="{
                  'border-orange': loginData.password.active,
                  'border-grayForm': !loginData.password.active,
                  'border-red':
                    loginData.email.message && !loginData.email.active
                }"
              >
                <div class="mr-2">
                  <img
                    src="@/assets/icon/lock_orange@2x.png"
                    alt=""
                    class="w-full"
                  />
                </div>
                <input
                  type="Password"
                  @focus="loginData.password.active = true"
                  @blur="loginData.password.active = false"
                  v-model="loginData.password.data"
                  class="w-full text-sm placeholder-grayText bg-grayForm outline-none"
                  placeholder="Password"
                />
              </div>
              <div class="flex justify-between mb-10">
                <!-- <div class="flex text-sm items-center text-darkBlueText">
                  <input type="checkbox" class="mr-4" />
                  <span>Keep me in</span>
                </div> -->
                <div class="text-orange text-sm">
                  <router-link
                    to="/forgot-password"
                    class="font-whitney-semibold"
                    >Forgot Password?</router-link
                  >
                </div>
              </div>

              <button
                type="submit"
                class="w-full py-3 text-white bg-orange rounded-md font-whitney-bold"
              >
                Log In
              </button>
            </form>
            <!-- <div class="text-sm text-darkBlueText text-center">
              Don't have an account?
              <span class="text-orange"
                ><router-link to="/register">Sign Up</router-link></span
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-screen flex justify-center items-center" v-if="iframe">
      <div class="max-w-xl rounded-md bg-gray py-2 px-10 text-red">
        Error Getting Data
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Login",
  components: {
    ErrorMessage
  },
  created() {
    document.title = "Nevacloud | Log In";
  },
  computed: {
    ...mapState({
      loginData: "loginData"
    })
  },
  data: function() {
    return {
      em: false,
      emMessage: "Connection Error",
      iframe: false
    };
  },
  mounted: function() {
    if (window.location !== window.parent.location) {
      this.iframe = true;
    } else {
      this.iframe = false;
    }
    this.loginData.email.message = null;
    this.loginData.password.message = null;
  },
  methods: {
    ...mapActions(["login"]),
    logincheck(ev) {
      ev.preventDefault();
      this.loginData.email.message = null;
      this.loginData.password.message = null;
      // eslint-disable-next-line
      const emailChecker = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.loginData.email.data.match(emailChecker)) {
        this.loginData.email.message = "Email address is in wrong format";
        return;
      }
      if (this.loginData.password.data.length < 8) {
        this.loginData.email.message =
          "The password must be at least 8 characters.";
        return;
      }
      if (this.loginData.password.data.length > 64) {
        this.loginData.email.message =
          "maximum password length is 64 characters";
        return;
      }
      this.g_loading_create("Loading");

      this.login().then(response => {
        if (response.status === 200) {
          // var data = response.data;
          // if (!data.is_verified) {
          //   this.$router.push("/checkemail");
          // } else if (!data.is_active) {
          //   this.$router.push("/pending");
          // } else {
          this.$cookies.set("isLogin", true);
          this.$cookies.set("userID", response.data.id);
          this.$cookies.set("expired_at", response.expired_at);
          // window.localStorage.setItem("isLogin", true);
          // window.localStorage.setItem("userID", response.data.id);
          // window.localStorage.setItem("expired_at", response.expired_at);
          this.$router.push("/dashboard");
          // }
        } else if (response.status === 400) {
          this.loginData.email.message = response.error.message;
          this.loginData.email.active = false;
        } else {
          this.emMessage = "Connection Error";
          this.em = true;
        }
        this.g_loading_destroy();
      });
    }
    // async recaptcha() {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded();

    //   // Execute reCAPTCHA with action "login".
    //   const token = await this.$recaptcha("login");

    //   this.$store.dispatch("siteverify", token);

    //   // Do stuff with the received token.
    // },
  }
};
</script>

<style lang="scss" scoped></style>
