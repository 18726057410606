import axios from "axios";
import Vue from "vue";

export default {
  state: {
    plan: null,
    planOption: {
      gp: true,
      mo: false,
      dc: false
    },
    selectedPlan: null,
    planConfig: null,
    IpTypeOption: null
  },
  actions: {
    async getPlan({ commit }, data) {
      const url = process.env.VUE_APP_SERVICE_URL + "plan";
      let token = null;
      if (!data) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        commit("updatePlan", response.data.data);
        return response.data.data;
      } catch (err) {
        console.log(err.response.data);
      }
    },
    async getPlanConfig({ commit }, uuid) {
      const url = process.env.VUE_APP_SERVICE_URL + "plan/" + uuid + "/options";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("setPlanConfig", response.data);
        return response.data;
      } catch (err) {
        console.log(err);
        return err.response.data;
      }
    }
  },
  mutations: {
    setPlanConfig(state, data) {
      // state.planConfig = data;
      state.IpTypeOption = data.data;
    },
    updatePlan: (state, data) => {
      // console.log("MASUUUKKK", data);

      state.plan = data;
      if (data.length > 0) {
        state.selectedPlan = data[0];
      }
      // state.deployParams.plan_id = state.selectedPlan.id;
      // state.totalPayment = state.selectedPlan.price_monthly;
    },
    resetSelectedPlan: state => {
      state.selectedPlan = null;
    },
    changePlan: (state, data) => {
      const planFilter = state.plan.filter(plan => plan.memory == data);

      if (planFilter.length > 0) {
        state.selectedPlan = planFilter[0];
        // state.totalPayment = state.selectedPlan.price_monthly;
      } else {
        // state.deployParams.plan_id = null;
        state.selectedPlan = {
          cpu: null,
          disk_size: null,
          id: null,
          max_volume_size: null,
          memory: null,
          name: null,
          price_hourly: null,
          price_monthly: null,
          quota_ip_address: 0,
          type: null
        };
      }
    },
    filterPlan: (state, data) => {
      const planFilter = state.plan.filter(plan => plan.memory > data);
      state.plan = planFilter;
      state.selectedPlan = planFilter[0];
    }
  },

  namespaced: true
};
