<template>
  <div class="rounded-md">
    <div class="flex items-center">
      <div class="w-5 h-5 loader"></div>
      <!-- <div class="">cek</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderMini"
};
</script>

<style lang="scss">
.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    z-index: 0;
    transform: rotate(0deg);
  }
  100% {
    z-index: 1;
    transform: rotate(360deg);
  }
}
</style>
