<template>
  <div @click="closeAll()">
    <sidebar v-if="!token && !partnerId && !iframe" />
    <div
      v-if="iframe || (!token && !partnerId)"
      :class="[
        !token && !partnerId ? 'flex flex-col' : '',
        {
          'min-content': minimize && !token && !partnerId,
          content: !minimize && !token && !partnerId
        }
      ]"
    >
      <navbar v-if="vm_detail && plan && !token && !partnerId" />
      <div
        class="flex-1 relative"
        :class="!token && !partnerId ? ' px-10 pt-4 pb-6' : ''"
      >
        <div class v-if="vm_detail && plan">
          <!-- head -->
          <div class="flex justify-between items-start mb-4">
            <div class="flex justify-start">
              <div
                class="w-6 h-6 mt-1 rounded-full bg-orange flex items-center justify-center text-sm"
                v-if="!token && !partnerId"
                @click="$router.back()"
              >
                <i class="fas fa-chevron-left text-white cursor-pointer"></i>
              </div>
              <div class="mx-6">
                <div class="font-nunito text-xl font-bold">
                  {{ vm_detail.hostname }}
                </div>
                <div class="flex items-center">
                  <div class="w-4 mr-2">
                    <img src="@/assets/icon/ic_location.png" alt="" />
                  </div>
                  <div class="text-sm" v-if="vm_detail.details.zone.name">
                    {{ vm_detail.details.zone.name }}
                  </div>
                </div>
              </div>
              <div class="inline-block">
                <div
                  class="flex px-4 rounded-full py-1 items-center"
                  :class="statusVM(vm_detail.status)"
                >
                  <div
                    class="w-2 h-2 rounded-full mr-2"
                    v-if="
                      vm_detail.status === 'poweroff' ||
                        vm_detail.status === 'running'
                    "
                    :class="[
                      { 'bg-red': vm_detail.status === 'poweroff' },
                      { 'bg-blue': vm_detail.status === 'running' }
                    ]"
                  ></div>
                  <loader-mini
                    v-if="vm_detail.status === 'pending'"
                    class="mr-2"
                  ></loader-mini>
                  <div class="text-sm">{{ vm_detail.status }}</div>
                </div>
              </div>
            </div>
            <div class="flex items-center">
              <div class="inline-block mr-8">
                <button
                  v-if="vm_detail.status === 'running'"
                  class="p-2 text-sm text-white rounded-lg"
                  :class="iframe ? 'bg-dnBlue' : 'bg-orange'"
                  @click="launch = true"
                >
                  Launch Console
                </button>
                <button
                  v-else
                  class="bg-grayForm p-2 text-sm text-grayText rounded-lg cursor-not-allowed"
                >
                  Launch Console
                </button>
              </div>

              <div
                v-if="!disableAction.start"
                class="mr-8 relative"
                @click="
                  (confirmation.active = true),
                    (confirmation.data = {
                      id: id,
                      status: vm_detail.status,
                      type: 'startVM'
                    }),
                    (confirmation.textConfirmation =
                      'Are you sure you want to start VM ? ')
                "
                @mouseenter="vmAction.start = true"
                @mouseleave="vmAction.start = false"
              >
                <i
                  class="fas fa-play text-green text-xl opacity-50 hover:opacity-100 cursor-pointer"
                ></i>
                <div
                  v-if="vmAction.start"
                  class="absolute bg-darkBlueText w-20 rounded-md"
                  style="top: 40px; right: -27px"
                >
                  <div class="relative">
                    <div class="text-white text-center text-sm py-1">
                      Start VM
                    </div>
                    <div
                      class="absolute left-0 right-0 text-center text-darkBlueText"
                      style="top: -10px"
                    >
                      <i class="fas fa-sort-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mr-8 relative" v-else>
                <i
                  class="fas fa-play text-gray text-xl opacity-100 cursor-not-allowed"
                ></i>
              </div>
              <!-- restart action -->
              <div
                v-if="!disableAction.restart"
                class="w-5 h-5 mr-8 flex items-center justify-center col-span-1 opacity-50 hover:opacity-100 cursor-pointer relative"
                @click="
                  (confirmation.active = true),
                    (confirmation.data = {
                      id: id,
                      status: vm_detail.status,
                      type: 'restartVM'
                    }),
                    (confirmation.textConfirmation =
                      'Are you sure you want to Restart VM ? ')
                "
                @mouseenter="vmAction.restart = true"
                @mouseleave="vmAction.restart = false"
              >
                <i class="fas fa-redo-alt text-black text-xl"></i>
                <div
                  class="absolute bg-darkBlueText w-24 rounded-md"
                  style="top: 40px; right: -37px"
                  v-if="vmAction.restart"
                >
                  <div class="relative">
                    <div class="text-white text-center text-sm py-1">
                      Restart VM
                    </div>
                    <div
                      class="absolute left-0 right-0 text-center text-darkBlueText"
                      style="top: -10px"
                    >
                      <i class="fas fa-sort-up"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="w-5 h-5 mr-8 flex items-center justify-center col-span-1 opacity-100 cursor-not-allowed relative"
              >
                <i class="fas fa-redo-alt text-gray text-xl"></i>
              </div>
              <!-- stop action -->
              <div
                v-if="!disableAction.stop"
                class="w-5 h-5 flex items-center justify-center col-span-1 opacity-50 hover:opacity-100 cursor-pointer relative"
                @click="
                  (confirmation.active = true),
                    (confirmation.data = {
                      id: id,
                      status: vm_detail.status,
                      type: 'stopVM'
                    }),
                    (confirmation.textConfirmation =
                      'Are you sure you want to stop VM ? ')
                "
                @mouseenter="vmAction.stop = true"
                @mouseleave="vmAction.stop = false"
              >
                <!-- <img src="@/assets/icon/ic_delete.png" alt="" /> -->
                <i class="fas fa-power-off text-xl text-red"></i>
                <div
                  class="absolute bg-darkBlueText w-20 right-0 rounded-md"
                  style="top: 40px"
                  v-if="vmAction.stop"
                >
                  <div class="relative">
                    <div class="text-white text-center text-sm py-1">
                      Stop VM
                    </div>
                    <div
                      class="absolute right-0 text-center text-darkBlueText"
                      style="top: -10px; right: 6px"
                    >
                      <i class="fas fa-sort-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="w-5 h-5 flex items-center justify-center col-span-1 opacity-100 cursor-not-allowed relative"
              >
                <!-- <img src="@/assets/icon/ic_delete.png" alt="" /> -->
                <i class="fas fa-power-off text-xl text-gray"></i>
              </div>
            </div>
          </div>
          <!-- description -->
          <div class="py-4 bg-grayForm px-4 rounded-lg">
            <div
              class="grid grid-cols-10 mb-2 pb-4 border-opacity-50 border-b border-gray"
            >
              <div
                class="col-span-3 border-r border-gray border-opacity-50 px-4"
              >
                <div class="text-lg text-black font-bold mb-4">Summary</div>
                <div class="grid grid-cols-2 gap-2 text-darkBlueText text-md">
                  <div class="p-1 truncate">
                    {{
                      vm_detail.details.template.name
                        ? vm_detail.details.template.name
                        : "-"
                    }}
                  </div>
                  <div class="p-1 truncate">
                    {{ vm_detail.details.vcpu ? vm_detail.details.vcpu : "-" }}
                    CPU {{ vm_detail.details.vcpu > 1 ? "Cores" : "Core" }}
                  </div>
                  <div class="p-1 truncate">
                    {{
                      vm_detail.details.memory ? vm_detail.details.memory : "-"
                    }}
                    GB RAM
                  </div>
                  <div class="p-1 truncate">
                    {{
                      vm_detail.details.disk_size
                        ? vm_detail.details.disk_size
                        : "-"
                    }}
                    GB Storage
                  </div>
                </div>
                <!-- <div class="" v-if="!vir.public_dns">Public DNS : -</div> -->
              </div>
              <!-- IP Address -->
              <div class="col-span-3 border-r border-gray px-4">
                <div class="text-lg text-black font-bold mb-4">
                  IP Addresses
                </div>
                <div class="w-full mb-2">
                  <div
                    class="flex"
                    v-if="
                      IPDescription.length > 0 && IPDescription[0].ipv4_address
                    "
                  >
                    <div class="py-1 px-2 truncate bg-white flex-1">
                      {{
                        IPDescription.length > 0 &&
                        IPDescription[0].ipv4_address
                          ? IPDescription[0].ipv4_address
                          : "-"
                      }}
                    </div>
                    <div
                      class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative"
                      @click="copySucces('ipv4')"
                    >
                      <img
                        src="@/assets/icon/ic_copy.png"
                        class="w-5"
                        alt=""
                        v-clipboard:copy="IPDescription[0].ipv4_address"
                      />
                      <div
                        v-if="clip.ipv4"
                        class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                      >
                        Copied
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full mb-2">
                  <div
                    class="flex"
                    v-if="
                      IPDescription.length > 0 && IPDescription[0].ipv6_address
                    "
                  >
                    <div class="py-1 px-2 truncate bg-white flex-1">
                      {{
                        IPDescription.length > 0 &&
                        IPDescription[0].ipv6_address
                          ? IPDescription[0].ipv6_address
                          : "-"
                      }}
                    </div>
                    <div
                      class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative"
                      @click="copySucces('ipv6')"
                    >
                      <img
                        src="@/assets/icon/ic_copy.png"
                        class="w-5"
                        alt=""
                        v-clipboard:copy="IPDescription[0].ipv6_address"
                      />
                      <div
                        v-if="clip.ipv6"
                        class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                      >
                        Copied
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Access -->
              <div class="col-span-4 px-4">
                <div class="text-lg text-black font-bold mb-4">Access</div>
                <!-- <div class="w-full mb-2">
                  <div class="flex">
                    <div
                      class="bg-gray rounded-l w-32 py-1 px-2 flex-none text-sm"
                    >
                      Console Password
                    </div>
                    <div class="py-1 px-2 truncate bg-white flex-1">
                      {{ vncPassword ? vncPassword : 'Error' }}
                    </div>
                    <div
                      class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative"
                      @click="copySucces('vnc')"
                    >
                      <img
                        src="@/assets/icon/ic_copy.png"
                        class="w-5"
                        alt=""
                        v-clipboard:copy="vncPassword"
                      />
                      <div
                        v-if="clip.vnc"
                        class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                      >
                        Copied
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="w-full mb-2">
                  <div
                    class="flex"
                    v-if="
                      IPDescription.length > 0 && IPDescription[0].ipv4_address
                    "
                  >
                    <div class="bg-gray rounded-l w-32 py-1 px-2 flex-none">
                      SSH Access
                    </div>
                    <div class="py-1 px-2 truncate bg-white flex-1">
                      ssh root@{{
                        IPDescription.length > 0 &&
                        IPDescription[0].ipv4_address
                          ? IPDescription[0].ipv4_address
                          : "-"
                      }}
                    </div>
                    <div
                      class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative"
                      @click="copySucces('ssh')"
                    >
                      <img
                        src="@/assets/icon/ic_copy.png"
                        class="w-5"
                        alt=""
                        v-clipboard:copy="
                          'ssh root@' + IPDescription[0].ipv4_address
                        "
                      />
                      <div
                        v-if="clip.ssh"
                        class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                      >
                        Copied
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="flex" v-if="vm_detail.details.public_domain">
                    <div class="bg-gray rounded-l w-32 py-1 px-2 flex-none">
                      Public DNS
                    </div>
                    <div class="py-1 px-2 truncate bg-white flex-1">
                      {{
                        vm_detail.details.public_domain
                          ? vm_detail.details.public_domain
                          : "-"
                      }}
                    </div>
                    <div
                      class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative"
                      @click="copySucces('dns')"
                    >
                      <img
                        src="@/assets/icon/ic_copy.png"
                        class="w-5"
                        alt=""
                        v-clipboard:copy="vm_detail.details.public_domain"
                      />
                      <div
                        v-if="clip.dns"
                        class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                      >
                        Copied
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex text-sm text-darkBlueText">
              <div class="px-2 border-r border-gray">
                <span class="font-bold text-black">Plan : </span>
                {{ vm_detail.plan.name ? vm_detail.plan.name : "-" }}
              </div>
              <div class="px-2 border-r border-gray">
                <span class="font-bold text-black">Region : </span>
                {{
                  vm_detail.details.zone.name
                    ? vm_detail.details.zone.name
                    : "-"
                }}
              </div>
              <div class="px-2 border-r border-gray">
                <span class="font-bold text-black">Created : </span>
                {{ vm_detail.created_at ? vm_detail.created_at : "-" }}
              </div>
            </div>
          </div>

          <!-- panel & content  -->
          <div class="">
            <div class="py-6 mr-16 text-dark-gray flex">
              <div
                class="px-3 py-2 rounded-lg cursor-pointer"
                @click="changePanel(pan)"
                :class="{
                  'text-black bg-grayForm ': panelState === pan,
                  'hover:text-black': panelState !== pan
                }"
                v-for="pan in panel"
                :key="pan"
              >
                {{ pan }}
              </div>
            </div>
            <!-- Access -->
            <div class="" v-if="panelState === 'Access'">
              <div
                v-if="vm_detail.status !== 'running'"
                class="flex justify-between mb-4 px-3 py-2 border border-dangerBorder bg-dangerForm rounded-lg text-dangerText"
              >
                <div class="flex">
                  <div class="w-6 mr-4">
                    <img src="@/assets/icon/ic_vmnotrunning.png" alt="" />
                  </div>
                  <div class="">VM Must Be In Running State!</div>
                </div>
                <!-- <div class="text-dangerBorder" @click="vmnotif = false">
                  <i class="far fa-times-circle"></i>
                </div> -->
              </div>
              <div class="py-10 px-6 shadow-lg rounded-md">
                <h1 class="text-black font-bold text-xl mb-2 font-nunito">
                  Console Acces
                </h1>
                <p class="text-darkBlueText mb-4">
                  Open up console VNC connection and access your Virtual Machine
                  screen. You will be able to use your Mouse and Keyboard to
                  remotely control your Virtual Machine.
                </p>

                <!-- <p class="text-darkBlueText mb-4">
                  Under Maintenance
                </p> -->
                <button
                  v-if="vm_detail.status === 'running'"
                  class="focus:outline-none px-4 py-2 rounded-lg text-white"
                  :class="iframe ? 'bg-dnBlue' : 'bg-orange'"
                  @click="launch = true"
                >
                  Launch Console
                </button>
                <button
                  v-else
                  class="focus:outline-none px-4 py-2 bg-dark-gray rounded-lg text-white cursor-not-allowed"
                >
                  Launch Console
                </button>
              </div>
            </div>
            <!-- end Access -->
            <!-- History -->
            <div class="flex-1 py-4" v-if="panelState === 'History'">
              <div class="py-10 px-6 shadow-lg rounded-md">
                <h1 class="text-black font-nunito font-bold text-xl mb-2">
                  Virtual Machine History
                </h1>
                <div class="" v-if="vmLogs && vmLogs.length != 0">
                  <div
                    class="grid grid-cols-5 px-4 py-4 border-b border-white2 text-darkBlueText"
                  >
                    <div class="col-span-4">Event</div>
                    <div class="">Time</div>
                  </div>
                  <div class="max-h-64 overflow-y-scroll">
                    <div
                      class="grid grid-cols-5 px-4 py-3 border-b border-white2 text-darkBlueText"
                      v-for="log in vmLogs"
                      :key="log.id"
                    >
                      <div class="col-span-4">{{ log.description }}</div>
                      <div class="">{{ log.created_at }}</div>
                    </div>
                  </div>
                </div>

                <!-- <table
                  class="table-fixed w-full mt-12"
                  v-if="vmLogs && vmLogs.length != 0"
                >
                  <thead>
                    <tr class="text-center bg-white-100 text-dark-gray">
                      <th class="w-1/2 font-normal py-2 px-4">
                        {{ log.description }}
                      </th>
                      <th class="w-1/2 font-normal py-2 px-4">
                        {{ log.created_at }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border border-white-100 text-slate"
                      v-for="log in vmLogs"
                      :key="log.id"
                    >
                      <td class="p-4">
                        <div>{{ log.description }}</div>
                      </td>
                      <td class="p-4 text-center">{{ log.created_at }}</td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
            <!-- end History -->
            <!-- Console Password -->
            <div class="flex-1 py-4" v-if="panelState === 'Root Password'">
              <div class="py-10 px-6 shadow-lg rounded-md">
                <h1 class="text-black font-nunito font-bold text-xl mb-2">
                  Reset Root Password
                </h1>
                <p class="text-darkBlueText mb-2">
                  Clicking Reset Root Password below will immediately change
                  your root password. Add your new root password in input form.
                  <br />
                  <br />Do you wish to proceed?
                </p>
                <div class="flex">
                  <input
                    type="password"
                    placeholder="New Root Password"
                    v-model="vm_password"
                    class="w-64 px-4 py-3 border border-gray rounded-lg mr-2 outline-none"
                  />
                  <button
                    class="focus:outline-none px-4 py-3 rounded-lg bg-grayText text-white cursor-not-allowed"
                    v-if="vm_password.length === 0"
                  >
                    Reset
                  </button>
                  <button
                    class="focus:outline-none px-4 py-3 rounded-lg text-white"
                    :class="iframe ? 'bg-dnBlue' : 'bg-orange'"
                    @click="changePasswordVM()"
                    v-if="vm_password.length > 0"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <!-- end Console Password -->
            <!-- Volume -->
            <div class="flex-1 py-4" v-if="panelState === 'Volume'">
              <div class="py-10 px-6 shadow-lg rounded-md">
                <div class="flex justify-between items-center">
                  <h1 class="text-black font-nunito font-bold text-xl mb-2">
                    Volumes
                  </h1>
                  <button
                    @click.prevent="addvolume.active = true"
                    class="px-3 py-1 hover:bg-orange hover:text-white border-orange border text-orange rounded-lg"
                  >
                    Add Volumes
                  </button>
                </div>

                <div
                  class=""
                  v-if="vm_detail.volume && vm_detail.volume.length > 0"
                >
                  <div
                    class="grid grid-cols-7 px-4 py-4 border-b border-white2 text-darkBlueText text-sm"
                  >
                    <div class="col-span-2">Name</div>
                    <!-- <div class="col-span-2">Persistent</div> -->
                    <div class="col-span-2">Size</div>
                    <div class="col-span-2">Target</div>
                    <div class=""></div>
                  </div>
                  <div
                    class="grid grid-cols-7 px-4 py-4 border-b border-white2 text-darkBlueText"
                    v-for="vim in vm_detail.volume"
                    :key="vim.id"
                  >
                    <div class="col-span-2 text-black">
                      {{ vim.name }}
                    </div>
                    <!-- <div class="col-span-2">{{ volume.persistent }}</div> -->
                    <div class="col-span-2 text-black">{{ vim.size }} GB</div>
                    <div class="col-span-1 text-black">{{ vim.target }}</div>
                    <div class="col-span-2 text-right">
                      <div
                        @click="
                          (confirmation.active = true),
                            (confirmation.data = {
                              volumeId: vim.id,
                              vmId: vm_detail.id,
                              status: vm_detail.status,
                              type: 'volume'
                            }),
                            (confirmation.textConfirmation =
                              'Are you sure you want to detach ' +
                              vim.name +
                              ' ?')
                        "
                        class="inline-block text-red border border-white2 px-2 text-xs py-1 rounded-full cursor-pointer mr-2"
                      >
                        Detach
                      </div>
                      <div
                        class="inline-block text-blue border border-white2 px-2 text-xs py-1 rounded-full cursor-pointer"
                        v-if="vim.target"
                        @click="
                          (config.active = true),
                            (config.data = {
                              name: vim.name,
                              target: vim.target
                            })
                        "
                      >
                        Show Config
                      </div>
                    </div>
                  </div>
                </div>

                <p v-else class="text-dark-gray mt-4">
                  Looks like you don't have any Volume yet
                </p>
              </div>
            </div>
            <!-- end Volume -->
            <!-- Network -->
            <div class="flex-1 py-4" v-if="panelState === 'Network'">
              <div class="py-10 px-6 shadow-lg rounded-md">
                <div class="flex justify-between items-center">
                  <h1 class="text-black font-nunito font-bold text-xl mb-2">
                    Allocated IP Addresses
                  </h1>
                  <!-- <button
                    class="border border-orange text-orange px-4 py-2 hover:bg-orange hover:text-white rounded-lg font-whitney-semibold text-sm"
                    @click.prevent="addIpTemplate = true"
                  >
                    Add IP
                  </button> -->
                </div>

                <div
                  class=""
                  v-if="
                    vm_detail.ip_address && vm_detail.ip_address.length != 0
                  "
                >
                  <div
                    class="grid grid-cols-9 px-4 py-4 border-b border-white2 text-darkBlueText text-sm"
                  >
                    <div class="col-span-2">No</div>
                    <div class="col-span-2">IPv4</div>
                    <div class="col-span-2">IPv6</div>
                    <div class="col-span-2">Type</div>
                    <div class=""></div>
                  </div>
                  <div
                    class="grid grid-cols-9 px-4 py-4 border-b border-white2 text-darkBlueText"
                    v-for="(ip, index) in vm_detail.ip_address"
                    :key="ip.id"
                  >
                    <div class="col-span-2 text-black">{{ index + 1 }}</div>
                    <div class="col-span-2 text-black" v-if="ip.ipv4_address">
                      {{ ip.ipv4_address }}
                    </div>
                    <div class="col-span-2" v-if="!ip.ipv4_address">null</div>
                    <div class="col-span-2 text-black" v-if="ip.ipv6_address">
                      {{ ip.ipv6_address }}
                    </div>
                    <div class="col-span-2" v-if="!ip.ipv6_address">null</div>
                    <div class="col-span-2">
                      {{
                        ip.name.substring(0, 7) === "default" ? "Main" : "Addon"
                      }}
                    </div>
                    <div class="" v-if="ip.name.substring(0, 7) !== 'default'">
                      <div
                        @click="
                          (confirmation.active = true),
                            (confirmation.data = {
                              ipId: ip.id,
                              type: 'network'
                            }),
                            (confirmation.textConfirmation =
                              'Are you sure you want to delete?')
                        "
                        class="inline-block text-red border border-white2 px-2 text-xs py-1 rounded-full cursor-pointer"
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  v-if="
                    vm_detail.ip_address && vm_detail.ip_address.length === 0
                  "
                  class="text-dark-gray mt-4"
                >
                  Looks like you don't addon IP yet
                </p>
              </div>
            </div>
            <!-- end Network -->
            <!-- Resize -->
            <div class="flex-1 py-4" v-if="plan && panelState === 'Resize'">
              <div class="pt-10 pb-24 px-6 shadow-lg rounded-md">
                <h1 class="text-black font-nunito font-bold text-xl mb-2">
                  Resize
                </h1>

                <p class="text-darkBlueText mt-6 mb-6">
                  This instance is currently on {{ vm_detail.plan.type }} plan.
                  You must turn off your instance before resizing and you can't
                  resize to lower plans.
                </p>
                <!-- <div class="flex mb-6">
                  <div
                    class="bg-purple p-4 flex items-center justify-center rounded-l-lg w-24"
                  >
                    <img src="@/assets/icon/ic_memory.png" alt="" />
                  </div>
                  <div
                    class="flex-1 rounded-r-lg flex p-4 bg-purpleLight items-center justify-between"
                  >
                    <div class="">
                      <div class="text-black">General Purpose</div>
                      <div class="text-black text-sm">
                        Rp
                        <span class="text-base text-orange font-whitney-bold">{{
                          selectedPlan.price_monthly
                            ? formatRupiah(
                                selectedPlan.price_monthly.toString()
                              )
                            : formatRupiah(
                                vm_detail.plan.price_monthly.toString()
                              )
                        }}</span>
                        /month
                      </div>
                      <div class="text-darkBlueText text-xs">
                        Rp
                        {{
                          selectedPlan.price_hourly
                            ? formatRupiah(selectedPlan.price_hourly.toString())
                            : formatRupiah(
                                vm_detail.plan.price_hourly.toString()
                              )
                        }}
                        /hour
                      </div>
                    </div>
                    <div class="grid grid-cols-4 gap-2">
                      
                      <div class="bg-white p-2 text-center rounded-md">
                        <div class="text-darkBlueText">CPU</div>
                        <div class="text-black font-whitney-bold">
                          {{
                            selectedPlan.vcpu
                              ? selectedPlan.vcpu
                              : vm_detail.plan.vcpu
                          }}
                        </div>
                      </div>
                      <div class="bg-white p-2 text-center rounded-md">
                        <div class="text-darkBlueText">Memory</div>
                        <div class="text-black font-whitney-bold">
                          {{
                            selectedPlan.memory
                              ? selectedPlan.memory
                              : vm_detail.plan.memory
                          }}
                          GB
                        </div>
                      </div>
                      <div class="bg-white p-2 text-center rounded-md">
                        <div class="text-darkBlueText">Disk Size</div>
                        <div class="text-black font-whitney-bold">
                          {{
                            selectedPlan.disk_size
                              ? selectedPlan.disk_size
                              : vm_detail.plan.disk_size
                          }}
                          GB
                        </div>
                      </div>
                    </div>
                    <div class="inline-block">
                      <button
                        class="p-2 bg-orange rounded-lg text-white"
                        v-if="selectedPlan && sliderActive.value > 0"
                        @click="
                          (confirmation.active = true),
                            (confirmation.data = {
                              type: 'resize',
                            }),
                            (confirmation.textConfirmation =
                              'Are you sure you want to resize ?')
                        "
                      >
                        Resize Plan
                      </button>
                      <button
                        class="p-2 bg-grayText rounded-lg text-white"
                        v-else
                      >
                        Resize Plan
                      </button>
                    </div>
                  </div>
                </div>
                <div class="px-4" v-if="plan">
                  <vue-slider
                    ref="sliderActive"
                    v-model="sliderActive.value"
                    v-bind="sliderActive.option"
                    :order="false"
                  ></vue-slider>
                </div> -->
                <div class="grid grid-cols-8 gap-4" v-if="plan">
                  <div class="col-span-6">
                    <package />
                  </div>
                  <div class="col-span-2">
                    <div class="">
                      <div class="mt-4 pb-16 sticky">
                        <!-- billings  -->
                        <div class="">
                          <div class="p-4 billing rounded-lg mx-4">
                            <h2
                              class="font-nunito text-white font-bold text-xl mb-2"
                            >
                              Prorate
                            </h2>
                            <div class="mb-2">
                              <h3 class="text-white mb-2">Package</h3>
                              <div
                                class="flex justify-between text-white text-sm mb-2"
                                v-if="prorate.oldplan"
                              >
                                <div class="">Old Package</div>
                                <div class="flex-none">
                                  {{ prorate.oldplan }}
                                </div>
                              </div>
                              <div
                                class="flex justify-between text-white text-sm mb-2"
                                v-if="prorate.newplan"
                              >
                                <div class="">New Package</div>
                                <div class="flex-none">
                                  {{ prorate.newplan }}
                                </div>
                              </div>
                              <div
                                class="text-white text-sm mb-2"
                                v-if="prorate.daysuntilrenewal"
                              >
                                Renewal in {{ prorate.daysuntilrenewal }} days
                              </div>
                            </div>

                            <div
                              class="py-2 border-b border-t border-dashed border-white text-white border-opacity-25 mb-2"
                            >
                              <div class="text-sm flex justify-between mb-2">
                                <div class="">Subtotal</div>

                                <div class="" v-if="price">
                                  Rp.
                                  {{
                                    price
                                      ? formatRupiah(price.toString())
                                      : "0.00"
                                  }}
                                </div>
                              </div>
                              <div
                                class="text-sm flex justify-between"
                                v-if="ppn && !deployParams.apply_credit"
                              >
                                <div class="">PPN 10%</div>
                                <div
                                  class=""
                                  v-if="ppn && !deployParams.apply_credit"
                                >
                                  Rp.
                                  {{
                                    ppn ? formatRupiah(ppn.toString()) : "0.00"
                                  }}
                                </div>
                              </div>
                            </div>

                            <div class="flex justify-between">
                              <h3 class="text-white mb-2">Total</h3>

                              <h3 class="text-white mb-2" v-if="total">
                                Rp.
                                {{
                                  total
                                    ? formatRupiah(total.toString())
                                    : "0.00"
                                }}
                              </h3>
                            </div>

                            <div
                              class="p-2 border border-white rounded-lg text-sm text-white mb-6"
                              v-if="
                                total &&
                                  selectedPlan.memory !== vm_detail.plan.memory
                              "
                            >
                              <div
                                class="flex items-center mb-2 cursor-pointer"
                                @click="
                                  $store.commit(
                                    'deployVM/changeApplyCredit',
                                    true
                                  )
                                "
                              >
                                <div
                                  class="w-4 h-4 rounded-full border hover:border-orange cursor-pointer flex items-center justify-center mr-2"
                                  :class="
                                    deployParams.apply_credit
                                      ? 'border-white'
                                      : 'border-gray'
                                  "
                                >
                                  <div
                                    v-if="deployParams.apply_credit"
                                    class="w-2 h-2 rounded-full bg-white"
                                  ></div>
                                </div>
                                <div class="">Apply balance</div>
                              </div>
                              <div
                                class="flex items-center cursor-pointer"
                                @click="
                                  $store.commit(
                                    'deployVM/changeApplyCredit',
                                    false
                                  )
                                "
                              >
                                <div
                                  class="w-4 h-4 rounded-full border hover:border-orange cursor-pointer flex items-center justify-center mr-2"
                                  :class="
                                    deployParams.apply_credit
                                      ? 'border-gray'
                                      : 'border-white'
                                  "
                                >
                                  <div
                                    v-if="!deployParams.apply_credit"
                                    class="w-2 h-2 rounded-full bg-white"
                                  ></div>
                                </div>
                                <div class="">Direct payment</div>
                              </div>
                            </div>
                            <button
                              class="w-full py-2 text-white border border-white bg-none rounded-lg focus:outline-none hover:bg-white hover:text-orange"
                              v-if="prorate.price && !confirmation.active"
                              @click="
                                (confirmation.active = true),
                                  (confirmation.data = {
                                    type: 'resize'
                                  }),
                                  (confirmation.textConfirmation =
                                    'Are you sure you want to resize ?')
                              "
                            >
                              Order Now
                            </button>
                            <button
                              class="w-full py-2 text-darkBlueText bg-white rounded-lg focus:outline-none cursor-not-allowed"
                              v-else
                            >
                              Order Now
                            </button>
                          </div>
                        </div>
                        <!-- end billings  -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end Resize -->

            <!-- Backup -->
            <div class="flex-1 py-4" v-if="panelState === 'Images'">
              <div class="py-10 px-6 shadow-lg rounded-md">
                <h1 class="text-black font-nunito font-bold text-xl mb-2">
                  Backup Images
                </h1>
                <p class="text-darkBlueText mb-2">
                  Clicking Backup below will immediately create a backup image.
                  <br />

                  <br />Do you wish to proceed?
                </p>
                <div class="flex">
                  <input
                    type="name"
                    placeholder="Backup image name"
                    v-model="backupimage"
                    class="px-4 py-3 border border-gray mr-4 w-64 rounded-lg"
                  />
                  <button
                    class="focus:outline-none px-4 py-3 bg-grayText text-white cursor-not-allowed rounded-lg"
                    v-if="backupimage.length === 0"
                  >
                    Backup
                  </button>
                  <button
                    class="focus:outline-none px-4 py-3 bg-orange text-white rounded-lg"
                    v-if="backupimage.length > 0"
                    @click="backupImageVM()"
                  >
                    Backup
                  </button>
                </div>
              </div>
            </div>
            <!-- end Backup -->
            <!-- Chart -->
            <div class="flex-1 py-4" v-if="panelState === 'Graph'">
              <div class="">
                <div class="flex justify-end mb-4">
                  <div
                    class="flex items-center cursor-pointer"
                    @click="refreshMetrics()"
                  >
                    <div class="w-5 mr-2">
                      <img src="@/assets/icon/ic_refreshData.png" alt="" />
                    </div>
                    <div class="text-darkBlueText text-md">Refresh Data</div>
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-4">
                  <!-- cpu -->
                  <div
                    class="border rounded-lg border-white2 p-4"
                    :class="
                      extend === 'cpu'
                        ? 'col-span-3 order-1'
                        : 'col-span-1 order-2'
                    "
                  >
                    <div class="flex justify-between">
                      <h2 class="text-lg text-black font-nunito font-bold mb-4">
                        CPU
                      </h2>
                      <div
                        class="w-5"
                        v-if="
                          typeof chart.cpu === 'object' &&
                            chart.cpu !== null &&
                            Object.keys(chart.cpu).length !== 0 &&
                            extend !== 'cpu'
                        "
                        @click="(extend = 'cpu'), refreshMetrics()"
                      >
                        <img
                          src="@/assets/icon/ic_zoom.png"
                          class="cursor-pointer"
                          alt=""
                        />
                      </div>
                      <div
                        class="text-lg text-grayText cursor-pointer"
                        v-if="
                          typeof chart.cpu === 'object' &&
                            chart.cpu !== null &&
                            Object.keys(chart.cpu).length !== 0 &&
                            extend === 'cpu'
                        "
                        @click="(extend = null), refreshMetrics()"
                      >
                        <i class="far fa-times-circle"></i>
                      </div>
                    </div>

                    <area-chart
                      v-if="
                        typeof chart.cpu === 'object' &&
                          chart.cpu !== null &&
                          Object.keys(chart.cpu).length !== 0
                      "
                      :data="chart.cpu"
                      :colors="['#2793FF']"
                      height="200px"
                      class="mx-auto mb-4 w-full"
                    />
                    <div
                      class="flex items-center justify-center text-dark-gray text-md py-20"
                      v-else-if="
                        typeof chart.cpu === 'object' &&
                          chart.cpu !== null &&
                          Object.keys(chart.cpu).length === 0
                      "
                    >
                      Can't Get Data CPU
                    </div>
                    <div
                      class="flex items-center justify-center"
                      v-if="!chart.cpu"
                    >
                      <loader />
                      <div class="ml-4 text-dark-gray text-md py-10">
                        Loading CPU Graph
                      </div>
                    </div>
                  </div>

                  <!-- Disk -->
                  <div
                    class="border rounded-lg border-white2 p-4"
                    :class="
                      extend === 'disk'
                        ? 'col-span-3 order-1'
                        : 'col-span-1 order-2'
                    "
                  >
                    <div class="flex justify-between">
                      <h2 class="text-lg text-black font-nunito font-bold mb-4">
                        Disk I/O
                      </h2>
                      <div
                        class="w-5"
                        v-if="
                          typeof chart.disk[0].data === 'object' &&
                            typeof chart.disk[1].data === 'object' &&
                            chart.disk[0].data !== null &&
                            chart.disk[1].data !== null &&
                            Object.keys(chart.disk[0].data).length !== 0 &&
                            Object.keys(chart.disk[1].data).length !== 0 &&
                            extend !== 'disk'
                        "
                        @click="(extend = 'disk'), refreshMetrics()"
                      >
                        <img
                          src="@/assets/icon/ic_zoom.png"
                          class="cursor-pointer"
                          alt=""
                        />
                      </div>
                      <div
                        class="text-lg text-grayText cursor-pointer"
                        v-if="
                          typeof chart.disk[0].data === 'object' &&
                            typeof chart.disk[1].data === 'object' &&
                            chart.disk[0].data !== null &&
                            chart.disk[1].data !== null &&
                            Object.keys(chart.disk[0].data).length !== 0 &&
                            Object.keys(chart.disk[1].data).length !== 0 &&
                            extend === 'disk'
                        "
                        @click="(extend = null), refreshMetrics()"
                      >
                        <i class="far fa-times-circle"></i>
                      </div>
                    </div>

                    <area-chart
                      v-if="
                        typeof chart.disk[0].data === 'object' &&
                          typeof chart.disk[1].data === 'object' &&
                          chart.disk[0].data !== null &&
                          chart.disk[1].data !== null &&
                          Object.keys(chart.disk[0].data).length !== 0 &&
                          Object.keys(chart.disk[1].data).length !== 0
                      "
                      :data="chart.disk"
                      :colors="['#E81933', '#2793FF']"
                      height="235px"
                      legend="bottom"
                      class="mx-auto w-full mb-4"
                    />
                    <div
                      class="flex items-center justify-center text-dark-gray text-md py-20"
                      v-else-if="
                        typeof chart.disk[0].data === 'object' &&
                          typeof chart.disk[1].data === 'object' &&
                          chart.disk[0].data !== null &&
                          chart.disk[1].data !== null &&
                          Object.keys(chart.disk[0].data).length === 0 &&
                          Object.keys(chart.disk[1].data).length === 0
                      "
                    >
                      Can't Get Data Disk
                    </div>
                    <div
                      class="flex items-center justify-center"
                      v-if="!chart.disk[0].data && !chart.disk[1].data"
                    >
                      <loader />
                      <div class="ml-4 text-dark-gray text-md py-10">
                        Loading Disk I/O
                      </div>
                    </div>
                  </div>

                  <!-- bandwidth -->
                  <div
                    class="border rounded-lg border-white2 p-4"
                    :class="
                      extend === 'bandwidth'
                        ? 'col-span-3 order-1'
                        : 'col-span-1 order-3'
                    "
                  >
                    <div class="flex justify-between">
                      <h2 class="text-lg text-black font-nunito font-bold mb-4">
                        Bandwidth
                      </h2>
                      <div
                        class="w-5"
                        v-if="
                          typeof chart.bandwidth[0].data === 'object' &&
                            typeof chart.bandwidth[1].data === 'object' &&
                            chart.bandwidth[0].data !== null &&
                            chart.bandwidth[1].data !== null &&
                            Object.keys(chart.bandwidth[0].data).length !== 0 &&
                            Object.keys(chart.bandwidth[1].data).length !== 0 &&
                            extend !== 'bandwidth'
                        "
                        @click="(extend = 'bandwidth'), refreshMetrics()"
                      >
                        <img
                          src="@/assets/icon/ic_zoom.png"
                          class="cursor-pointer"
                          alt=""
                        />
                      </div>
                      <div
                        class="text-lg text-grayText cursor-pointer"
                        v-if="
                          typeof chart.bandwidth[0].data === 'object' &&
                            typeof chart.bandwidth[1].data === 'object' &&
                            chart.bandwidth[0].data !== null &&
                            chart.bandwidth[1].data !== null &&
                            Object.keys(chart.bandwidth[0].data).length !== 0 &&
                            Object.keys(chart.bandwidth[1].data).length !== 0 &&
                            extend === 'bandwidth'
                        "
                        @click="(extend = null), refreshMetrics()"
                      >
                        <i class="far fa-times-circle"></i>
                      </div>
                    </div>
                    <area-chart
                      v-if="
                        typeof chart.bandwidth[0].data === 'object' &&
                          typeof chart.bandwidth[1].data === 'object' &&
                          chart.bandwidth[0].data !== null &&
                          chart.bandwidth[1].data !== null &&
                          Object.keys(chart.bandwidth[0].data).length !== 0 &&
                          Object.keys(chart.bandwidth[1].data).length !== 0
                      "
                      :data="chart.bandwidth"
                      :colors="['#FFC400', '#6DC24B']"
                      height="235px"
                      legend="bottom"
                      class="mx-auto w-full mb-4"
                    />
                    <div
                      class="flex items-center justify-center text-dark-gray text-md py-20"
                      v-else-if="
                        typeof chart.bandwidth[0].data === 'object' &&
                          typeof chart.bandwidth[1].data === 'object' &&
                          chart.bandwidth[0].data !== null &&
                          chart.bandwidth[1].data !== null &&
                          Object.keys(chart.bandwidth[0].data).length === 0 &&
                          Object.keys(chart.bandwidth[1].data).length === 0
                      "
                    >
                      Can't Get Data Bandwith
                    </div>
                    <div
                      class="flex items-center justify-center"
                      v-if="
                        !chart.bandwidth[0].data && !chart.bandwidth[1].data
                      "
                    >
                      <loader />
                      <div class="ml-4 text-dark-gray text-md py-10">
                        Loading Bandwidth
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end Chart -->

            <!-- Reinstall -->
            <div class="flex-1 py-4" v-if="panelState === 'Reinstall'">
              <div class="pt-10 pb-24 px-6 shadow-lg rounded-md">
                <h1 class="text-black font-nunito font-bold text-xl mb-2">
                  Reinstall
                </h1>
                <p class="text-darkBlueText mb-2">
                  Reset main partition and create clean installation of selected
                  Operating System
                </p>

                <!-- pick OS -->
                <div class="mt-4">
                  <div
                    class="flex items-center justify-center text-sm mb-6"
                    v-if="Object.keys(template).length !== 0"
                  >
                    <div
                      class="py-2 px-3 cursor-pointer"
                      @click="selectTemplateOption('default')"
                      :class="
                        templateOption.default
                          ? 'bg-grayForm text-black rounded-lg'
                          : ''
                      "
                    >
                      Distribution
                    </div>
                    <!-- <div
                  class="py-2 px-3 cursor-pointer"
                  @click="selectTemplateOption('backup')"
                  :class="
                    templateOption.backup
                      ? 'bg-grayForm text-black rounded-lg'
                      : ''
                  "
                >
                  Backup
                </div> -->
                    <!-- <div
                  class="py-2 px-3 cursor-pointer"
                  @click="selectTemplateOption('app')"
                  :class="
                    templateOption.app
                      ? 'bg-grayForm text-black rounded-lg'
                      : ''
                  "
                >
                  Apps
                </div> -->
                  </div>
                  <!-- Distribution -->
                  <div class="">
                    <div
                      class="grid grid-cols-3 gap-3"
                      v-if="
                        templateOption === 'default' &&
                          Object.keys(template).length !== 0
                      "
                    >
                      <div
                        class="p-3 border rounded-lg"
                        v-for="(value, key) in template"
                        :key="key"
                        :class="
                          value.version ? 'border-orange' : 'border-white2'
                        "
                      >
                        <div
                          class="flex p-3 rounded-lg items-center bg-grayForm mb-2"
                        >
                          <div class="w-10 mr-4">
                            <img
                              src="@/assets/icon/ic_freebsd.png"
                              alt=""
                              v-if="key === 'bsd'"
                            />
                            <img
                              src="@/assets/icon/ic_opensuse.png"
                              alt=""
                              v-if="key === 'opensuse'"
                            />
                            <img
                              src="@/assets/icon/ic_ubuntu.png"
                              alt=""
                              v-if="key === 'ubuntu'"
                            />
                            <img
                              src="@/assets/icon/ic_fedora.png"
                              alt=""
                              v-if="key === 'fedora'"
                            />
                            <img
                              src="@/assets/icon/ic_debian.png"
                              alt=""
                              v-if="key === 'debian'"
                            />
                            <img
                              src="@/assets/icon/ic_centos.png"
                              alt=""
                              v-if="key === 'centos'"
                            />
                          </div>
                          <div class="">
                            <div class="font-whitney-semibold text-black">
                              {{ key.toUpperCase() }}
                            </div>

                            <!-- <div class="text-sm text-darkBlueText">
                            Last Version : 18.0.4
                          </div> -->
                          </div>
                        </div>
                        <div
                          class="p-3 rounded-lg items-center border border-white2 flex justify-between text-sm relative"
                          @click.stop="openTemplate(value)"
                        >
                          <div class="text-black" v-if="value.version">
                            {{ value.version }}
                          </div>
                          <div class="text-black" v-else>Select Version</div>
                          <div class="text-darkBlueText">
                            <i class="fas fa-chevron-down"></i>
                          </div>
                          <div
                            class="absolute right-0 left-0 text-center"
                            style="top: 50px; z-index: 100"
                            v-if="value.active"
                          >
                            <div
                              class="p-2 rounded-lg border border-grayForm bg-white"
                            >
                              <div
                                v-for="data in value.data"
                                :key="data.id"
                                @click="selectTemplate(data)"
                                class="text-black p-2 rounded-lg text-sm cursor-pointer hover:text-orange border-b border-grayForm last:border-none"
                              >
                                {{ data.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="text-center" v-else>Loading</div>
                  </div>

                  <!-- backup -->
                  <div
                    class=""
                    v-if="
                      templateOption.backup && images && images.length !== 0
                    "
                  >
                    <div
                      class="grid grid-cols-10 px-4 py-2 items-center rounded-lg cursor-pointer mb-2 last:mb-0"
                      v-for="image in images"
                      :key="image.id"
                      @click="pickImage(image)"
                      :class="{
                        'border border-white2': !image.active,
                        'border border-orange': image.active
                      }"
                    >
                      <div class="col-span-1 flex items-center">
                        <div
                          class="w-4 h-4 rounded-md border-2 flex items-center justify-center"
                          :class="{
                            'border-orange bg-orange': image.active,
                            'border-grayb': !image.active
                          }"
                        ></div>
                        <div class="ml-2 w-10 h-10 bg-grayForm rounded-md p-2">
                          <img
                            src="@/assets/icon/ic_fedora.png"
                            class="w-full"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="col-span-3">{{ image.name }}</div>
                      <div class="col-span-3 text-darkBlueText text-sm">
                        {{ image.group }} Ver. 20.04
                      </div>
                      <div
                        class="col-span-3 text-right text-darkBlueText text-sm"
                      >
                        Created on 23/09/2020
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-center"
                    v-if="templateOption.backup && !images"
                  >
                    Loading
                  </div>
                  <div
                    class="text-center"
                    v-if="
                      templateOption.backup && images && images.length === 0
                    "
                  >
                    You don't have backup images
                  </div>

                  <!-- apps -->
                  <div
                    v-if="templateOption.app"
                    class="text-center py-6 bg-gray text-dark-gray text-2xl font-bold border border-gray rounded-lg"
                  >
                    <i class="fas fa-tools mr-4"></i>Under Maintenance
                  </div>
                </div>

                <button
                  v-if="Object.keys(template).length !== 0 && reinstallButton"
                  class="w-full rounded-lg py-3 text-white opacity-80 hover:opacity-100 mt-4"
                  :class="iframe ? 'bg-dnBlue' : 'bg-orange'"
                  @click="
                    (confirmation.active = true),
                      (confirmation.data = {
                        type: 'reinstall'
                      }),
                      (confirmation.textConfirmation =
                        'Are you sure you want to reinstall ?')
                  "
                >
                  Reinstall VM
                </button>
                <button
                  v-if="!reinstallButton"
                  class="w-full rounded-lg py-3 text-white bg-grayText mt-4"
                >
                  Reinstall VM
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="vm_detail === undefined"
          class="flex flex-col flex-1 items-center justify-center min-h-screen"
        >
          <div class="text-2xl text-darkBlueText font-nunito font-bold mb-16">
            Error Getting your VM Data
          </div>
          <div class="mb-16">
            <img
              src="@/assets/img/img_nodata.png"
              class="w-2/3 mx-auto"
              alt=""
            />
          </div>
        </div>
        <loading-full :show="loading" />
      </div>
    </div>
    <div
      class="w-full h-screen flex justify-center items-center"
      v-if="token && partnerId && !iframe"
    >
      <div class="max-w-xl rounded-md bg-gray py-2 px-10 text-red">
        Error Getting Data
      </div>
    </div>

    <graph-zoom
      v-if="graphZoomData.active"
      @dismiss="graphZoomData.active = false"
      :data="graphZoomData.data"
      :name="graphZoomData.name"
      :colors="graphZoomData.colors"
    />
    <add-volume
      v-if="addvolume.active"
      :will_dismiss="addvolume.dismiss"
      @dismiss="addvolume.active = false"
      :data="{ vm_name: vm_detail.hostname, vmUUID: vm_detail.id }"
      @get="getdetail(id)"
    />
    <confirmation
      v-if="confirmation.active"
      @accept="chooseType(confirmation.data)"
      @dismiss="(confirmation.active = false), (confirmation.data = null)"
      :data="confirmation.data"
      :textConfirmation="confirmation.textConfirmation"
    />
    <addIp
      v-if="addIpTemplate"
      @dismiss="addIpTemplate = false"
      :vmId="id"
      @get="getdetail(id)"
    />
    <config-volume
      v-if="config.active"
      :volumeData="config.data"
      @dismiss="config.active = false"
    />
    <lauch-console
      :pwd="vncPassword"
      @accept="launchConsole()"
      v-model="launch"
    />
    <invoice-detail
      v-if="invoice.active"
      :snap="invoice.snap"
      :data="invoice.data"
      @dismiss="(invoice.active = false), (onTopup = false)"
      @changePayment="(invoice.active = false), getInvoiceDetail()"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import loader from "@/components/loaderMini.vue";
import confirmation from "@/components/confirmation.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import addIp from "@/components/addIp.vue";
import graphZoom from "@/components/graphZoom.vue";
import Pusher from "pusher-js";
import LoaderMini from "../../components/loaderMini.vue";
import AddVolume from "../../components/addVolume.vue";
import ConfigVolume from "../../components/configVolume.vue";
import Package from "../../components/VMCreate/package.vue";
import LauchConsole from "../../components/lauchConsole.vue";
import LoadingFull from "../../components/VMCreate/loadingFull.vue";
import InvoiceDetail from "../../components/invoiceDetail.vue";

export default {
  name: "DetailVM",
  components: {
    sidebar,
    navbar,
    loader,
    confirmation,
    addIp,
    graphZoom,
    LoaderMini,
    AddVolume,
    ConfigVolume,
    Package,
    LauchConsole,
    LoadingFull,
    InvoiceDetail
  },
  computed: {
    ...mapState([
      "vm_detail",
      "panel",
      "images",
      "panelState",
      "chart",
      "vmLogs",
      "vnc",
      "vncPassword",
      "minimize",
      "payment"
    ]),
    invoices() {
      return this.$store.state.invoice.invoices;
    },
    userdata() {
      return this.$store.state.userdata;
    },
    zoneList() {
      return this.$store.state.zone.zoneList;
    },
    deployParams() {
      return this.$store.state.deployVM.deployParams;
    },
    plan() {
      return this.$store.state.plan.plan;
    },
    selectedPlan() {
      return this.$store.state.plan.selectedPlan;
    },
    templateOption() {
      return this.$store.state.template.templateOption;
    },
    template() {
      return this.$store.state.template.template;
    },
    selectedTemplate() {
      return this.$store.state.template.selectedTemplate;
    },
    price() {
      let price;
      if (this.prorate.price) {
        price = this.prorate.price.replace("Rp", "");
        price = price.replace(",", "");
        price = price.replace(" IDR", "");
        price = parseFloat(price);
      }
      return price;
    },
    ppn() {
      if (this.price) {
        return parseInt(this.price * 0.1);
      }
      return null;
    },
    total() {
      if (!this.deployParams.apply_credit) {
        return this.price + this.ppn;
      } else if (this.deployParams.apply_credit) {
        return this.price;
      }
      return null;
    }
  },

  created() {
    document.title = "Nevacloud | Detail Virtual Machine";
    if (!this.$router.currentRoute.query.id) {
      this.$router.go(-1);
    } else {
      if (
        this.$router.currentRoute.query.token &&
        this.$router.currentRoute.query.vmtoken &&
        this.$router.currentRoute.query.partnerId
      ) {
        if (window.location !== window.parent.location) {
          this.iframe = true;
        } else {
          this.iframe = false;
        }
        this.token = '"' + this.$router.currentRoute.query.token + '"';
        JSON.stringify(this.token);
        this.partnerId = this.$router.currentRoute.query.partnerId;
        this.vmtoken = this.$router.currentRoute.query.vmtoken;
        this.id = {
          id: this.$router.currentRoute.query.id + "?token=" + this.vmtoken,
          token: this.token
        };
        this.changePanelContent([
          "Volume",
          "Network",
          "Backup",
          "Resize",
          "Images"
        ]);
      } else {
        this.id = this.$router.currentRoute.query.id;
      }
      this.getdetail(this.id);
      this.getVNCPassword(this.id);
      if (this.panelState === "Graph") {
        this.refreshMetrics();
      }
      // if (this.panelState === "Volume") {
      //   if (!this.volumeVM) {
      //     this.getVolume().then((response) => {
      //       let volumeHadVM = response.data.filter(
      //         (volume) => volume.virtual_machine
      //       );
      //       this.volumeVM = volumeHadVM.filter(
      //         (volume) => volume.virtual_machine.id === this.id
      //       );
      //     });
      //   }
      // }
      if (this.panelState === "Reinstall") {
        if (!this.zoneList) {
          this.getZone(this.id.token);
        }
      }
      if (this.panelState === "History") {
        this.clearVMLogs();
        this.getVMLogs();
      }
    }
  },
  data: function() {
    return {
      invoice: {
        active: false,
        snap: null,
        data: null
      },
      invoiceId: null,
      loading: true,
      launch: false,
      config: {
        active: false,
        data: null
      },
      addvolume: {
        active: false,
        dismiss: false
      },
      label: null,
      extend: null,
      vmAction: { start: false, restart: false, stop: false },
      disableAction: { start: false, restart: false, stop: false },
      clip: { ipv4: false, ipv6: false, ssh: false, dns: false, vnc: false },
      graphZoomData: {
        active: false,
        data: null,
        name: null,
        colors: null
      },
      IPDescription: [],
      iframe: false,
      token: false,
      vmtoken: false,
      partnerId: null,
      addIpTemplate: false,
      // vmnotif: false,
      vm_password: "",
      backupimage: "",
      id: null,
      plan_id: null,
      volumeVM: null,
      reinstallButton: true,
      confirmation: {
        active: false,
        data: null,
        textConfirmation: null
      },
      sliderActive: {
        value: 0,
        option: {
          dotSize: 24,
          disabled: false,
          height: 10,
          width: "auto",
          contained: true,
          min: 0,
          max: null,
          marks: {},
          duration: 0.5,
          tooltip: "none"
        }
      },
      sliderDisabled: {
        value: [0, 10],
        option: {
          dotSize: 24,
          height: 10,
          width: "auto",
          contained: true,
          data: [0, 10],
          marks: true,
          duration: 0.5,
          tooltip: "none",
          disabled: true
        }
      },
      prorate: {
        oldplan: null,
        newplan: null,
        daysuntilrenewal: null,
        price: null
      }
    };
  },
  mounted: function() {
    this.$store.commit("deployVM/resetDeployParams");
    this.$store.commit("zone/resetZoneList");
    this.$store.commit("template/resetTemplate");
    this.$store.commit("resetVNCPassword");
    // PUSHER
    let pusher;
    let channel;
    if (this.$cookies.isKey("userID") && this.$cookies.isKey("token")) {
      const token = this.$cookies.get("token");
      Pusher.logToConsole = false;
      pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        app_id: process.env.VUE_APP_PUSHER_APP_ID,
        secret: process.env.VUE_APP_PUSHER_SECRET,
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "Application/json"
          }
        }
      });
      window.pusher = pusher;
      channel = pusher.subscribe(
        "private-Vm.User." + this.$cookies.get("userID")
      );
      channel.bind("VirtualMachineRunning", data => {
        this.commitVMStatus(data);
      });
      channel.bind("VirtualMachinePoweroff", data => {
        this.commitVMStatus(data);
      });
    }
    if (
      this.$router.currentRoute.query.id &&
      this.$router.currentRoute.query.token
    ) {
      this.token = '"' + this.$router.currentRoute.query.token + '"';
      JSON.stringify(this.token);
      const token = JSON.parse(this.token);
      Pusher.logToConsole = false;
      pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        app_id: process.env.VUE_APP_PUSHER_APP_ID,
        secret: process.env.VUE_APP_PUSHER_SECRET,
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "Application/json"
          }
        }
      });
      window.pusher = pusher;
      channel = pusher.subscribe(
        "private-Vm." + this.$router.currentRoute.query.id
      );
      channel.bind("VirtualMachineRunning", data => {
        this.commitVMStatus(data);
      });
      channel.bind("VirtualMachinePoweroff", data => {
        this.commitVMStatus(data);
      });
    }

    this.getdetail(this.id);
    this.getVNCPassword(this.id);
  },
  watch: {
    "sliderActive.value": function(oldVal) {
      this.$store.commit(
        "plan/changePlan",
        this.sliderActive.option.marks[oldVal].split(" GB")[0]
      );
    },
    vm_detail: function() {
      let filter = this.vm_detail.ip_address.filter(res => {
        return res.name === "default_public";
      });
      this.IPDescription = filter;
    },
    selectedTemplate(e) {
      // console.log(e);
      this.deployParams.template_id = e.id;
      // this.deployParams.template_type =
    },
    selectedPlan() {
      if (this.vm_detail) {
        if (this.selectedPlan.memory !== this.vm_detail.plan.memory) {
          this.resizeVM(true);
        }
      }
    }
  },
  methods: {
    ...mapActions(["getImage"]),
    ...mapMutations(["resetVMDetail", "clearVMLogs"]),
    closeAll() {
      this.$store.commit("template/closeAlltemplate");
    },
    getPlan(data) {
      this.$store.dispatch("plan/getPlan", data).then(async () => {
        while (!this.vm_detail)
          await new Promise(resolve => setTimeout(resolve, 100));

        await this.$store.commit("plan/filterPlan", this.vm_detail.plan.memory);

        while (!this.total)
          await new Promise(resolve => setTimeout(resolve, 100));

        if (this.userdata && this.userdata.credit >= this.total) {
          this.$store.commit("deployVM/changeApplyCredit", true);
        }
      });
    },
    getZone(data) {
      this.$store.dispatch("zone/getZone", data).then(res => {
        if (res.status === 200) {
          let short_name = this.vm_detail.details.zone.short_name;
          this.changeZone(undefined, short_name);
        } else {
          alert("Error getting zone");
        }
      });
    },
    getTemplate(data) {
      this.$store.dispatch("template/getTemplate", data);
    },
    reinstallVM() {
      this.disableActionFunction(true);
      this.confirmation.active = false;
      this.reinstallButton = false;
      this.closeAll();
      if (!this.deployParams.template_id || !this.deployParams.template_type) {
        this.g_notif_create(false, "Failed", "Choose OS template");
        this.disableActionFunction(false);
        return;
      }
      this.g_loading_create("Loading");
      let data = {
        id: null,
        token: null
      };
      if (typeof this.id === "string") {
        data.id = this.id;
      } else {
        data.id = this.id;
        data.token = this.id.token;
      }
      this.$store.dispatch("deployVM/reinstallVM", data).then(response => {
        this.disableActionFunction(false);
        this.reinstallButton = true;
        this.g_loading_destroy();
        if (response.status === 200) {
          this.g_notif_create(
            true,
            "Success",
            "Installation finished successfully"
          );
          this.$store.commit("template/resetTemplate");
          this.$store.commit("deployVM/resetDeployTemplate");
          this.getZone(this.id.token);
        } else {
          if (response.error.message) {
            this.g_notif_create(false, "Failed", response.error.message);
          } else {
            this.g_notif_create(false, "Failed", response.message);
          }
        }
      });
    },
    statusVM(status) {
      if (status === "poweroff") {
        return "bg-red-10";
      } else if (status === "running") {
        return "bg-blue-10";
      } else {
        return "bg-grayForm";
      }
    },
    openTemplate(value) {
      this.$store.commit("template/openTemplate", value);
    },
    selectTemplate(data) {
      this.$store.commit("template/changeTemplate", { data });
    },
    changeZone(id, short_name) {
      this.$store.commit("zone/changeZone", { id: id, short_name: short_name });
      let zoneActive = this.zoneList.filter(zone => {
        return zone.active === true;
      });

      this.$store.commit("deployVM/changeZone", zoneActive[0].short_name);

      if (this.deployParams.zone) {
        this.getTemplate(this.id.token);
      }
    },
    chooseType(data) {
      if (data.type === "network") {
        this.detachNetwork(data);
      } else if (data.type === "volume") {
        this.detachVolume(data);
      } else if (data.type === "reinstall") {
        this.reinstallVM();
      } else if (data.type === "resize") {
        this.resizeVM();
      } else if (data.type === "startVM") {
        this.startVM(data.id, data.status);
      } else if (data.type === "stopVM") {
        this.stopVM(data.id, data.status);
      } else if (data.type === "restartVM") {
        this.restartVM(data.id, data.status);
      }
    },
    detachNetwork(data) {
      this.g_loading_create("Loading");
      this.$store.dispatch("detachIp", data.ipId).then(response => {
        this.g_loading_destroy();
        this.confirmation.active = false;
        this.confirmation.data = null;
        if (response.status === 200) {
          this.g_notif_create(true, "Success", "IP has been deleted");
          this.getdetail(this.id);
        } else {
          this.g_notif_create(false, "Failed", response.error.message);
        }
      });
    },
    detachVolume(data) {
      if (data.status !== "running") {
        if (data.status !== "poweroff") {
          this.g_notif_create(
            false,
            "Failed",
            "VM must be in Running or Poweroff state"
          );
          return;
        }
      }
      // let dataParams = {
      //   vmId: data.vmId,
      //   volumeId: data.volumeId,
      // };
      this.g_loading_create("Loading");
      this.$store.dispatch("detachVolume", data.volumeId).then(response => {
        this.confirmation.active = false;
        this.confirmation.data = null;
        this.g_loading_destroy();

        if (response.status === 200) {
          this.g_notif_create(true, "Success", "Volume has been detach");
          this.getdetail(this.id);
          // this.getVolume().then((response) => {
          //   let volumeHadVM = response.data.filter(
          //     (volume) => volume.virtual_machine
          //   );
          //   this.volumeVM = volumeHadVM.filter(
          //     (volume) => volume.virtual_machine.id === this.id
          //   );
          // });
        } else {
          this.g_notif_create(false, "Failed", response.error.message);
        }
      });
    },
    changePanel(data) {
      this.$store.commit("changePanelState", data);
      if (data === "Graph") {
        this.getMetrics(this.id);
      }
      if (data === "Reinstall") {
        if (!this.zoneList) {
          this.getZone(this.id.token);
        }
      }
      // if (data === "Volume") {
      //   if (!this.volumeVM) {
      //     this.getVolume().then((response) => {
      //       let volumeHadVM = response.data.filter(
      //         (volume) => volume.virtual_machine
      //       );
      //       this.volumeVM = volumeHadVM.filter(
      //         (volume) => volume.virtual_machine.id === this.id
      //       );
      //     });
      //   }
      // }
      if (data === "History") {
        this.clearVMLogs();
        this.getVMLogs();
      }
    },
    commitVMStatus(data) {
      this.$store.commit("changeVM", data);
    },
    getdetail(id) {
      this.loading = true;
      this.resetVMDetail();
      this.$store
        .dispatch("detailVM", id)
        .then(this.getPlan(this.id.token))
        .then(this.getVMLogs())
        .finally(() => {
          setTimeout(() => {
            if (this.vm_detail.status !== "running") {
              // this.vmnotif = true;
            }
          }, 300);
          this.loading = false;
        })
        .catch(() => {
          if (!this.token && !this.partnerId) {
            const path = `/dashboard`;
            if (this.$route.path !== path) this.$router.push(path);
          }
          this.loading = false;
        });
    },
    getVNCPassword(id) {
      this.$store.dispatch("getVNCPassword", id);
    },
    getMetrics() {
      this.$store.dispatch("getMetrics", this.id);
    },
    getVMLogs() {
      this.$store.dispatch("getVMLogs", this.id, "COBAAAAA");
    },
    refreshMetrics() {
      this.$store.commit("resetMetrics");
      this.getMetrics();
    },

    changePasswordVM() {
      this.g_loading_create("Loading");
      this.$store
        .dispatch("resetPasswordVM", {
          id: this.id,
          vm_password: this.vm_password,
          token: this.id.token
        })
        .then(response => {
          this.g_loading_destroy();
          if (response.status === 200) {
            this.g_notif_create(true, "Success", "Password Changed");
            this.vm_password = "";
          } else {
            this.g_notif_create(
              false,
              "Failed",
              response.data.error.fields.password[0]
            );
          }
        });
    },
    resizeVM(calc = false) {
      this.prorate = {
        oldplan: null,
        newplan: null,
        daysuntilrenewal: null,
        price: null
      };
      this.plan_id = this.selectedPlan.id;

      if (!this.plan_id) {
        alert("Please Choose One");
        return;
      }
      this.g_loading_create("Loading");
      let data = {
        plan_id: this.plan_id,
        calc: calc
      };
      if (typeof this.id === "string") {
        data.id = this.id;
      } else {
        data.id = this.id.id;
        data.token = this.id.token;
      }
      this.$store.dispatch("resizeVM", data).then(response => {
        this.confirmation.active = false;
        this.confirmation.data = null;
        this.g_loading_destroy();
        if (calc) {
          if (response.status === 200) {
            this.prorate = response.data;
          }
        } else {
          if (response.status === 200) {
            // this.g_notif_create(true, "Success", "Plan Resize");
            this.invoiceId = response.invoice_id;
            this.getInvoiceDetail();
            // this.$router.go();
          } else {
            this.g_notif_create(false, "Failed", response.error.message);
          }
        }
      });
    },
    getInvoiceDetail() {
      this.g_loading_create("Loading Invoice");
      this.$store
        .dispatch("invoice/getInvoiceDetail", {
          id: this.invoiceId,
          query: this.payment.method
        })
        .then(res => {
          this.g_loading_destroy();
          if (res && res.status === 200) {
            this.invoice.snap = res.snap_token;
            this.invoice.data = res.data;
            this.invoice.active = true;
            if (res.data.status === "Paid") {
              this.$router.go();
            }
          } else {
            this.g_notif_create(
              false,
              "Something went wrong",
              "Error getting invoice data"
            );
            console.log("error", res);
          }
        });
    },

    backupImageVM() {
      if (!this.backupimage || this.backupimage.length < 8) {
        alert("backup name must be at least 8 characters.");
        return;
      }
      // eslint-disable-next-line
      const hostnameChecker = /^[a-zA-Z0-9.-]+$/;
      if (!this.backupimage.match(hostnameChecker)) {
        alert("Space Not Allowed");
        return;
      }
      this.g_loading_create("Loading");
      this.$store
        .dispatch("backupImageVM", {
          id: this.id,
          name: this.backupimage
        })
        .then(response => {
          this.g_loading_destroy();
          if (response.status === 200) {
            this.g_notif_create(true, "Success", "Image Backup");
          } else {
            if ("fields" in response.error) {
              this.g_notif_create(
                false,
                "Failed",
                response.error.fields.name[0]
              );
            } else {
              this.g_notif_create(false, "Failed", response.error.message);
            }
          }
        });
    },
    disableActionFunction(type) {
      this.disableAction.start = type;
      this.disableAction.stop = type;
      this.disableAction.restart = type;
    },
    startVM(id, status) {
      this.disableActionFunction(true);
      this.confirmation.active = false;
      this.confirmation.data = null;
      if (status !== "poweroff") {
        this.g_notif_create(false, "Failed", "VM must be in poweroff State");
        this.disableActionFunction(false);
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("startVM", id).then(res => {
        this.disableActionFunction(false);
        this.g_loading_destroy();
        if (res.status !== 200) {
          this.g_notif_create(false, res.data.error.message);
        }
      });
    },
    stopVM(id, status) {
      this.disableActionFunction(true);
      this.confirmation.active = false;
      this.confirmation.data = null;
      if (status !== "running") {
        this.g_notif_create(false, "Failed", "VM must be in running State");
        this.disableActionFunction(false);
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("stopVM", id).then(res => {
        this.disableActionFunction(false);
        this.g_loading_destroy();
        if (res.status !== 200) {
          this.g_notif_create(false, res.data.error.message);
          this.notif.header = "Failed";
          this.notif.message = res.data.error.message;
          this.notif.active = false;
          this.notif.show = true;
        }
      });
    },
    restartVM(id, status) {
      this.disableActionFunction(true);
      this.confirmation.active = false;
      this.confirmation.data = null;
      if (status !== "running") {
        this.g_notif_create(false, "Failed", "VM must be in running State");
        this.disableActionFunction(false);
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("restartVM", id).then(() => {
        this.disableActionFunction(false);
        this.g_loading_destroy();
      });
    },
    destroyVM(id) {
      this.confirmation.active = false;
      this.confirmation.data = null;
      this.g_loading_create("Loading");
      this.$store.dispatch("destroyVM", id).then(this.g_loading_destroy());
    },
    launchConsole() {
      this.g_loading_create("Loading");
      this.$store.commit("resetVNC");

      this.$store.dispatch("getVNC", this.id).then(res => {
        this.g_loading_destroy();
        if (res.status === 200) {
          let routeData;
          if (this.iframe) {
            routeData =
              process.env.VUE_APP_DOMAINESIA_URL +
              "/cloudconsole.php?id=" +
              this.id.id.split("?").join("&");
            window.open(routeData, "_blank");
          } else {
            if (typeof this.id !== "string") {
              routeData = this.$router.resolve({
                path: "/console",
                query: { id: this.id.id }
              });
            } else {
              routeData = this.$router.resolve({
                path: "/console",
                query: { id: this.id }
              });
            }

            window.open(routeData.href, "_blank");
          }
        } else {
          this.g_notif_create(false, "Failed", res.error.message);
        }
      });
    },
    changePanelContent(data) {
      this.$store.commit("changePanelContent", data);
    },
    copySucces(data) {
      if (data === "ipv4") {
        this.clip.ipv4 = true;
        setTimeout(() => {
          this.clip.ipv4 = false;
        }, 500);
      } else if (data === "ipv6") {
        this.clip.ipv6 = true;
        setTimeout(() => {
          this.clip.ipv6 = false;
        }, 500);
      } else if (data === "ssh") {
        this.clip.ssh = true;
        setTimeout(() => {
          this.clip.ssh = false;
        }, 500);
      } else if (data === "dns") {
        this.clip.dns = true;
        setTimeout(() => {
          this.clip.dns = false;
        }, 500);
      } else if (data === "vnc") {
        this.clip.vnc = true;
        setTimeout(() => {
          this.clip.vnc = false;
        }, 500);
      }
    }
  }
};
</script>

<style>
.vue-slider-mark-step {
  width: 0px !important;
}
.vue-slider-dot-handle-disabled {
  width: 0 !important;
  height: 0 !important;
}
.vue-slider-rail {
  background-color: #f7f7f9 !important;
}

.vue-slider-mark-label {
  padding: 6px 12px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  margin-top: 16px !important;
}
.vue-slider-process {
  background-color: #68d391 !important;
}
.billing {
  background: rgb(239, 91, 12);
  background: linear-gradient(
    147deg,
    rgba(239, 91, 12, 1) 0%,
    rgba(213, 53, 60, 1) 100%
  );
}
</style>
