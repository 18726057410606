<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10" @click="checkCloseDetail()">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              SSH Keys
            </h1>
            <div
              class="w-10 h-10 border-2 border-orange text-2xl font-bold flex items-center justify-center rounded-lg text-orange cursor-pointer btn-hover"
              @click="ssh = true"
            >
              +
            </div>
          </div>
          <!-- <div class="">
            <div
              class="text-darkBlueText py-2 px-3 bg-grayForm rounded-lg flex items-center"
            >
              <div class="w-6 mr-4">
                <img src="@/assets/icon/ic_search.png" alt="" />
              </div>
              <input
                type="text"
                class="w-full bg-grayForm placeholder-darkBlueText outline-none"
                placeholder="Search"
              />
            </div>
          </div> -->
        </div>

        <div
          class="bg-white p-8 shadow-md rounded-lg mb-20"
          v-if="list_data && list_data.length != 0"
        >
          <!-- head -->
          <div
            class="grid grid-cols-9 gap-4 text-darkBlueText border-b border-white2 py-6"
          >
            <div class="col-span-3">Name</div>
            <div class="col-span-3">Fingerprint</div>
            <div class="col-span-2">Created</div>
            <div class=""></div>
          </div>

          <!-- content -->
          <div class="">
            <div
              class="py-4 border-b border-white2"
              v-for="list in list_data"
              :key="list.id"
            >
              <div class="grid grid-cols-9 gap-4 items-center text-black">
                <div class="col-span-3 truncate">
                  {{ list.name }}
                </div>
                <div class="col-span-3 truncate">
                  {{ list.fingerprint }}
                </div>
                <div class="col-span-2 truncate">
                  {{ formatDate(list.created_at) }}
                  {{ formatTime(list.created_at) }}
                </div>
                <div class="">
                  <div class="relative">
                    <div
                      class="icon text-right text-darkBlueText hover:text-orange cursor-pointer"
                      @click.stop="closeMenu(list.id)"
                    >
                      <i class="fas fa-circle mr-1"></i>
                      <i class="fas fa-circle mr-1"></i>
                      <i class="fas fa-circle"></i>
                    </div>
                    <div
                      class="absolute right-0"
                      style="top: 12px; left: -35px; z-index: 5"
                      v-if="list.menu"
                    >
                      <div class="shadow-lg rounded-lg bg-white text-center">
                        <div
                          class="py-2 cursor-pointer hover:bg-orange rounded-lg hover:text-white"
                          @click="
                            (changeSSHData.active = true),
                              (changeSSHData.data = list),
                              (list.menu = false)
                          "
                        >
                          <span class="text-xs flex-1">Change</span>
                        </div>

                        <div
                          class="py-2 rounded-lg cursor-pointer text-red hover:bg-red hover:text-white"
                          @click="
                            (confirmation_active = true),
                              (id_delete = list.id),
                              (list.menu = false)
                          "
                        >
                          <span class="text-xs flex-1">Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- no data -->

        <div
          class="flex flex-col flex-1 items-center justify-center"
          v-if="list_data && list_data.length === 0"
        >
          <div class="mt-6 mb-16">
            <img src="@/assets/img/no_ssh.png" class="w-2/3 mx-auto" alt="" />
          </div>
          <div class="text-2xl text-darkBlueText font-nunito font-bold mb-16">
            You don't have any SSH Keys
          </div>

          <button
            class="text-orange border border-orange rounded-md py-2 px-3 btn-hover"
            @click="openssh()"
          >
            Create SSH Keys
          </button>
        </div>
      </div>
    </div>
    <ssh v-if="ssh" @dismiss="ssh = false" @success="(ssh = false), getSSH()" />
    <ChangeSSH
      v-if="changeSSHData.active"
      @dismiss="(changeSSHData.active = false), (changeSSHData.data = null)"
      @succes="
        (changeSSHData.active = false), (changeSSHData.data = null), getSSH()
      "
      :sshData="changeSSHData.data"
    />
    <confirmation
      v-if="confirmation_active"
      @accept="deletessh()"
      @dismiss="(confirmation_active = false), (id_delete = null)"
    />
    <Notification
      v-if="notif.show"
      @dismiss="notif.show = false"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import ssh from "@/components/addssh.vue";
import confirmation from "@/components/confirmation.vue";
import Notification from "@/components/Notification.vue";
import { mapState, mapActions } from "vuex";
import ChangeSSH from "../../components/changeSSH.vue";
export default {
  name: "SSH-Keys",
  components: {
    sidebar,
    navbar,
    ssh,
    confirmation,
    Notification,
    ChangeSSH
  },
  created() {
    document.title = "Nevacloud | SSH Keys";
    if (!this.list_data) {
      this.g_loading_create("Loading");
    }
    this.getSSH().then(() => {
      this.g_loading_destroy();
    });
  },
  computed: {
    ...mapState({
      list_data: "sshList",
      minimize: "minimize"
    })
  },
  data: function() {
    return {
      ssh: false,
      changeSSHData: {
        active: null,
        data: null
      },
      confirmation_active: false,
      id_delete: null,
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      }
    };
  },
  mounted: function() {},
  methods: {
    ...mapActions(["getSSH"]),

    closeMenu(id) {
      this.list_data.forEach(data => {
        if (data.id == id) {
          if (!data.menu) {
            data.menu = true;
          } else {
            data.menu = false;
          }
        } else {
          data.menu = false;
        }
      });
    },

    deletessh() {
      this.g_loading_create("Loading");
      this.$store
        .dispatch("deletessh", { sshkey: this.id_delete })
        .then(() => {
          this.confirmation_active = false;
          this.id_delete = null;

          this.notif = {
            active: true,
            message: "Delete SSH Key Succes",
            show: true,
            header: "Succes!"
          };
          this.getSSH();
          this.g_loading_destroy();
        })
        .catch(err => {
          this.confirmation_active = false;
          this.id_delete = null;
          this.g_loading_destroy();
          this.notif = {
            active: false,
            message: "Delete SSH Key Failed",
            show: true,
            header: "Something Went Wrong"
          };
          console.log("err", err);
        });
    },
    checkCloseDetail() {
      this.list_data.forEach(data => {
        data.menu = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 6px;
}
</style>
