<template>
  <div class="bg-blue">
    <div
      class="container max-w-xl flex items-center justify-center flex-col min-h-screen py-12"
    >
      <div class="pb-12">
        <img class="w-56" src="@/assets/logo/logo.png" alt="Domainesia Logo" />
      </div>
      <div class="md:p-12 px-4 py-8 bg-white rounded-md shadow m-4 w-full">
        <div class="text-center">
          <div class="text-orange">
            <i class="fas fa-envelope fa-7x"></i>
          </div>
          <div class="text-3xl text-slate">Check Your Inbox!</div>
          <p class="text-slate">
            We already sent you a confirmation email, Verify it's you
            <br />Didn't get our email?
            <span class="text-blue cursor-pointer" @click="resend()"
              >Resend</span
            >
          </p>
          <button
            @click="logout($router)"
            class="outline-none py-2 px-4 border-solid bg-blue text-white rounded-md mt-4"
          >
            Log out
          </button>
        </div>
      </div>
    </div>
    <Notification
      v-if="notif"
      @dismiss="notif = false"
      :header="notifheader"
      :message="notifmessage"
      :active="notifstatus"
    />
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
export default {
  name: "CheckEmail",
  components: {
    Notification
  },
  created() {
    document.title = "Nevacloud | Verify Email";
  },
  data: function() {
    return {
      notif: false,
      notifstatus: null,
      notifheader: null,
      notifmessage: null
    };
  },
  mounted: function() {},
  methods: {
    resend() {
      this.g_loading_create("Loading");
      this.$store
        .dispatch("resend")
        .then(() => {
          this.g_loading_destroy();
          this.notifheader = "Check Your Email";
          this.notifmessage =
            "We just re-sent you a confirmation email. Check out your inbox";
          this.notifstatus = true;
          this.notif = true;
        })
        .catch(() => {
          this.g_loading_destroy();
          this.notifheader = "Something Went Wrong";
          this.notifmessage = "Please wait 5 minutes";
          this.notifstatus = false;
          this.notif = true;
        });
    }
  }
};
</script>
