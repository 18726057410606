<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      @click="dismiss()"
      style="z-index: 20"
    >
      <div
        class="container m-auto max-w-xl border border-white-100 mt-16 bg-white rounded-lg form-container"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div class="py-4 px-4">
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
          <h1 class="text-3xl text-slate mb-4">Other Payment Methods</h1>
          <div class="overflow-hidden w-full h-full mt-4">
            <div class="overflow-y-scroll h-full pr-10 w-full box-content">
              <div
                class="flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange"
                @click="
                  (paymentMethod = 'permata_va'),
                    (paymentName = 'Permata Virtual Account'),
                    succesClose()
                "
              >
                <div class="w-28 mr-4 flex-none">
                  <img src="@/assets/payments/permata.jpg" alt="" />
                </div>
                <span>Permata Virtual Account</span>
              </div>
              <div
                class="flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange"
                @click="
                  (paymentMethod = 'bni_va'),
                    (paymentName = 'BNI Virtual Account'),
                    succesClose()
                "
              >
                <div class="w-28 mr-4 flex-none">
                  <img src="@/assets/payments/bni.jpg" alt="" />
                </div>
                <span>BNI Virtual Account</span>
              </div>
              <div
                class="flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange"
                @click="
                  (paymentMethod = 'danamon_online'),
                    (paymentName = 'Danamon Online'),
                    succesClose()
                "
              >
                <div class="w-28 mr-4 flex-none">
                  <img src="@/assets/payments/danamon.jpg" alt="" />
                </div>
                <span>Danamon Online</span>
              </div>
              <div
                class="flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange"
                @click="
                  (paymentMethod = 'cimb_clicks'),
                    (paymentName = 'CIMB Clicks'),
                    succesClose()
                "
              >
                <div class="w-28 mr-4 flex-none">
                  <img src="@/assets/payments/cimb.jpg" alt="" />
                </div>
                <span>CIMB Clicks</span>
              </div>
              <div
                class="flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange"
                @click="
                  (paymentMethod = 'other_va'),
                    (paymentName = 'Other Virtual Account'),
                    succesClose()
                "
              >
                <div class="w-28 mr-4 flex-none">
                  <img src="@/assets/payments/atmbersama.jpg" alt="" />
                </div>
                <span>Other Virtual Account</span>
              </div>
              <div
                class="flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange"
                @click="
                  (paymentMethod = 'bca_klikpay'),
                    (paymentName = 'BCA Klik Pay'),
                    succesClose()
                "
              >
                <div class="w-28 mr-4 flex-none">
                  <img src="@/assets/payments/bcaklikpay.png" alt="" />
                </div>
                <span>BCA Klik Pay</span>
              </div>
              <div
                class="flex items-center py-2 cursor-pointer hover:text-orange"
                @click="
                  (paymentMethod = 'alfamart'),
                    (paymentName = 'Alfamart'),
                    succesClose()
                "
              >
                <div class="w-28 mr-4 flex-none">
                  <img src="@/assets/payments/alfamart.jpg" alt="" />
                </div>
                <span>Alfamart</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "paymentMethods",
  data: function() {
    return {
      will_dismiss: false,
      paymentMethod: null,
      paymentName: null
    };
  },
  mounted: function() {},
  methods: {
    succesClose() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("receivePayment", {
          paymentMethod: this.paymentMethod,
          paymentName: this.paymentName
        });
      }, 300);
    },
    dismiss() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
