var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(!_vm.iframe)?_c('div',{staticClass:"grid grid-cols-2 items-center justify-center"},[_vm._m(0),_c('div',{staticClass:"min-h-screen"},[_c('div',{staticClass:"max-w-sm min-h-screen mx-auto flex items-center justify-center"},[_c('div',{staticClass:"w-full"},[(_vm.em)?_c('ErrorMessage',{staticClass:"mb-4",attrs:{"message":_vm.emMessage},on:{"dismiss":function($event){_vm.em = false}}}):_vm._e(),_c('h1',{staticClass:"text-2xl mb-6 font-nunito font-bold"},[_vm._v("Login")]),_c('form',{staticClass:"mb-6",on:{"submit":function($event){$event.preventDefault();return _vm.logincheck($event)}}},[(_vm.loginData.email.message)?_c('div',{staticClass:"flex px-2 py-3 mb-2 bg-red-10 border border-red-20 items-center justify-between text-red rounded-md"},[_c('div',{},[_vm._v(" "+_vm._s(_vm.loginData.email.message)+" ")]),_c('div',{on:{"click":function($event){_vm.loginData.email.message = null}}},[_c('i',{staticClass:"far fa-times-circle cursor-pointer"})])]):_vm._e(),_c('div',{staticClass:"bg-grayForm p-3 rounded-md mb-2 border",class:{
                'border-orange': _vm.loginData.email.active,
                'border-grayForm': !_vm.loginData.email.active,
                'border-red':
                  _vm.loginData.email.message && !_vm.loginData.email.active
              }},[_c('div',{staticClass:"flex"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.email.data),expression:"loginData.email.data"}],staticClass:"w-full text-sm placeholder-grayText bg-grayForm outline-none",attrs:{"type":"email","placeholder":"Email Address"},domProps:{"value":(_vm.loginData.email.data)},on:{"focus":function($event){_vm.loginData.email.active = true},"blur":function($event){_vm.loginData.email.active = false},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginData.email, "data", $event.target.value)}}})])]),_c('div',{staticClass:"flex bg-grayForm p-3 border rounded-md mb-4",class:{
                'border-orange': _vm.loginData.password.active,
                'border-grayForm': !_vm.loginData.password.active,
                'border-red':
                  _vm.loginData.email.message && !_vm.loginData.email.active
              }},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.password.data),expression:"loginData.password.data"}],staticClass:"w-full text-sm placeholder-grayText bg-grayForm outline-none",attrs:{"type":"Password","placeholder":"Password"},domProps:{"value":(_vm.loginData.password.data)},on:{"focus":function($event){_vm.loginData.password.active = true},"blur":function($event){_vm.loginData.password.active = false},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginData.password, "data", $event.target.value)}}})]),_c('div',{staticClass:"flex justify-between mb-10"},[_c('div',{staticClass:"text-orange text-sm"},[_c('router-link',{staticClass:"font-whitney-semibold",attrs:{"to":"/forgot-password"}},[_vm._v("Forgot Password?")])],1)]),_c('button',{staticClass:"w-full py-3 text-white bg-orange rounded-md font-whitney-bold",attrs:{"type":"submit"}},[_vm._v(" Log In ")])])],1)])])]):_vm._e(),(_vm.iframe)?_c('div',{staticClass:"w-full h-screen flex justify-center items-center"},[_c('div',{staticClass:"max-w-xl rounded-md bg-gray py-2 px-10 text-red"},[_vm._v(" Error Getting Data ")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center min-h-screen text-white bg-orange items-center"},[_c('div',{staticClass:"w-64"},[_c('img',{staticClass:"w-full",attrs:{"src":require("@/assets/logo/logo-white.png"),"alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-2"},[_c('img',{staticClass:"w-full",attrs:{"src":require("@/assets/icon/email_orange@2x.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-2"},[_c('img',{staticClass:"w-full",attrs:{"src":require("@/assets/icon/lock_orange@2x.png"),"alt":""}})])}]

export { render, staticRenderFns }