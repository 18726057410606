<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      @click="dismiss()"
      style="z-index: 20"
    >
      <div
        class="container m-auto max-w-3xl border border-white-100 mt-16 bg-white rounded-lg form-container"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div class="py-8 px-4">
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
          <h1 class="text-3xl text-slate mb-4">Attach Volume</h1>
          <div
            class="p-4 bg-white2 border border-gray rounded-lg grid grid-cols-2 gap-4"
          >
            <div class="">
              <div class="text-slate mb-2 text-sm">Volume Name</div>
              <div class="text-orange font-bold font-nunito truncate">
                {{ volumeData.volumeName }}
              </div>
            </div>
            <div class="">
              <div class="text-slate mb-2 text-sm">Volume Size</div>
              <div class="text-orange font-bold font-nunito truncate">
                {{ volumeData.volumeSize }}GB
              </div>
            </div>
          </div>
          <form @submit.prevent="attachVolume" class="mt-4">
            <div class="mb-4">
              <div class="text-slate mb-2 text-sm">Select Virtual Machine</div>
              <div class="relative">
                <div
                  @click="choose = !choose"
                  class="border flex justify-between w-full p-2 rounded-xl"
                  :class="
                    choose
                      ? 'border-orange text-orange'
                      : 'border-gray text-slate'
                  "
                >
                  <span class="text-dark-gray" v-if="!vm_name">Select</span>
                  <span class="text-black" v-else>{{ vm_name }}</span>
                  <span class="text-dark-gray" v-if="!choose"
                    ><i class="fas fa-chevron-up"></i
                  ></span>
                  <span class="text-dark-gray" v-if="choose"
                    ><i class="fas fa-chevron-down"></i
                  ></span>
                </div>
                <div
                  class="absolute border border-white2 rounded-xl mt-2 inset-x-0 bg-white"
                  v-if="choose && vmList.length > 0"
                >
                  <div
                    v-for="vm in vmList"
                    :key="vm.id"
                    class="p-2 text-darkBlueText hover:text-orange   cursor-pointer"
                    :class="
                      vmList.length > 1
                        ? 'border-b border-white2 last:border-none'
                        : ''
                    "
                    @click="
                      (vm_name = vm.hostname),
                        (vmUUID = vm.id),
                        (choose = false)
                    "
                  >
                    {{ vm.hostname }}
                  </div>
                </div>
              </div>
            </div>

            <button
              class="w-full p-4 bg-orange text-white mt-2 rounded-xl"
              type="submit"
            >
              Attach Volume
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "attachVolume",
  components: {},
  computed: {
    ...mapState(["vmList"])
  },
  props: {
    volumeData: null
  },
  created() {
    this.getVM();
  },
  data: function() {
    return {
      will_dismiss: false,
      choose: false,
      vm_name: null,
      vmUUID: null
    };
  },
  mounted: function() {
    this.vm_name = null;
  },
  methods: {
    ...mapActions(["getVM"]),
    attachVolume() {
      if (!this.vmUUID) {
        alert("Please Choose Virtual Machine");
        return;
      }

      this.g_loading_create("Loading");
      this.$store
        .dispatch("attachVolume", {
          vmId: this.vmUUID,
          volumeId: this.volumeData.volumeId
        })
        .then(response => {
          this.g_loading_destroy();
          if (response.status === 200) {
            this.g_notif_create(true, "Success", "Volume has been attached");
            this.succesClose();
          } else {
            this.g_notif_create(false, "Failed", response.error.message);
          }
        });
      //
    },
    succesClose() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
      this.$emit("get");
    },
    dismiss() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
