import TurndownService from "turndown";
import VueCookies from "vue-cookies";
// import router from "../router";
import store from "../store";

export default {
  methods: {
    checklogin() {
      if (!VueCookies.isKey("isLogin")) {
        this.logout();
        return false;
      }
      let now = new Date();
      if (!VueCookies.isKey("expired_at")) {
        this.logout();
        return false;
      }
      let expired = new Date(VueCookies.get("expired_at"));
      if (now >= expired) {
        this.logout();
        return false;
      }
      store.dispatch("getme");
      return true;
    },
    logout() {
      this.g_loading_create("Loading");

      if (VueCookies.isKey("isLogin")) {
        VueCookies.remove("isLogin");
      }
      if (VueCookies.isKey("userID")) {
        VueCookies.remove("userID");
      }
      if (VueCookies.isKey("expired_at")) {
        VueCookies.remove("expired_at");
      }
      this.g_loading_destroy();
    },
    g_loading_create(message) {
      store.state.loading.dismiss = false;
      store.state.loading.message = message;
      store.state.loading.active = true;
    },
    g_loading_destroy() {
      store.state.loading.dismiss = true;
      setTimeout(() => {
        store.state.loading.active = false;
      }, 400);
    },
    g_notif_create: (active, header, message) => {
      const app = document.getElementById("app");
      const container = document.createElement("div");
      container.id = "notification";
      container.classList.add("bg-black");
      container.classList.add("bg-opacity-75");
      container.classList.add("fixed");
      container.classList.add("inset-0");
      container.style.zIndex = "30";
      container.onclick = function() {
        document.getElementById("notification").remove();
        return active;
      };

      const containerWidth = document.createElement("div");
      containerWidth.classList.add("container");
      containerWidth.classList.add("max-w-xl");
      containerWidth.classList.add("m-auto");
      containerWidth.onclick = function(event) {
        event.stopPropagation();
        return active;
      };

      const notifBody = document.createElement("div");
      notifBody.classList.add("mx-2");
      notifBody.classList.add("mt-56");
      notifBody.classList.add("py-8");
      notifBody.classList.add("px-6");
      notifBody.classList.add("rounded-md");
      active
        ? notifBody.classList.add("bg-green")
        : notifBody.classList.add("bg-red");

      const notifContent = document.createElement("div");
      notifContent.classList.add("flex");
      notifContent.classList.add("items-start");
      notifContent.classList.add("text-white");

      const notifIcon = document.createElement("div");
      notifIcon.classList.add("pb-4");
      notifIcon.classList.add("pr-8");
      notifIcon.classList.add("text-3xl");

      const icon = document.createElement("i");
      active
        ? (icon.classList.add("fas"), icon.classList.add("fa-check"))
        : (icon.classList.add("far"), icon.classList.add("fa-times-circle"));

      const headerContainer = document.createElement("div");
      headerContainer.classList.add("flex");
      headerContainer.classList.add("flex-col");
      headerContainer.classList.add("flex-1");

      const headerContent = document.createElement("div");
      headerContent.classList.add("text-3xl");
      const headertext = document.createTextNode(header);
      headerContent.appendChild(headertext);
      const p = document.createElement("p");
      const ptext = document.createTextNode(message);
      p.appendChild(ptext);

      const dismissContainer = document.createElement("div");
      dismissContainer.classList.add("text-right");
      dismissContainer.classList.add("p-1");
      dismissContainer.classList.add("cursor-pointer");
      dismissContainer.classList.add("ml-2");
      dismissContainer.onclick = function() {
        document.getElementById("notification").remove();
        return active;
      };
      const dismissIcon = document.createElement("i");
      dismissIcon.classList.add("fas");
      dismissIcon.classList.add("fa-times");

      dismissContainer.appendChild(dismissIcon);

      headerContainer.appendChild(headerContent);
      headerContainer.appendChild(p);

      notifIcon.appendChild(icon);

      notifContent.appendChild(notifIcon);
      notifContent.appendChild(headerContainer);
      notifContent.appendChild(dismissContainer);

      notifBody.appendChild(notifContent);

      containerWidth.appendChild(notifBody);

      container.appendChild(containerWidth);

      app.appendChild(container);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    formatRupiah(angka) {
      let number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },
    formatDate(thedate) {
      let mydate = new Date(thedate);
      let month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ][mydate.getMonth()];
      let str = month + ", " + mydate.getDate() + " " + mydate.getFullYear();
      return str;
    },
    formatTime(ndate) {
      let date = new Date(ndate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    decodeHTMLMessage(data) {
      let dummyElem = document.createElement("DIV");
      let ret = "";

      dummyElem.innerHTML = data;
      document.body.appendChild(dummyElem);
      ret = dummyElem.textContent; // just grap the decoded string which contains the desired HTML tags
      document.body.removeChild(dummyElem);

      return ret;
    },
    turndownMarkdown(data) {
      var turndownService = new TurndownService();
      var markdown = turndownService.turndown(data);
      return markdown;
    },
    generateHostnameOS(template, version, zone) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result + "." + zone + "." + template + "." + version;
    }
  }
};
