<template>
  <div id="app">
    <router-view />
    <loading
      v-if="loading.active"
      :message="loading.message"
      :will_dismiss="loading.dismiss"
    />
    <loading-full />
  </div>
</template>

<script>
import loading from "@/components/loading.vue";
import loadingFull from "@/components/VMCreate/loadingFull.vue";
import { mapState } from "vuex";

export default {
  name: "Loading",
  components: {
    loading,
    loadingFull
  },
  mounted: function() {},
  computed: {
    ...mapState(["loading"])
  }
};
</script>
