import axios from "axios";
import Vue from "vue";

export default {
  state: {
    deployParams: {
      template_id: null,
      template_type: "default",
      plan_id: null,
      zone: null,
      sshKey: [],
      vm_password: null,
      hostname: null,
      volume_name: null,
      volume_size: 20,
      configoptions: [],
      apply_credit: false
    },
    totalPayment: null,
    packageBilling: null,
    addIp: false
  },
  actions: {
    async addInstance({ state, commit, rootState }) {
      const url = process.env.VUE_APP_SERVICE_URL + "vm";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      var params = new FormData();
      params.append("zone", state.deployParams.zone);
      params.append("plan_id", state.deployParams.plan_id);
      params.append("template_id", state.deployParams.template_id);
      params.append("hostname", state.deployParams.hostname);
      params.append("template_type", state.deployParams.template_type);
      // params.append("volume", state.deployParams.volume);
      // params.append("ip_address", state.deployParams.ip_address);

      if (state.deployParams.template_type === "app") {
        for (var key in rootState.template.selectedTemplate.form) {
          if (!rootState.template.selectedTemplate.form[key].value) {
            alert("Please fill form " + key);
            return;
          } else {
            params.append(
              "form[" + key + "]",
              rootState.template.selectedTemplate.form[key].value
            );
          }
        }
      }
      if (state.deployParams.configoptions.length !== 0) {
        state.deployParams.configoptions.forEach((con, index) => {
          params.append("configoptions[" + index + "][id]", con.id);
        });
        state.deployParams.configoptions.forEach((con, index) => {
          params.append("configoptions[" + index + "][value]", con.value);
        });
      }

      if (rootState.volume.active) {
        params.append("volume_name", state.deployParams.volume_name);
        params.append("volume_size", state.deployParams.volume_size);
      }

      if (state.deployParams.vm_password) {
        params.append("password", state.deployParams.vm_password);
      }

      if (state.deployParams.apply_credit) {
        params.append("apply_credit", 1);
      }
      if (state.deployParams.sshKey.length != 0) {
        var i = 0;
        state.deployParams.sshKey.forEach(ssh => {
          params.append("ssh_key[" + i + "][id]", ssh);
          i++;
        });
      }

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });

        commit("resetDeployParams");
        return response;
      } catch (err) {
        // console.log(err.response.data);
        return err.response.data;
      }
    },
    async reinstallVM({ state }, data) {
      let id = null;
      let url = process.env.VUE_APP_SERVICE_URL + "vm/";
      if (typeof data.id === "string") {
        id = data.id;
        url += id + "/reinstall";
      } else {
        let split = data.id.id.split("?");
        id = split[0];
        url += id + "/reinstall?" + split[1];
      }

      var params = new FormData();
      params.append("template_id", state.deployParams.template_id);
      params.append("template_type", state.deployParams.template_type);

      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: header
        });
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    }
  },
  mutations: {
    changeZone: (state, data) => {
      state.deployParams.zone = data;
    },
    changePlan: (state, data) => {
      state.deployParams.plan_id = data;
    },
    changePackageBilling: (state, data) => {
      state.packageBilling = data;
    },
    changeTotalPayment: (state, data) => {
      state.totalPayment = data;
    },
    changeApplyCredit: (state, data) => {
      state.deployParams.apply_credit = data;
    },
    addIpTypeOption: (state, data) => {
      state.deployParams.configoptions.forEach(dat => {
        dat.IpTypeOption = data;
      });
      state.deployParams.configoptions[0].IpTypeOption.active = true;
    },
    resetAdvance: state => {
      state.deployParams.configoptions = [];
      Vue.set(state, "addIp", false);
    },

    switchIp: (state, data) => {
      if (state.addIp) {
        state.deployParams.configoptions = [];
        Vue.set(state, "addIp", !state.addIp);
        return;
      }
      if (data > 0) {
        Vue.set(state, "addIp", !state.addIp);

        let ndata = {
          id: null,
          value: 0,
          active: false,
          name: false,
          price: 0
        };
        state.deployParams.configoptions.push(ndata);
      }
    },
    addDeployIp: state => {
      let ndata = {
        id: null,
        value: 0,
        active: false,
        name: false,
        price: 0
      };
      state.deployParams.configoptions.push(ndata);
    },
    deleteDeployIp: (state, data) => {
      state.deployParams.configoptions.splice(data, 1);
    },
    addValueIp: (state, data) => {
      state.deployParams.configoptions[data].value += 1;
    },
    minValueIP: (state, data) => {
      state.deployParams.configoptions[data].value -= 1;
    },
    switchIpType: (state, data) => {
      state.deployParams.configoptions.forEach((dat, index) => {
        if (index === data) {
          if (dat.active) {
            dat.active = false;
          } else {
            dat.active = true;
          }
        } else {
          dat.active = false;
        }
      });
    },
    chooseIpType: (state, data) => {
      state.deployParams.configoptions[data.index].name = data.listIp.name;
      state.deployParams.configoptions[data.index].id = data.listIp.id;
      state.deployParams.configoptions[data.index].price = data.listIp.price;
    },
    resetDeployParams: state => {
      state.deployParams = {
        template_id: null,
        plan_id: null,
        zone: null,
        sshKey: [],
        vm_password: null,
        hostname: null,
        volume_name: null,
        volume_size: 20,
        configoptions: [],
        template_type: "default",
        apply_credit: false
      };
      state.totalPayment = null;
    },
    resetDeployTemplate: (state, data) => {
      state.deployParams.template_type = data;
      state.deployParams.template_id = null;
    }
  },

  namespaced: true
};
