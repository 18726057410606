<template>
  <div class="bg-blue">
    <div
      class="container max-w-lg flex items-center justify-center flex-col min-h-screen py-12"
    >
      <div class="pb-12">
        <img class="w-56" src="@/assets/logo/logo.png" alt="Domainesia Logo" />
      </div>
      <div class="md:p-12 px-4 py-8 bg-white rounded-md shadow m-4 w-full">
        <h3 class="text-slate text-center font-medium text-3xl">
          404 Page Not Found
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",

  created() {
    document.title = "Nevacloud | 404 Not Found";
  }
};
</script>

<style lang="scss" scoped></style>
