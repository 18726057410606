<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      @click="dismiss()"
      style="z-index: 20"
    >
      <div
        class="fixed right-0 bottom-0 top-0 bg-grayForm rounded-l-xl max-w-sm form-container"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div class="py-8 px-8">
          <div class="flex justify-between items-center mb-8">
            <h1 class="text-2xl text-slate text-orange font-bold font-nunito">
              Volume Configuration
            </h1>
            <div class="text-xl text-gray cursor-pointer" @click="dismiss()">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <div class="flex mb-8">
            <div class="w-1 bg-orange mr-2 flex-none font-bold"></div>
            <p>Volume “{{ volumeData.name }}” has been attached.</p>
          </div>

          <div class="text-sm">
            <div class="mb-4">
              <p class="mb-4">
                To start using your new volume, you’ll need to a filesystem on
                it:
              </p>
              <p class="mb-2 font-bold">
                &bull; Create a filesystem if you haven’t done so
              </p>
              <div
                class="flex justify-between items-center bg-dangerForm rounded-r-md"
              >
                <p
                  class="bg-white text-sm rounded-l-md text-darkBlueText p-2 flex-1 truncate"
                >
                  mkfs.ext4 "/dev/{{ volumeData.target }}"
                </p>
                <div
                  class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative flex-none"
                  @click="copySucces('one')"
                >
                  <img
                    src="@/assets/icon/ic_copy.png"
                    class="w-5"
                    alt=""
                    v-clipboard:copy="copy.one"
                  />
                  <div
                    v-if="clip.one"
                    class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                  >
                    Copied
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p class="mb-4">
                After the volume has a filesystem, you can create a mountpoint:
              </p>
              <p class="mb-2 font-bold">&bull; Create a mountpoint</p>
              <div
                class="flex justify-between items-center bg-dangerForm rounded-r-md"
              >
                <p
                  class="bg-white text-sm rounded-l-md text-darkBlueText p-2 flex-1 truncate"
                >
                  mkdir "/mnt/{{
                    volumeData.name.split(" ").length > 0
                      ? volumeData.name.split(" ").join("")
                      : volumeData.name
                  }}"
                </p>
                <div
                  class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative flex-none"
                  @click="copySucces('two')"
                >
                  <img
                    src="@/assets/icon/ic_copy.png"
                    class="w-5"
                    alt=""
                    v-clipboard:copy="copy.two"
                  />
                  <div
                    v-if="clip.two"
                    class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                  >
                    Copied
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p class="mb-4">Finally you can mount the new volume:</p>
              <p class="mb-2 font-bold">&bull; Mount volume</p>
              <div
                class="flex justify-between items-center bg-dangerForm rounded-r-md"
              >
                <p
                  class="bg-white text-sm rounded-l-md text-darkBlueText p-2 flex-1 truncate"
                >
                  mount "/dev/{{ volumeData.target }}" "/mnt/{{
                    volumeData.name.split(" ").length > 0
                      ? volumeData.name.split(" ").join("")
                      : volumeData.name
                  }}"
                </p>
                <div
                  class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative flex-none"
                  @click="copySucces('three')"
                >
                  <img
                    src="@/assets/icon/ic_copy.png"
                    class="w-5"
                    alt=""
                    v-clipboard:copy="copy.three"
                  />
                  <div
                    v-if="clip.three"
                    class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                  >
                    Copied
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p class="mb-4">
                If you want the volume to persist on every boot, you’ll need to
                add a line like the following to your /etc/fstab file:
              </p>
              <p class="mb-2 font-bold">
                &bull; Mount every time your VM boots
              </p>
              <div
                class="flex justify-between items-center bg-dangerForm rounded-r-md"
              >
                <p
                  class="bg-white text-sm rounded-l-md text-darkBlueText p-2 flex-1 truncate"
                >
                  /dev/{{ volumeData.target }} /mnt/{{
                    volumeData.name.split(" ").length > 0
                      ? volumeData.name.split(" ").join("")
                      : volumeData.name
                  }}
                  ext4 defaults,noatime,nofail 0 2
                </p>
                <div
                  class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative flex-none"
                  @click="copySucces('four')"
                >
                  <img
                    src="@/assets/icon/ic_copy.png"
                    class="w-5"
                    alt=""
                    v-clipboard:copy="copy.four"
                  />
                  <div
                    v-if="clip.four"
                    class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                  >
                    Copied
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "configVolume",
  props: {
    // will_dismiss: {
    //   type: Boolean,
    //   default: false,
    // },
    volumeData: null
  },
  created() {},

  data: function() {
    return {
      will_dismiss: false,
      copy: {
        one: "mkfs.ext4 " + '"' + "/dev/sdc" + '"',
        two: "mkdir " + '"' + "{/mnt/volumelabel}" + '"',
        three:
          "mount " +
          '"' +
          "/dev/sdc" +
          '"' +
          " " +
          '"' +
          "/mnt/volumelabel" +
          '"',
        four: "/dev/sdc /mnt/{volumelabel} ext4 defaults,noatime,nofail 0 2"
      },
      clip: { one: false, two: false, three: false, four: false }
    };
  },
  mounted: function() {
    this.copy.one = "mkfs.ext4 " + '"' + "/dev/" + this.volumeData.target + '"';

    this.copy.two =
      "mkdir " +
      '"' +
      "/mnt/" +
      (this.volumeData.name.split(" ").length > 0
        ? this.volumeData.name.split(" ").join("")
        : this.volumeData.name) +
      '"';

    this.copy.three =
      "mount " +
      '"' +
      "/dev/" +
      this.volumeData.target +
      '"' +
      " " +
      '"' +
      "/mnt/" +
      (this.volumeData.name.split(" ").length > 0
        ? this.volumeData.name.split(" ").join("")
        : this.volumeData.name) +
      '"';

    this.copy.four =
      "/dev/" +
      this.volumeData.target +
      " /mnt/" +
      (this.volumeData.name.split(" ").length > 0
        ? this.volumeData.name.split(" ").join("")
        : this.volumeData.name) +
      " ext4 defaults,noatime,nofail 0 2";
  },
  methods: {
    copySucces(data) {
      if (data === "one") {
        this.clip.one = true;
        setTimeout(() => {
          this.clip.one = false;
        }, 500);
      } else if (data === "two") {
        this.clip.two = true;
        setTimeout(() => {
          this.clip.two = false;
        }, 500);
      } else if (data === "three") {
        this.clip.three = true;
        setTimeout(() => {
          this.clip.three = false;
        }, 500);
      } else if (data === "four") {
        this.clip.four = true;
        setTimeout(() => {
          this.clip.four = false;
        }, 500);
      }
    },

    succesClose() {
      this.$emit("dismiss");
      this.$emit("get");
    },
    dismiss() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateX(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
</style>
