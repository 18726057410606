<template>
  <div
    class="fixed top-0 left-0 bottom-0 border-r-2 border-white2 bg-white2 z-10"
    :class="minimize ? '' : 'sidebar'"
  >
    <div
      class="mb-4 py-2 px-4 logo-container flex justify-between items-center"
      v-if="!minimize"
    >
      <div class="flex items-center">
        <img
          src="@/assets/logo/logo.png"
          alt=""
          class="mr-2"
          style="width: 7rem"
        />
      </div>

      <i
        class="fas fa-chevron-left text-darkBlueText cursor-pointer"
        @click="changeMinimize"
      ></i>
    </div>
    <div
      class="px-4 py-4 mb-4 logo-container flex items-center justify-center"
      v-if="minimize"
    >
      <div class="w-5 cursor-pointer" @click="changeMinimize">
        <img
          src="@/assets/icon/ic_hamburger_sidenav.png"
          alt=""
          class="w-full"
        />
      </div>
    </div>
    <!-- navigation -->
    <div class="">
      <!-- instance -->
      <div class="px-4">
        <router-link to="/dashboard">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/dashboard') ||
              current_page.path === '/create-instance'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText'
            ]"
          >
            <div
              class="w-6 h-6 p-1 flex items-center justify-center bg-white rounded-full"
            >
              <img src="@/assets/logo/icon.png" alt="" />

              <!-- <img src="@/assets/icon/logo3.png" alt="" v-else /> -->
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-2 flex-1'">
              Instance
            </div>
          </div>
        </router-link>
      </div>

      <!-- apps -->
      <div class="px-4">
        <router-link to="/apps">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/apps') ||
              current_page.path === '/apps'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText'
            ]"
          >
            <div class="w-5">
              <img
                src="@/assets/icon/ic_app.png"
                alt=""
                v-if="
                  current_page.path.startsWith('/apps') ||
                    current_page.path == '/apps'
                "
              />
              <img src="@/assets/icon/ic_app_f.png" alt="" v-else />
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-3 flex-1'">
              Apps
            </div>
          </div>
        </router-link>
      </div>

      <!-- Images -->
      <!-- <div class="px-4">
        <router-link to="/images">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/images') ||
              current_page.path === '/images'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText',
            ]"
          >
            <div class="w-5">
              <img
                src="@/assets/icon/ic_image.png"
                alt=""
                v-if="
                  current_page.path.startsWith('/images') ||
                  current_page.path == '/images'
                "
              />
              <img src="@/assets/icon/ic_image_f.png" alt="" v-else />
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-3 flex-1'">
              Images
            </div>
          </div>
        </router-link>
      </div> -->

      <!-- SSH Keys -->
      <div class="px-4">
        <router-link to="/ssh-keys">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/ssh-keys') ||
              current_page.path === '/ssh-keys'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText'
            ]"
          >
            <div class="w-5">
              <img
                src="@/assets/icon/ic_ssh.png"
                alt=""
                v-if="current_page.path == '/ssh-keys'"
              />
              <img src="@/assets/icon/ic_ssh_f.png" alt="" v-else />
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-3 flex-1'">
              SSH Keys
            </div>
          </div>
        </router-link>
      </div>

      <!-- Volume -->
      <div class="px-4">
        <router-link to="/volume">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/volume') ||
              current_page.path === '/volume'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText'
            ]"
          >
            <div class="w-5">
              <img
                src="@/assets/icon/ic_volume.png"
                alt=""
                v-if="current_page.path == '/volume'"
              />
              <img src="@/assets/icon/ic_volume_f.png" alt="" v-else />
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-3 flex-1'">
              Volume
            </div>
          </div>
        </router-link>
      </div>

      <!-- Networks -->
      <div class="px-4">
        <router-link to="/networks">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/networks') ||
              current_page.path === '/networks'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText'
            ]"
          >
            <div class="w-5">
              <img
                src="@/assets/icon/ic_network.png"
                alt=""
                v-if="current_page.path == '/networks'"
              />
              <img src="@/assets/icon/ic_network_f.png" alt="" v-else />
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-3 flex-1'">
              Networks
            </div>
          </div>
        </router-link>
      </div>

      <!-- Billing -->
      <div class="px-4">
        <router-link to="/billing">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/billing') ||
              current_page.path === '/billing'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText'
            ]"
          >
            <div class="w-5">
              <img
                src="@/assets/icon/ic_billing.png"
                alt=""
                v-if="current_page.path == '/billing'"
              />
              <img src="@/assets/icon/ic_billing_f.png" alt="" v-else />
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-3 flex-1'">
              Billing
            </div>
          </div>
        </router-link>
      </div>

      <!-- Support -->
      <div class="px-4">
        <router-link to="/support">
          <div
            class="h-50px flex items-center p-3 mx-auto rounded-lg"
            :class="[
              minimize ? 'w-50px justify-center' : '',
              current_page.path.startsWith('/support') ||
              current_page.path === '/support'
                ? 'bg-orange text-white'
                : 'bg-white2 text-darkBlueText'
            ]"
          >
            <div class="w-5">
              <img
                src="@/assets/icon/ic_support.png"
                alt=""
                v-if="current_page.path.startsWith('/support')"
              />
              <img src="@/assets/icon/ic_support_f.png" alt="" v-else />
            </div>
            <div class="" :class="minimize ? 'hidden' : 'block ml-3 flex-1'">
              Support
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="px-4 mt-20">
      <div
        class="px-3 py-4 flex bg-white rounded-lg shadow-md items-center cursor-pointer"
        :class="minimize ? 'flex-col' : 'justify-between'"
        @mouseover="logout_ic = true"
        @mouseleave="logout_ic = false"
        @click="logout(), $router.push('/login')"
      >
        <!-- <div
          class="w-5 cursor-pointer"
          :class="minimize ? 'mb-10' : ''"
          @mouseover="setting_ic = true"
          @mouseleave="setting_ic = false"
        >
          <img
            src="@/assets/icon/ic_setting_f.png"
            class="w-full"
            alt=""
            v-if="!setting_ic"
          />
          <img
            src="@/assets/icon/ic_setting.png"
            class="w-full"
            alt=""
            v-else
          />
        </div> -->
        <div class="w-5 cursor-pointer">
          <img
            src="@/assets/icon/ic_logout_f.png"
            class="w-full"
            alt=""
            v-if="!logout_ic"
          />
          <img src="@/assets/icon/ic_logout.png" class="w-full" alt="" v-else />
        </div>
        <div
          :class="{
            hidden: minimize,
            'ml-3 flex-1': !minimize,
            'text-darkBlueText': !logout_ic,
            'text-orange': logout_ic
          }"
        >
          Logout
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Sidebar",
  data: function() {
    return {
      current_page: {
        path: ""
      },
      logout_ic: false,
      setting_ic: false
    };
  },
  computed: {
    ...mapState(["minimize"])
  },
  mounted: function() {
    this.current_page = this.$router.currentRoute;
  },
  methods: {
    ...mapMutations(["changeMinimize"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logo-container {
  height: 80px;
}
.sidebar {
  width: 200px;
}
.w-50px {
  width: 50px;
}
.h-50px {
  height: 50px;
}
</style>
