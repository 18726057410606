<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar
        btn_text="New Instance"
        @action="$router.push('/create-instance')"
      />
      <div class="flex-1 pt-4 px-10 pb-40" v-if="userdata">
        <h1 class="text-3xl font-nunito font-bold text-black mb-10">Profile</h1>

        <!-- head -->
        <div class="px-10 py-4 grad relative rounded-md">
          <div class="flex justify-end items-center">
            <div class="mr-4 flex-initial">
              <img
                src="@/assets/icon/ic_doodle.png"
                class="ml-auto xl:w-4/5"
                alt=""
              />
            </div>
            <div class="flex flex-col items-center justify-between flex-none">
              <button
                v-if="!onReset"
                class="flex items-center cursor-pointer text-orange bg-white mb-4 rounded-md px-4 py-2 btn-hover"
                @click="editProfile()"
              >
                <i class="fas fa-pen mr-4"></i>
                <span>Edit</span>
              </button>
              <button
                v-else
                class="flex items-center cursor-pointer text-white bg-gray mb-4 rounded-md px-4 py-2"
              >
                <i class="fas fa-pen mr-4"></i>
                <span>Edit</span>
              </button>
              <!-- <div class="text-sm text-white">Instance Limit : 3</div> -->
            </div>
          </div>
          <div
            class="absolute px-10 left-0 flex items-start"
            style="bottom: -25%"
          >
            <div class="w-24 h-24 rounded-full bg-red mr-8">
              <!-- <img src="@/assets/icon/ic_robot.png" alt="" /> -->
              <v-gravatar
                :email="userdata.email"
                class="w-full rounded-full"
                default-img="retro"
              />
            </div>
            <div class="text-white">
              <div class="font-nunito text-2xl">
                {{ userdata.fullname }}
              </div>
              <div class="text-sm">
                Member Since {{ userdata.created_at.slice(0, 10) }}
              </div>
            </div>
          </div>
        </div>
        <!-- content -->
        <div class="mx-40 px-2">
          <!-- nav -->
          <div class="py-10 flex items-center justify-between">
            <div class="py-1 px-4 bg-grayForm rounded-md text-black">
              General
            </div>
            <!-- <vue-hcaptcha
              size="invisible"
              :sitekey="hcaptchaKey"
              theme="dark"
              @verify="verifyCaptcha"
              @closed="g_loading_destroy(), (onReset = false)"
              ref="invisibleHcaptchaReset"
            ></vue-hcaptcha> -->
            <button
              class="text-orange bg-white border border-orange rounded-lg mb-4 rounded-md px-4 hover:border-white py-2 btn-hover"
              @click="requestPassword = true"
              v-if="!onReset"
            >
              Request Reset Password
            </button>
            <button
              v-else
              class="bg-gray border border-gray rounded-lg mb-4 text-white rounded-md px-4 py-2"
            >
              Request Reset Password
            </button>
            <!-- <div class="py-1 px-4 text-darkBlueText">Security</div>
            <div class="py-1 px-4 text-darkBlueText">Referrals</div> -->
          </div>
          <!-- user info -->
          <div class="p-6 grid grid-cols-4 gap-4 bg-grayForm rounded-md">
            <div class="border-l border-grayForm flex justify-center">
              <div class="inline-block">
                <div class="flex items-center flex-initial mb-2">
                  <i class="fas fa-map-marker-alt text-orange mr-4"></i>
                  <div class="text-black font-whitney-medium">Address</div>
                </div>
                <div
                  class="text-darkBlueText text-sm inline-block flex-initial"
                  v-if="
                    userdata.address1 === 'default' ||
                      userdata.address1 === null ||
                      userdata.address1 === ''
                  "
                >
                  -
                </div>
                <div
                  class="text-darkBlueText text-sm inline-block flex-initial"
                  v-else
                >
                  {{ userdata.address1 }}
                </div>
              </div>
            </div>
            <div class="border-l border-grayb flex justify-center">
              <div class="inline-block">
                <div class="flex items-center flex-initial mb-2">
                  <i class="fas fa-phone text-orange mr-4"></i>
                  <div class="text-black font-whitney-medium">Phone</div>
                </div>
                <div
                  class="text-darkBlueText text-sm inline-block flex-initial"
                  v-if="
                    userdata.phonenumber === 'default' ||
                      userdata.phonenumber === null ||
                      userdata.phonenumber === ''
                  "
                >
                  -
                </div>
                <div
                  v-else
                  class="text-darkBlueText text-sm inline-block flex-initial"
                >
                  {{ userdata.phonenumber }}
                </div>
              </div>
            </div>
            <div class="border-l border-grayb flex justify-center">
              <div class="inline-block">
                <div class="flex items-center mb-2">
                  <i class="far fa-credit-card text-orange mr-4"></i>
                  <div class="text-black font-whitney-medium">NPWP</div>
                </div>
                <div class="text-darkBlueText text-sm" v-if="userdata.tax_id">
                  {{ userdata.tax_id }}
                </div>
                <div class="text-darkBlueText text-sm" v-else>-</div>
              </div>
            </div>
            <div class="border-l border-grayb flex justify-center">
              <div class="inline-block">
                <div class="flex items-center mb-2">
                  <i class="far fa-building text-orange mr-4"></i>
                  <div class="text-black font-whitney-medium">Company</div>
                </div>
                <div class="text-darkBlueText text-sm">
                  {{ userdata.companyname }}
                </div>
              </div>
            </div>
          </div>
          <!-- menu -->
          <!-- <div class="mt-6">
            <div
              class="p-6 rounded-lg border border-white2 mb-6 overflow-hidden height-tab"
            >
              <div class="flex justify-between">
                <div class="flex items-center mb-8 cursor-pointer">
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                    ></i>
                  </div>
                  <h2 class="font-nunito font-bold text-lg text-black">
                    Sign-in method
                  </h2>
                </div>
              </div>
            </div>
            <div
              class="p-6 rounded-lg border border-white2 mb-6 overflow-hidden height-tab"
            >
              <div class="flex justify-between">
                <div class="flex items-center mb-8 cursor-pointer">
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                    ></i>
                  </div>
                  <h2 class="font-nunito font-bold text-lg text-black">
                    Email Preferences
                  </h2>
                </div>
              </div>
            </div>
            <div
              class="p-6 rounded-lg border border-white2 mb-6 overflow-hidden height-tab"
            >
              <div class="flex justify-between">
                <div class="flex items-center mb-8 cursor-pointer">
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                    ></i>
                  </div>
                  <h2 class="font-nunito font-bold text-lg text-black">
                    Deactive Account
                  </h2>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div
          class="mt-12 flex items-start p-6 border border-gray rounded-md mb-4"
          v-if="userdata"
        >
          <div
            class="w-20 h-20 rounded-full bg-orange flex items-center justify-center mr-4"
          >
            <div class="text-white text-2xl" v-if="userdata.name">
              {{ userdata.name.slice(0, 2).toUpperCase() }}
            </div>
          </div>
          <div class="flex-1 text-dark-gray text-md">
            <p
              class="text-base text-slate font-semibold mb-2"
              v-if="userdata.email"
            >
              {{ userdata.email }}
            </p>
            <p class="mb-2" v-if="userdata.created_at">
              Member Since, {{ userdata.created_at.slice(0, 10) }}
            </p>
            <button
              class="text-blue mb-2 focus:outline-none"
              @click="edit = true"
              v-if="!userdata.user_info"
            >
              Add Your Address
            </button>
            <p>Instance Limit : 3</p>
          </div>
          <button
            class="focus:outline-none px-4 py-2 bg-blue text-white rounded-md"
            @click="edit = true"
          >
            Edit Profile
          </button>
        </div>
        <div
          class="grid grid-cols-2 grid-rows-3 p-6 border border-gray rounded-md"
          v-if="userdata.user_info"
        >
          <div class="grid grid-cols-3">
            <div class="text-dark-gray">Name</div>
            <div class="text-slate font-semibold">{{ userdata.name }}</div>
          </div>
          <div class="grid grid-cols-3">
            <div class="text-dark-gray">Phone Number</div>
            <div class="text-slate font-semibold">
              {{ userdata.user_info.phone_number }}
            </div>
          </div>
          <div class="grid grid-cols-3">
            <div class="text-dark-gray">NPWP</div>
            <div class="text-slate font-semibold">
              {{ userdata.user_info.npwp }}
            </div>
          </div>
          <div class="grid grid-cols-3">
            <div class="text-dark-gray">Company</div>
            <div class="text-slate font-semibold">
              {{ userdata.user_info.company }}
            </div>
          </div>
          <div class="grid grid-cols-3">
            <div class="text-dark-gray">Address</div>
            <div class="text-slate col-start-2 col-end-4 font-semibold">
              {{ userdata.user_info.address }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <editProfile
      v-if="edit"
      @dismiss="edit = false"
      @get="$store.dispatch('getme')"
    />
    <request-reset-password
      v-if="requestPassword"
      @dismiss="requestPassword = false"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import { mapState } from "vuex";
import editProfile from "@/components/editProfile.vue";
// import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import RequestResetPassword from "../../components/requestResetPassword.vue";

export default {
  name: "DetailVM",
  components: {
    sidebar,
    navbar,
    editProfile,
    // VueHcaptcha,
    RequestResetPassword
  },
  computed: {
    ...mapState(["userdata", "minimize"])
  },
  created() {
    document.title = "Nevacloud | Profile";
  },
  data: function() {
    return {
      user: null,
      image: null,
      edit: false,
      requestPassword: false,
      hcaptchaKey: process.env.VUE_APP_HCAPTCHA_SITEKEY,
      onReset: false
    };
  },
  mounted: function() {},
  methods: {
    editProfile() {
      this.g_loading_create("Loading");
      this.$store.dispatch("getme").then(() => {
        this.g_loading_destroy();
        this.edit = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.grad {
  background: rgb(105, 96, 204);
  background: linear-gradient(
    90deg,
    rgba(105, 96, 204, 1) 0%,
    rgba(83, 141, 247, 1) 40%,
    rgba(109, 187, 217, 1) 100%
  );
}
.height-tab {
  height: 77px;
}
</style>
