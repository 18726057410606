import { render, staticRenderFns } from "./apps.vue?vue&type=template&id=2134479f&scoped=true&"
import script from "./apps.vue?vue&type=script&lang=js&"
export * from "./apps.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2134479f",
  null
  
)

export default component.exports