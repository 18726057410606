<template>
  <div
    class="fixed bottom-0 right-0 m-6 loader-container shadow-md"
    :class="{ 'will-dismiss': will_dismiss }"
  >
    <div class="max-w-sm m-auto">
      <div class="py-4 px-6 rounded-md bg-white">
        <div class="flex items-center">
          <div class="w-8 h-8 loader"></div>
          <div class="mx-6">{{ message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    message: null,
    will_dismiss: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
  z-index: 30;
}
.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.will-dismiss {
  opacity: 0;
  transform: translateX(100%);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeSlideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
</style>
