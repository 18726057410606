<template>
  <div
    class="inset-0 h-screen flex items-center justify-center z-50"
    :class="
      show
        ? 'absolute bg-white left-0 right-0'
        : 'fixed bg-black bg-opacity-75 '
    "
    v-if="loadingFull || show"
  >
    <div :class="show ? 'mb-56' : ''">
      <Lottie
        className="animation"
        renderer="canvas"
        :loop="true"
        :autoplay="true"
        :animationData="lottieData"
        @getLottieInstance="getLottieInstance"
      />
      <div class="text-white text-center" v-if="!show">
        Preparing your instance... <br />
        Please don't close your browser window.
      </div>
    </div>

    <!-- @onEnterFrame="onEnterFrame"
      @onComplete="onComplete"
      @onLoopComplete="onLoopComplete" -->
  </div>
</template>

<script>
import Lottie from "vue-lottie-web";
import lottieData from "@/assets/lottie/loading.json";

export default {
  name: "Loading",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Lottie
  },
  computed: {
    loadingFull() {
      return this.$store.state.loadingFull;
    }
  },
  data() {
    return {
      lottieData,
      lottieInstance: ""
    };
  },

  methods: {
    getLottieInstance(lottieInstance) {
      this.lottieInstance = lottieInstance;
    },
    lottiePlay() {
      this.lottieInstance && this.lottieInstance.play();
    },
    lottieRePlay() {
      this.lottieInstance && this.lottieInstance.stop();
      setTimeout(() => {
        this.lottieInstance.play();
      });
    },
    lottiePause() {
      this.lottieInstance && this.lottieInstance.pause();
    },
    lottieStop() {
      this.lottieInstance && this.lottieInstance.stop();
    }
    // onComplete(e) {
    //   console.log("onComplete", e);
    // },
    // onLoopComplete(e) {
    //   console.log("onLoopComplete", e);
    // },
    // onEnterFrame(e) {
    //   console.log("onEnterFrame", e);
    // },
  }
};
</script>

<style scoped>
canvas {
  height: 250px !important;
}
</style>
