<template>
  <div class>
    <sidebar />
    <div class="" :class="minimize ? 'min-content' : 'content'">
      <navbar btn_text="New Instance" />
      <div class="flex justify-between items-start px-10">
        <div class="flex-1 pt-4 mr-4 pb-32">
          <div class="flex items-center">
            <div
              class="w-6 h-6 rounded-full bg-orange flex items-center justify-center text-sm mr-6 mb-1"
              @click="$router.back()"
            >
              <i class="fas fa-chevron-left text-white cursor-pointer"></i>
            </div>
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Create New
            </h1>
          </div>

          <form class="mt-4">
            <!-- choose location  -->
            <div
              class="p-6 rounded-lg border border-white2 mb-6 overflow-hidden"
              :class="
                tab.datacenter
                  ? 'shadow-lg h-auto transition duration-500 ease-in-out'
                  : 'height-tab transition duration-500 ease-in-out'
              "
            >
              <div class="flex justify-between">
                <div
                  class="flex items-center mb-8 cursor-pointer"
                  @click="tab.datacenter = !tab.datacenter"
                >
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-down text-orange cursor-pointer"
                      v-if="tab.datacenter"
                    ></i>
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                      v-else
                    ></i>
                  </div>
                  <h2
                    class="font-nunito font-bold text-lg"
                    :class="tab.datacenter ? 'text-orange' : 'text-black'"
                  >
                    Choose Datacenter Location
                  </h2>
                </div>
                <div
                  class="text-darkBlueText"
                  v-if="deployParams.zone && deployParams.zone === 'yk'"
                >
                  Yogyakarta
                </div>
              </div>

              <zone />
            </div>
            <!-- end choose location  -->

            <!-- choose Package -->
            <div
              class="px-6 pt-6 rounded-lg border border-white2 mb-6 overflow-hidden"
              :class="optionTabPackage"
            >
              <div class="flex justify-between">
                <div
                  class="flex items-center mb-8 cursor-pointer"
                  @click="deployParams.zone ? (tab.package = !tab.package) : ''"
                >
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-down text-orange cursor-pointer"
                      v-if="tab.package"
                    ></i>
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                      v-else
                    ></i>
                  </div>
                  <h2
                    class="font-nunito font-bold text-lg"
                    :class="tab.package ? 'text-orange' : 'text-black'"
                  >
                    Choose Package
                  </h2>
                </div>
                <div class="text-darkBlueText" v-if="selectedPlan">
                  {{ selectedPlan.name }}
                </div>
              </div>

              <div class="" v-show="tab.package">
                <!-- PACKAGE DISINI -->

                <package />
              </div>
              <div class="text-center" v-if="!plan">Loading</div>
            </div>
            <!-- end choose Package -->

            <!-- choose OS -->
            <div
              class="p-6 rounded-lg border border-white2 mb-6 h-auto"
              :class="
                tab.os
                  ? 'shadow-lg  transition duration-500 ease-in-out'
                  : ' transition duration-500 ease-in-out'
              "
            >
              <div class="flex justify-between">
                <div
                  class="flex items-center cursor-pointer"
                  :class="tab.os ? 'mb-8' : ''"
                  @click="deployParams.zone ? (tab.os = !tab.os) : ''"
                >
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-down text-orange cursor-pointer"
                      v-if="tab.os"
                    ></i>
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                      v-else
                    ></i>
                  </div>
                  <h2
                    class="font-nunito font-bold text-lg"
                    :class="tab.os ? 'text-orange' : 'text-black'"
                  >
                    Choose Operating System
                  </h2>
                </div>

                <div
                  class="text-darkBlueText"
                  v-if="
                    selectedTemplate &&
                      selectedTemplate.name &&
                      templateOption === 'default'
                  "
                >
                  {{ selectedTemplate.name }}
                </div>
                <div
                  class="text-darkBlueText"
                  v-if="
                    selectedTemplate &&
                      selectedTemplate.name &&
                      templateOption === 'app'
                  "
                >
                  {{
                    selectedTemplate.name.split("_").length > 1
                      ? selectedTemplate.name.split("_")[1]
                      : selectedTemplate.name
                  }}
                </div>
              </div>

              <Template v-if="tab.os" />
            </div>
            <!-- end choose OS -->

            <!-- choose Authentication -->
            <div
              class="p-6 rounded-lg border border-white2 mb-6 overflow-hidden"
              :class="
                tab.auth
                  ? 'shadow-lg h-auto transition duration-500 ease-in-out'
                  : 'height-tab transition duration-500 ease-in-out '
              "
            >
              <div class="flex justify-between">
                <div
                  class="flex items-center mb-8 cursor-pointer"
                  @click="deployParams.zone ? (tab.auth = !tab.auth) : ''"
                >
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-down text-orange cursor-pointer"
                      v-if="tab.auth"
                    ></i>
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                      v-else
                    ></i>
                  </div>
                  <h2
                    class="font-nunito font-bold text-lg"
                    :class="tab.auth ? 'text-orange' : 'text-black'"
                  >
                    Choose Authentication
                  </h2>
                </div>
                <span class="text-darkBlueText" v-if="deployParams.zone">
                  {{ authOption.ssh ? "SSH Keys" : "Password" }}
                </span>
              </div>

              <div
                class="p-4 flex border rounded-lg mb-4"
                :class="
                  authOption.ssh ? 'border-orange bg-orange2' : 'border-white2'
                "
                @click="(authOption.ssh = true), (authOption.password = false)"
              >
                <div class="w-12 mr-4">
                  <img src="@/assets/icon/ic_sshkeys.png" alt="" />
                </div>
                <div class="">
                  <div class="mb-4">
                    <div class="">SSH Keys</div>
                    <div class="">A more secure authentication method</div>
                  </div>
                  <div
                    class="grid grid-cols-4 gap-4 items-center"
                    v-if="authOption.ssh"
                  >
                    <div
                      class="p-2 text-center cursor-pointer border rounded-lg font-whitney-medium text-orange bg-white"
                      :class="ssh.active ? 'border-orange ' : 'border-white'"
                      @click="changeSSH(ssh.id)"
                      v-for="ssh in sshList"
                      :key="ssh.id"
                    >
                      {{ ssh.name }}
                    </div>
                    <div
                      class="flex items-center text-orange cursor-pointer"
                      @click="sshtemplate.active = true"
                    >
                      <div
                        class="text-xl mr-1 flex items-center justify-center"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </div>
                      <div class="">Create New</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="p-4 border rounded-lg flex"
                :class="
                  authOption.password
                    ? 'border-orange bg-orange2'
                    : 'border-white2'
                "
                @click="(authOption.ssh = false), (authOption.password = true)"
              >
                <div class="w-12 mr-4">
                  <img src="@/assets/icon/ic_password.png" alt="" />
                </div>
                <div class="flex-1">
                  <div class="">Password</div>
                  <div class="mb-4">
                    Create a root password to access instance (less secure)
                  </div>
                  <div class="" v-if="authOption.password">
                    <input
                      type="password"
                      class="w-full px-4 py-2 mb-2 border border-white2 rounded-lg outline-none focus:border-orange"
                      v-model="deployParams.vm_password"
                    />
                    <span class="text-red text-sm block" v-if="passwordMessage">
                      {{ passwordMessage }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- end choose Authentication -->

            <!-- Hostname Option -->
            <div
              class="p-6 rounded-lg border border-white2 mb-6 h-auto"
              :class="
                tab.hostname
                  ? 'shadow-lg  transition duration-500 ease-in-out'
                  : 'transition duration-500 ease-in-out'
              "
            >
              <div class="flex justify-between">
                <div
                  class="flex items-center cursor-pointer"
                  :class="tab.hostname ? 'mb-8' : ''"
                  @click="
                    deployParams.zone ? (tab.hostname = !tab.hostname) : ''
                  "
                >
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-down text-orange cursor-pointer"
                      v-if="tab.hostname"
                    ></i>
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                      v-else
                    ></i>
                  </div>
                  <h2
                    class="font-nunito font-bold text-lg"
                    :class="tab.hostname ? 'text-orange' : 'text-black'"
                  >
                    Hostname
                  </h2>
                </div>
                <div class="text-darkBlueText">{{ deployParams.hostname }}</div>
              </div>

              <!-- hostname -->
              <div
                class="p-4 border border-white2 rounded-lg"
                v-if="tab.hostname"
              >
                <div class="flex items-center">
                  <div class="mr-4">Enter Hostname :</div>
                  <div class="flex-1 p-2 border border-white2 rounded-lg">
                    <input
                      type="text"
                      class="w-full outline-none"
                      v-model="deployParams.hostname"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- end Hostname Optionn -->
            <!-- Advanced Option -->
            <div
              class="p-6 rounded-lg border border-white2 mb-6 h-auto"
              :class="
                tab.advanced
                  ? 'shadow-lg  transition duration-500 ease-in-out'
                  : 'transition duration-500 ease-in-out'
              "
            >
              <div class="flex justify-between">
                <div
                  class="flex items-center cursor-pointer"
                  :class="tab.advanced ? 'mb-8' : ''"
                  @click="
                    deployParams.zone ? (tab.advanced = !tab.advanced) : ''
                  "
                >
                  <div
                    class="w-6 h-6 rounded-full border border-grayb flex items-center justify-center text-sm mr-6"
                  >
                    <i
                      class="fas fa-chevron-down text-orange cursor-pointer"
                      v-if="tab.advanced"
                    ></i>
                    <i
                      class="fas fa-chevron-right text-orange cursor-pointer"
                      v-else
                    ></i>
                  </div>
                  <h2
                    class="font-nunito font-bold text-lg"
                    :class="tab.advanced ? 'text-orange' : 'text-black'"
                  >
                    Advanced Options
                  </h2>
                </div>
                <div class="text-darkBlueText" v-if="tab.advanced">
                  <span class="" v-if="volume.active">
                    {{ "Storage " + deployParams.volume_size + " GB" }}
                  </span>
                  <span class="px-2" v-if="volume.active && addIp">&</span>
                  <span class="" v-if="addIp">
                    {{ "IP Requested " + totalIPRequested }}
                  </span>
                </div>
              </div>

              <!-- menu -->
              <div
                class="flex items-center justify-center text-sm mb-6"
                v-if="tab.advanced"
              >
                <!-- nav block storage -->
                <div
                  class="py-2 px-3 cursor-pointer"
                  :class="
                    tab.advanced && tabAdvanced.block
                      ? 'bg-grayForm text-black rounded-lg'
                      : ''
                  "
                  @click="switchTabAdvanced('block')"
                >
                  Add Block Storage
                </div>

                <!-- nav additional IP -->
                <!-- <div
                  class="py-2 px-3 cursor-pointer"
                  :class="
                    tabAdvanced.ip ? 'bg-grayForm text-black rounded-lg' : ''
                  "
                  @click="switchTabAdvanced('ip')"
                >
                  Additional Public IP
                </div> -->

                <!-- nav backup -->
                <!-- <div
                  class="py-2 px-3 cursor-pointer"
                  :class="
                    tabAdvanced.backup
                      ? 'bg-grayForm text-black rounded-lg'
                      : ''
                  "
                  @click="switchTabAdvanced('backup')"
                >
                  Automatic Backup
                </div> -->
              </div>

              <!-- block storage  -->
              <div
                class="px-4 pt-4 pb-4 border border-white2 rounded-lg"
                v-if="tab.advanced && tabAdvanced.block"
              >
                <div
                  class="flex justify-between"
                  :class="volume.active ? 'mb-8' : ''"
                >
                  <div class="">Add Block Storage</div>
                  <div
                    class="w-12 rounded-full flex"
                    :class="
                      volume.active ? 'bg-green justify-end' : 'bg-white2'
                    "
                    @click="switchVolume()"
                  >
                    <div
                      class="w-6 h-6 bg-white rounded-full border-2"
                      :class="volume.active ? 'border-green' : 'border-white2'"
                    ></div>
                  </div>
                </div>
                <div class="mb-8" v-if="volume.active">
                  <!-- <vue-slider
                    ref="sliderBlockActive"
                    v-model="sliderBlockActive.value"
                    v-bind="sliderBlockActive.option"
                    :interval="10"
                    :order="false"
                  ></vue-slider> -->
                  <div class="grid grid-cols-7 gap-2 items-center mb-4">
                    <div class="col-span-2">Volume Label</div>
                    <div class="col-span-5 p-2 border border-white2 rounded-lg">
                      <input
                        type="text"
                        class="w-full outline-none"
                        v-model="deployParams.volume_name"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-7 gap-2 items-center">
                    <div class="col-span-2">Size</div>
                    <div
                      class="col-span-5 flex items-center justify-between p-2 border border-white2 rounded-lg"
                    >
                      <input
                        type="number"
                        v-model="deployParams.volume_size"
                        class="w-full outline-none flex-1"
                        @keypress="onlyNumber"
                      />
                      <div class="flex-none ml-4">GB</div>
                    </div>
                  </div>
                  <div
                    class="flex justify-end text-red text-sm"
                    :class="
                      deployParams.volume_size < 20 ||
                      deployParams.volume_size > selectedPlan.max_volume_size
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    *Size must be between 20GB and
                    {{ selectedPlan.max_volume_size }}GB
                  </div>
                </div>
                <!-- <div class="text-black" v-if="volume.active">
                  Rp
                  <span class="text-orange font-whitney-bold">5000</span> /month
                </div>
                <div class="text-darkBlueText text-sm" v-if="volume.active">
                  Rp 500 /hour
                </div> -->
              </div>

              <!--  IP  -->
              <div
                class="p-4 border border-white2 rounded-lg"
                v-if="tab.advanced && tabAdvanced.ip"
              >
                <div class="flex justify-between" :class="addIp ? 'mb-4' : ''">
                  <div class="">
                    <div class="">
                      Additional Public IP ({{ totalIPRequested }}/
                      {{ selectedPlan.quota_ip_address }})
                    </div>
                    <p class="text-dangerText text-xs">
                      *Free: 1 IPv4 and 1 IPv6 addresses already included in the
                      package
                    </p>
                  </div>

                  <div class="">
                    <div
                      class="w-12 rounded-full flex"
                      :class="addIp ? 'bg-green justify-end' : 'bg-white2'"
                      @click.prevent="switchIp(selectedPlan.quota_ip_address)"
                    >
                      <div
                        class="w-6 h-6 bg-white rounded-full border-2"
                        :class="addIp ? 'border-green' : 'border-white2'"
                      ></div>
                    </div>
                  </div>
                </div>

                <div
                  class=""
                  v-if="addIp && deployParams.configoptions.length > 0"
                >
                  <div
                    class="border border-gray px-4 py-2 flex mb-4 rounded-lg relative"
                    v-for="(ip, index) in deployParams.configoptions"
                    :key="index"
                  >
                    <div
                      class="absolute font-extrabold text-grayText cursor-pointer right-0 top-0 px-4 py-2"
                      v-if="index !== 0"
                      @click="deleteDeployIp(index)"
                    >
                      X
                    </div>
                    <div class="flex-1 pr-4">
                      <div class="text-slate mb-4">
                        Allocated IP
                        <!-- <span class="text-xs text-dark-gray"
                          >(Total IP Requested)</span
                        > -->
                      </div>
                      <div class="flex items-center justify-between">
                        <div
                          class="px-4 pb-1 bg-grayb rounded-lg text-2xl cursor-pointer"
                          @click="minValueIP(index)"
                        >
                          -
                        </div>
                        <div class="px-2 py-2">{{ ip.value }}</div>
                        <div
                          class="px-4 pb-1 bg-grayb rounded-lg text-xl cursor-pointer"
                          @click="addValueIp(index)"
                        >
                          +
                        </div>
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class="text-slate mb-4">IP Version</div>
                      <div class="relative">
                        <div
                          class="border flex items-center justify-between border-gray px-2 py-2 cursor-pointer"
                          @click="switchIpType(index)"
                        >
                          <div class="" v-if="ip.name">
                            {{ ip.name }}
                          </div>
                          <div class="" v-else>Choose</div>
                          <i
                            v-if="ip.active === false"
                            class="fas fa-chevron-down"
                          ></i>
                          <i
                            v-if="ip.active === true"
                            class="fas fa-chevron-up"
                          ></i>
                        </div>
                        <div
                          v-if="ip.active"
                          class="absolute border bg-white border-gray right-0 left-0 mt-1 z-10"
                        >
                          <div
                            class="p-2 text-slate hover:text-white hover:bg-blue cursor-pointer"
                            v-for="listIp in IpTypeOption"
                            :key="listIp.id"
                            @click="chooseIpType(listIp, index)"
                          >
                            {{ listIp.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex items-center justify-end"
                    v-if="totalIPRequested < selectedPlan.quota_ip_address"
                  >
                    <div
                      class="border border-gray px-4 py-2 text-center mb-4 hover:text-orange hover:border-orange cursor-pointer rounded-lg text-2xl inline-block"
                      @click="addDeployIp()"
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>

              <!-- backup -->
              <!-- <div
                class="p-4 border border-white2 rounded-lg"
                v-if="tab.advanced && tabAdvanced.backup"
              >
                <div class="flex justify-between">
                  <div class="">Automatic Backup</div>
                  <div
                    class="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in bg-white2 rounded-full mb-4"
                  >
                    <input
                      type="checkbox"
                      name="toggle"
                      id="toggle"
                      v-model="backupCheked"
                      class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-2 border-white2 outline-none appearance-none cursor-pointer"
                    />
                    <label
                      for="toggle"
                      class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                    ></label>
                  </div>
                </div>
                <div class="flex" v-if="backupCheked">
                  <div
                    class="flex items-center mr-4 cursor-pointer"
                    @click="
                      (radioChekcked.custom = true),
                        (radioChekcked.daily = false),
                        (radioChekcked.monthly = false)
                    "
                  >
                    <div
                      class="w-5 h-5 rounded-full border-2 flex items-center justify-center mr-4"
                      :class="
                        radioChekcked.custom ? 'border-orange' : 'border-grayb'
                      "
                    >
                      <div
                        class="w-3 h-3 rounded-full bg-orange"
                        v-if="radioChekcked.custom"
                      ></div>
                    </div>
                    <div class="">Custom</div>
                  </div>
                  <div
                    class="flex items-center mr-4 cursor-pointer"
                    @click="
                      (radioChekcked.custom = false),
                        (radioChekcked.daily = true),
                        (radioChekcked.monthly = false)
                    "
                  >
                    <div
                      class="w-5 h-5 rounded-full border-2 flex items-center justify-center mr-4"
                      :class="
                        radioChekcked.daily ? 'border-orange' : 'border-grayb'
                      "
                    >
                      <div
                        class="w-3 h-3 rounded-full bg-orange"
                        v-if="radioChekcked.daily"
                      ></div>
                    </div>
                    <div class="">Daily</div>
                  </div>
                  <div
                    class="flex items-center cursor-pointer"
                    @click="
                      (radioChekcked.custom = false),
                        (radioChekcked.daily = false),
                        (radioChekcked.monthly = true)
                    "
                  >
                    <div
                      class="w-5 h-5 rounded-full border-2 flex items-center justify-center mr-4"
                      :class="
                        radioChekcked.monthly ? 'border-orange' : 'border-grayb'
                      "
                    >
                      <div
                        class="w-3 h-3 rounded-full bg-orange"
                        v-if="radioChekcked.monthly"
                      ></div>
                    </div>
                    <div class="">Monthly</div>
                  </div>
                </div>
                <div class="flex" v-else>
                  <div class="flex items-center mr-4 cursor-not-allowed">
                    <div
                      class="w-5 h-5 rounded-full border-2 border-grayb flex items-center justify-center mr-4"
                    ></div>
                    <div class="text-grayb">Custom</div>
                  </div>
                  <div class="flex items-center mr-4 cursor-not-allowed">
                    <div
                      class="w-5 h-5 rounded-full border-2 border-grayb flex items-center justify-center mr-4"
                    ></div>
                    <div class="text-grayb">Daily</div>
                  </div>
                  <div class="flex items-center cursor-not-allowed">
                    <div
                      class="w-5 h-5 rounded-full border-2 border-grayb flex items-center justify-center mr-4"
                    ></div>
                    <div class="text-grayb">Monthly</div>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- end Advanced Optionn -->
          </form>
        </div>

        <!-- billings and most populer option -->
        <div class="w-1/4 mt-4 pb-16 sticky" style="top: 82px">
          <!-- billings  -->
          <div class="">
            <div class="p-4 billing rounded-lg mx-4">
              <h2 class="font-nunito text-white font-bold text-xl mb-2">
                Billing
              </h2>
              <div class="mb-2" v-if="plan && selectedPlan">
                <h3 class="text-white mb-2">Package</h3>
                <div class="flex justify-between text-white text-sm">
                  <div class="" v-if="planOption.gp">
                    {{
                      selectedPlan.name ? selectedPlan.name : "General Purpose"
                    }}
                  </div>
                  <!-- <div class="" v-if="planOption.mo">Memory Optimize</div>
                  <div class="" v-if="planOption.dc">Dedicated CPU</div> -->
                  <div class="flex-none">
                    Rp.
                    {{
                      selectedPlan.price_monthly
                        ? formatRupiah(selectedPlan.price_monthly.toString())
                        : 0
                    }}
                  </div>
                </div>
              </div>
              <div class="mb-2" v-if="addIp || volume.active">
                <h3 class="text-white mb-2">Additional Package</h3>
                <div
                  class="flex justify-between text-white text-sm mb-2"
                  v-if="volume.active"
                >
                  <div class="">Block Storage</div>
                  <div class="flex-none">
                    Rp.
                    {{
                      deployParams.volume_size
                        ? formatRupiah(
                            (deployParams.volume_size * 10000).toString()
                          )
                        : 0
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between text-white text-sm"
                  v-if="addonIPPrice !== 0 && addIp"
                >
                  <div class="">Additional IP</div>
                  <div class="">
                    Rp.
                    {{
                      addonIPPrice ? formatRupiah(addonIPPrice.toString()) : 0
                    }}
                  </div>
                </div>
              </div>

              <div
                class="py-2 border-b border-t border-dashed border-white text-white border-opacity-25 mb-2"
              >
                <div class="text-sm flex justify-between mb-2">
                  <div class="">Subtotal</div>
                  <div class="">
                    Rp.
                    {{
                      totalPayment ? formatRupiah(subtotal.toString()) : "0.00"
                    }}
                  </div>
                </div>
                <div
                  class="text-sm flex justify-between"
                  v-if="!deployParams.apply_credit"
                >
                  <div class="">PPN 10%</div>
                  <div class="">
                    Rp.
                    {{ totalPayment ? formatRupiah(tax.toString()) : "0.00" }}
                  </div>
                </div>
              </div>

              <div class="flex justify-between">
                <h3 class="text-white mb-2">Total</h3>
                <h3 class="text-white mb-2" v-if="totalPayment">
                  Rp. {{ formatRupiah(totalPayment.toString()) }}
                </h3>
              </div>

              <div
                class="p-2 border border-white rounded-lg text-sm text-white mb-6"
                v-if="
                  totalPayment &&
                    userdata &&
                    parseInt(userdata.credit) >= totalPayment
                "
              >
                <div
                  class="flex items-center cursor-pointer mb-2"
                  @click="$store.commit('deployVM/changeApplyCredit', true)"
                >
                  <div
                    class="w-4 h-4 rounded-full border hover:border-orange cursor-pointer flex items-center justify-center mr-2"
                    :class="
                      deployParams.apply_credit ? 'border-white' : 'border-gray'
                    "
                  >
                    <div
                      v-if="deployParams.apply_credit"
                      class="w-2 h-2 rounded-full bg-white"
                    ></div>
                  </div>
                  <div class="">Apply balance</div>
                </div>
                <div
                  class="flex items-center cursor-pointer"
                  @click="$store.commit('deployVM/changeApplyCredit', false)"
                >
                  <div
                    class="w-4 h-4 rounded-full border hover:border-orange cursor-pointer flex items-center justify-center mr-2"
                    :class="
                      deployParams.apply_credit ? 'border-gray' : 'border-white'
                    "
                  >
                    <div
                      v-if="!deployParams.apply_credit"
                      class="w-2 h-2 rounded-full bg-white"
                    ></div>
                  </div>
                  <div class="">Direct payment</div>
                </div>
              </div>

              <button
                class="w-full py-2 text-white border border-white bg-none rounded-lg focus:outline-none hover:bg-white hover:text-orange"
                v-if="submit && totalPayment"
                @click.prevent="postInstance()"
              >
                Order Now
              </button>
              <button
                class="w-full py-2 text-darkBlueText bg-white rounded-lg focus:outline-none cursor-not-allowed"
                v-else
              >
                Order Now
              </button>
            </div>
          </div>
          <!-- end billings  -->
        </div>

        <!-- end -->
      </div>
    </div>

    <invoice-detail
      v-if="invoice.active"
      :snap="invoice.snap"
      :data="invoice.data"
      @dismiss="(invoice.active = false), $router.push('/dashboard')"
      @changePayment="(invoice.active = false), getInvoiceDetail()"
    />
    <sshComponent
      v-if="sshtemplate.active"
      :will_dismiss="sshtemplate.dismiss"
      @dismiss="sshtemplate.active = false"
      @success="(sshtemplate.active = false), getSSH()"
    />
    <Notification
      v-if="notif.show"
      @dismiss="closeNotif()"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import sshComponent from "@/components/addssh.vue";
import Notification from "@/components/Notification.vue";
// import planTemplate from "@/components/planTemplate.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Zone from "../../components/VMCreate/zone.vue";
import Template from "../../components/VMCreate/template.vue";
import Package from "../../components/VMCreate/package.vue";
import InvoiceDetail from "../../components/invoiceDetail.vue";
export default {
  name: "createInstance",
  components: {
    sidebar,
    navbar,
    sshComponent,
    Notification,
    Zone,
    Template,
    Package,
    InvoiceDetail
    // planTemplate,
  },
  watch: {
    addIp(e) {
      if (!e && this.deployParams.zone) {
        this.addonIPPrice = 0;
        this.$store.commit(
          "deployVM/changeTotalPayment",
          this.selectedPlan.price_monthly +
            (this.volume.active
              ? this.addonIPPrice + this.deployParams.volume_size * 10000
              : 0)
        );
        this.totalIPRequested = 0;
      }
    },
    "volume.active"(e) {
      if (e && this.deployParams.zone) {
        this.$store.commit(
          "deployVM/changeTotalPayment",
          this.selectedPlan.price_monthly +
            this.addonIPPrice +
            this.deployParams.volume_size * 10000
        );
      } else if (!e && this.deployParams.zone) {
        this.$store.commit(
          "deployVM/changeTotalPayment",
          this.selectedPlan.price_monthly + this.addonIPPrice
        );
      }
    },
    "deployParams.volume_size"() {
      if (this.volume.active) {
        this.$store.commit(
          "deployVM/changeTotalPayment",
          this.selectedPlan.price_monthly +
            this.addonIPPrice +
            this.deployParams.volume_size * 10000
        );
      } else {
        if (this.selectedPlan) {
          this.$store.commit(
            "deployVM/changeTotalPayment",
            this.selectedPlan.price_monthly + this.addonIPPrice
          );
        }
      }
    },
    "deployParams.zone": function(e) {
      if (e) {
        this.tab.datacenter = false;
        this.tab.os = true;
        this.tab.package = true;
        this.$store.commit("template/updateTemplateOption", "default");
        this.$store.dispatch("template/getTemplate");
        this.$store.dispatch("plan/getPlan");
        if (this.userdata && this.userdata.credit >= this.totalPayment) {
          this.$store.commit("deployVM/changeApplyCredit", true);
        }

        this.getSSH();
      }
    },
    selectedPlan(e) {
      // console.log("ini E Selected Plan", e);
      if (this.deployParams.zone) {
        this.deployParams.plan_id = e.id;
        this.$store.commit(
          "deployVM/changeTotalPayment",
          this.selectedPlan.price_monthly
        );
        this.$store.dispatch("plan/getPlanConfig", this.selectedPlan.id);
      }
    },
    selectedTemplate(e) {
      if (e) {
        if (this.templateOption === "default") {
          this.deployParams.template_id = e.id;
          this.deployParams.template_type = "default";
          this.tab.os = false;

          this.tab.auth = true;
          // this.tabAdvanced.block = false;
          // this.tabAdvanced.hostname = true;
          this.tab.hostname = true;
          // this.tab.advanced = true;
          this.deployParams.hostname = this.generateHostnameOS(
            e.name.split(" ")[0],
            e.name.split(" ")[1],
            this.deployParams.zone
          );
          // console.log(
          //   this.generateHostnameOS(
          //     e.name.split(" ")[0],
          //     e.name.split(" ")[1],
          //     this.deployParams.zone
          //   )
          // );
        } else if (this.templateOption === "app") {
          this.deployParams.template_id = e.id;
          this.deployParams.template_type = "app";

          this.tab.auth = true;
          // this.tabAdvanced.block = false;
          // this.tabAdvanced.hostname = true;
          this.tab.hostname = true;
        }
      }
    }
  },
  computed: {
    ...mapState([
      "sshList",
      "volume",
      // "IpTypeOption",
      "minimize",
      "payment"
    ]),
    plan() {
      return this.$store.state.plan.plan;
    },
    packageBilling() {
      return this.$store.state.deployVM.packageBilling;
    },
    addIp() {
      return this.$store.state.deployVM.addIp;
    },
    planOption() {
      return this.$store.state.plan.planOption;
    },
    selectedPlan() {
      return this.$store.state.plan.selectedPlan;
    },
    totalPayment() {
      let total = this.deployParams.apply_credit
        ? this.subtotal
        : this.subtotal + this.tax;

      return total;
    },
    deployParams() {
      return this.$store.state.deployVM.deployParams;
    },
    template() {
      return this.$store.state.template.template;
    },
    templateOption() {
      return this.$store.state.template.templateOption;
    },
    selectedTemplate() {
      return this.$store.state.template.selectedTemplate;
    },
    IpTypeOption() {
      return this.$store.state.plan.IpTypeOption;
    },
    invoiceDetail() {
      return this.$store.state.invoice.invoiceDetail;
    },
    userdata() {
      return this.$store.state.userdata;
    },
    tax() {
      return this.$store.state.deployVM.totalPayment * 0.1;
    },
    subtotal() {
      return this.$store.state.deployVM.totalPayment;
    },

    optionTabPackage() {
      if (this.tab.package && this.plan) {
        return "shadow-lg h-auto transition duration-500 ease-in-out pb-16";
      } else if (this.tab.package && !this.plan) {
        return "shadow-lg h-auto transition duration-500 ease-in-out pb-6";
      } else {
        return "height-tab transition duration-500 ease-in-out pb-6";
      }
    }
  },

  created() {
    document.title = "Nevacloud | Create Instance";
  },
  data: function() {
    return {
      invoice: {
        active: false,
        snap: null,
        data: null
      },
      radioChekcked: {
        custom: false,
        daily: false,
        monthly: false
      },
      backupCheked: false,
      notif: {
        show: false,
        header: null,
        message: null,
        active: false
      },
      submit: true,
      authOption: {
        ssh: true,
        password: false
      },
      tab: {
        datacenter: true,
        os: false,
        package: false,
        auth: false,
        hostname: false,
        advanced: false
      },
      tabAdvanced: {
        block: true,
        ip: false,
        backup: false,
        hostname: false
      },
      totalIPRequested: 0,
      sliderBlockActive: {
        value: 20,
        option: {
          dotSize: 24,
          height: 10,
          width: "auto",
          contained: true,
          min: 20,
          max: 50,
          silent: true,
          marks: {
            // 10: "10 GB",
            20: "20 GB",
            30: "30 GB",
            40: "40 GB",
            50: "50 GB"
          },
          duration: 0.5,
          tooltip: "none"
        }
      },

      ssh: null,
      sshtemplate: {
        active: false,
        dismiss: false
      },
      password: {
        data: null,
        active: false,
        message: null
      },
      tMessage: null,
      pMessage: null,
      sMessage: null,
      hMessage: null,
      passwordMessage: null,
      volumeMessage: null,
      ipMessage: null,
      addonIPPrice: null,
      vmID: null
    };
  },
  mounted: function() {
    if (this.$router.currentRoute.query.apps && this.deployParams.zone) {
      this.tab.datacenter = false;
      this.tab.os = true;
      // this.tab.package = true;
      this.$store.commit(
        "template/selectApp",
        this.$router.currentRoute.query.apps
      );

      this.$store.dispatch("plan/getPlan");
      this.getSSH();
      this.tab.auth = true;
      this.tab.hostname = true;
    } else {
      this.$store.commit("deployVM/resetDeployParams");
      this.$store.commit("deployVM/resetAdvance");
      this.$store.commit("plan/resetSelectedPlan");
      this.$store.commit("resetVolume");
      this.$store.commit("zone/resetZoneList");
      this.$store.commit("template/resetSelectedTemplate");
      // if (!this.zoneList) {
      //   this.getZone();
      // }
      this.getZone();
    }
  },

  methods: {
    ...mapActions(["getSSH", "getPlan"]),
    ...mapMutations(["switchVolume", "switchPersistent"]),
    addInstance() {
      this.$store.dispatch("deployVM/addInstance").then(response => {
        this.submit = true;
        if (response.status == 200) {
          // this.g_notif_create(true, "Success", "Instance Added");
          this.vmID = response.data.invoice_id;
          this.getInvoiceDetail();

          // this.$router.push("/dashboard");
        } else {
          this.$store.commit("changeLoadingFull", false);
          this.g_notif_create(false, "Failed", response.error.message);
          // this.submit = true;
        }
      });
      this.checkDeployIP();
    },
    getInvoiceDetail() {
      if (!this.$store.state.loadingFull) {
        this.$store.commit("changeLoadingFull", true);
      }
      this.$store
        .dispatch("invoice/getInvoiceDetail", {
          id: this.vmID,
          query: this.payment.method
        })
        .then(res => {
          this.$store.commit("changeLoadingFull", false);
          this.invoice.snap = res.snap_token;
          this.invoice.data = res.data;
          this.invoice.active = true;
        });
    },
    switchIp(data) {
      this.$store.commit("deployVM/switchIp", data);
    },
    addDeployIp(data) {
      let val = 0;
      this.deployParams.configoptions.forEach(dc => {
        val += dc.value;
      });
      let valNull = this.deployParams.configoptions.filter(res => {
        return res.value === 0;
      });
      let idNull = this.deployParams.configoptions.filter(res => {
        return res.id === null;
      });
      if (valNull.length > 0) {
        alert("Please Fill The IP Size");
      } else if (idNull.length > 0) {
        alert("Please Choose IP Type");
      } else if (val + 1 <= this.selectedPlan.quota_ip_address) {
        this.$store.commit("deployVM/addDeployIp", data);
        // this.totalIPRequested = val + 1;
      } else {
        alert("Maximum IP Request");
      }

      this.checkDeployIP();
    },
    checkDeployIP() {
      if (this.deployParams.configoptions.length > 0) {
        this.addonIPPrice = 0;
        let val = 0;
        // console.log("cek", this.deployParams.configoptions);
        this.deployParams.configoptions.forEach(dc => {
          this.addonIPPrice += dc.value * dc.price;
          val += dc.value;
        });
        this.totalIPRequested = val;

        this.$store.commit(
          "deployVM/changeTotalPayment",
          this.selectedPlan.price_monthly +
            this.addonIPPrice +
            (this.volume.active ? this.deployParams.volume_size * 10000 : 0)
        );
      } else {
        this.addonIPPrice = 0;
      }
    },
    deleteDeployIp(data) {
      this.$store.commit("deployVM/deleteDeployIp", data);
      this.checkDeployIP();
    },
    addValueIp(data) {
      let val = 0;
      this.deployParams.configoptions.forEach(dc => {
        val += dc.value;
      });

      if (val + 1 <= this.selectedPlan.quota_ip_address) {
        this.$store.commit("deployVM/addValueIp", data);
      } else {
        alert("Maximum IP Request");
      }
      this.checkDeployIP();
    },
    minValueIP(data) {
      if (this.deployParams.configoptions[data].value !== 0) {
        this.$store.commit("deployVM/minValueIP", data);
      }
      this.checkDeployIP();
    },
    switchIpType(data) {
      this.$store.commit("deployVM/switchIpType", data);
      this.checkDeployIP();
    },
    getZone() {
      this.$store.dispatch("zone/getZone");
    },

    switchTabAdvanced(tab) {
      if (tab === "block") {
        this.tabAdvanced = {
          block: true,
          ip: false,
          backup: false,
          hostname: false
        };
      }
      if (tab === "ip") {
        this.tabAdvanced = {
          block: false,
          ip: true,
          backup: false,
          hostname: false
        };
      }
      if (tab === "backup") {
        this.tabAdvanced = {
          block: false,
          ip: false,
          backup: true,
          hostname: false
        };
      }
      if (tab === "hostname") {
        this.tabAdvanced = {
          block: false,
          ip: false,
          backup: false,
          hostname: true
        };
      }
    },
    chooseIpType(listIp, index) {
      this.$store.commit("deployVM/chooseIpType", {
        listIp: listIp,
        index: index
      });
      this.switchIpType(index);
    },

    changeSSH(id) {
      this.$store.commit("changeSSH", { id });
      // this.$store.commit("deployVM/changeSSH", {id})

      this.sshList.forEach(ssh => {
        if (ssh.id == id) {
          if (ssh.active) {
            const sshkeyList = this.deployParams.sshKey.find(sshkeyList => {
              sshkeyList.id == id;
            });
            if (!sshkeyList) {
              this.deployParams.sshKey.push(ssh.id);
            }
          } else {
            const sshkeyList = this.deployParams.sshKey.indexOf(id);
            this.deployParams.sshKey.splice(sshkeyList, 1);
          }
        }
      });
    },

    pickImage(data) {
      this.$store.commit("pickImage", data);
    },

    selectVolume(data) {
      this.$store.commit("changeVolume", { data });
    },

    postInstance() {
      this.tMessage = null;
      this.pMessage = null;
      this.sMessage = null;
      this.passwordMessage = null;
      this.volumeMessage = null;
      this.ipMessage = null;
      if (!this.deployParams.template_id) {
        this.tMessage = "Please Choose Template";
        alert("Please Choose Template");
        return;
      }
      if (this.deployParams.template_type === "app") {
        for (var key in this.selectedTemplate.form) {
          if (!this.selectedTemplate.form[key].value) {
            alert("Please fill form " + key);
            return;
          }
        }
      }

      if (!this.deployParams.plan_id) {
        this.pMessage = "Please Choose Package";
        alert("Please Choose Package");
        return;
      }

      if (this.volume.active) {
        if (!this.deployParams.volume_name) {
          alert("Volume label is required");
          return;
        }
        if (
          this.deployParams.volume_size < 20 ||
          this.deployParams.volume_size > this.selectedPlan.max_volume_size
        ) {
          alert(
            "Volume size must be between 20GB and " +
              this.selectedPlan.max_volume_size +
              " GB"
          );
          return;
        }
      }

      if (this.authOption.ssh) {
        if (this.deployParams.sshKey.length == 0) {
          this.sMessage = "Please Choose Authentication";
          alert("Please Choose SSH");
          return;
        }
        this.deployParams.vm_password = null;
      }

      if (this.authOption.password) {
        if (!this.deployParams.vm_password) {
          this.passwordMessage = "Password can not be empty";
          alert("Password can not be empty");
          return;
        }
        if (this.deployParams.vm_password.length < 8) {
          this.passwordMessage = "The password must be at least 8 characters.";
          alert("The password must be at least 8 characters.");
          return;
        }
        this.deployParams.sshKey = [];
      }

      if (!this.deployParams.hostname) {
        this.hMessage = "Hostname can not be empty";
        alert("Hostname can not be empty");
        return;
      }
      // eslint-disable-next-line
      const hostnameChecker = /^[a-zA-Z0-9.-]+$/;
      if (!this.deployParams.hostname.match(hostnameChecker)) {
        this.hMessage = "Space Not Allowed";
        alert("Space Not Allowed");
        return;
      }
      if (this.deployParams.hostname.length <= 8) {
        this.hMessage = "Hostname must be at least 8 characters.";
        alert("Hostname must be at least 8 characters.");
        return;
      }

      let valNull = this.deployParams.configoptions.filter(res => {
        return res.value === 0;
      });
      if (valNull.length > 0) {
        alert("Please Fill The IP Size");
        return;
      }
      valNull = this.deployParams.configoptions.filter(res => {
        return res.id === null;
      });
      if (valNull.length > 0) {
        alert("Please Choose IP Type");
        return;
      }

      this.submit = false;
      // console.log("deploy param", this.deployParams);

      this.$store.commit("changeLoadingFull", true);
      this.addInstance();
    },
    closeNotif() {
      if (!this.notif.active) {
        this.notif.show = false;
      } else {
        this.notif.show = false;
        this.$router.push("/dashboard");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.submit-container {
  position: fixed;
  bottom: 0;
  right: 24px;
}
.billing {
  background: rgb(239, 91, 12);
  background: linear-gradient(
    147deg,
    rgba(239, 91, 12, 1) 0%,
    rgba(213, 53, 60, 1) 100%
  );
}
.height-tab {
  height: 77px;
}
</style>
