import axios from "axios";
import Vue from "vue";

export default {
  state: {
    template: {},
    templateOption: "default",
    selectedTemplate: null
  },
  actions: {
    async getTemplate({ state, commit, rootState }, data) {
      const url = process.env.VUE_APP_SERVICE_URL + "template";

      let token = null;
      if (!data) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      var type = null;
      if (state.templateOption === "default") {
        type = "default";
      } else if (state.templateOption === "backup") {
        type = "backup";
      } else {
        type = "app";
      }
      if (!rootState.deployVM.deployParams.zone) {
        return;
      } else {
        try {
          const response = await axios.get(url, {
            params: {
              zone: rootState.deployVM.deployParams.zone,
              type: type
            },
            credentials: true,
            headers: header
          });
          if (type === "default") {
            commit("updateTemplate", response.data.data);
          } else if (type === "backup") {
            //belum di handle
            commit("updateImages", response.data);
          } else if (type === "app") {
            commit("updateTemplateApps", response.data.data);
          }
          return response.data;
        } catch (err) {
          console.log(err);
        }
      }
    }
  },
  mutations: {
    updateTemplate: (state, data) => {
      function sortObj(obj) {
        return Object.keys(obj)
          .sort()
          .reverse()
          .reduce(function(result, key) {
            result[key] = obj[key];
            return result;
          }, {});
      }
      let template = {};
      data.forEach(data => {
        if (!Object.prototype.hasOwnProperty.call(template, data.group)) {
          template[data.group] = {
            active: false,
            data: [],
            version: null
          };
          template[data.group].data.push(data);
        } else {
          template[data.group].data.push(data);
        }
      });

      let orderedKey = sortObj(template);

      for (var key in orderedKey) {
        orderedKey[key].data
          .sort(function(a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .reverse();
      }

      Vue.set(state, "template", orderedKey);
    },
    updateTemplateApps: (state, data) => {
      data.forEach(dat => {
        dat.active = false;
        Object.keys(dat.form).forEach(key => {
          dat.form[key].value = null;
        });
      });
      Vue.set(state, "template", data);
    },
    changeTemplate: (state, data) => {
      for (var key in state.template) {
        if (data.data.group === key) {
          state.template[key].version = data.data.name;
          state.selectedTemplate = data.data;
        } else {
          state.template[key].version = null;
          state.template[key].active = false;
        }
      }
      // console.log("state.template", state.template);
    },
    openTemplate: (state, data) => {
      var key = data.data[0].group;
      // state.template.forEach(temp => {

      // });
      for (var name in state.template) {
        if (name === key && !state.template[name].active) {
          state.template[name].active = true;
        } else {
          state.template[name].active = false;
        }
      }
      // state.template = {
      //   ...state.template,
      //   [key]: { ...state.template[key], active: !state.template[key].active },
      // };
      // console.log(state.template);
    },
    closeAlltemplate: state => {
      for (var name in state.template) {
        state.template[name].active = false;
      }
    },
    updateTemplateOption: (state, data) => {
      state.template = {};
      state.templateOption = data;
    },
    resetTemplate: state => {
      state.template = {};
      state.templateOption = "default";
    },
    resetSelectedTemplate: state => {
      state.selectedTemplate = null;
    },
    selectApp: (state, data) => {
      let filter = state.template.filter(res => {
        return res.id === data;
      });

      state.template.forEach(res => {
        if (res.id === data) {
          res.active = true;
        } else {
          res.active = false;
        }
      });

      state.selectedTemplate = filter[0];
      console.log("selected", state.selectedTemplate);
    }
  },

  namespaced: true
};
