<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10 pb-10 flex flex-col">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Ticket Detail
            </h1>
          </div>
        </div>
        <!-- reply -->
        <div class="" v-if="ticketDetail">
          <div class="">
            <!-- head -->
            <div
              class="bg-grayForm p-4 rounded-lg text-black text-opacity-70 flex justify-between mb-4"
            >
              <div class="">
                <div class="flex items-center mb-2 font-bold">
                  <div class="w-24">Subject</div>
                  <div class="">: {{ ticketDetail.subject }}</div>
                </div>
                <div class="flex items-center text-sm">
                  <div class="w-24">Department</div>
                  <div class="">: {{ ticketDetail.department_name }}</div>
                </div>
              </div>
              <div class="text-right">
                <div class="mb-2">Status : {{ ticketDetail.status }}</div>
                <div class="text-sm">
                  {{ formatDate(ticketDetail.date) }}
                  {{ formatTime(ticketDetail.date) }}
                </div>
              </div>
            </div>
            <!-- content -->
            <div
              class="border border-gray p-4 rounded-lg mb-2"
              v-for="(rep, index) in ticketDetail.reply"
              :key="index"
            >
              <div
                class="text-black text-opacity-80 flex items-center justify-between mb-4"
              >
                <div class="font-bold">{{ rep.name }}</div>
                <div class="text-sm">
                  {{ formatDate(rep.date) }} {{ formatTime(rep.date) }}
                </div>
              </div>

              <div
                class="text-sm p-4 bg-grayForm text-black text-opacity-80 rounded-lg mb-4"
              >
                <vue-markdown>{{ rep.message }}</vue-markdown>
              </div>
              <div class="" v-if="rep.attachment.length > 0">
                Attachment :
                <span v-for="(att, index) in rep.attachment" :key="index"
                  ><a
                    target="_blank"
                    :href="att.download"
                    class="text-blue cursor-pointer"
                    >{{ att.filename }}</a
                  >
                  <span class="mx-1" v-if="index !== rep.attachment.length - 1"
                    >|</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- button add reply -->
        <div class="mb-4" v-if="ticketDetail">
          <button
            class="text-orange bg-dangerForm py-3 w-full rounded-lg"
            v-if="!showForm"
            @click="showForm = true"
          >
            Add Reply
          </button>
          <button
            class="text-opacity-80 bg-dark-gray text-white py-3 w-full rounded-lg"
            v-else
            @click="showForm = false"
          >
            Close Reply
          </button>
        </div>
        <!-- form -->
        <div class="" v-if="showForm">
          <form @submit.prevent="confirmation_active = true">
            <div class="mb-6">
              <div class="text-slate text-md mb-2">
                Message
                <span class="text-xs text-red" v-if="mError">*Required</span>
              </div>
              <vue-editor
                :editorToolbar="customToolbar"
                v-model="form.message"
                class=""
              ></vue-editor>
            </div>

            <div class="mb-6">
              <div class="text-slate text-md mb-2">Attachment</div>
              <div class="w-full p-4 rounded-lg border border-gray">
                <div
                  class="bg-gray py-1 px-2 rounded-lg inline-block mr-2"
                  v-for="(file, index) in form.files"
                  :key="index"
                >
                  <div class="mr-2 inline-block">{{ file.name }}</div>
                  <div
                    class="inline-block text-black cursor-pointer text-opacity-80"
                    @click="spliceFile(index)"
                  >
                    x
                  </div>
                </div>
                <button
                  class="bg-grayb py-1 px-2 rounded-lg"
                  @click.prevent="chooseFile()"
                >
                  Choose File
                </button>
                <input
                  type="file"
                  @change="onFileChange"
                  multiple="multiple"
                  hidden
                  id="fileTicket"
                />
              </div>
            </div>

            <div class="text-right">
              <button
                @click="
                  (showForm = false), (form.message = null), (form.files = [])
                "
                class="px-10 py-3 bg-gray mr-6 text-white rounded-lg"
              >
                Cancel
              </button>
              <button
                @click.stop=""
                v-if="sendButton"
                class="px-10 py-3 bg-gray mr-6 text-white rounded-lg"
              >
                Submit
              </button>
              <button
                type="submit"
                v-else
                class="px-10 py-3 bg-orange text-white rounded-lg"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <!-- end form -->
      </div>
    </div>
    <confirmation
      v-if="confirmation_active"
      @accept="submitTicket()"
      @dismiss="confirmation_active = false"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import { VueEditor } from "vue2-editor";
import confirmation from "@/components/confirmation.vue";

import { mapState } from "vuex";
export default {
  name: "Images",
  components: {
    sidebar,
    navbar,
    VueEditor,
    confirmation
  },
  created() {
    document.title = "Nevacloud | Ticket Detail";
    // if (!this.networks) {
    //   this.g_loading_create("Loading");
    // }
    // this.getVM().then(() => {
    //   this.g_loading_destroy();
    // });
  },
  computed: {
    ...mapState({
      networks: "networks",
      minimize: "minimize"
    }),
    ticketDetail() {
      return this.$store.state.support.ticketDetail;
    }
  },

  data: function() {
    return {
      sendButton: false,
      confirmation_active: false,
      showForm: false,
      mError: false,
      files: null,
      form: {
        id: null,
        message: null,
        files: []
      },

      customToolbar: [
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
        // [
        //   { align: "" },
        //   { align: "center" },
        //   { align: "right" },
        //   { align: "justify" },
        // ],
        // [{ color: [] }],
      ]
    };
  },
  mounted() {
    this.$store.commit("support/clearTicketDetail");
    this.getDetailTicket();
    this.form.id = this.$router.currentRoute.query.id;
  },
  methods: {
    onFileChange(e) {
      if (!e.target.files.length) return;
      e.target.files.forEach(file => {
        this.form.files.push(file);
      });
    },
    spliceFile(index) {
      this.form.files.splice(index, 1);
    },

    chooseFile() {
      document.getElementById("fileTicket").click();
    },

    getDetailTicket() {
      this.g_loading_create("Loading");
      this.$store
        .dispatch("support/getTicketDetail", this.$router.currentRoute.query.id)
        .then(res => {
          this.g_loading_destroy();
          res.status !== 200
            ? this.g_notif_create(false, "False", "Something went Wrong")
            : "";
        });
    },

    submitTicket() {
      this.confirmation_active = false;
      this.mError = false;
      if (!this.form.message) {
        this.mError = true;
        return;
      }

      let message = this.turndownMarkdown(this.form.message);

      let newForm = {
        id: this.form.id,
        message: message,
        files: this.form.files
      };
      this.sendButton = true;
      this.g_loading_create("Loading");
      this.$store.dispatch("support/replyTicket", newForm).then(res => {
        this.g_loading_destroy();
        this.sendButton = false;
        if (res.status === 200) {
          this.g_notif_create(true, "Succes", "Reply has been send");
          this.$router.push("/support");
        } else {
          console.log(res);
          this.g_notif_create(false, "Failed", "Something went wrong");
        }
      });
    }
  }
};
</script>

<style>
.ql-toolbar.ql-snow {
  border-color: #dbdfe6 !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.ql-container.ql-snow {
  border-color: #dbdfe6 !important;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
</style>
