<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10 pb-16">
        <h1 class="text-3xl font-nunito font-bold text-black mb-10">Billing</h1>
        <div
          class=""
          :class="
            bilNavigation === 'add' ? 'flex justify-between items-start' : ''
          "
        >
          <div class="flex-1 mr-8">
            <div class="flex items-center justify-between mb-10">
              <div class="flex">
                <div
                  class="px-4 py-2 cursor-pointer"
                  :class="
                    bilNavigation === 'add'
                      ? 'text-black bg-grayForm rounded-full'
                      : 'text-darkBlueText'
                  "
                  @click="bilNavigation = 'add'"
                >
                  Add Deposit
                </div>
                <div
                  class="px-4 py-2 cursor-pointer"
                  :class="
                    bilNavigation === 'invoice'
                      ? 'text-black bg-grayForm rounded-full'
                      : 'text-darkBlueText'
                  "
                  @click="bilNavigation = 'invoice'"
                >
                  Invoice
                </div>
                <div
                  class="px-4 py-2 cursor-pointer"
                  :class="
                    bilNavigation === 'bHistory'
                      ? 'text-black bg-grayForm rounded-full'
                      : 'text-darkBlueText'
                  "
                  @click="bilNavigation = 'bHistory'"
                >
                  Orders
                </div>
              </div>
              <!-- <div
                class="w-1/5 bg-grayForm py-3 px-4 text-darkBlueText flex rounded-lg"
                @click="$refs.search.focus()"
                :class="bilNavigation === 'add' ? 'invisible' : 'visible'"
              >
                <div class="mr-4 text-xl"><i class="fas fa-search"></i></div>
                <input
                  type="text"
                  ref="search"
                  class="outline-none w-full bg-grayForm"
                  placeholder="Search"
                />
              </div> -->
            </div>

            <!-- bilnav add -->
            <div class="" v-if="bilNavigation === 'add'">
              <div class="mb-4">
                <div class="flex">
                  <div class="flex-1 mr-4">
                    <div class="text-orange text-xl font-nunito font-bold">
                      Deposit
                    </div>
                    <div class="text-darkBlueText mb-4">
                      The deposit will be added to your credit balance. You will
                      have the option to pay for services directly from your
                      credit balance and avoid service suspension.
                    </div>
                  </div>
                  <div
                    class="p-8 border border-white2 rounded-lg"
                    v-if="billingData"
                  >
                    <div class="text-darkBlueText">
                      Remaining Account Deposit
                    </div>
                    <div
                      class="text-lg text-orange font-whitney-semibold"
                      v-if="billingData"
                    >
                      IDR {{ formatRupiah(billingData.credits.toString()) }}
                    </div>
                    <div class="text=lg text-red" v-else>Can't Get Data</div>
                  </div>
                </div>
              </div>
              <div
                class="p-8 border border-white2 rounded-lg mb-4"
                v-if="paymentOption === 'midtrans'"
              >
                <div class="font-bold mb-4">Payment Methods:</div>
                <div class="grid grid-cols-5 gap-3 mb-4">
                  <div
                    class="border border-white2 rounded-lg flex items-center justify-center py-4 cursor-pointer hover:border-orange hover:text-orange"
                    @click="changePayment('bca_va', 'BCA Virtual Account')"
                  >
                    <div class="">
                      <img
                        src="@/assets/payments/bca.jpg"
                        class="w-3/4 mx-auto mb-2"
                        alt=""
                      />
                      <div class="text-center text-sm font-bold">
                        BCA Virtual Account
                      </div>
                    </div>
                  </div>
                  <div
                    class="border border-white2 rounded-lg flex items-center justify-center py-4 cursor-pointer hover:border-orange hover:text-orange"
                    @click="changePayment('bri_va', 'BRI Virtual Account')"
                  >
                    <div class="">
                      <img
                        src="@/assets/payments/bri.jpg"
                        class="w-3/4 mx-auto mb-2"
                        alt=""
                      />
                      <div class="text-center text-sm font-bold">
                        BRI Virtual Account
                      </div>
                    </div>
                  </div>
                  <div
                    class="border border-white2 rounded-lg flex items-center justify-center py-4 cursor-pointer hover:border-orange hover:text-orange"
                    @click="changePayment('gopay', 'Gopay')"
                  >
                    <div class="">
                      <img
                        src="@/assets/payments/gopay.jpg"
                        class="w-3/4 mx-auto mb-2"
                        alt=""
                      />
                      <div class="text-center text-sm font-bold">Gopay</div>
                    </div>
                  </div>
                  <div
                    class="border border-white2 rounded-lg flex items-center justify-center py-4 cursor-pointer hover:border-orange hover:text-orange"
                    @click="changePayment('shopeepay', 'Shopee Pay')"
                  >
                    <div class="">
                      <img
                        src="@/assets/payments/shopeepay.jpg"
                        class="w-3/4 mx-auto mb-2"
                        alt=""
                      />
                      <div class="text-center text-sm font-bold">
                        Shopee Pay
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex items-center justify-center py-4 cursor-pointer text-darkBlueText hover:text-orange"
                    @click="selectPM = true"
                  >
                    <div class="">
                      <img
                        src="@/assets/icon/ic_add.png"
                        class="w-1/4 mx-auto mb-3 mt-5"
                        alt=""
                      />
                      <div class="text-center text-sm font-bold">
                        Other Payment Method
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center mb-4">
                  <input
                    type="text"
                    class="px-4 py-2 w-1/4 border border-gray rounded-lg mr-4 outline-none cursor-default"
                    placeholder="Selected Payment Method"
                    v-model="payment.name"
                    readonly
                  />
                  <!-- <div class="" v-if="paymentLogo">
                    <img
                      v-if="paymentLogo"
                      :src="getImgUrl(paymentLogo)"
                      class="h-14"
                      alt=""
                    />
                  </div> -->
                </div>
                <div class="font-bold mb-4">Set Amount</div>

                <div class="flex items-center text-sm mb-4">
                  <div
                    class="rounded-full px-3 py-1 bg-gray mr-2 cursor-pointer hover:bg-orange hover:text-white"
                    @click="amount = 10000"
                  >
                    10.000
                  </div>
                  <div
                    class="rounded-full px-3 py-1 bg-gray mr-2 cursor-pointer hover:bg-orange hover:text-white"
                    @click="amount = 20000"
                  >
                    20.000
                  </div>
                  <div
                    class="rounded-full px-3 py-1 bg-gray mr-2 cursor-pointer hover:bg-orange hover:text-white"
                    @click="amount = 50000"
                  >
                    50.000
                  </div>
                  <div
                    class="rounded-full px-3 py-1 bg-gray mr-2 cursor-pointer hover:bg-orange hover:text-white"
                    @click="amount = 100000"
                  >
                    100.000
                  </div>
                  <div
                    class="rounded-full px-3 py-1 bg-gray mr-2 cursor-pointer hover:bg-orange hover:text-white"
                    @click="amount = 500000"
                  >
                    500.000
                  </div>
                  <div
                    class="rounded-full px-3 py-1 bg-gray mr-2 cursor-pointer hover:bg-orange hover:text-white"
                    @click="amount = 1000000"
                  >
                    1.000.000
                  </div>
                  <div
                    class="rounded-full px-3 py-1 bg-gray mr-2 cursor-pointer hover:bg-orange hover:text-white"
                    @click="amount = 5000000"
                  >
                    5.000.000
                  </div>
                </div>
                <div class="flex items-center justify-center">
                  <input
                    type="text"
                    class="px-4 py-2 w-full border border-gray rounded-lg mr-4 outline-none focus:border-orange"
                    placeholder="0.00"
                    @keypress="onlyNumber"
                    v-model="amount"
                  />
                  <button
                    v-if="onTopup"
                    class="text-white flex-none px-4 py-2 bg-gray border border-gray rounded-lg cursor-not-allowed"
                  >
                    Top Up
                  </button>
                  <button
                    v-else
                    class="btn-hover text-orange flex-none px-4 py-2 border border-orange rounded-lg"
                    @click.prevent="topUpBalance()"
                  >
                    Top Up
                  </button>
                </div>
              </div>
            </div>

            <!-- bilnav invoice -->
            <div
              class="p-4 shadow-lg rounded-lg"
              v-if="bilNavigation === 'invoice' && invoices"
            >
              <!-- head -->
              <div
                class="grid grid-cols-10 text-darkBlueText text-sm border-b border-white2"
              >
                <!-- <div class="col-span-2 p-4">Invoices</div> -->
                <div class="col-span-1 p-4">Invoice ID</div>
                <div class="col-span-2 p-4">Invoice Date</div>
                <div class="col-span-2 p-4">Total</div>
                <div class="col-span-3 p-4">Due Date</div>
                <div class="col-span-2 p-4">Status</div>
              </div>
              <!-- body -->
              <div
                class="grid grid-cols-10 text-darkBlueText text-sm border-b border-white2 hover:bg-orange2 cursor-pointer"
                @click="(invoiceId = inv.id), getInvoiceDetail(true)"
                v-for="inv in invoices.data"
                :key="inv.id"
              >
                <!-- <div class="col-span-2 p-4">Dummy Invoices</div> -->
                <div class="col-span-1 p-4">{{ inv.id }}</div>
                <div class="col-span-2 p-4">{{ inv.date }}</div>
                <div class="col-span-2 p-4">
                  IDR {{ formatRupiah(inv.total.split(".00")[0].toString()) }}
                </div>
                <div class="col-span-3 p-4">{{ inv.duedate }}</div>
                <div class="col-span-2 p-4">
                  <div class="inline-block">
                    <div
                      class="flex px-2 py-1 rounded-full items-center"
                      :class="
                        inv.status === 'Paid' ? 'bg-blue-dot10' : 'bg-red-10'
                      "
                    >
                      <div
                        class="w-2 h-2 rounded-full mr-2"
                        :class="
                          inv.status === 'Paid' ? 'bg-blue-dot' : 'bg-red'
                        "
                      ></div>
                      <div class="text-black">{{ inv.status }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center items-center mt-4">
                <div class="text-sm flex items-center justify-center">
                  <button
                    @click.prevent="
                      invoices.pagination.currentPage === 1
                        ? ''
                        : getInvoices(1)
                    "
                    class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                  >
                    First
                  </button>
                  <div
                    @click="
                      invoices.pagination.currentPage > 1
                        ? getInvoices(invoices.pagination.currentPage - 1)
                        : ''
                    "
                    class="w-6 h-6 mx-4 flex text-center text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </div>
                </div>
                <div class="mx-4">
                  <span class="text-black font-bold">{{
                    invoices.pagination.currentPage
                  }}</span>
                  <!-- <span class="text-xs text-dark-gray mr-2">of</span>
              <span
                class="text-xs text-dark-gray"
                v-if="vm_pagination.totalPages"
                >{{ vm_pagination.totalPages }}</span
              > -->
                </div>
                <div class="text-sm flex items-center justify-center">
                  <div
                    @click="
                      invoices.pagination.currentPage <
                      invoices.pagination.totalPages
                        ? getInvoices(invoices.pagination.currentPage + 1)
                        : ''
                    "
                    class="w-6 h-6 mx-4 text-center flex text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </div>
                  <button
                    @click.prevent="
                      invoices.pagination.currentPage ===
                      invoices.pagination.totalPages
                        ? ''
                        : getInvoices(invoices.pagination.totalPages)
                    "
                    class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                  >
                    Last
                  </button>
                </div>
              </div>
            </div>

            <!-- bilnav billing -->
            <div
              class="p-4 shadow-lg rounded-lg"
              v-if="bilNavigation === 'bHistory' && orders"
            >
              <!-- head -->
              <div
                class="grid grid-cols-8 text-darkBlueText text-sm border-b border-white2"
              >
                <div class="col-span-2 p-4">Invoice ID</div>
                <div class="col-span-4 p-4">Description</div>
                <div class="col-span-2 p-4">Amount</div>
              </div>
              <!-- body -->
              <div
                class="grid grid-cols-8 text-darkBlueText text-sm border-b border-white2"
                v-for="ord in orders"
                :key="ord.id"
              >
                <div
                  class="col-span-2 p-4"
                  :class="
                    ord.invoiceid ? 'hover:text-orange cursor-pointer' : ''
                  "
                  @click="
                    ord.invoiceid
                      ? ((invoiceId = ord.invoiceid), getInvoiceDetail(true))
                      : ''
                  "
                >
                  {{ ord.invoiceid ? ord.invoiceid : "-" }}
                </div>
                <div class="col-span-4 p-4">
                  {{ ord.name }}
                </div>
                <div class="col-span-2 p-4">
                  IDR {{ formatRupiah(ord.amount.split(".00")[0].toString()) }}
                </div>
              </div>

              <div class="flex justify-center items-center mt-4">
                <div class="text-sm flex items-center justify-center">
                  <button
                    class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                  >
                    First
                  </button>
                  <div
                    class="w-6 h-6 mx-4 flex text-center text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </div>
                </div>
                <div class="mx-4">
                  <span class="text-black font-bold">1</span>
                  <!-- <span class="text-xs text-dark-gray mr-2">of</span>
              <span
                class="text-xs text-dark-gray"
                v-if="vm_pagination.totalPages"
                >{{ vm_pagination.totalPages }}</span
              > -->
                </div>
                <div class="text-sm flex items-center justify-center">
                  <div
                    class="w-6 h-6 mx-4 text-center flex text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </div>
                  <button
                    class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                  >
                    Last
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <invoice-detail
      v-if="invoice.active"
      :snap="invoice.snap"
      :data="invoice.data"
      @dismiss="(invoice.active = false), (onTopup = false)"
      @changePayment="(invoice.active = false), getInvoiceDetail()"
    />
    <payment-methods
      v-if="selectPM"
      @dismiss="selectPM = false"
      @receivePayment="receivePayment"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import { mapState, mapActions } from "vuex";
import InvoiceDetail from "../../components/invoiceDetail.vue";
import PaymentMethods from "../../components/paymentMethods.vue";

export default {
  name: "DetailVM",
  components: {
    sidebar,
    navbar,
    InvoiceDetail,
    PaymentMethods
  },

  computed: {
    ...mapState([
      "billingData",
      "billingTotalCharge",
      "billingCharges",
      "billingLogs",
      "minimize",
      "payment"
    ]),

    invoices() {
      return this.$store.state.invoice.invoices;
    },
    orders() {
      return this.$store.state.invoice.orders;
    }
  },
  created() {
    document.title = "Nevacloud | Billing";
  },
  data: function() {
    return {
      // paymentOption: { midtrans: true, paypal: false },

      // paymentLogo: null,
      paypalAmount: null,
      promoCode: null,
      tableOption: { all: true, date: false },
      expand: false,
      month: null,
      filterData: null,
      paymentOption: "midtrans",
      paypalData: null,
      bilNavigation: "add",
      invoice: {
        active: false,
        snap: null,
        data: null
      },
      selectPM: false,
      amount: null,
      onTopup: false,
      invoiceId: null
    };
  },
  mounted: function() {
    this.$store.commit("resetPayment");
    this.getBalance();
    this.getInvoices();
    this.getOrders();
  },
  methods: {
    ...mapActions(["getBalance"]),
    // getImgUrl(img) {
    //   return require(img).default;
    // },
    receivePayment(e) {
      this.changePayment(e.paymentMethod, e.paymentName);
      this.selectPM = false;
    },
    topUpBalance() {
      if (!this.payment.method) {
        alert("Choose Payment Method");
        return;
      }
      if (!this.amount) {
        alert("Amount Can not be empty");
        return;
      }
      if (this.amount < 10000) {
        alert("Top Up Minimum 10.000");
        return;
      }
      this.onTopup = true;
      this.g_loading_create("Loading");
      this.$store.dispatch("postBalance", this.amount).then(res => {
        this.g_loading_destroy();
        if (res.status === 200) {
          this.amount = null;
          this.invoiceId = res.data.invoice_id;
          this.getInvoiceDetail();
        } else {
          this.onTopup = false;
        }
      });
    },
    getInvoices(data) {
      this.g_loading_create("Loading");
      this.$store
        .dispatch("invoice/getInvoices", data)
        .then(this.g_loading_destroy());
    },
    getInvoiceDetail(reset) {
      this.g_loading_create("Loading Invoice");
      if (reset) {
        this.$store.commit("resetPayment");
      }
      this.$store
        .dispatch("invoice/getInvoiceDetail", {
          id: this.invoiceId,
          query: this.payment.method
        })
        .then(res => {
          this.g_loading_destroy();
          if (res && res.status === 200) {
            this.invoice.snap = res.snap_token;
            this.invoice.data = res.data;
            this.invoice.active = true;
          } else {
            this.g_notif_create(
              false,
              "Something went wrong",
              "Error getting invoice data"
            );
            console.log("error", res);
          }
        });
    },
    getOrders() {
      this.g_loading_create("Loading");
      this.$store.dispatch("invoice/getOrders").then(this.g_loading_destroy());
    },
    filter() {
      this.filterData = this.billingLogs.filter(log =>
        log.date.startsWith(this.month)
      );
    },
    changePayment(method, name) {
      this.$store.commit("changePayment", { method: method, name: name });
    }
  }
};
</script>

<style lang="scss" scoped></style>
