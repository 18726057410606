<template>
  <div class="bg-blue">
    <div
      class="container max-w-xl flex items-center justify-center flex-col min-h-screen py-12"
    >
      <div class="pb-12">
        <img class="w-56" src="@/assets/logo/logo.png" alt="Domainesia Logo" />
      </div>
      <div class="md:p-12 px-4 py-8 bg-white rounded-md shadow m-4 w-full">
        <div class="text-center">
          <div class="text-orange">
            <i class="far fa-clock fa-7x"></i>
          </div>
          <div class="text-3xl text-slate">One Step Closer!</div>
          <p class="text-slate">
            Wait, your account will be activated by our admin soon
          </p>
          <button
            @click="logout($router)"
            class="outline-none py-2 px-4 border-solid bg-blue text-white rounded-md mt-4"
          >
            Log out
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unactive",
  components: {},
  created() {
    document.title = "Nevacloud | Pending Account";
  },
  data: function() {
    return {};
  },
  mounted: function() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
