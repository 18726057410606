var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"fixed inset-0 bg-black bg-opacity-75",staticStyle:{"z-index":"20"},on:{"click":function($event){return _vm.dismiss()}}},[_c('div',{staticClass:"container m-auto max-w-xl border border-white-100 mt-16 bg-white rounded-lg form-container",class:{ 'will-dismiss': _vm.will_dismiss },on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"py-4 px-4"},[_c('div',{staticClass:"text-right text-xl text-gray cursor-pointer",on:{"click":function($event){return _vm.dismiss()}}},[_c('i',{staticClass:"fas fa-times"})]),_c('h1',{staticClass:"text-3xl text-slate mb-4"},[_vm._v("Other Payment Methods")]),_c('div',{staticClass:"overflow-hidden w-full h-full mt-4"},[_c('div',{staticClass:"overflow-y-scroll h-full pr-10 w-full box-content"},[_c('div',{staticClass:"flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange",on:{"click":function($event){(_vm.paymentMethod = 'permata_va'),
                  (_vm.paymentName = 'Permata Virtual Account'),
                  _vm.succesClose()}}},[_vm._m(0),_c('span',[_vm._v("Permata Virtual Account")])]),_c('div',{staticClass:"flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange",on:{"click":function($event){(_vm.paymentMethod = 'bni_va'),
                  (_vm.paymentName = 'BNI Virtual Account'),
                  _vm.succesClose()}}},[_vm._m(1),_c('span',[_vm._v("BNI Virtual Account")])]),_c('div',{staticClass:"flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange",on:{"click":function($event){(_vm.paymentMethod = 'danamon_online'),
                  (_vm.paymentName = 'Danamon Online'),
                  _vm.succesClose()}}},[_vm._m(2),_c('span',[_vm._v("Danamon Online")])]),_c('div',{staticClass:"flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange",on:{"click":function($event){(_vm.paymentMethod = 'cimb_clicks'),
                  (_vm.paymentName = 'CIMB Clicks'),
                  _vm.succesClose()}}},[_vm._m(3),_c('span',[_vm._v("CIMB Clicks")])]),_c('div',{staticClass:"flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange",on:{"click":function($event){(_vm.paymentMethod = 'other_va'),
                  (_vm.paymentName = 'Other Virtual Account'),
                  _vm.succesClose()}}},[_vm._m(4),_c('span',[_vm._v("Other Virtual Account")])]),_c('div',{staticClass:"flex items-center py-2 border-b border-white2 cursor-pointer hover:text-orange",on:{"click":function($event){(_vm.paymentMethod = 'bca_klikpay'),
                  (_vm.paymentName = 'BCA Klik Pay'),
                  _vm.succesClose()}}},[_vm._m(5),_c('span',[_vm._v("BCA Klik Pay")])]),_c('div',{staticClass:"flex items-center py-2 cursor-pointer hover:text-orange",on:{"click":function($event){(_vm.paymentMethod = 'alfamart'),
                  (_vm.paymentName = 'Alfamart'),
                  _vm.succesClose()}}},[_vm._m(6),_c('span',[_vm._v("Alfamart")])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-28 mr-4 flex-none"},[_c('img',{attrs:{"src":require("@/assets/payments/permata.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-28 mr-4 flex-none"},[_c('img',{attrs:{"src":require("@/assets/payments/bni.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-28 mr-4 flex-none"},[_c('img',{attrs:{"src":require("@/assets/payments/danamon.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-28 mr-4 flex-none"},[_c('img',{attrs:{"src":require("@/assets/payments/cimb.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-28 mr-4 flex-none"},[_c('img',{attrs:{"src":require("@/assets/payments/atmbersama.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-28 mr-4 flex-none"},[_c('img',{attrs:{"src":require("@/assets/payments/bcaklikpay.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-28 mr-4 flex-none"},[_c('img',{attrs:{"src":require("@/assets/payments/alfamart.jpg"),"alt":""}})])}]

export { render, staticRenderFns }