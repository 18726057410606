<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      @click="dismiss()"
      style="z-index: 20"
    >
      <div
        class="container m-auto max-w-xl border border-white-100 mt-16 bg-white rounded-lg form-container flex flex-col py-4"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div class="flex-none flex items-start justify-between mb-4 px-4">
          <h1 class="text-3xl text-slate">Edit Profile</h1>
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div class="overflow-hidden w-full h-full mt-4 px-4">
          <div class="overflow-y-scroll h-full pr-10 w-full box-content">
            <form class="">
              <div
                class="text-slate text-md"
                :class="emailMessage ? '' : 'mb-2'"
              >
                Email
              </div>
              <span class="text-red text-xs" v-if="emailMessage">
                {{ emailMessage }}
              </span>

              <input
                type="text"
                class="w-full mb-4 outline-none border border-gray p-4 rounded-lg cursor-not-allowed"
                :class="{ 'border-red': emailMessage }"
                placeholder="Email"
                v-model="data.email"
                readonly
              />

              <div
                class="text-slate text-md"
                :class="firstnameMessage ? '' : 'mb-2'"
              >
                First Name
              </div>
              <span class="text-red text-xs" v-if="firstnameMessage">
                {{ firstnameMessage }}
              </span>
              <input
                type="text"
                class="w-full mb-4 outline-none border border-gray p-4 rounded-lg focus:border-blue"
                :class="{ 'border-red': firstnameMessage }"
                placeholder="Name"
                v-model="data.firstname"
              />

              <div
                class="text-slate text-md"
                :class="lastnameMessage ? '' : 'mb-2'"
              >
                Last Name
              </div>
              <span class="text-red text-xs" v-if="lastnameMessage">
                {{ lastnameMessage }}
              </span>
              <input
                type="text"
                class="w-full mb-4 outline-none border border-gray p-4 rounded-lg focus:border-blue"
                :class="{ 'border-red': lastnameMessage }"
                placeholder="Name"
                v-model="data.lastname"
              />

              <div
                class="text-slate text-md"
                :class="phoneMessage ? '' : 'mb-2'"
              >
                Phone Number
              </div>
              <span class="text-red text-xs" v-if="phoneMessage">
                {{ phoneMessage }}
              </span>
              <input
                type="text"
                class="w-full mb-4 outline-none border border-gray p-4 rounded-lg focus:border-blue"
                :class="{ 'border-red': phoneMessage }"
                placeholder="Phone Number"
                v-model="data.phone_number"
                @blur="phoneMessage = false"
              />

              <div
                class="text-slate text-md"
                :class="addressMessage ? '' : 'mb-2'"
              >
                Address
              </div>
              <span class="text-red text-xs" v-if="addressMessage">
                {{ addressMessage }}
              </span>
              <input
                type="text"
                class="w-full mb-4 outline-none border border-gray p-4 rounded-lg focus:border-blue"
                :class="{ 'border-red': addressMessage }"
                placeholder="Address"
                v-model="data.address"
              />

              <div
                class="text-slate text-md"
                :class="companyMessage ? '' : 'mb-2'"
              >
                Company
              </div>
              <span class="text-red text-xs" v-if="companyMessage">
                {{ companyMessage }}
              </span>
              <input
                type="text"
                @mouseleave="companyMessage = false"
                class="w-full mb-4 outline-none border border-gray p-4 rounded-lg focus:border-blue"
                :class="{ 'border-red': companyMessage }"
                placeholder="Company"
                v-model="data.companyname"
              />

              <div
                class="text-slate text-md"
                :class="npwpMessage ? '' : 'mb-2'"
              >
                NPWP
              </div>
              <span class="text-red text-xs" v-if="npwpMessage">
                {{ npwpMessage }}
              </span>
              <input
                type="text"
                class="w-full mb-4 outline-none border border-gray p-4 rounded-lg focus:border-blue"
                :class="{ 'border-red': npwpMessage }"
                placeholder="NPWP"
                v-model="data.npwp"
              />

              <div
                class="text-slate text-md"
                :class="taxTypeMessage ? '' : 'mb-2'"
              >
                Tax Type
              </div>
              <span class="text-red text-xs" v-if="taxTypeMessage">
                {{ taxTypeMessage }}
              </span>
              <div class="mb-4 flex justify-between items-center px-2">
                <div
                  class="flex items-center cursor-pointer"
                  @click="data.taxType = 'personal'"
                >
                  <div
                    class="w-4 h-4 mr-2 rounded-full border hover:border-orange cursor-pointer flex items-center justify-center"
                    :class="
                      data.taxType === 'personal'
                        ? 'border-orange'
                        : 'border-gray'
                    "
                  >
                    <div
                      v-if="data.taxType === 'personal'"
                      class="w-2 h-2 rounded-full bg-orange"
                    ></div>
                  </div>
                  <div class="">Personal</div>
                </div>
                <div
                  class="flex items-center cursor-pointer"
                  @click="data.taxType = 'pkp'"
                >
                  <div
                    class="w-4 h-4 mr-2 rounded-full border hover:border-orange cursor-pointer flex items-center justify-center"
                    :class="
                      data.taxType === 'pkp' ? 'border-orange' : 'border-gray'
                    "
                  >
                    <div
                      v-if="data.taxType === 'pkp'"
                      class="w-2 h-2 rounded-full bg-orange"
                    ></div>
                  </div>
                  <div class="">PKP</div>
                </div>
                <div
                  class="flex items-center cursor-pointer"
                  @click="data.taxType = 'nonpkp'"
                >
                  <div
                    class="w-4 h-4 mr-2 rounded-full border hover:border-orange cursor-pointer flex items-center justify-center"
                    :class="
                      data.taxType === 'nonpkp'
                        ? 'border-orange'
                        : 'border-gray'
                    "
                  >
                    <div
                      v-if="data.taxType === 'nonpkp'"
                      class="w-2 h-2 rounded-full bg-orange"
                    ></div>
                  </div>
                  <div class="">Non PKP</div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="flex-none mt-4 px-4">
          <button
            class="w-full p-4 bg-orange text-white mt-2 rounded-lg"
            @click.prevent="checkPost()"
            v-if="!onSubmit"
          >
            Save Profile
          </button>
          <div
            class="w-full p-4 bg-gray text-white mt-2 rounded-lg cursor-not-allowed text-center"
            v-else
          >
            Save Profile
          </div>
        </div>
      </div>
    </div>
    <Notification
      v-if="notif.show"
      @dismiss="closeNotif()"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    />
    <change-email
      v-if="changeEmailData"
      :oldEmail="oldEmail"
      @dismiss="(changeEmailData = false), (onSubmit = false)"
      @succesDismiss="(changeEmailData = false), post()"
    />
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import { mapState, mapActions } from "vuex";
import ChangeEmail from "./ChangeEmail.vue";
export default {
  name: "editProfile",
  components: {
    Notification,
    ChangeEmail
  },
  props: {},

  computed: {
    ...mapState({ data: "postMeData" })
  },
  data: function() {
    return {
      onSubmit: false,
      changeEmailData: false,
      oldEmail: null,
      will_dismiss: false,
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      },
      firstnameMessage: null,
      lastnameMessage: null,
      emailMessage: null,
      phoneMessage: null,
      addressMessage: null,
      companyMessage: null,
      npwpMessage: null,
      taxTypeMessage: null,
      passwordMessage: null,
      password: null
    };
  },
  mounted() {
    this.oldEmail = this.data.email;
  },
  methods: {
    ...mapActions(["postMe"]),
    checkPost() {
      this.firstnameMessage = null;
      this.lastnameMessage = null;
      this.emailMessage = null;
      this.phoneMessage = null;
      this.addressMessage = null;
      this.companyMessage = null;
      this.npwpMessage = null;
      this.taxTypeMessage = null;
      if (!this.data.firstname) {
        this.firstnameMessage = "First Name could not be empty";
        return;
      }
      if (!this.data.lastname) {
        this.lastnameMessage = "Last Name could not be empty";
        return;
      }

      if (this.changeEmail) {
        if (!this.data.email) {
          this.emailMessage = "Email could not be empty";
          return;
        }
        if (!this.password) {
          this.passwordMessage = "Password could not be empty";
          return;
        }
      }

      if (!this.data.phone_number) {
        this.phoneMessage = "Phone Number could not be empty";
        return;
      }
      //eslint-disable-next-line
      var phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
      if (!this.data.phone_number.match(phoneRegex)) {
        this.phoneMessage = "Phone number format doest support";
        return;
      }
      if (7 <= this.data.phone_number.length >= 16) {
        this.phoneMessage =
          "Phone number must be at least 7 number and maximum 16 numbers";
      }
      if (!this.data.address) {
        this.addressMessage = "Address could not be empty";
        return;
      }
      if (this.data.address.length < 4) {
        this.addressMessage = "Address must be at least 4 character";
        return;
      }
      if (!this.data.companyname) {
        this.companyMessage = "Company could not be empty";
        return;
      }
      if (this.data.companyname.length < 4) {
        this.companyMessage = "Company name must be at least 4 character";
        return;
      }
      // eslint-disable-next-line
      var npwpChecker = /^\d+$/;
      if (!this.data.npwp.match(npwpChecker)) {
        this.npwpMessage = "NPWP format doest support";
        return;
      }
      if (this.data.npwp.length !== 15) {
        this.npwpMessage = "NPWP must be 15 number";
        return;
      }
      if (!this.data.npwp) {
        this.npwpMessage = "NPWP could not be empty";
        return;
      }

      if (!this.data.taxType && this.data.npwp) {
        this.taxTypeMessage = "Tax Type Required";
        return;
      }
      this.onSubmit = true;

      if (this.oldEmail !== this.data.email) {
        this.changeEmailData = true;
      } else {
        this.post();
      }
    },
    post() {
      this.g_loading_create("Loading");
      this.postMe().then(response => {
        this.onSubmit = false;
        this.g_loading_destroy();
        if (response.status === 200) {
          this.notif.header = "Succes";
          this.notif.message = "Profile has been updated";
          this.notif.active = true;
          this.notif.show = true;
        } else {
          let res = response.error.fields;
          this.g_notif_create(false, "Error", res[Object.keys(res)[0]][0]);
        }
      });
    },
    dismiss() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
    },
    succesClose() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
      this.$emit("get");
    },
    closeNotif() {
      this.notif.show = false;
      this.succesClose();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
  height: 700px;
  overflow: hidden;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
