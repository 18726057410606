<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10" @click="checkCloseDetail()">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Volume
            </h1>
            <div
              class="w-10 h-10 border-2 border-orange text-2xl font-bold flex items-center justify-center rounded-lg text-orange cursor-pointer btn-hover"
              @click="volume.active = true"
            >
              +
            </div>
          </div>
          <div class="">
            <div
              class="text-darkBlueText py-2 px-3 bg-grayForm rounded-lg flex items-center"
            >
              <div class="w-6 mr-4">
                <img src="@/assets/icon/ic_search.png" alt="" />
              </div>
              <input
                type="text"
                class="w-full bg-grayForm placeholder-darkBlueText outline-none"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div
          class="bg-white p-8 shadow-md rounded-lg mb-20"
          v-if="volumeData && volumeData.length != 0"
        >
          <!-- head -->
          <div
            class="grid grid-cols-10 gap-4 text-darkBlueText border-b border-white2 py-6"
          >
            <div class="col-span-3">Name</div>
            <div class="col-span-2">Size</div>
            <div class="col-span-2">Virtual Machine</div>
            <div class="col-span-2">Target</div>
            <div class=""></div>
          </div>

          <!-- content -->
          <div class="">
            <div
              class="py-4 border-b border-white2"
              v-for="volume in volumeData"
              :key="volume.id"
            >
              <div class="grid grid-cols-10 gap-4 items-center text-black">
                <div class="col-span-3 truncate">
                  {{ volume.name }}
                </div>

                <div class="col-span-2 truncate">{{ volume.size }} GB</div>
                <div class="col-span-2 truncate" v-if="volume.virtualMachine">
                  <router-link
                    :to="
                      '/dashboard/productdetails?id=' + volume.virtualMachine.id
                    "
                  >
                    <div class="hover:text-orange cursor-pointer">
                      {{ volume.virtualMachine.hostname }}
                    </div>
                  </router-link>
                </div>
                <div class="col-span-2 truncate" v-else>-</div>
                <div class="col-span-2 truncate">
                  {{ volume.target }}
                </div>
                <div class="">
                  <div class="relative">
                    <div
                      class="icon text-right text-darkBlueText hover:text-orange cursor-pointer"
                      @click.stop="closeMenu(volume.id)"
                    >
                      <i class="fas fa-circle mr-1"></i>
                      <i class="fas fa-circle mr-1"></i>
                      <i class="fas fa-circle"></i>
                    </div>
                    <div
                      class="absolute right-0"
                      style="top: 12px; left: -35px; z-index: 5"
                      v-if="volume.menu"
                    >
                      <div class="shadow-lg rounded-lg bg-white text-center">
                        <div
                          v-if="volume.virtualMachine && volume.target"
                          class="py-2 cursor-pointer hover:bg-orange rounded-lg hover:text-white"
                          @click="
                            (config.active = true),
                              (config.data = {
                                name: volume.name,
                                target: volume.target
                              }),
                              (volume.menu = false)
                          "
                        >
                          <span class="text-xs flex-1">Show Config</span>
                        </div>
                        <div
                          v-if="!volume.virtualMachine"
                          class="py-2 cursor-pointer hover:bg-orange rounded-lg hover:text-white"
                          @click="
                            (attachVolume.active = true),
                              (attachVolume.data = {
                                volumeId: volume.id,
                                volumeName: volume.name,
                                volumePersistent: volume.persistent,
                                volumeSize: volume.size
                              }),
                              (volume.menu = false)
                          "
                        >
                          <span class="text-xs flex-1">Attach</span>
                        </div>
                        <div
                          v-if="volume.virtualMachine"
                          class="py-2 cursor-pointer rounded-lg hover:bg-orange hover:text-white"
                          @click="
                            (confirmationDetach.active = true),
                              (confirmationDetach.data = {
                                volumeId: volume.id,
                                vmId: volume.virtualMachine.id,
                                status: volume.virtualMachine.status,
                                action: 'detach'
                              }),
                              (confirmationDetach.textConfirmation =
                                'Warning: Please make sure to unmount the disk and the system is not currently writing to the disk before detaching the Volume. Doing otherwise could cause data loss.'),
                              (volume.menu = false)
                          "
                        >
                          <span class="text-xs flex-1">Detach</span>
                        </div>
                        <div
                          v-if="!volume.virtualMachine"
                          class="py-2 rounded-lg cursor-pointer text-red hover:bg-red hover:text-white"
                          @click="
                            (confirmation.active = true),
                              (confirmation.data = {
                                volumeId: volume.id,
                                dataVM: volume.virtualMachine,
                                action: 'delete'
                              }),
                              (confirmation.textConfirmation =
                                'Are you sure you want to delete ' +
                                volume.name +
                                ' ?'),
                              (volume.menu = false)
                          "
                        >
                          <span class="text-xs flex-1">Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- no data -->
        <div
          class="flex flex-col flex-1 items-center justify-center"
          v-if="volumeData && volumeData.length === 0"
        >
          <div class="mt-6 mb-16">
            <img
              src="@/assets/img/no_volume.png"
              class="w-2/3 mx-auto"
              alt=""
            />
          </div>
          <div class="text-2xl text-darkBlueText font-nunito font-bold mb-16">
            You don't have any active products
          </div>
          <div
            class="text-orange border border-orange rounded-md py-2 px-3 cursor-pointer btn-hover"
            @click="volume.active = true"
          >
            Add Volume
          </div>
        </div>
      </div>
    </div>
    <addVolume
      v-if="volume.active"
      @dismiss="volume.active = false"
      @get="getVolume()"
    />
    <attachVolume
      v-if="attachVolume.active"
      :volumeData="attachVolume.data"
      @dismiss="attachVolume.active = false"
      @get="getVolume()"
    />
    <confirmation
      v-if="confirmation.active"
      @accept="option(confirmation.data)"
      @dismiss="(confirmation.active = false), (confirmation.data = null)"
      :data="confirmation.data"
      :textConfirmation="confirmation.textConfirmation"
    />
    <confirmation-detach
      v-if="confirmationDetach.active"
      @accept="option(confirmationDetach.data)"
      @dismiss="
        (confirmationDetach.active = false), (confirmationDetach.data = null)
      "
      :data="confirmationDetach.data"
      :textConfirmation="confirmationDetach.textConfirmation"
    />
    <!-- <invoice-detail /> -->
    <config-volume
      v-if="config.active"
      :volumeData="config.data"
      @dismiss="config.active = false"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import confirmation from "@/components/confirmation.vue";
import addVolume from "@/components/addVolume.vue";
import attachVolume from "@/components/attachVolume.vue";
import { mapState, mapActions } from "vuex";
import ConfirmationDetach from "../../components/confirmationDetach.vue";
import ConfigVolume from "../../components/configVolume.vue";
// import InvoiceDetail from "../../components/invoiceDetail.vue";
export default {
  name: "Volume",
  components: {
    sidebar,
    navbar,
    confirmation,
    addVolume,
    attachVolume,
    ConfirmationDetach,
    ConfigVolume
    // InvoiceDetail,
  },
  created() {
    document.title = "Nevacloud | Volume";
    if (!this.volumeData) {
      this.g_loading_create("Loading");
    }
    this.getVolume().then(() => this.g_loading_destroy(this.$store));
  },
  computed: {
    ...mapState(["volumeData", "minimize"])
  },

  data: function() {
    return {
      confirmation_active: false,
      volume: {
        active: false,
        dismiss: false,
        data: null
      },
      attachVolume: {
        active: false
      },
      config: {
        active: false,
        dismiss: false,
        data: null
      },
      confirmationDetach: {
        active: false,
        data: null,
        textConfirmation: null
      },
      confirmation: {
        active: false,
        data: null,
        textConfirmation: null
      },
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      }
    };
  },
  mounted: function() {},
  methods: {
    ...mapActions(["getVolume"]),

    option(data) {
      if (data.action === "detach") {
        this.detach(data);
      } else if (data.action === "delete") {
        this.delete(data);
      }
    },
    closeMenu(id) {
      this.volumeData.forEach(data => {
        if (data.id == id) {
          if (!data.menu) {
            data.menu = true;
          } else {
            data.menu = false;
          }
        } else {
          data.menu = false;
        }
      });
    },
    checkCloseDetail() {
      this.volumeData.forEach(data => {
        data.menu = false;
      });
    },
    delete(data) {
      if (data.dataVM) {
        this.g_notif_create(false, "Failed", "Volume must be in Detach state");
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("deleteVolume", data.volumeId).then(response => {
        this.confirmation.active = false;
        this.confirmation.data = null;
        this.g_loading_destroy();
        if (response.status === 200) {
          this.g_notif_create(true, "Success", "Volume has been deleted");
          this.getVolume();
        } else {
          this.g_notif_create(false, "Failed", response.error.message);
        }
      });
    },
    detach(data) {
      // if (data.status !== "running") {
      //   if (data.status !== "poweroff") {
      //     this.g_notif_create(
      //       false,
      //       "Failed",
      //       "VM must be in Running or Poweroff state"
      //     );
      //     return;
      //   }
      // }
      let dataParams = {
        vmId: data.vmId,
        volumeId: data.volumeId
      };
      this.g_loading_create("Loading");
      this.$store.dispatch("detachVolume", dataParams).then(response => {
        this.confirmation.active = false;
        this.confirmation.data = null;
        this.g_loading_destroy();

        if (response.status === 200) {
          this.g_notif_create(true, "Success", "Volume has been detach");
          this.getVolume();
        } else {
          this.g_notif_create(false, "Failed", response.error.message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 6px;
}
</style>
