<template>
  <div class="bg-blue">
    <div
      class="container max-w-lg flex items-center justify-center flex-col min-h-screen py-12"
    >
      <div class="pb-12">
        <img class="w-56" src="@/assets/logo/logo.png" alt="Domainesia Logo" />
      </div>
      <div class="md:p-12 px-4 py-8 bg-white rounded-md shadow m-4 w-full">
        <h3 class="text-slate text-center font-medium text-3xl mb-12">
          New Password
        </h3>
        <ErrorMessage
          v-if="em"
          message="The Given Data is Invalid"
          @dismiss="em = false"
        />
        <form @submit="reset">
          <div class="my-6">
            <div
              class="flex border py-4 rounded-md ease-out duration-700"
              :class="{
                'border-blue': password.active,
                'border-gray': !password.active,
                'border-red': password.message && !password.active
              }"
            >
              <span
                class="px-4 text-base text-dark-gray"
                :class="{ 'text-red': password.message }"
              >
                <i class="fas fa-unlock"></i>
              </span>
              <input
                type="password"
                v-model="password.data"
                @focus="password.active = true"
                @blur="password.active = false"
                class="w-full px-2 border-none outline-none box-border"
                :class="{ 'placeholder-red': password.message }"
                placeholder="New Password"
              />
            </div>
            <span v-if="password.message" class="text-red text-xs">{{
              password.message
            }}</span>
          </div>

          <div class="my-6">
            <div
              class="flex border py-4 rounded-md ease-out duration-700"
              :class="{
                'border-blue': repassword.active,
                'border-gray': !repassword.active,
                'border-red': repassword.message && !repassword.active
              }"
            >
              <span
                class="px-4 text-base text-dark-gray"
                :class="{ 'text-red': repassword.message }"
              >
                <i class="fas fa-unlock"></i>
              </span>
              <input
                type="password"
                v-model="repassword.data"
                @focus="repassword.active = true"
                @blur="repassword.active = false"
                class="w-full px-2 border-none outline-none box-border"
                :class="{ 'placeholder-red': repassword.message }"
                placeholder="Confirm Your New Password"
              />
            </div>
            <span v-if="repassword.message" class="text-red text-xs">{{
              repassword.message
            }}</span>
          </div>

          <button
            class="py-6 w-full bg-blue text-white rounded-md font-medium mb-6 outline-none"
            type="submit"
          >
            Send
          </button>
          <div class="text-center">
            <span class="text-slate">
              Back to
              <router-link to="/login" class="text-blue">Login</router-link>
            </span>
          </div>
        </form>
      </div>
    </div>
    <Notification
      v-if="notif"
      @dismiss="closeNotif()"
      header="Succes"
      message="Reset password succes"
      active="true"
    />
  </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Notification from "@/components/Notification.vue";
export default {
  name: "Home",
  components: {
    ErrorMessage,
    Notification
  },
  created() {
    document.title = "Nevacloud | New Password";
  },
  data: function() {
    return {
      password: {
        active: false,
        message: null,
        error: null
      },
      repassword: {
        active: false,
        message: null,
        error: null
      },
      notif: false,
      em: false
    };
  },
  mounted: function() {
    if (
      !this.$router.currentRoute.query.email ||
      !this.$router.currentRoute.query.token
    ) {
      this.$router.go(-1);
    }
  },
  methods: {
    reset(ev) {
      ev.preventDefault();
      this.password.message = null;
      this.repassword.message = null;

      if (!this.password.data) {
        this.password.message = "Password is Required";
        return;
      }
      if (!this.repassword.data) {
        this.repassword.message = "Password is Required";
        return;
      }
      if (this.password.data != this.repassword.data) {
        this.repassword.message = "Password is not the same";
        return;
      }
      this.g_loading_create("Loading");
      var email = this.$router.currentRoute.query.email;
      var token = this.$router.currentRoute.query.token;

      this.$store
        .dispatch("resetpassword", {
          email: email,
          token: token,
          password: this.password.data,
          confirm_password: this.repassword.data
        })
        .then(() => {
          this.g_loading_destroy();
          this.notif = true;
        })
        .catch(err => {
          this.g_loading_destroy();
          this.em = true;
          console.log(err);
        });
    },
    closeNotif() {
      this.notif = false;
      this.$router.push("/login");
    }
  }
};
</script>

<style></style>
