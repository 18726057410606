"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
/*
 * Mapping from Unicode codepoints to X11/RFB keysyms
 *
 * This file was automatically generated from keysymdef.h
 * DO NOT EDIT!
 */

/* Functions at the bottom */

var codepoints = {
    0x0100: 0x03c0, // XK_Amacron
    0x0101: 0x03e0, // XK_amacron
    0x0102: 0x01c3, // XK_Abreve
    0x0103: 0x01e3, // XK_abreve
    0x0104: 0x01a1, // XK_Aogonek
    0x0105: 0x01b1, // XK_aogonek
    0x0106: 0x01c6, // XK_Cacute
    0x0107: 0x01e6, // XK_cacute
    0x0108: 0x02c6, // XK_Ccircumflex
    0x0109: 0x02e6, // XK_ccircumflex
    0x010a: 0x02c5, // XK_Cabovedot
    0x010b: 0x02e5, // XK_cabovedot
    0x010c: 0x01c8, // XK_Ccaron
    0x010d: 0x01e8, // XK_ccaron
    0x010e: 0x01cf, // XK_Dcaron
    0x010f: 0x01ef, // XK_dcaron
    0x0110: 0x01d0, // XK_Dstroke
    0x0111: 0x01f0, // XK_dstroke
    0x0112: 0x03aa, // XK_Emacron
    0x0113: 0x03ba, // XK_emacron
    0x0116: 0x03cc, // XK_Eabovedot
    0x0117: 0x03ec, // XK_eabovedot
    0x0118: 0x01ca, // XK_Eogonek
    0x0119: 0x01ea, // XK_eogonek
    0x011a: 0x01cc, // XK_Ecaron
    0x011b: 0x01ec, // XK_ecaron
    0x011c: 0x02d8, // XK_Gcircumflex
    0x011d: 0x02f8, // XK_gcircumflex
    0x011e: 0x02ab, // XK_Gbreve
    0x011f: 0x02bb, // XK_gbreve
    0x0120: 0x02d5, // XK_Gabovedot
    0x0121: 0x02f5, // XK_gabovedot
    0x0122: 0x03ab, // XK_Gcedilla
    0x0123: 0x03bb, // XK_gcedilla
    0x0124: 0x02a6, // XK_Hcircumflex
    0x0125: 0x02b6, // XK_hcircumflex
    0x0126: 0x02a1, // XK_Hstroke
    0x0127: 0x02b1, // XK_hstroke
    0x0128: 0x03a5, // XK_Itilde
    0x0129: 0x03b5, // XK_itilde
    0x012a: 0x03cf, // XK_Imacron
    0x012b: 0x03ef, // XK_imacron
    0x012e: 0x03c7, // XK_Iogonek
    0x012f: 0x03e7, // XK_iogonek
    0x0130: 0x02a9, // XK_Iabovedot
    0x0131: 0x02b9, // XK_idotless
    0x0134: 0x02ac, // XK_Jcircumflex
    0x0135: 0x02bc, // XK_jcircumflex
    0x0136: 0x03d3, // XK_Kcedilla
    0x0137: 0x03f3, // XK_kcedilla
    0x0138: 0x03a2, // XK_kra
    0x0139: 0x01c5, // XK_Lacute
    0x013a: 0x01e5, // XK_lacute
    0x013b: 0x03a6, // XK_Lcedilla
    0x013c: 0x03b6, // XK_lcedilla
    0x013d: 0x01a5, // XK_Lcaron
    0x013e: 0x01b5, // XK_lcaron
    0x0141: 0x01a3, // XK_Lstroke
    0x0142: 0x01b3, // XK_lstroke
    0x0143: 0x01d1, // XK_Nacute
    0x0144: 0x01f1, // XK_nacute
    0x0145: 0x03d1, // XK_Ncedilla
    0x0146: 0x03f1, // XK_ncedilla
    0x0147: 0x01d2, // XK_Ncaron
    0x0148: 0x01f2, // XK_ncaron
    0x014a: 0x03bd, // XK_ENG
    0x014b: 0x03bf, // XK_eng
    0x014c: 0x03d2, // XK_Omacron
    0x014d: 0x03f2, // XK_omacron
    0x0150: 0x01d5, // XK_Odoubleacute
    0x0151: 0x01f5, // XK_odoubleacute
    0x0152: 0x13bc, // XK_OE
    0x0153: 0x13bd, // XK_oe
    0x0154: 0x01c0, // XK_Racute
    0x0155: 0x01e0, // XK_racute
    0x0156: 0x03a3, // XK_Rcedilla
    0x0157: 0x03b3, // XK_rcedilla
    0x0158: 0x01d8, // XK_Rcaron
    0x0159: 0x01f8, // XK_rcaron
    0x015a: 0x01a6, // XK_Sacute
    0x015b: 0x01b6, // XK_sacute
    0x015c: 0x02de, // XK_Scircumflex
    0x015d: 0x02fe, // XK_scircumflex
    0x015e: 0x01aa, // XK_Scedilla
    0x015f: 0x01ba, // XK_scedilla
    0x0160: 0x01a9, // XK_Scaron
    0x0161: 0x01b9, // XK_scaron
    0x0162: 0x01de, // XK_Tcedilla
    0x0163: 0x01fe, // XK_tcedilla
    0x0164: 0x01ab, // XK_Tcaron
    0x0165: 0x01bb, // XK_tcaron
    0x0166: 0x03ac, // XK_Tslash
    0x0167: 0x03bc, // XK_tslash
    0x0168: 0x03dd, // XK_Utilde
    0x0169: 0x03fd, // XK_utilde
    0x016a: 0x03de, // XK_Umacron
    0x016b: 0x03fe, // XK_umacron
    0x016c: 0x02dd, // XK_Ubreve
    0x016d: 0x02fd, // XK_ubreve
    0x016e: 0x01d9, // XK_Uring
    0x016f: 0x01f9, // XK_uring
    0x0170: 0x01db, // XK_Udoubleacute
    0x0171: 0x01fb, // XK_udoubleacute
    0x0172: 0x03d9, // XK_Uogonek
    0x0173: 0x03f9, // XK_uogonek
    0x0178: 0x13be, // XK_Ydiaeresis
    0x0179: 0x01ac, // XK_Zacute
    0x017a: 0x01bc, // XK_zacute
    0x017b: 0x01af, // XK_Zabovedot
    0x017c: 0x01bf, // XK_zabovedot
    0x017d: 0x01ae, // XK_Zcaron
    0x017e: 0x01be, // XK_zcaron
    0x0192: 0x08f6, // XK_function
    0x01d2: 0x10001d1, // XK_Ocaron
    0x02c7: 0x01b7, // XK_caron
    0x02d8: 0x01a2, // XK_breve
    0x02d9: 0x01ff, // XK_abovedot
    0x02db: 0x01b2, // XK_ogonek
    0x02dd: 0x01bd, // XK_doubleacute
    0x0385: 0x07ae, // XK_Greek_accentdieresis
    0x0386: 0x07a1, // XK_Greek_ALPHAaccent
    0x0388: 0x07a2, // XK_Greek_EPSILONaccent
    0x0389: 0x07a3, // XK_Greek_ETAaccent
    0x038a: 0x07a4, // XK_Greek_IOTAaccent
    0x038c: 0x07a7, // XK_Greek_OMICRONaccent
    0x038e: 0x07a8, // XK_Greek_UPSILONaccent
    0x038f: 0x07ab, // XK_Greek_OMEGAaccent
    0x0390: 0x07b6, // XK_Greek_iotaaccentdieresis
    0x0391: 0x07c1, // XK_Greek_ALPHA
    0x0392: 0x07c2, // XK_Greek_BETA
    0x0393: 0x07c3, // XK_Greek_GAMMA
    0x0394: 0x07c4, // XK_Greek_DELTA
    0x0395: 0x07c5, // XK_Greek_EPSILON
    0x0396: 0x07c6, // XK_Greek_ZETA
    0x0397: 0x07c7, // XK_Greek_ETA
    0x0398: 0x07c8, // XK_Greek_THETA
    0x0399: 0x07c9, // XK_Greek_IOTA
    0x039a: 0x07ca, // XK_Greek_KAPPA
    0x039b: 0x07cb, // XK_Greek_LAMDA
    0x039c: 0x07cc, // XK_Greek_MU
    0x039d: 0x07cd, // XK_Greek_NU
    0x039e: 0x07ce, // XK_Greek_XI
    0x039f: 0x07cf, // XK_Greek_OMICRON
    0x03a0: 0x07d0, // XK_Greek_PI
    0x03a1: 0x07d1, // XK_Greek_RHO
    0x03a3: 0x07d2, // XK_Greek_SIGMA
    0x03a4: 0x07d4, // XK_Greek_TAU
    0x03a5: 0x07d5, // XK_Greek_UPSILON
    0x03a6: 0x07d6, // XK_Greek_PHI
    0x03a7: 0x07d7, // XK_Greek_CHI
    0x03a8: 0x07d8, // XK_Greek_PSI
    0x03a9: 0x07d9, // XK_Greek_OMEGA
    0x03aa: 0x07a5, // XK_Greek_IOTAdieresis
    0x03ab: 0x07a9, // XK_Greek_UPSILONdieresis
    0x03ac: 0x07b1, // XK_Greek_alphaaccent
    0x03ad: 0x07b2, // XK_Greek_epsilonaccent
    0x03ae: 0x07b3, // XK_Greek_etaaccent
    0x03af: 0x07b4, // XK_Greek_iotaaccent
    0x03b0: 0x07ba, // XK_Greek_upsilonaccentdieresis
    0x03b1: 0x07e1, // XK_Greek_alpha
    0x03b2: 0x07e2, // XK_Greek_beta
    0x03b3: 0x07e3, // XK_Greek_gamma
    0x03b4: 0x07e4, // XK_Greek_delta
    0x03b5: 0x07e5, // XK_Greek_epsilon
    0x03b6: 0x07e6, // XK_Greek_zeta
    0x03b7: 0x07e7, // XK_Greek_eta
    0x03b8: 0x07e8, // XK_Greek_theta
    0x03b9: 0x07e9, // XK_Greek_iota
    0x03ba: 0x07ea, // XK_Greek_kappa
    0x03bb: 0x07eb, // XK_Greek_lamda
    0x03bc: 0x07ec, // XK_Greek_mu
    0x03bd: 0x07ed, // XK_Greek_nu
    0x03be: 0x07ee, // XK_Greek_xi
    0x03bf: 0x07ef, // XK_Greek_omicron
    0x03c0: 0x07f0, // XK_Greek_pi
    0x03c1: 0x07f1, // XK_Greek_rho
    0x03c2: 0x07f3, // XK_Greek_finalsmallsigma
    0x03c3: 0x07f2, // XK_Greek_sigma
    0x03c4: 0x07f4, // XK_Greek_tau
    0x03c5: 0x07f5, // XK_Greek_upsilon
    0x03c6: 0x07f6, // XK_Greek_phi
    0x03c7: 0x07f7, // XK_Greek_chi
    0x03c8: 0x07f8, // XK_Greek_psi
    0x03c9: 0x07f9, // XK_Greek_omega
    0x03ca: 0x07b5, // XK_Greek_iotadieresis
    0x03cb: 0x07b9, // XK_Greek_upsilondieresis
    0x03cc: 0x07b7, // XK_Greek_omicronaccent
    0x03cd: 0x07b8, // XK_Greek_upsilonaccent
    0x03ce: 0x07bb, // XK_Greek_omegaaccent
    0x0401: 0x06b3, // XK_Cyrillic_IO
    0x0402: 0x06b1, // XK_Serbian_DJE
    0x0403: 0x06b2, // XK_Macedonia_GJE
    0x0404: 0x06b4, // XK_Ukrainian_IE
    0x0405: 0x06b5, // XK_Macedonia_DSE
    0x0406: 0x06b6, // XK_Ukrainian_I
    0x0407: 0x06b7, // XK_Ukrainian_YI
    0x0408: 0x06b8, // XK_Cyrillic_JE
    0x0409: 0x06b9, // XK_Cyrillic_LJE
    0x040a: 0x06ba, // XK_Cyrillic_NJE
    0x040b: 0x06bb, // XK_Serbian_TSHE
    0x040c: 0x06bc, // XK_Macedonia_KJE
    0x040e: 0x06be, // XK_Byelorussian_SHORTU
    0x040f: 0x06bf, // XK_Cyrillic_DZHE
    0x0410: 0x06e1, // XK_Cyrillic_A
    0x0411: 0x06e2, // XK_Cyrillic_BE
    0x0412: 0x06f7, // XK_Cyrillic_VE
    0x0413: 0x06e7, // XK_Cyrillic_GHE
    0x0414: 0x06e4, // XK_Cyrillic_DE
    0x0415: 0x06e5, // XK_Cyrillic_IE
    0x0416: 0x06f6, // XK_Cyrillic_ZHE
    0x0417: 0x06fa, // XK_Cyrillic_ZE
    0x0418: 0x06e9, // XK_Cyrillic_I
    0x0419: 0x06ea, // XK_Cyrillic_SHORTI
    0x041a: 0x06eb, // XK_Cyrillic_KA
    0x041b: 0x06ec, // XK_Cyrillic_EL
    0x041c: 0x06ed, // XK_Cyrillic_EM
    0x041d: 0x06ee, // XK_Cyrillic_EN
    0x041e: 0x06ef, // XK_Cyrillic_O
    0x041f: 0x06f0, // XK_Cyrillic_PE
    0x0420: 0x06f2, // XK_Cyrillic_ER
    0x0421: 0x06f3, // XK_Cyrillic_ES
    0x0422: 0x06f4, // XK_Cyrillic_TE
    0x0423: 0x06f5, // XK_Cyrillic_U
    0x0424: 0x06e6, // XK_Cyrillic_EF
    0x0425: 0x06e8, // XK_Cyrillic_HA
    0x0426: 0x06e3, // XK_Cyrillic_TSE
    0x0427: 0x06fe, // XK_Cyrillic_CHE
    0x0428: 0x06fb, // XK_Cyrillic_SHA
    0x0429: 0x06fd, // XK_Cyrillic_SHCHA
    0x042a: 0x06ff, // XK_Cyrillic_HARDSIGN
    0x042b: 0x06f9, // XK_Cyrillic_YERU
    0x042c: 0x06f8, // XK_Cyrillic_SOFTSIGN
    0x042d: 0x06fc, // XK_Cyrillic_E
    0x042e: 0x06e0, // XK_Cyrillic_YU
    0x042f: 0x06f1, // XK_Cyrillic_YA
    0x0430: 0x06c1, // XK_Cyrillic_a
    0x0431: 0x06c2, // XK_Cyrillic_be
    0x0432: 0x06d7, // XK_Cyrillic_ve
    0x0433: 0x06c7, // XK_Cyrillic_ghe
    0x0434: 0x06c4, // XK_Cyrillic_de
    0x0435: 0x06c5, // XK_Cyrillic_ie
    0x0436: 0x06d6, // XK_Cyrillic_zhe
    0x0437: 0x06da, // XK_Cyrillic_ze
    0x0438: 0x06c9, // XK_Cyrillic_i
    0x0439: 0x06ca, // XK_Cyrillic_shorti
    0x043a: 0x06cb, // XK_Cyrillic_ka
    0x043b: 0x06cc, // XK_Cyrillic_el
    0x043c: 0x06cd, // XK_Cyrillic_em
    0x043d: 0x06ce, // XK_Cyrillic_en
    0x043e: 0x06cf, // XK_Cyrillic_o
    0x043f: 0x06d0, // XK_Cyrillic_pe
    0x0440: 0x06d2, // XK_Cyrillic_er
    0x0441: 0x06d3, // XK_Cyrillic_es
    0x0442: 0x06d4, // XK_Cyrillic_te
    0x0443: 0x06d5, // XK_Cyrillic_u
    0x0444: 0x06c6, // XK_Cyrillic_ef
    0x0445: 0x06c8, // XK_Cyrillic_ha
    0x0446: 0x06c3, // XK_Cyrillic_tse
    0x0447: 0x06de, // XK_Cyrillic_che
    0x0448: 0x06db, // XK_Cyrillic_sha
    0x0449: 0x06dd, // XK_Cyrillic_shcha
    0x044a: 0x06df, // XK_Cyrillic_hardsign
    0x044b: 0x06d9, // XK_Cyrillic_yeru
    0x044c: 0x06d8, // XK_Cyrillic_softsign
    0x044d: 0x06dc, // XK_Cyrillic_e
    0x044e: 0x06c0, // XK_Cyrillic_yu
    0x044f: 0x06d1, // XK_Cyrillic_ya
    0x0451: 0x06a3, // XK_Cyrillic_io
    0x0452: 0x06a1, // XK_Serbian_dje
    0x0453: 0x06a2, // XK_Macedonia_gje
    0x0454: 0x06a4, // XK_Ukrainian_ie
    0x0455: 0x06a5, // XK_Macedonia_dse
    0x0456: 0x06a6, // XK_Ukrainian_i
    0x0457: 0x06a7, // XK_Ukrainian_yi
    0x0458: 0x06a8, // XK_Cyrillic_je
    0x0459: 0x06a9, // XK_Cyrillic_lje
    0x045a: 0x06aa, // XK_Cyrillic_nje
    0x045b: 0x06ab, // XK_Serbian_tshe
    0x045c: 0x06ac, // XK_Macedonia_kje
    0x045e: 0x06ae, // XK_Byelorussian_shortu
    0x045f: 0x06af, // XK_Cyrillic_dzhe
    0x0490: 0x06bd, // XK_Ukrainian_GHE_WITH_UPTURN
    0x0491: 0x06ad, // XK_Ukrainian_ghe_with_upturn
    0x05d0: 0x0ce0, // XK_hebrew_aleph
    0x05d1: 0x0ce1, // XK_hebrew_bet
    0x05d2: 0x0ce2, // XK_hebrew_gimel
    0x05d3: 0x0ce3, // XK_hebrew_dalet
    0x05d4: 0x0ce4, // XK_hebrew_he
    0x05d5: 0x0ce5, // XK_hebrew_waw
    0x05d6: 0x0ce6, // XK_hebrew_zain
    0x05d7: 0x0ce7, // XK_hebrew_chet
    0x05d8: 0x0ce8, // XK_hebrew_tet
    0x05d9: 0x0ce9, // XK_hebrew_yod
    0x05da: 0x0cea, // XK_hebrew_finalkaph
    0x05db: 0x0ceb, // XK_hebrew_kaph
    0x05dc: 0x0cec, // XK_hebrew_lamed
    0x05dd: 0x0ced, // XK_hebrew_finalmem
    0x05de: 0x0cee, // XK_hebrew_mem
    0x05df: 0x0cef, // XK_hebrew_finalnun
    0x05e0: 0x0cf0, // XK_hebrew_nun
    0x05e1: 0x0cf1, // XK_hebrew_samech
    0x05e2: 0x0cf2, // XK_hebrew_ayin
    0x05e3: 0x0cf3, // XK_hebrew_finalpe
    0x05e4: 0x0cf4, // XK_hebrew_pe
    0x05e5: 0x0cf5, // XK_hebrew_finalzade
    0x05e6: 0x0cf6, // XK_hebrew_zade
    0x05e7: 0x0cf7, // XK_hebrew_qoph
    0x05e8: 0x0cf8, // XK_hebrew_resh
    0x05e9: 0x0cf9, // XK_hebrew_shin
    0x05ea: 0x0cfa, // XK_hebrew_taw
    0x060c: 0x05ac, // XK_Arabic_comma
    0x061b: 0x05bb, // XK_Arabic_semicolon
    0x061f: 0x05bf, // XK_Arabic_question_mark
    0x0621: 0x05c1, // XK_Arabic_hamza
    0x0622: 0x05c2, // XK_Arabic_maddaonalef
    0x0623: 0x05c3, // XK_Arabic_hamzaonalef
    0x0624: 0x05c4, // XK_Arabic_hamzaonwaw
    0x0625: 0x05c5, // XK_Arabic_hamzaunderalef
    0x0626: 0x05c6, // XK_Arabic_hamzaonyeh
    0x0627: 0x05c7, // XK_Arabic_alef
    0x0628: 0x05c8, // XK_Arabic_beh
    0x0629: 0x05c9, // XK_Arabic_tehmarbuta
    0x062a: 0x05ca, // XK_Arabic_teh
    0x062b: 0x05cb, // XK_Arabic_theh
    0x062c: 0x05cc, // XK_Arabic_jeem
    0x062d: 0x05cd, // XK_Arabic_hah
    0x062e: 0x05ce, // XK_Arabic_khah
    0x062f: 0x05cf, // XK_Arabic_dal
    0x0630: 0x05d0, // XK_Arabic_thal
    0x0631: 0x05d1, // XK_Arabic_ra
    0x0632: 0x05d2, // XK_Arabic_zain
    0x0633: 0x05d3, // XK_Arabic_seen
    0x0634: 0x05d4, // XK_Arabic_sheen
    0x0635: 0x05d5, // XK_Arabic_sad
    0x0636: 0x05d6, // XK_Arabic_dad
    0x0637: 0x05d7, // XK_Arabic_tah
    0x0638: 0x05d8, // XK_Arabic_zah
    0x0639: 0x05d9, // XK_Arabic_ain
    0x063a: 0x05da, // XK_Arabic_ghain
    0x0640: 0x05e0, // XK_Arabic_tatweel
    0x0641: 0x05e1, // XK_Arabic_feh
    0x0642: 0x05e2, // XK_Arabic_qaf
    0x0643: 0x05e3, // XK_Arabic_kaf
    0x0644: 0x05e4, // XK_Arabic_lam
    0x0645: 0x05e5, // XK_Arabic_meem
    0x0646: 0x05e6, // XK_Arabic_noon
    0x0647: 0x05e7, // XK_Arabic_ha
    0x0648: 0x05e8, // XK_Arabic_waw
    0x0649: 0x05e9, // XK_Arabic_alefmaksura
    0x064a: 0x05ea, // XK_Arabic_yeh
    0x064b: 0x05eb, // XK_Arabic_fathatan
    0x064c: 0x05ec, // XK_Arabic_dammatan
    0x064d: 0x05ed, // XK_Arabic_kasratan
    0x064e: 0x05ee, // XK_Arabic_fatha
    0x064f: 0x05ef, // XK_Arabic_damma
    0x0650: 0x05f0, // XK_Arabic_kasra
    0x0651: 0x05f1, // XK_Arabic_shadda
    0x0652: 0x05f2, // XK_Arabic_sukun
    0x0e01: 0x0da1, // XK_Thai_kokai
    0x0e02: 0x0da2, // XK_Thai_khokhai
    0x0e03: 0x0da3, // XK_Thai_khokhuat
    0x0e04: 0x0da4, // XK_Thai_khokhwai
    0x0e05: 0x0da5, // XK_Thai_khokhon
    0x0e06: 0x0da6, // XK_Thai_khorakhang
    0x0e07: 0x0da7, // XK_Thai_ngongu
    0x0e08: 0x0da8, // XK_Thai_chochan
    0x0e09: 0x0da9, // XK_Thai_choching
    0x0e0a: 0x0daa, // XK_Thai_chochang
    0x0e0b: 0x0dab, // XK_Thai_soso
    0x0e0c: 0x0dac, // XK_Thai_chochoe
    0x0e0d: 0x0dad, // XK_Thai_yoying
    0x0e0e: 0x0dae, // XK_Thai_dochada
    0x0e0f: 0x0daf, // XK_Thai_topatak
    0x0e10: 0x0db0, // XK_Thai_thothan
    0x0e11: 0x0db1, // XK_Thai_thonangmontho
    0x0e12: 0x0db2, // XK_Thai_thophuthao
    0x0e13: 0x0db3, // XK_Thai_nonen
    0x0e14: 0x0db4, // XK_Thai_dodek
    0x0e15: 0x0db5, // XK_Thai_totao
    0x0e16: 0x0db6, // XK_Thai_thothung
    0x0e17: 0x0db7, // XK_Thai_thothahan
    0x0e18: 0x0db8, // XK_Thai_thothong
    0x0e19: 0x0db9, // XK_Thai_nonu
    0x0e1a: 0x0dba, // XK_Thai_bobaimai
    0x0e1b: 0x0dbb, // XK_Thai_popla
    0x0e1c: 0x0dbc, // XK_Thai_phophung
    0x0e1d: 0x0dbd, // XK_Thai_fofa
    0x0e1e: 0x0dbe, // XK_Thai_phophan
    0x0e1f: 0x0dbf, // XK_Thai_fofan
    0x0e20: 0x0dc0, // XK_Thai_phosamphao
    0x0e21: 0x0dc1, // XK_Thai_moma
    0x0e22: 0x0dc2, // XK_Thai_yoyak
    0x0e23: 0x0dc3, // XK_Thai_rorua
    0x0e24: 0x0dc4, // XK_Thai_ru
    0x0e25: 0x0dc5, // XK_Thai_loling
    0x0e26: 0x0dc6, // XK_Thai_lu
    0x0e27: 0x0dc7, // XK_Thai_wowaen
    0x0e28: 0x0dc8, // XK_Thai_sosala
    0x0e29: 0x0dc9, // XK_Thai_sorusi
    0x0e2a: 0x0dca, // XK_Thai_sosua
    0x0e2b: 0x0dcb, // XK_Thai_hohip
    0x0e2c: 0x0dcc, // XK_Thai_lochula
    0x0e2d: 0x0dcd, // XK_Thai_oang
    0x0e2e: 0x0dce, // XK_Thai_honokhuk
    0x0e2f: 0x0dcf, // XK_Thai_paiyannoi
    0x0e30: 0x0dd0, // XK_Thai_saraa
    0x0e31: 0x0dd1, // XK_Thai_maihanakat
    0x0e32: 0x0dd2, // XK_Thai_saraaa
    0x0e33: 0x0dd3, // XK_Thai_saraam
    0x0e34: 0x0dd4, // XK_Thai_sarai
    0x0e35: 0x0dd5, // XK_Thai_saraii
    0x0e36: 0x0dd6, // XK_Thai_saraue
    0x0e37: 0x0dd7, // XK_Thai_sarauee
    0x0e38: 0x0dd8, // XK_Thai_sarau
    0x0e39: 0x0dd9, // XK_Thai_sarauu
    0x0e3a: 0x0dda, // XK_Thai_phinthu
    0x0e3f: 0x0ddf, // XK_Thai_baht
    0x0e40: 0x0de0, // XK_Thai_sarae
    0x0e41: 0x0de1, // XK_Thai_saraae
    0x0e42: 0x0de2, // XK_Thai_sarao
    0x0e43: 0x0de3, // XK_Thai_saraaimaimuan
    0x0e44: 0x0de4, // XK_Thai_saraaimaimalai
    0x0e45: 0x0de5, // XK_Thai_lakkhangyao
    0x0e46: 0x0de6, // XK_Thai_maiyamok
    0x0e47: 0x0de7, // XK_Thai_maitaikhu
    0x0e48: 0x0de8, // XK_Thai_maiek
    0x0e49: 0x0de9, // XK_Thai_maitho
    0x0e4a: 0x0dea, // XK_Thai_maitri
    0x0e4b: 0x0deb, // XK_Thai_maichattawa
    0x0e4c: 0x0dec, // XK_Thai_thanthakhat
    0x0e4d: 0x0ded, // XK_Thai_nikhahit
    0x0e50: 0x0df0, // XK_Thai_leksun
    0x0e51: 0x0df1, // XK_Thai_leknung
    0x0e52: 0x0df2, // XK_Thai_leksong
    0x0e53: 0x0df3, // XK_Thai_leksam
    0x0e54: 0x0df4, // XK_Thai_leksi
    0x0e55: 0x0df5, // XK_Thai_lekha
    0x0e56: 0x0df6, // XK_Thai_lekhok
    0x0e57: 0x0df7, // XK_Thai_lekchet
    0x0e58: 0x0df8, // XK_Thai_lekpaet
    0x0e59: 0x0df9, // XK_Thai_lekkao
    0x2002: 0x0aa2, // XK_enspace
    0x2003: 0x0aa1, // XK_emspace
    0x2004: 0x0aa3, // XK_em3space
    0x2005: 0x0aa4, // XK_em4space
    0x2007: 0x0aa5, // XK_digitspace
    0x2008: 0x0aa6, // XK_punctspace
    0x2009: 0x0aa7, // XK_thinspace
    0x200a: 0x0aa8, // XK_hairspace
    0x2012: 0x0abb, // XK_figdash
    0x2013: 0x0aaa, // XK_endash
    0x2014: 0x0aa9, // XK_emdash
    0x2015: 0x07af, // XK_Greek_horizbar
    0x2017: 0x0cdf, // XK_hebrew_doublelowline
    0x2018: 0x0ad0, // XK_leftsinglequotemark
    0x2019: 0x0ad1, // XK_rightsinglequotemark
    0x201a: 0x0afd, // XK_singlelowquotemark
    0x201c: 0x0ad2, // XK_leftdoublequotemark
    0x201d: 0x0ad3, // XK_rightdoublequotemark
    0x201e: 0x0afe, // XK_doublelowquotemark
    0x2020: 0x0af1, // XK_dagger
    0x2021: 0x0af2, // XK_doubledagger
    0x2022: 0x0ae6, // XK_enfilledcircbullet
    0x2025: 0x0aaf, // XK_doubbaselinedot
    0x2026: 0x0aae, // XK_ellipsis
    0x2030: 0x0ad5, // XK_permille
    0x2032: 0x0ad6, // XK_minutes
    0x2033: 0x0ad7, // XK_seconds
    0x2038: 0x0afc, // XK_caret
    0x203e: 0x047e, // XK_overline
    0x20a9: 0x0eff, // XK_Korean_Won
    0x20ac: 0x20ac, // XK_EuroSign
    0x2105: 0x0ab8, // XK_careof
    0x2116: 0x06b0, // XK_numerosign
    0x2117: 0x0afb, // XK_phonographcopyright
    0x211e: 0x0ad4, // XK_prescription
    0x2122: 0x0ac9, // XK_trademark
    0x2153: 0x0ab0, // XK_onethird
    0x2154: 0x0ab1, // XK_twothirds
    0x2155: 0x0ab2, // XK_onefifth
    0x2156: 0x0ab3, // XK_twofifths
    0x2157: 0x0ab4, // XK_threefifths
    0x2158: 0x0ab5, // XK_fourfifths
    0x2159: 0x0ab6, // XK_onesixth
    0x215a: 0x0ab7, // XK_fivesixths
    0x215b: 0x0ac3, // XK_oneeighth
    0x215c: 0x0ac4, // XK_threeeighths
    0x215d: 0x0ac5, // XK_fiveeighths
    0x215e: 0x0ac6, // XK_seveneighths
    0x2190: 0x08fb, // XK_leftarrow
    0x2191: 0x08fc, // XK_uparrow
    0x2192: 0x08fd, // XK_rightarrow
    0x2193: 0x08fe, // XK_downarrow
    0x21d2: 0x08ce, // XK_implies
    0x21d4: 0x08cd, // XK_ifonlyif
    0x2202: 0x08ef, // XK_partialderivative
    0x2207: 0x08c5, // XK_nabla
    0x2218: 0x0bca, // XK_jot
    0x221a: 0x08d6, // XK_radical
    0x221d: 0x08c1, // XK_variation
    0x221e: 0x08c2, // XK_infinity
    0x2227: 0x08de, // XK_logicaland
    0x2228: 0x08df, // XK_logicalor
    0x2229: 0x08dc, // XK_intersection
    0x222a: 0x08dd, // XK_union
    0x222b: 0x08bf, // XK_integral
    0x2234: 0x08c0, // XK_therefore
    0x223c: 0x08c8, // XK_approximate
    0x2243: 0x08c9, // XK_similarequal
    0x2245: 0x1002248, // XK_approxeq
    0x2260: 0x08bd, // XK_notequal
    0x2261: 0x08cf, // XK_identical
    0x2264: 0x08bc, // XK_lessthanequal
    0x2265: 0x08be, // XK_greaterthanequal
    0x2282: 0x08da, // XK_includedin
    0x2283: 0x08db, // XK_includes
    0x22a2: 0x0bfc, // XK_righttack
    0x22a3: 0x0bdc, // XK_lefttack
    0x22a4: 0x0bc2, // XK_downtack
    0x22a5: 0x0bce, // XK_uptack
    0x2308: 0x0bd3, // XK_upstile
    0x230a: 0x0bc4, // XK_downstile
    0x2315: 0x0afa, // XK_telephonerecorder
    0x2320: 0x08a4, // XK_topintegral
    0x2321: 0x08a5, // XK_botintegral
    0x2395: 0x0bcc, // XK_quad
    0x239b: 0x08ab, // XK_topleftparens
    0x239d: 0x08ac, // XK_botleftparens
    0x239e: 0x08ad, // XK_toprightparens
    0x23a0: 0x08ae, // XK_botrightparens
    0x23a1: 0x08a7, // XK_topleftsqbracket
    0x23a3: 0x08a8, // XK_botleftsqbracket
    0x23a4: 0x08a9, // XK_toprightsqbracket
    0x23a6: 0x08aa, // XK_botrightsqbracket
    0x23a8: 0x08af, // XK_leftmiddlecurlybrace
    0x23ac: 0x08b0, // XK_rightmiddlecurlybrace
    0x23b7: 0x08a1, // XK_leftradical
    0x23ba: 0x09ef, // XK_horizlinescan1
    0x23bb: 0x09f0, // XK_horizlinescan3
    0x23bc: 0x09f2, // XK_horizlinescan7
    0x23bd: 0x09f3, // XK_horizlinescan9
    0x2409: 0x09e2, // XK_ht
    0x240a: 0x09e5, // XK_lf
    0x240b: 0x09e9, // XK_vt
    0x240c: 0x09e3, // XK_ff
    0x240d: 0x09e4, // XK_cr
    0x2423: 0x0aac, // XK_signifblank
    0x2424: 0x09e8, // XK_nl
    0x2500: 0x08a3, // XK_horizconnector
    0x2502: 0x08a6, // XK_vertconnector
    0x250c: 0x08a2, // XK_topleftradical
    0x2510: 0x09eb, // XK_uprightcorner
    0x2514: 0x09ed, // XK_lowleftcorner
    0x2518: 0x09ea, // XK_lowrightcorner
    0x251c: 0x09f4, // XK_leftt
    0x2524: 0x09f5, // XK_rightt
    0x252c: 0x09f7, // XK_topt
    0x2534: 0x09f6, // XK_bott
    0x253c: 0x09ee, // XK_crossinglines
    0x2592: 0x09e1, // XK_checkerboard
    0x25aa: 0x0ae7, // XK_enfilledsqbullet
    0x25ab: 0x0ae1, // XK_enopensquarebullet
    0x25ac: 0x0adb, // XK_filledrectbullet
    0x25ad: 0x0ae2, // XK_openrectbullet
    0x25ae: 0x0adf, // XK_emfilledrect
    0x25af: 0x0acf, // XK_emopenrectangle
    0x25b2: 0x0ae8, // XK_filledtribulletup
    0x25b3: 0x0ae3, // XK_opentribulletup
    0x25b6: 0x0add, // XK_filledrighttribullet
    0x25b7: 0x0acd, // XK_rightopentriangle
    0x25bc: 0x0ae9, // XK_filledtribulletdown
    0x25bd: 0x0ae4, // XK_opentribulletdown
    0x25c0: 0x0adc, // XK_filledlefttribullet
    0x25c1: 0x0acc, // XK_leftopentriangle
    0x25c6: 0x09e0, // XK_soliddiamond
    0x25cb: 0x0ace, // XK_emopencircle
    0x25cf: 0x0ade, // XK_emfilledcircle
    0x25e6: 0x0ae0, // XK_enopencircbullet
    0x2606: 0x0ae5, // XK_openstar
    0x260e: 0x0af9, // XK_telephone
    0x2613: 0x0aca, // XK_signaturemark
    0x261c: 0x0aea, // XK_leftpointer
    0x261e: 0x0aeb, // XK_rightpointer
    0x2640: 0x0af8, // XK_femalesymbol
    0x2642: 0x0af7, // XK_malesymbol
    0x2663: 0x0aec, // XK_club
    0x2665: 0x0aee, // XK_heart
    0x2666: 0x0aed, // XK_diamond
    0x266d: 0x0af6, // XK_musicalflat
    0x266f: 0x0af5, // XK_musicalsharp
    0x2713: 0x0af3, // XK_checkmark
    0x2717: 0x0af4, // XK_ballotcross
    0x271d: 0x0ad9, // XK_latincross
    0x2720: 0x0af0, // XK_maltesecross
    0x27e8: 0x0abc, // XK_leftanglebracket
    0x27e9: 0x0abe, // XK_rightanglebracket
    0x3001: 0x04a4, // XK_kana_comma
    0x3002: 0x04a1, // XK_kana_fullstop
    0x300c: 0x04a2, // XK_kana_openingbracket
    0x300d: 0x04a3, // XK_kana_closingbracket
    0x309b: 0x04de, // XK_voicedsound
    0x309c: 0x04df, // XK_semivoicedsound
    0x30a1: 0x04a7, // XK_kana_a
    0x30a2: 0x04b1, // XK_kana_A
    0x30a3: 0x04a8, // XK_kana_i
    0x30a4: 0x04b2, // XK_kana_I
    0x30a5: 0x04a9, // XK_kana_u
    0x30a6: 0x04b3, // XK_kana_U
    0x30a7: 0x04aa, // XK_kana_e
    0x30a8: 0x04b4, // XK_kana_E
    0x30a9: 0x04ab, // XK_kana_o
    0x30aa: 0x04b5, // XK_kana_O
    0x30ab: 0x04b6, // XK_kana_KA
    0x30ad: 0x04b7, // XK_kana_KI
    0x30af: 0x04b8, // XK_kana_KU
    0x30b1: 0x04b9, // XK_kana_KE
    0x30b3: 0x04ba, // XK_kana_KO
    0x30b5: 0x04bb, // XK_kana_SA
    0x30b7: 0x04bc, // XK_kana_SHI
    0x30b9: 0x04bd, // XK_kana_SU
    0x30bb: 0x04be, // XK_kana_SE
    0x30bd: 0x04bf, // XK_kana_SO
    0x30bf: 0x04c0, // XK_kana_TA
    0x30c1: 0x04c1, // XK_kana_CHI
    0x30c3: 0x04af, // XK_kana_tsu
    0x30c4: 0x04c2, // XK_kana_TSU
    0x30c6: 0x04c3, // XK_kana_TE
    0x30c8: 0x04c4, // XK_kana_TO
    0x30ca: 0x04c5, // XK_kana_NA
    0x30cb: 0x04c6, // XK_kana_NI
    0x30cc: 0x04c7, // XK_kana_NU
    0x30cd: 0x04c8, // XK_kana_NE
    0x30ce: 0x04c9, // XK_kana_NO
    0x30cf: 0x04ca, // XK_kana_HA
    0x30d2: 0x04cb, // XK_kana_HI
    0x30d5: 0x04cc, // XK_kana_FU
    0x30d8: 0x04cd, // XK_kana_HE
    0x30db: 0x04ce, // XK_kana_HO
    0x30de: 0x04cf, // XK_kana_MA
    0x30df: 0x04d0, // XK_kana_MI
    0x30e0: 0x04d1, // XK_kana_MU
    0x30e1: 0x04d2, // XK_kana_ME
    0x30e2: 0x04d3, // XK_kana_MO
    0x30e3: 0x04ac, // XK_kana_ya
    0x30e4: 0x04d4, // XK_kana_YA
    0x30e5: 0x04ad, // XK_kana_yu
    0x30e6: 0x04d5, // XK_kana_YU
    0x30e7: 0x04ae, // XK_kana_yo
    0x30e8: 0x04d6, // XK_kana_YO
    0x30e9: 0x04d7, // XK_kana_RA
    0x30ea: 0x04d8, // XK_kana_RI
    0x30eb: 0x04d9, // XK_kana_RU
    0x30ec: 0x04da, // XK_kana_RE
    0x30ed: 0x04db, // XK_kana_RO
    0x30ef: 0x04dc, // XK_kana_WA
    0x30f2: 0x04a6, // XK_kana_WO
    0x30f3: 0x04dd, // XK_kana_N
    0x30fb: 0x04a5, // XK_kana_conjunctive
    0x30fc: 0x04b0 // XK_prolongedsound
};

exports.default = {
    lookup: function lookup(u) {
        // Latin-1 is one-to-one mapping
        if (u >= 0x20 && u <= 0xff) {
            return u;
        }

        // Lookup table (fairly random)
        var keysym = codepoints[u];
        if (keysym !== undefined) {
            return keysym;
        }

        // General mapping as final fallback
        return 0x01000000 | u;
    }
};