<template>
  <div class="">
    <div class="novncbody">
      <div id="top_bar">
        <!-- <div id="status" v-if="status_load !== null">{{ status_load }}</div> -->
        <div id="status">Loading</div>
        <div class="sendCtrlAltDelButton" @click="sendCtrlAltDel()">
          Send CtrlAltDel
        </div>
      </div>
      <div id="screen" ref="screen">
        <!-- This is where the remote screen will appear -->
      </div>
    </div>
    <textarea id="clipboard" style="position: fixed; z-index: -1"></textarea>
  </div>
</template>

<!-- Promise polyfill for IE11 -->
<script src="../assets/vendors/novnc/promise.js"></script>
<!-- ES2015/ES6 modules polyfill -->

<script
  nomodule
  src="../assets/vendors/novnc/vendor/browser-es-module-loader/dist/browser-es-module-loader.js"
></script>

<script>
import RFB from "../assets/vendors/novnc/rfb.js";
import { mapState } from "vuex";
export default {
  name: "noVNC",
  created() {
    document.title = "Nevacloud | Console VNC";
    if (!this.$router.currentRoute.query.id) {
      this.$router.push("dashboard");
    } else {
      this.id = this.$router.currentRoute.query.id;
    }
  },
  computed: {
    ...mapState(["vnc"])
  },
  data: function() {
    return {
      rfb: null,
      desktopname: null,
      teks: null,
      status_load: null,
      id: null
    };
  },
  mounted: function() {
    let url = this.$cookies.get("vnc_url");

    // let url = JSON.parse(localStorage.vnc_url);
    this.rfb = new RFB(document.getElementById("screen"), url, {
      wsProtocols: ["binary", "base64"]
    });
    let clipboard = document.getElementById("clipboard");

    // Add listeners to important events from the RFB module
    this.rfb.addEventListener("connect", e => {
      this.connectedToServer;
      // var viewer = document.getElementsByTagName("canvas")[0];

      // document.body.addEventListener("paste", (e) => {
      //   console.log("MASUK PADAHAL");
      //   this.rfb.clipboardPasteFrom(e.clipboardData.getData("text"));
      // });

      // document.body.addEventListener("keydown", (e) => {
      //   if (e.keyCode !== 86) e.preventDefault();
      //   setTimeout(() => {
      //     viewer.dispatchEvent(new e.constructor(e.type, e));
      //     this.rfb.focus();
      //   }, 1);
      // });

      // document.body.addEventListener("keyup", (e) => {
      //   if (e.keyCode !== 86) e.preventDefault();
      //   setTimeout(() => {
      //     viewer.dispatchEvent(new e.constructor(e.type, e));
      //     this.rfb.focus();
      //   }, 1);
      // });

      // viewer.addEventListener("keydown", (e) => {
      //   if (e.ctrlKey) document.activeElement.blur();
      // });

      // viewer.addEventListener("keyup", (e) => {
      //   if (e.ctrlKey && [67, 88].includes(e.keyCode)) {
      //     clipboard.focus();
      //     clipboard.select();
      //     document.execCommand("copy");
      //     this.rfb.focus();
      //   }
      // });
    });
    this.rfb.addEventListener("disconnect", this.disconnectedFromServer);
    this.rfb.addEventListener(
      "credentialsrequired",
      this.credentialsAreRequired
    );
    this.rfb.addEventListener("desktopname", this.updateDesktopName);
    this.rfb.addEventListener("clipboard", e => {
      clipboard.value = e.detail.text;
    });

    // Set parameters that can be changed on an active connection
    this.rfb.viewOnly = this.readQueryVariable("view_only", false);
    this.rfb.scaleViewport = this.readQueryVariable("scale", false);
  },
  methods: {
    connectedToServer(e) {
      this.status("Connected to " + "centos 8");
    },

    // This function is called when we are disconnected
    disconnectedFromServer(e) {
      if (e.detail.clean) {
        this.status("Disconnected");
      } else {
        this.status("Something went wrong, connection is closed");
      }
    },

    // When this function is called, the server requires
    // credentials to authenticate
    credentialsAreRequired() {
      const password = prompt("Password Required:");
      this.rfb.sendCredentials({ password: password });
    },

    // When this function is called we have received
    // a desktop name from the server
    updateDesktopName(e) {
      this.desktopName = "centos 8";
    },

    // Since most operating systems will catch Ctrl+Alt+Del
    // before they get a chance to be intercepted by the browser,
    // we provide a way to emulate this key sequence.
    sendCtrlAltDel() {
      this.rfb.sendCtrlAltDel();
      return false;
    },

    // Show a status text in the top bar
    status(text) {
      this.text = text;
    },

    // This function extracts the value of one variable from the
    // query string. If the variable isn't defined in the URL
    // it returns the default value instead.
    readQueryVariable(name, defaultValue) {
      // A URL with a query parameter can look like this:
      // https://www.example.com?myqueryparam=myvalue
      //
      // Note that we use location.href instead of location.search
      // because Firefox < 53 has a bug w.r.t location.search
      const re = new RegExp(".*[?&]" + name + "=([^&#]*)"),
        match = document.location.href.match(re);

      if (match) {
        // We have to decode the URL since want the cleartext value
        return decodeURIComponent(match[1]);
      }

      return defaultValue;
    }
  }
};
</script>

<style>
.novncbody {
  margin: 0;
  background-color: dimgrey;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.novncbody {
  height: 100vh;
}

#top_bar {
  background-color: #6e84a3;
  color: white;
  font: bold 12px Helvetica;
  padding: 6px 5px 4px 5px;
  border-bottom: 1px outset;
}
#status {
  text-align: center;
}
.sendCtrlAltDelButton {
  position: fixed;
  top: 0px;
  right: 0px;
  border: 1px outset;
  padding: 5px 5px 4px 5px;
  cursor: pointer;
}

#screen {
  flex: 1; /* fill remaining space */
  overflow: hidden;
}
</style>
