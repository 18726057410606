<template>
  <div
    class="w-full flex items-center py-4 border border-orange px-4 rounded-md text-orange"
  >
    <i class="fas fa-exclamation-circle"></i>
    <span class="px-3 flex-1">{{ message }}</span>
    <i class="fas fa-times cursor-pointer" @click="dismiss()"></i>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    message: null
  },
  methods: {
    dismiss: function() {
      this.$emit("dismiss");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
