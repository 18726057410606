import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/login.vue";
import Register from "../views/auth/register.vue";
import CheckEmail from "../views/auth/checkemail.vue";
import Unactive from "../views/auth/unactive.vue";
import Dashboard from "../views/user/dashboard.vue";
import DetailVM from "../views/user/detailvm.vue";
import SSHKeys from "../views/user/sshkeys.vue";
import NotFound from "../views/notfound.vue";
import Forgot from "../views/auth/forgotpassword.vue";
import NewPassword from "../views/auth/newpassword.vue";
import createInstansces from "../views/user/instance.vue";
import Billing from "../views/user/billing.vue";
import Profile from "../views/user/profile.vue";
import Images from "../views/user/images.vue";
import Networks from "../views/user/networks.vue";
import Support from "../views/user/support.vue";
import Ticket from "../views/user/createTicket.vue";
import DetailTicket from "../views/user/detailTicket.vue";
import Announcements from "../views/user/announcements.vue";
import Volume from "../views/user/volume.vue";
import noVNC from "../views/noVNC.vue";
import apps from "../views/user/apps.vue";
import global from "@/mixins";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter(to, from, next) {
      if (!global.methods.checklogin()) {
        next();
      } else {
        next("/dashboard");
      }
    }
  },

  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter(to, from, next) {
      if (!global.methods.checklogin()) {
        next();
      } else {
        next("/dashboard");
      }
    }
  },
  {
    path: "/",
    name: "Home",
    // component: Dashboard,
    beforeEnter(to, from, next) {
      next("/dashboard");
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/dashboard/productdetails*",
    name: "DetailVM",
    component: DetailVM,
    beforeEnter(to, from, next) {
      if (to.query.partnerId && to.query.token) {
        next();
      } else {
        if (global.methods.checklogin()) {
          next();
        } else {
          next("/login");
        }
      }
    }
  },
  {
    path: "/apps",
    name: "Apps",
    component: apps,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/ssh-keys",
    name: "SSH Keys",
    component: SSHKeys,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/announcements",
    name: "Announcements",
    component: Announcements,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/images",
    name: "Images",
    component: Images,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/networks",
    name: "Networks",
    component: Networks,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/support/newticket",
    name: "Ticket",
    component: Ticket,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/support/detail-ticket*",
    name: "DetailTicket",
    component: DetailTicket,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin() && to.query.id) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/volume",
    name: "Volume",
    component: Volume,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/create-instance*",
    name: "CreateInstance",
    component: createInstansces,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin()) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/checkemail",
    name: "CheckEmail",
    component: CheckEmail,
    beforeEnter(to, from, next) {
      if (!global.methods.checklogin()) {
        next();
      } else {
        next("/dashboard");
      }
    }
  },
  {
    path: "/pending",
    name: "Unactive",
    component: Unactive,
    beforeEnter(to, from, next) {
      if (!global.methods.checklogin()) {
        next();
      } else {
        next("/dashboard");
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    component: Forgot,
    beforeEnter(to, from, next) {
      if (!global.methods.checklogin()) {
        next();
      } else {
        next("/dashboard");
      }
    }
  },
  {
    path: "/resetpassword*",
    name: "New Password",
    component: NewPassword,
    beforeEnter(to, from, next) {
      if (!global.methods.checklogin()) {
        next();
      } else {
        next("/dashboard");
      }
    }
  },
  {
    path: "/console*",
    name: "noVNC",
    component: noVNC,
    beforeEnter(to, from, next) {
      if (global.methods.checklogin() && Vue.$cookies.isKey("vnc_url")) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
