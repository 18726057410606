<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10 flex flex-col">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Support
            </h1>
            <router-link to="/support/newticket">
              <div
                class="w-10 h-10 border-2 border-orange text-2xl font-bold flex items-center justify-center rounded-lg text-orange cursor-pointer btn-hover"
              >
                +
              </div>
            </router-link>
          </div>
          <!-- <div class="">
            <div
              class="text-darkBlueText py-2 px-3 bg-grayForm rounded-lg flex items-center"
            >
              <div class="w-6 mr-4">
                <img src="@/assets/icon/ic_search.png" alt="" />
              </div>
              <input
                type="text"
                class="w-full bg-grayForm placeholder-darkBlueText outline-none"
                placeholder="Search"
              />
            </div>
          </div> -->
        </div>

        <!-- list show -->
        <div
          class="flex mt-8 justify-end text-slate"
          v-if="tickets && tickets.length > 0"
        >
          <!-- <div class="flex flex-1 items-center">
            <div class="mr-2">Show</div>
            <div class="relative">
              <div
                class="px-4 py-1 border border-gray mb-1"
                style="width: 54px"
              >
                {{ pagination.perPage }}
              </div>
              <div
                v-if="show"
                class="absolute text-center border border-gray right-0 left-0"
                style="width: 54px"
              >
                <div
                  class="bg-white px-4 py-1 hover:text-blue cursor-pointer"
                  @click="showTotal(5)"
                >
                  5
                </div>
                <div
                  class="bg-white px-4 py-1 hover:text-blue cursor-pointer"
                  @click="showTotal(10)"
                >
                  10
                </div>
                <div
                  class="bg-white px-4 py-1 hover:text-blue cursor-pointer"
                  @click="showTotal(20)"
                >
                  20
                </div>
              </div>
            </div>
            <div class="ml-2">
              <i
                v-if="!show"
                @click="show = true"
                class="fas fa-chevron-down hover:text-blue"
              ></i>
              <i
                v-if="show"
                @click="show = false"
                class="fas fa-chevron-up hover:text-blue"
              ></i>
            </div>
          </div> -->
          <div class>
            Total Data :
            <span class="font-bold">{{ pagination.total }}</span>
          </div>
        </div>

        <div
          class="bg-white py-8 px-4 shadow-md rounded-lg mb-20"
          v-if="tickets"
        >
          <!-- head -->
          <div
            class="px-4 grid grid-cols-7 gap-4 text-darkBlueText border-b border-white2 py-6"
          >
            <div class="col-span-2">Subject</div>
            <div class="col-span-2">Department</div>
            <div class="col-span-1">Status</div>
            <div class="col-span-2">Date</div>
          </div>
          <!-- content -->
          <div class="" v-if="tickets && tickets.length > 0">
            <div
              class="py-4 px-4 border-b border-white2"
              v-for="(tic, index) in tickets"
              :key="index"
            >
              <div class="grid grid-cols-7 gap-4 items-center text-black">
                <!-- subject -->
                <div class="col-span-2">
                  <div
                    class="truncate hover:text-orange cursor-pointer inline-block"
                    @click="$router.push('/support/detail-ticket?id=' + tic.id)"
                  >
                    {{ tic.subject }}
                  </div>
                </div>
                <!-- department -->
                <div class="col-span-2">{{ tic.department_name }}</div>

                <!-- Status -->
                <div class="col-span-1">{{ tic.status }}</div>
                <!-- Date -->
                <div class="col-span-2">
                  {{ formatDate(tic.date) }} {{ formatTime(tic.date) }}
                </div>
              </div>
            </div>
          </div>
          <div class="" v-if="tickets && tickets.length === 0">
            <div class="py-4 px-4 border-b border-white2 hover:bg-dangerForm">
              <div class="grid grid-cols-1 gap-4 items-center text-black">
                <div class="text-center">No Records Found</div>
              </div>
            </div>
          </div>
          <!-- pagination -->
          <div
            class="flex justify-center items-center mt-4"
            v-if="tickets && tickets.length !== 0"
          >
            <div class="text-sm flex items-center justify-center">
              <button
                class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                @click="firstPage()"
              >
                First
              </button>
              <div
                class="w-6 h-6 mx-4 flex text-center text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                @click="previousPage()"
              >
                <i class="fas fa-chevron-left"></i>
              </div>
            </div>
            <div class="mx-4">
              <span class="text-black font-bold"
                >{{ pagination.currentPage }}
              </span>
              <span class="text-xs text-dark-gray mr-2">of</span>
              <span class="text-xs text-dark-gray">{{
                pagination.totalPages
              }}</span>
            </div>
            <div class="text-sm flex items-center justify-center">
              <div
                class="w-6 h-6 mx-4 text-center flex text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                @click="nextPage()"
              >
                <i class="fas fa-chevron-right"></i>
              </div>
              <button
                class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                @click="lastPage()"
              >
                Last
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <confirmation
      v-if="confirmation_active"
      @accept="deleteImage()"
      @dismiss="(confirmation_active = false), (id_delete = null)"
    /> -->
    <!-- <Notification
      v-if="notif.show"
      @dismiss="notif.show = false"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    /> -->
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
// import confirmation from "@/components/confirmation.vue";
// import Notification from "@/components/Notification.vue";
import { mapState } from "vuex";
export default {
  name: "Images",
  components: {
    sidebar,
    navbar
    // confirmation,
    // Notification
  },
  created() {
    document.title = "Nevacloud | Support";
    // if (!this.networks) {
    //   this.g_loading_create("Loading");
    // }
    // this.getVM().then(() => {
    //   this.g_loading_destroy();
    // });
  },
  computed: {
    ...mapState({
      networks: "networks",
      minimize: "minimize"
    }),
    pagination() {
      return this.$store.state.support.ticket_pagination;
    },
    tickets() {
      return this.$store.state.support.tickets;
    }
  },
  data: function() {
    return {
      confirmation_active: false,
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      },
      show: false
    };
  },
  mounted() {
    this.$store.commit("support/clearTickets");
    this.getTickets();
  },
  methods: {
    getTickets() {
      this.g_loading_create("Loading");
      this.$store.dispatch("support/getTicketList").then(res => {
        this.g_loading_destroy();
        if (res.status !== 200) {
          console.log(res);
          alert("Something went wrong");
        }
      });
    },
    firstPage() {
      if (this.pagination.currentPage != 1) {
        this.g_loading_create("Loading");
        this.$store.dispatch("support/getTicketList", 1).then(res => {
          this.g_loading_destroy();
          res.status !== 200
            ? this.g_notif_create(false, "False", "Something went Wrong")
            : "";
        });
      }
    },
    previousPage() {
      if (
        this.pagination.currentPage <= this.pagination.totalPages &&
        this.pagination.currentPage != 1
      ) {
        this.g_loading_create("Loading");
        this.$store
          .dispatch("support/getTicketList", this.pagination.currentPage - 1)
          .then(res => {
            this.g_loading_destroy();
            res.status !== 200
              ? this.g_notif_create(false, "False", "Something went Wrong")
              : "";
          });
      }
    },
    nextPage() {
      if (this.pagination.currentPage < this.pagination.totalPages) {
        this.g_loading_create("Loading");
        this.$store
          .dispatch("support/getTicketList", this.pagination.currentPage + 1)
          .then(res => {
            this.g_loading_destroy();
            res.status !== 200
              ? this.g_notif_create(false, "False", "Something went Wrong")
              : "";
          });
      }
    },
    lastPage() {
      if (this.pagination.currentPage != this.pagination.totalPages) {
        this.g_loading_create("Loading");
        this.$store
          .dispatch("support/getTicketList", this.pagination.totalPages)
          .then(res => {
            this.g_loading_destroy();
            res.status !== 200
              ? this.g_notif_create(false, "False", "Something went Wrong")
              : "";
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
