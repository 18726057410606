<template>
  <div class="">
    <div class="grid grid-cols-3 gap-6" v-if="zoneList && zoneList.length > 0">
      <div
        v-for="zon in zoneList"
        :key="zon.id"
        class="cursor-pointer border flex items-center border-white2 p-4 rounded-lg"
        @click="changeZone(zon.short_name, zon.active)"
        :class="{
          'border-orange': zon.active,
          'border-white2': !zon.active
        }"
      >
        <div class="w-16 mr-4">
          <img src="@/assets/id.png" alt="" />
        </div>
        <div class="">
          <div class="text-black font-whitney-medium mb-1">
            {{ zon.name }}
          </div>
          <div class="text-darkBlueText text-sm">Indonesia</div>
        </div>
      </div>
    </div>
    <div class="text-center" v-if="!zoneList">Loading</div>
    <div class="text-center" v-if="zoneList && zoneList.length === 0">
      Cant get data
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    zoneList() {
      return this.$store.state.zone.zoneList;
    },
    deployParams() {
      return this.$store.state.deployParams;
    }
  },
  methods: {
    changeZone(short_name, active) {
      if (active) {
        return;
      }
      this.$store.commit("zone/changeZone", { short_name: short_name });

      let zoneActive = this.zoneList.filter(zone => {
        return zone.active === true;
      });

      this.$store.commit("deployVM/changeZone", zoneActive[0].short_name);
    }
  }
};
</script>

<style></style>
