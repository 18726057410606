<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      @click="dismiss()"
      style="z-index: 20"
    >
      <div
        class="container m-auto max-w-xl border border-white-100 mt-16 bg-white rounded-lg form-container"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div class="py-4 px-4">
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
          <h1 class="text-3xl text-slate">New Volume</h1>
          <p class="text-dark-gray mt-4">
            Add new volume to your virtual machine
          </p>
          <form @submit.prevent="addVolume" class="mt-4">
            <div class="mb-4">
              <div class="text-slate mb-2 text-sm">Select Virtual Machine</div>
              <div class="relative">
                <div
                  @click="choose = !choose"
                  class="border flex justify-between w-full p-2 rounded-lg"
                  :class="
                    choose
                      ? 'border-orange text-orange'
                      : 'border-gray text-slate'
                  "
                >
                  <span class="text-dark-gray" v-if="!vm_name">Select</span>
                  <span class="text-black" v-else>{{ vm_name }}</span>
                  <span class="text-dark-gray" v-if="!choose"
                    ><i class="fas fa-chevron-up"></i
                  ></span>
                  <span class="text-dark-gray" v-if="choose"
                    ><i class="fas fa-chevron-down"></i
                  ></span>
                </div>
                <div
                  class="absolute border border-white2 rounded-lg mt-2 inset-x-0 bg-white"
                  v-if="choose && vmList.length > 0"
                >
                  <div
                    v-for="vm in vmList"
                    :key="vm.id"
                    class="p-2 text-darkBlueText hover:text-orange   cursor-pointer"
                    :class="
                      vmList.length > 1
                        ? 'border-b border-white2 last:border-none'
                        : ''
                    "
                    @click="
                      (vm_name = vm.hostname),
                        (vmUUID = vm.id),
                        (choose = false)
                    "
                  >
                    {{ vm.hostname }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <div class="text-slate mb-2 text-sm">Volume Name</div>
              <input
                class="w-full px-2 py-2 border focus:border-orange border-gray  rounded-lg outline-none"
                type="text"
                v-model="volumeName"
                placeholder="Volume Name"
                maxlength="30"
              />
            </div>

            <div class="mb-2">
              <div class="text-slate mb-2 text-sm">
                Volume Size
                <span class="text-xs">( min 20 GB )</span>
              </div>
              <input
                class="w-full px-2 py-2 border focus:border-orange rounded-lg border-gray outline-none"
                type="number"
                placeholder="Volume Size"
                v-model="size"
                min="1"
                max="50"
              />
            </div>

            <button
              class="w-full p-4 bg-orange text-white mt-2 rounded-xl"
              v-if="!onSubmit"
              type="submit"
            >
              Create Volume
            </button>
            <button
              v-else
              class="w-full p-4 bg-gray text-white mt-2 cursor-not-allowed rounded-xl"
            >
              Create Volume
            </button>
          </form>
        </div>
      </div>
    </div>
    <invoice-detail
      v-if="invoice.active"
      :snap="invoice.snap"
      :data="invoice.data"
      @dismiss="(invoice.active = false), succesClose()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InvoiceDetail from "./invoiceDetail.vue";

export default {
  name: "AddVolume",
  components: { InvoiceDetail },
  computed: {
    ...mapState({ vmList: "vmList", volume: "volume" }),
    invoiceDetail() {
      return this.$store.state.invoice.invoiceDetail;
    }
  },
  props: {
    data: null
  },
  created() {
    this.getVM();
  },
  data: function() {
    return {
      will_dismiss: false,
      onSubmit: false,
      invoice: {
        active: false,
        snap: null,
        data: null
      },
      volumeName: null,
      vmUUID: null,

      size: null,
      choose: false,
      vm_name: null
    };
  },
  mounted: function() {
    this.vm_name = null;
    if (this.data) {
      this.vm_name = this.data.vm_name;
      this.vmUUID = this.data.vmUUID;
    }
  },
  methods: {
    ...mapActions(["getVM"]),
    addVolume() {
      if (!this.volumeName) {
        alert("Volume name can't be empty");
        return;
      }
      if (!this.size) {
        alert("Size can't be empty");
        return;
      }
      this.onSubmit = true;
      this.g_loading_create("Loading");
      this.$store
        .dispatch("createVolume", {
          vmId: this.vmUUID,
          volumeName: this.volumeName,
          size: this.size
        })
        .then(response => {
          this.g_loading_destroy();
          if (response.status === 200) {
            // this.g_notif_create(
            //   true,
            //   "Success",
            //   "New volume has been created, wait for the invoice to pop-up"
            // );
            this.getInvoiceDetail(response.invoice_id);
            // this.succesClose();
          } else {
            this.onSubmit = false;
            this.g_notif_create(false, "Failed", response.error.message);
          }
        });
      //
    },
    succesClose() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
      this.$emit("get");
    },
    getInvoiceDetail(data) {
      this.g_loading_create("Loading Invoice");
      this.$store.dispatch("invoice/getInvoiceDetail", data).then(res => {
        if (res.status === 200) {
          this.invoice.snap = res.snap_token;
          this.invoice.data = res.data;
          this.invoice.active = true;
        }
      });
    },
    dismiss() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
