<template>
  <div class="bg-black bg-opacity-75 fixed inset-0" style="z-index: 10">
    <div class="container max-w-md m-auto">
      <div class="mx-2 mt-56 py-8 px-6 rounded-md border border-gray bg-white">
        <div class="text-center">
          <div class="text-slate text-2xl mb-6" v-if="textConfirmation">
            {{ textConfirmation }}
          </div>
          <div class="text-slate text-2xl mb-6" v-else>Are You Sure ?</div>

          <div class>
            <button
              @click="$emit('accept')"
              class="px-8 py-2 bg-green text-white rounded-md font-semibold mr-4 focus:outline-none"
            >
              YES
            </button>
            <button
              @click="$emit('dismiss')"
              class="px-8 py-2 bg-red text-white rounded-md font-semibold mr-4 focus:outline-none"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirmation",
  props: {
    data: null,
    textConfirmation: null
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
