<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      @click="dismiss()"
      style="z-index: 20"
    >
      <div
        class="container m-auto max-w-xl border border-white-100 mt-16 bg-white rounded-lg form-container"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div class="py-4 px-4">
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
          <h1 class="text-3xl text-slate mb-4">Request Reset Password</h1>

          <form @submit.prevent="requestPassword" class="mt-4">
            <div class="mb-2">
              <div class="text-slate mb-2 text-sm">Old Password</div>
              <input
                class="w-full px-2 py-2 border focus:border-orange rounded-lg border-gray outline-none"
                type="password"
                placeholder="Old Password"
                v-model="oldPassword"
              />
            </div>

            <button
              class="w-full p-4 bg-orange text-white mt-2 rounded-xl"
              type="submit"
            >
              Request
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "requestResetPassword",
  data: function() {
    return {
      will_dismiss: false,
      oldPassword: null
    };
  },
  mounted: function() {},
  methods: {
    requestPassword() {
      if (!this.oldPassword) {
        alert("Please input your old password");
        return;
      }

      this.g_loading_create("Loading");

      this.$store.dispatch("resetPasswordLogin", this.oldPassword).then(res => {
        this.g_loading_destroy();

        if (res.status === 200) {
          this.g_notif_create(true, "Succes", "Please Check Your Email");
          this.dismiss();
        } else {
          this.g_notif_create(false, "Failed", res.error.message);
        }
      });
    },

    dismiss() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
