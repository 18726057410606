<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10" v-if="vm" @click="checkCloseDetail()">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Instances
            </h1>
            <router-link to="/create-instance">
              <div
                class="w-10 h-10 border-2 border-orange text-2xl font-bold flex items-center justify-center rounded-lg text-orange btn-hover"
              >
                +
              </div>
            </router-link>
          </div>
          <div class="" v-if="vm_pagination && vm != 0">
            <div
              class="text-darkBlueText py-2 px-3 bg-grayForm rounded-lg flex items-center"
            >
              <div class="w-6 mr-4">
                <img src="@/assets/icon/ic_search.png" alt="" />
              </div>
              <input
                type="text"
                class="w-full bg-grayForm placeholder-darkBlueText outline-none"
                placeholder="Search"
                v-model="vmSearch"
              />
            </div>
          </div>
        </div>

        <!-- list show -->
        <div class="flex text-slate" v-if="vm_pagination && vm != 0">
          <div class="flex flex-1 items-center">
            <div class="mr-2">Show</div>
            <div class="relative">
              <div
                class="py-1 border border-gray mb-1 w-14 text-center rounded-lg"
              >
                {{ vm_pagination.showTotal }}
              </div>
              <div
                v-if="show"
                class="absolute text-center border border-gray right-0 left-0 w-14 rounded-lg bg-white"
              >
                <div
                  class="px-4 py-1 hover:text-orange cursor-pointer"
                  @click="showTotal(5)"
                >
                  5
                </div>
                <div
                  class="px-4 py-1 hover:text-orange cursor-pointer"
                  @click="showTotal(10)"
                >
                  10
                </div>
                <div
                  class="px-4 py-1 hover:text-orange cursor-pointer"
                  @click="showTotal(20)"
                >
                  20
                </div>
              </div>
            </div>
            <div class="ml-2">
              <i
                @click="show = !show"
                class="fas hover:text-orange"
                :class="show ? 'fa-chevron-down' : 'fa-chevron-up'"
              ></i>
            </div>
          </div>
          <div class v-if="vm_pagination.totalData.length != 0">
            Total Instances:
            <span class="font-bold">{{ vm_pagination.totalData.length }}</span>
          </div>
        </div>

        <div
          class="bg-white p-8 shadow-md rounded-lg mb-20"
          v-if="vm && vm != 0 && vmFilter.length === 0 && !vmSearch"
        >
          <!-- head -->
          <div
            class="grid grid-cols-12 gap-4 text-darkBlueText border-b border-white2 py-6"
          >
            <div class="col-span-3">Server</div>
            <div class="col-span-2">IP Address</div>
            <div class="col-span-2">Location</div>
            <div class="col-span-2">Plan</div>
            <div class="col-span-2">Status</div>
            <div class=""></div>
          </div>
          <!-- content -->
          <div class="">
            <div
              class="py-4 border-b border-white2"
              v-for="vir in vm"
              :key="vir.id"
            >
              <div
                class="grid grid-cols-12 gap-4 items-center text-black"
                :class="vir.extend ? 'mb-4' : ''"
              >
                <!-- server -->
                <div class="col-span-3">
                  <div class="flex items-center" v-if="vir.details">
                    <div
                      class="w-10 h-10 flex items-center justify-center p-2 bg-grayForm rounded-lg mr-2"
                    >
                      <img
                        src="@/assets/icon/ic_freebsd.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'freebsd' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_opensuse.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'opensuse' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_ubuntu.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'ubuntu' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_fedora.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'fedora' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_debian.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'debian' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_centos.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'centos' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        v-if="
                          vir.details.template.name === 'CentOS7_CyberPanel'
                        "
                        src="@/assets/apps/app_cyberpanel.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.name === 'CentOS7_cPanel'"
                        src="@/assets/apps/app_cpanel.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.name === 'CentOS7_Plesk'"
                        src="@/assets/apps/app_plesk.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.name === 'App_WordPress'"
                        src="@/assets/apps/app_wordpress.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.group === 'gitlab'"
                        src="@/assets/apps/app_gitlab.png"
                        alt=""
                      />
                      <img
                        src="@/assets/icon/ic_rocky_linux.png"
                        alt=""
                        v-if="vir.details.template.group === 'rocky linux'"
                      />
                    </div>
                    <div
                      class="truncate hover:text-orange cursor-pointer"
                      @click="
                        (invoiceId = vir.order_invoice),
                          vir.has_provision
                            ? $router.push(
                                '/dashboard/productdetails?id=' + vir.id
                              )
                            : getInvoiceDetail(true),
                          (vir.menu = false)
                      "
                    >
                      {{ vir.hostname }}
                    </div>
                  </div>
                </div>
                <!-- ip address -->
                <div class="col-span-2" v-if="vir.ip_address.length > 0">
                  {{
                    vir.ip_address[0].ipv4_address
                      ? vir.ip_address[0].ipv4_address
                      : "-"
                  }}
                </div>

                <!-- location -->
                <div
                  class="col-span-2"
                  v-if="vir.details && vir.details.zone.name"
                >
                  {{ vir.details.zone.name }}
                </div>

                <div class="col-span-2" v-else>-</div>
                <!-- plan -->
                <div class="col-span-2">
                  <!-- IDR {{ formatRupiah(vir.plan.price_monthly.toString()) }} -->
                  {{ vir.plan.name }}
                </div>
                <!-- status -->
                <div class="col-span-2" v-if="vir.status">
                  <div class="inline-block">
                    <!-- <div
                      class="flex py-2 px-3 rounded-full items-center"
                      :class="[
                        { 'bg-red-10': vir.status === 'poweroff' },
                        { 'bg-blue-10': vir.status === 'running' },
                        {
                          'bg-grayForm': vir.status !== 'poweroff' || 'running',
                        },
                      ]"
                    > -->
                    <div
                      class="flex py-2 px-3 rounded-full items-center"
                      :class="statusVM(vir.status)"
                    >
                      <div
                        class="w-2 h-2 rounded-full mr-2"
                        v-if="
                          vir.status === 'poweroff' || vir.status === 'running'
                        "
                        :class="[
                          { 'bg-red': vir.status === 'poweroff' },
                          { 'bg-blue': vir.status === 'running' }
                        ]"
                      ></div>
                      <loader-mini
                        v-if="vir.status === 'pending' && vir.has_provision"
                        class="mr-2"
                      ></loader-mini>
                      <div class="text-sm">
                        {{ vir.has_provision ? vir.status : "Unpaid" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" v-else>-</div>

                <div class="col-span-1">
                  <div class="flex items-center justify-between">
                    <div class="relative" v-if="vir.has_provision">
                      <div
                        class="icon cursor-pointer text-darkBlueText flex flex-col xl:block hover:text-orange"
                        v-if="vir.status !== 'suspended' && !vir.suspended"
                        @click="closeDetail(vir.id)"
                      >
                        <i class="fas fa-circle mr-1"></i>
                        <i class="fas fa-circle mr-1"></i>
                        <i class="fas fa-circle"></i>
                      </div>
                      <div
                        class="absolute right-0"
                        style="top: 24px; left: -190px; z-index: 5"
                        v-if="vir.menu"
                      >
                        <div
                          class="p-6 bg-white rounded-lg shadow-lg text-darkBlueText"
                        >
                          <div
                            class="items-center justify-center py-2 cursor-pointer gap-4 grid grid-cols-5"
                            @click.stop="
                              $router.push(
                                '/dashboard/productdetails?id=' + vir.id
                              ),
                                (vir.menu = false)
                            "
                          >
                            <div
                              class="col-span-1 flex items-center justify-center"
                            >
                              <i
                                class="fas fa-info-circle text-xl text-blue"
                              ></i>
                            </div>

                            <span class="col-span-4">VM Detail</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status === 'running'"
                            @click.stop="stopVM(vir.id, vir.status)"
                          >
                            <div
                              class="col-span-1 flex items-center justify-center"
                            >
                              <i class="fas fa-power-off text-xl text-red"></i>
                            </div>

                            <span class="col-span-4">Stop VM</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status === 'poweroff'"
                            @click.stop="startVM(vir.id, vir.status)"
                          >
                            <div
                              class="col-span-1 flex items-center justify-center"
                            >
                              <i class="fas fa-play text-green text-xl"></i>
                            </div>

                            <span class="col-span-4">Start VM</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status === 'running'"
                            @click.stop="restartVM(vir.id, vir.status)"
                          >
                            <div
                              class="w-5 h-5 mr-4 flex items-center justify-center col-span-1"
                            >
                              <!-- <img src="@/assets/icon/ic_restart.png" alt="" /> -->
                              <i class="fas fa-redo-alt text-black text-xl"></i>
                            </div>
                            <span class="col-span-4">Restart VM</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status !== 'pending'"
                            @click.stop="
                              closeDetail(vir.id),
                                (confirmation.active = true),
                                (confirmation.data = vir.id),
                                (confirmation.textConfirmation =
                                  'Are you sure you want to delete ' +
                                  vir.hostname +
                                  ' ?')
                            "
                          >
                            <div
                              class="w-5 h-5 col-span-1 flex items-center justify-center"
                            >
                              <img src="@/assets/icon/ic_delete.png" alt="" />
                            </div>
                            <span class="col-span-4">Destroy VM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-6 h-6 flex items-center justify-center rounded-full text-sm border border-grayb text-orange cursor-pointer"
                      v-if="vir.has_provision"
                      @click.stop="vir.extend = !vir.extend"
                    >
                      <i class="fas fa-chevron-down" v-if="!vir.extend"></i>
                      <i class="fas fa-chevron-up" v-if="vir.extend"></i>
                    </div>
                  </div>
                </div>
              </div>

              <!-- extend -->
              <div
                class="py-4 bg-grayForm gap-1 px-4 text-darkBlueText rounded-lg grid-cols-2 lg:grid-cols-4 xl:grid-cols-12 text-sm"
                :class="vir.extend ? 'grid' : ' hidden'"
              >
                <div class="col-span-2 xl:col-span-4" v-if="vir.details">
                  <div class="mb-2" v-if="vir.plan">
                    {{ vir.details.vcpu }} CPU / {{ vir.details.memory }}GB
                    Memory / {{ vir.details.disk_size }}GB Disk Size
                  </div>
                  <div class="" v-if="vir.details.public_domain">
                    Public DNS : {{ vir.details.public_domain }}
                  </div>
                  <!-- <div class="" v-if="!vir.details.public_domain">
                    Public DNS : -
                  </div> -->
                </div>
                <div class="col-span-2">
                  <div class="" v-if="vir.details && vir.details.template.name">
                    OS : {{ vir.details.template.name }}
                  </div>
                  <div class="" v-else>OS : -</div>
                </div>

                <div class="col-span-2" v-if="vir.ip_address.length > 0">
                  Ipv4 :
                  {{
                    vir.ip_address[0].ipv4_address
                      ? vir.ip_address[0].ipv4_address
                      : "-"
                  }}
                </div>

                <div
                  class="col-span-2"
                  v-if="
                    vir.ip_address.length > 0 && vir.ip_address[0].ipv6_address
                  "
                >
                  Ipv6 : {{ vir.ip_address[0].ipv6_address }}
                </div>
                <div class="col-span-2" v-else>Ipv6 : -</div>
                <div class="col-span-2" v-if="vir.ip_address.length > 1">
                  IP Private :
                  {{
                    vir.ip_address[1].ipv4_address
                      ? vir.ip_address[1].ipv4_address
                      : "-"
                  }}
                </div>
              </div>

              <!-- menu -->
            </div>
          </div>

          <!-- pagination -->
          <div
            class="flex justify-center items-center mt-4"
            v-if="vm_pagination && vm != 0"
          >
            <div class="text-sm flex items-center justify-center">
              <button
                class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                @click="firstPage()"
              >
                First
              </button>
              <div
                class="w-6 h-6 mx-4 flex text-center text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                @click="previousPage()"
              >
                <i class="fas fa-chevron-left"></i>
              </div>
            </div>
            <div class="mx-4">
              <span
                class="text-black font-bold"
                v-if="vm_pagination.currentPage"
                >{{ vm_pagination.currentPage }}</span
              >
            </div>
            <div class="text-sm flex items-center justify-center">
              <div
                class="w-6 h-6 mx-4 text-center flex text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                @click="nextPage()"
              >
                <i class="fas fa-chevron-right"></i>
              </div>
              <button
                class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                @click="lastPage()"
              >
                Last
              </button>
            </div>
          </div>
        </div>

        <div
          class="bg-white p-8 shadow-md rounded-lg mb-20"
          v-if="vmFilter && vmFilter.length > 0"
        >
          <!-- head -->
          <div
            class="grid grid-cols-12 gap-4 text-darkBlueText border-b border-white2 py-6"
          >
            <div class="col-span-3">Server</div>
            <div class="col-span-2">IP Address</div>
            <div class="col-span-2">Location</div>
            <div class="col-span-2">Plan</div>
            <div class="col-span-2">Status</div>
            <div class=""></div>
          </div>
          <!-- content -->
          <div class="">
            <div
              class="py-4 border-b border-white2"
              v-for="vir in vmFilter"
              :key="vir.id"
            >
              <div
                class="grid grid-cols-12 gap-4 items-center text-black"
                :class="vir.extend ? 'mb-4' : ''"
              >
                <!-- server -->
                <div class="col-span-3">
                  <div class="flex items-center" v-if="vir.details">
                    <div
                      class="w-10 h-10 flex items-center justify-center p-2 bg-grayForm rounded-lg mr-2"
                    >
                      <img
                        src="@/assets/icon/ic_freebsd.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'freebsd' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_opensuse.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'opensuse' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_ubuntu.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'ubuntu' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_fedora.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'fedora' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_debian.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'debian' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        src="@/assets/icon/ic_centos.png"
                        alt=""
                        v-if="
                          vir.details.template.group === 'centos' &&
                            vir.details.template.label !== 'app_cloudora'
                        "
                      />
                      <img
                        v-if="
                          vir.details.template.name === 'CentOS7_CyberPanel'
                        "
                        src="@/assets/apps/app_cyberpanel.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.name === 'CentOS7_cPanel'"
                        src="@/assets/apps/app_cpanel.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.name === 'CentOS7_Plesk'"
                        src="@/assets/apps/app_plesk.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.name === 'App_WordPress'"
                        src="@/assets/apps/app_wordpress.png"
                        alt=""
                      />
                      <img
                        v-if="vir.details.template.group === 'gitlab'"
                        src="@/assets/apps/app_gitlab.png"
                        alt=""
                      />
                      <img
                        src="@/assets/icon/ic_rocky_linux.png"
                        alt=""
                        v-if="vir.details.template.group === 'rocky linux'"
                      />
                    </div>
                    <div
                      class="truncate hover:text-orange cursor-pointer"
                      @click="
                        $router.push('/dashboard/productdetails?id=' + vir.id),
                          (vir.menu = false)
                      "
                    >
                      {{ vir.hostname }}
                    </div>
                  </div>
                </div>
                <!-- ip address -->
                <div class="col-span-2" v-if="vir.ip_address.length > 0">
                  {{
                    vir.ip_address[0].ipv4_address
                      ? vir.ip_address[0].ipv4_address
                      : "-"
                  }}
                </div>
                <div class="col-span-2" v-else>-</div>
                <!-- location -->
                <div
                  class="col-span-2"
                  v-if="vir.details && vir.details.zone.name"
                >
                  {{ vir.details.zone.name }}
                </div>

                <div class="col-span-2" v-else>-</div>
                <!-- plan -->
                <div class="col-span-2">
                  <!-- IDR {{ formatRupiah(vir.plan.price_monthly.toString()) }} -->
                  {{ vir.plan.name }}
                </div>
                <!-- status -->
                <div class="col-span-2" v-if="vir.status">
                  <div class="inline-block">
                    <!-- <div
                      class="flex py-2 px-3 rounded-full items-center"
                      :class="[
                        { 'bg-red-10': vir.status === 'poweroff' },
                        { 'bg-blue-10': vir.status === 'running' },
                        {
                          'bg-grayForm': vir.status !== 'poweroff' || 'running',
                        },
                      ]"
                    > -->
                    <div
                      class="flex py-2 px-3 rounded-full items-center"
                      :class="statusVM(vir.status)"
                    >
                      <div
                        class="w-2 h-2 rounded-full mr-2"
                        v-if="
                          vir.status === 'poweroff' || vir.status === 'running'
                        "
                        :class="[
                          { 'bg-red': vir.status === 'poweroff' },
                          { 'bg-blue': vir.status === 'running' }
                        ]"
                      ></div>
                      <loader-mini
                        v-if="vir.status === 'pending' && vir.has_provision"
                        class="mr-2"
                      ></loader-mini>
                      <div class="text-sm">
                        {{ vir.has_provision ? vir.status : "Unpaid" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" v-else>-</div>

                <div class="col-span-1">
                  <div class="flex items-center justify-between">
                    <div class="relative" v-if="vir.has_provision">
                      <div
                        class="icon cursor-pointer text-darkBlueText flex flex-col xl:block hover:text-orange"
                        v-if="vir.status !== 'suspended' && !vir.suspended"
                        @click="closeDetail(vir.id)"
                      >
                        <i class="fas fa-circle mr-1"></i>
                        <i class="fas fa-circle mr-1"></i>
                        <i class="fas fa-circle"></i>
                      </div>
                      <div
                        class="absolute right-0"
                        style="top: 24px; left: -190px; z-index: 5"
                        v-if="vir.menu"
                      >
                        <div
                          class="p-6 bg-white rounded-lg shadow-lg text-darkBlueText"
                        >
                          <div
                            class="items-center justify-center py-2 cursor-pointer gap-4 grid grid-cols-5"
                            @click.stop="
                              $router.push(
                                '/dashboard/productdetails?id=' + vir.id
                              ),
                                (vir.menu = false)
                            "
                          >
                            <div
                              class="col-span-1 flex items-center justify-center"
                            >
                              <i
                                class="fas fa-info-circle text-xl text-blue"
                              ></i>
                            </div>

                            <span class="col-span-4">VM Detail</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status === 'running'"
                            @click.stop="stopVM(vir.id, vir.status)"
                          >
                            <div
                              class="col-span-1 flex items-center justify-center"
                            >
                              <i class="fas fa-power-off text-xl text-red"></i>
                            </div>

                            <span class="col-span-4">Stop VM</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status === 'poweroff'"
                            @click.stop="startVM(vir.id, vir.status)"
                          >
                            <div
                              class="col-span-1 flex items-center justify-center"
                            >
                              <i class="fas fa-play text-green text-xl"></i>
                            </div>

                            <span class="col-span-4">Start VM</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status === 'running'"
                            @click.stop="restartVM(vir.id, vir.status)"
                          >
                            <div
                              class="w-5 h-5 mr-4 flex items-center justify-center col-span-1"
                            >
                              <!-- <img src="@/assets/icon/ic_restart.png" alt="" /> -->
                              <i class="fas fa-redo-alt text-black text-xl"></i>
                            </div>
                            <span class="col-span-4">Restart VM</span>
                          </div>
                          <div
                            class="grid grid-cols-5 gap-4 items-center justify-center py-2 cursor-pointer"
                            v-if="vir.status !== 'pending'"
                            @click.stop="
                              closeDetail(vir.id),
                                (confirmation.active = true),
                                (confirmation.data = vir.id),
                                (confirmation.textConfirmation =
                                  'Are you sure you want to delete ' +
                                  vir.hostname +
                                  ' ?')
                            "
                          >
                            <div
                              class="w-5 h-5 col-span-1 flex items-center justify-center"
                            >
                              <img src="@/assets/icon/ic_delete.png" alt="" />
                            </div>
                            <span class="col-span-4">Destroy VM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="vir.has_provision"
                      class="w-6 h-6 flex items-center justify-center rounded-full text-sm border border-grayb text-orange cursor-pointer"
                      @click.stop="vir.extend = !vir.extend"
                    >
                      <i class="fas fa-chevron-down" v-if="!vir.extend"></i>
                      <i class="fas fa-chevron-up" v-if="vir.extend"></i>
                    </div>
                  </div>
                </div>
              </div>

              <!-- extend -->
              <div
                class="py-4 bg-grayForm gap-1 px-4 text-darkBlueText rounded-lg grid-cols-2 lg:grid-cols-4 xl:grid-cols-12 text-sm"
                :class="vir.extend ? 'grid' : ' hidden'"
              >
                <div class="col-span-2 xl:col-span-4" v-if="vir.details">
                  <div class="mb-2" v-if="vir.plan">
                    {{ vir.details.vcpu }} CPU / {{ vir.details.memory }}GB
                    Memory / {{ vir.details.disk_size }}GB Disk Size
                  </div>
                  <div class="" v-if="vir.details.public_domain">
                    Public DNS : {{ vir.details.public_domain }}
                  </div>
                  <!-- <div class="" v-if="!vir.details.public_domain">
                    Public DNS : -
                  </div> -->
                </div>
                <div class="col-span-2">
                  <div class="" v-if="vir.details && vir.details.template.name">
                    OS : {{ vir.details.template.name }}
                  </div>
                  <div class="" v-else>OS : -</div>
                </div>

                <div class="col-span-2" v-if="vir.ip_address.length > 0">
                  Ipv4 :
                  {{
                    vir.ip_address[0].ipv4_address
                      ? vir.ip_address[0].ipv4_address
                      : "-"
                  }}
                </div>
                <div
                  class="col-span-2"
                  v-if="
                    vir.ip_address.length > 0 && vir.ip_address[0].ipv6_address
                  "
                >
                  Ipv6 : {{ vir.ip_address[0].ipv6_address }}
                </div>
                <div class="col-span-2" v-else>Ipv6 : -</div>
                <div class="col-span-2" v-if="vir.ip_address.length > 1">
                  IP Private :
                  {{
                    vir.ip_address[1].ipv4_address
                      ? vir.ip_address[1].ipv4_address
                      : "-"
                  }}
                </div>
                <!-- <div class="col-span-2" v-else>
                  <div class="">Ipv6 : -</div>
                </div> -->
              </div>

              <!-- menu -->
            </div>
          </div>

          <!-- pagination -->
          <!-- pagination -->
          <div
            class="flex justify-center items-center mt-4"
            v-if="vmFilterPagination && vmFilter.length != 0"
          >
            <div class="text-sm flex items-center justify-center">
              <button
                class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                @click="
                  vmFilterPagination.currentPage !== 1
                    ? ((vmFilterPagination.currentPage = 1),
                      searchPagination(vmFilterPagination.totalData))
                    : ''
                "
              >
                First
              </button>
              <div
                class="w-6 h-6 mx-4 flex text-center text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                @click="
                  vmFilterPagination.currentPage <=
                    vmFilterPagination.totalPages &&
                  vmFilterPagination.currentPage != 1
                    ? ((vmFilterPagination.currentPage -= 1),
                      searchPagination(vmFilterPagination.totalData))
                    : ''
                "
              >
                <i class="fas fa-chevron-left"></i>
              </div>
            </div>
            <div class="mx-4">
              <span
                class="text-black font-bold"
                v-if="vmFilterPagination.currentPage"
                >{{ vmFilterPagination.currentPage }}</span
              >
            </div>
            <div class="text-sm flex items-center justify-center">
              <div
                class="w-6 h-6 mx-4 text-center flex text-xs items-center justify-center cursor-pointer text-white bg-grayText rounded-full hover:bg-orange"
                @click="
                  vmFilterPagination.currentPage < vmFilterPagination.totalPages
                    ? ((vmFilterPagination.currentPage =
                        vmFilterPagination.totalPages),
                      searchPagination(vmFilterPagination.totalData))
                    : ''
                "
              >
                <i class="fas fa-chevron-right"></i>
              </div>
              <button
                class="py-2 px-4 text-grayText focus:outline-none hover:text-orange"
                @click="
                  vmFilterPagination.currentPage !=
                  vmFilterPagination.totalPages
                    ? ((vmFilterPagination.currentPage =
                        vmFilterPagination.totalPages),
                      searchPagination(vmFilterPagination.totalData))
                    : ''
                "
              >
                Last
              </button>
            </div>
          </div>
        </div>

        <!-- no data -->
        <div
          class="flex flex-col flex-1 items-center justify-center"
          v-if="(vm && vm.length) === 0 || (vmFilter.length === 0 && vmSearch)"
        >
          <div class="mt-6 mb-16">
            <img
              src="@/assets/img/img_nodata.png"
              class="w-2/3 mx-auto"
              alt=""
            />
          </div>
          <div class="text-2xl text-darkBlueText font-nunito font-bold mb-16">
            {{
              vmFilter.length === 0 && vmSearch
                ? "We cannot find the data you are searching for"
                : "You don't have any active products"
            }}
          </div>
          <router-link to="/create-instance">
            <button
              class="text-orange border border-orange rounded-md py-2 px-3 hover:bg-orange hover:text-white"
            >
              Create New Instance
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <confirmation
      v-if="confirmation.active"
      @accept="destroyVM(confirmation.data)"
      @dismiss="(confirmation.active = false), (confirmation.data = null)"
      :data="confirmation.data"
      :textConfirmation="confirmation.textConfirmation"
    />
    <Notification
      v-if="notif.show"
      @dismiss="closeNotif()"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    />
    <invoice-detail
      v-if="invoice.active"
      :snap="invoice.snap"
      :data="invoice.data"
      @dismiss="(invoice.active = false), (onTopup = false)"
      @changePayment="(invoice.active = false), getInvoiceDetail()"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
// import loader from "@/components/loaderMini.vue";
import Notification from "@/components/Notification.vue";
import confirmation from "@/components/confirmation.vue";
import { mapState, mapActions } from "vuex";
import Pusher from "pusher-js";
import LoaderMini from "../../components/loaderMini.vue";
import InvoiceDetail from "../../components/invoiceDetail.vue";
export default {
  name: "Dashboard",
  components: {
    sidebar,
    navbar,
    // loader,
    Notification,
    confirmation,
    LoaderMini,
    InvoiceDetail
  },
  computed: {
    ...mapState({
      vm: "vmList",
      vm_pagination: "vm_pagination",
      notif: "notification",
      minimize: "minimize",
      payment: "payment"
    })
  },
  watch: {
    vmSearch(e) {
      let filterData;
      if (e) {
        filterData = this.vm_pagination.totalData.filter(res => {
          return res.hostname.toLowerCase().includes(this.vmSearch);
        });
      } else filterData = [];
      if (filterData.length > 0) {
        this.searchPagination(filterData);
      } else {
        this.vmFilter = [];
        this.vmFilterPagination.currentPage = 1;
        this.vmFilterPagination.totalPages = null;
        this.vmFilterPagination.totalData = [];
      }
    },
    "vm_pagination.showTotal"(e) {
      if (e) {
        console.log("showtotal", e);
        this.vmFilterPagination.showTotal = JSON.parse(JSON.stringify(e));
        this.searchPagination(this.vmFilterPagination.totalData);
      }
    }
  },

  created() {
    document.title = "Nevacloud | Dashboard";
    if (!this.vm) {
      this.g_loading_create("Loading");
    }
    this.getVM().then(() => {
      this.vmFilterPagination = JSON.parse(JSON.stringify(this.vm_pagination));
      this.g_loading_destroy();
    });
  },

  data: function() {
    return {
      invoice: {
        active: false,
        snap: null,
        data: null
      },
      invoiceId: null,
      show: false,
      vmFilterPagination: null,
      vmSearch: null,
      vmFilter: [],
      confirmation: {
        active: false,
        data: null,
        textConfirmation: null,
        vmFilter: null
      }
    };
  },

  mounted: function() {
    // PUSHER

    if (this.$cookies.isKey("userID") && this.$cookies.isKey("token")) {
      const token = this.$cookies.get("token");
      Pusher.logToConsole = false;
      var pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        app_id: process.env.VUE_APP_PUSHER_APP_ID,
        secret: process.env.VUE_APP_PUSHER_SECRET,
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "Application/json"
          }
        }
      });
      var channel = pusher.subscribe(
        "private-Vm.User." + this.$cookies.get("userID")
      );
      channel.bind("VirtualMachineRunning", data => {
        this.commitVMStatus(data);
      });
      channel.bind("VirtualMachinePoweroff", data => {
        this.commitVMStatus(data);
      });
    }
  },
  methods: {
    ...mapActions(["getVM"]),
    statusVM(status) {
      if (status === "poweroff") {
        return "bg-red-10";
      } else if (status === "running") {
        return "bg-blue-10";
      } else {
        return "bg-grayForm";
      }
    },
    searchPagination(data) {
      this.vmFilterPagination.totalPages = Math.ceil(
        data.length / this.vmFilterPagination.showTotal
      );
      this.vmFilterPagination.totalData = data;
      var vmListData = this.vmFilterPagination.totalData.slice(
        (this.vmFilterPagination.currentPage - 1) *
          this.vmFilterPagination.showTotal,
        this.vmFilterPagination.currentPage * this.vmFilterPagination.showTotal
      );
      this.vmFilter = vmListData;
    },
    showTotal(data) {
      this.show = false;
      this.vm_pagination.currentPage = 1;
      this.vm_pagination.showTotal = data;
      this.$store.commit("updateVM", this.vm_pagination.totalData);
    },
    firstPage() {
      if (this.vm_pagination.currentPage != 1) {
        this.vm_pagination.currentPage = 1;
        this.$store.commit("updateVM", this.vm_pagination.totalData);
      }
    },
    previousPage() {
      if (
        this.vm_pagination.currentPage <= this.vm_pagination.totalPages &&
        this.vm_pagination.currentPage != 1
      ) {
        this.vm_pagination.currentPage -= 1;
        this.$store.commit("updateVM", this.vm_pagination.totalData);
      }
    },
    nextPage() {
      if (this.vm_pagination.currentPage < this.vm_pagination.totalPages) {
        this.vm_pagination.currentPage += 1;
        this.$store.commit("updateVM", this.vm_pagination.totalData);
      }
    },
    lastPage() {
      if (this.vm_pagination.currentPage != this.vm_pagination.totalPages) {
        this.vm_pagination.currentPage = this.vm_pagination.totalPages;
        this.$store.commit("updateVM", this.vm_pagination.totalData);
      }
    },
    closeDetail(id) {
      event.stopPropagation();
      this.vm.forEach(data => {
        if (data.id == id) {
          if (!data.menu) {
            data.menu = true;
          } else {
            data.menu = false;
          }
        } else {
          data.menu = false;
        }
      });
    },
    checkCloseDetail() {
      this.vm.forEach(data => {
        data.menu = false;
        data.extend = false;
      });
    },
    startVM(id, status) {
      if (status !== "poweroff") {
        this.notif.header = "Failed";
        this.notif.message = "VM must be in poweroff State";
        this.notif.active = false;
        this.notif.show = true;
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("startVM", id).then(res => {
        this.g_loading_destroy();
        if (res.status !== 200) {
          this.notif.header = "Failed";
          this.notif.message = res.data.error.message;
          this.notif.active = false;
          this.notif.show = true;
        }
      });
      this.closeDetail(id);
    },
    stopVM(id, status) {
      if (status !== "running") {
        this.notif.header = "Failed";
        this.notif.message = "VM must be in Running State";
        this.notif.active = false;
        this.notif.show = true;
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("stopVM", id).then(res => {
        this.g_loading_destroy();
        if (res.status !== 200) {
          this.notif.header = "Failed";
          this.notif.message = res.data.error.message;
          this.notif.active = false;
          this.notif.show = true;
        }
      });
      this.closeDetail(id);
    },
    restartVM(id, status) {
      if (status !== "running") {
        this.notif.header = "Failed";
        this.notif.message = "VM must be in running State";
        this.notif.active = false;
        this.notif.show = true;
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("restartVM", id).then(this.g_loading_destroy());
      this.closeDetail(id);
    },
    destroyVM(id) {
      this.closeDetail(id);
      this.confirmation.active = false;
      this.confirmation.data = null;
      this.g_loading_create("Loading");
      this.$store.dispatch("destroyVM", id).then(this.g_loading_destroy());
    },
    commitVMStatus(data) {
      this.$store.commit("changeVM", data);
    },
    closeNotif() {
      if (!this.notif.active) {
        this.notif.show = false;
      } else {
        this.notif.show = false;
      }
    },
    getInvoiceDetail(reset) {
      this.g_loading_create("Loading Invoice");

      if (reset) {
        this.$store.commit("resetPayment");
      }

      this.$store
        .dispatch("invoice/getInvoiceDetail", {
          id: this.invoiceId,
          query: this.payment.method
        })
        .then(res => {
          this.g_loading_destroy();
          if (res && res.status === 200) {
            this.invoice.snap = res.snap_token;
            this.invoice.data = res.data;
            this.invoice.active = true;
          } else {
            this.g_notif_create(
              false,
              "Something went wrong",
              "Error getting invoice data"
            );
            console.log("error", res);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 5px;
}
</style>
