<template>
  <div class="">
    <div class="fixed bottom-0 left-0 right-0 bg-white p-6 z-30">
      <div class="flex justify-between">
        <h2 class="text-lg text-black font-nunito font-bold mb-4">
          {{ name }}
        </h2>
        <div class="text-lg text-grayText" @click="dismiss()">
          <i class="far fa-times-circle"></i>
        </div>
      </div>
      <area-chart
        :data="data"
        :colors="colors"
        height="235px"
        class="mx-autow-full"
        legend="bottom"
      />
    </div>
    <div
      class="fixed inset-0 bg-darkBlueText bg-opacity-30 z-20"
      @click="dismiss()"
    ></div>
  </div>
</template>

<script>
export default {
  name: "graphZoom",
  props: {
    name: null,
    data: null,
    colors: null
  },
  methods: {
    dismiss() {
      this.$emit("dismiss");
    }
  }
};
</script>

<style></style>
