<template>
  <div class="">
    <div class="flex items-center justify-center text-sm mb-6">
      <div
        class="py-2 px-3 cursor-pointer"
        @click="
          (planOption.gp = true),
            (planOption.mo = false),
            (planOption.dc = false)
        "
        :class="planOption.gp ? 'bg-grayForm text-black rounded-lg' : ''"
      >
        General Purpose
      </div>
      <!-- memory optimize nav -->
      <!-- <div
                  class="py-2 px-3 cursor-pointer"
                  @click="
                    (planOption.mo = true),
                      (planOption.gp = false),
                      (planOption.dc = false)
                  "
                  :class="
                    planOption.mo ? 'bg-grayForm text-black rounded-lg' : ''
                  "
                >
                  Memory Optimize
                </div> -->
      <!-- Dedicated CPU nav -->
      <!-- <div
                  class="py-2 px-3 cursor-pointer"
                  @click="
                    (planOption.mo = false),
                      (planOption.gp = false),
                      (planOption.dc = true)
                  "
                  :class="
                    planOption.dc ? 'bg-grayForm text-black rounded-lg' : ''
                  "
                >
                  Dedicated CPU
                </div> -->
    </div>

    <div class="mb-6" v-if="plan && selectedPlan">
      <div
        class="grid grid-cols-10 border border-white2 items-center px-4 py-3 rounded-t-lg bg-white2 gap-2 text-sm text-darkBlueText"
      >
        <div class="col-span-2 grid grid-cols-5 items-center">
          <!-- <div
            class="w-4 h-4 rounded-full border border-orange bg-orange"
          ></div> -->
          <div class="col-span-1"></div>
          <div class="col-span-4">Plan</div>
        </div>
        <div class="col-span-2">Monthly</div>
        <div class="col-span-1">RAM</div>
        <div class="col-span-1">CPU</div>
        <!-- <div class="col-span-2">Max<br />Additional IP</div> -->
        <div class="col-span-2">Main Storage</div>
        <div class="col-span-2 truncate">Max Additional Storage</div>
      </div>

      <div
        class="grid grid-cols-10 border-b border-l border-r border-white2 items-center py-3 px-4 text-sm gap-2"
        v-for="plan in plan"
        :key="plan.id"
        @click="changePlan(plan)"
      >
        <div class="col-span-2 grid grid-cols-5 items-center">
          <div
            class="w-4 h-4 rounded-full border hover:border-orange cursor-pointer col-span-1 flex items-center justify-center"
            :class="
              selectedPlan.id === plan.id ? 'border-orange' : 'border-gray'
            "
          >
            <div
              v-if="selectedPlan.id === plan.id"
              class="w-2 h-2 rounded-full bg-orange"
            ></div>
          </div>

          <div class="col-span-4 truncate">{{ plan.name }}</div>
        </div>
        <div class="col-span-2">
          Rp {{ formatRupiah(plan.price_monthly.toString()) }}
        </div>
        <div class="col-span-1">{{ plan.memory }} GB</div>
        <div class="col-span-1">{{ plan.vcpu }}</div>
        <!-- <div class="col-span-2">{{ plan.quota_ip_address }}</div> -->
        <div class="col-span-2">{{ plan.disk_size }} GB</div>
        <div class="col-span-2">{{ plan.max_volume_size }} GB</div>
      </div>
    </div>

    <!-- <div class="flex mb-6" v-if="plan && selectedPlan">
      <div class="bg-purple p-4 rounded-l-lg w-28">
        <div class="flex items-center justify-center">
          <img
            src="@/assets/icon/ic_memory.png"
            class="w-12 mx-auto mb-2"
            alt=""
          />
        </div>

        <div class="text-center text-white font-whitney-bold">
          {{ selectedPlan.memory }} GB
        </div>
      </div>
      <div
        class="flex-1 rounded-r-lg flex p-4 bg-purpleLight items-center justify-between"
      >
        <div class="">
          <div class="text-black font-whitney-medium" v-if="selectedPlan.name">
            {{ selectedPlan.name ? selectedPlan.name : "General Purpose" }}
          </div>
          <div class="text-black text-sm" v-if="selectedPlan.price_monthly">
            Rp
            <span class="text-base text-orange font-whitney-bold">{{
              formatRupiah(selectedPlan.price_monthly.toString())
            }}</span>
            /month
          </div>
          <div
            class="text-darkBlueText text-xs"
            v-if="selectedPlan.price_hourly"
          >
            Rp
            {{ formatRupiah(selectedPlan.price_hourly.toString()) }}
            /hour
          </div>
        </div>
        <div class="grid grid-cols-3 gap-2">
          <div class="bg-white p-2 text-center rounded-md">
            <div class="text-darkBlueText">CPU</div>
            <div class="text-black font-whitney-bold" v-if="selectedPlan.vcpu">
              {{ selectedPlan.vcpu }}
            </div>
          </div>
          <div class="bg-white p-2 text-center rounded-md">
            <div class="text-darkBlueText">Memory</div>
            <div
              class="text-black font-whitney-bold"
              v-if="selectedPlan.memory"
            >
              {{ selectedPlan.memory }} GB
            </div>
          </div>
          <div class="bg-white p-2 text-center rounded-md">
            <div class="text-darkBlueText">Disk Size</div>
            <div
              class="text-black font-whitney-bold"
              v-if="selectedPlan.disk_size"
            >
              {{ selectedPlan.disk_size }} GB
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4">
      <vue-slider
        ref="sliderActive"
        v-model="sliderActive.value"
        v-bind="sliderActive.option"
        :order="false"
      ></vue-slider>
    </div> -->
  </div>
</template>

<script>
export default {
  computed: {
    deployParams() {
      return this.$store.state.deployVM.deployParams;
    },
    planOption() {
      return this.$store.state.plan.planOption;
    },
    selectedPlan() {
      return this.$store.state.plan.selectedPlan;
    },
    plan() {
      return this.$store.state.plan.plan;
    },
    totalPayment() {
      return this.$store.state.deployVM.totalPayment;
    }
  },

  watch: {
    // plan: function (e) {
    //   if (e) {

    //     this.sliderActive.value = 0;

    //     this.sliderActive.option.max = this.plan.length - 1;
    //     for (let x = 0; x < this.plan.length; x++) {
    //       this.sliderActive.option.marks[x] = this.plan[x].memory + " GB";
    //     }

    //   }
    // },
    // "sliderActive.value": function (oldVal) {
    //   this.$store.commit(
    //     "deployVM/changePackageBilling",
    //     this.sliderActive.option.marks[oldVal]
    //   );

    //   this.$store.commit(
    //     "plan/changePlan",
    //     this.sliderActive.option.marks[oldVal].split(" GB")[0]
    //   );
    //   this.$store.commit("deployVM/resetAdvance");

    //   this.$store.commit("deployVM/changePlan", this.selectedPlan.id);
    //   this.$store.commit(
    //     "deployVM/changeTotalPayment",
    //     this.selectedPlan.price_monthly
    //   );
    //   this.$store.dispatch("plan/getPlanConfig", this.selectedPlan.id);

    // },

    selectedPlan(e) {
      // console.log("ini E Selected Plan", e);
      if (this.deployParams.zone) {
        this.deployParams.plan_id = e.id;
      }
    },
    selectedTemplate(e) {
      this.deployParams.template_id = e.id;
      this.tab.os = false;
      this.tab.package = true;
      this.tab.auth = true;
      this.tabAdvanced.block = false;
      this.tabAdvanced.hostname = true;
      this.tab.advanced = true;
    }
  },
  data: function() {
    return {};
  },
  mounted() {},
  methods: {
    changePlan(data) {
      this.$store.commit("deployVM/changePackageBilling", data.memory);

      this.$store.commit("plan/changePlan", data.memory);
      this.$store.commit("deployVM/resetAdvance");

      this.$store.commit("deployVM/changePlan", this.selectedPlan.id);
      this.$store.commit(
        "deployVM/changeTotalPayment",
        this.selectedPlan.price_monthly
      );
      this.$store.dispatch("plan/getPlanConfig", this.selectedPlan.id);
    }
  }
};
</script>

<style></style>
