<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">Apps</h1>
            <!-- <div
              class="w-10 h-10 border-2 border-orange text-2xl font-bold flex items-center justify-center rounded-lg text-orange cursor-pointer"
            >
              +
            </div> -->
          </div>
          <div class="" v-if="template && template.length > 0">
            <div
              class="text-darkBlueText py-2 px-3 bg-grayForm rounded-lg flex items-center"
            >
              <div class="w-6 mr-4">
                <img src="@/assets/icon/ic_search.png" alt="" />
              </div>
              <input
                type="text"
                class="w-full bg-grayForm placeholder-darkBlueText outline-none"
                placeholder="Search"
                v-model="searchApps"
              />
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-1 lg:grid-cols-4 gap-4"
          v-if="template && template.length !== 0 && !filterApps"
        >
          <!-- <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_node.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Node</div>
                <div class="text-sm text-darkBlueText">
                  Express | Hapi | Puppeeteer
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_docker.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Docker</div>
                <div class="text-sm text-darkBlueText">Redis | MySQL</div>
              </div>
            </div>
          </div> -->
          <div
            class="col-span-1 cursor-pointer"
            @click="$router.push('/create-instance?apps=' + tem.id)"
            v-for="tem in template"
            :key="tem.id"
          >
            <div
              class="p-6 bg-grayForm rounded-lg hover:bg-orange2 hover:text-orange border border-white2 hover:border-orange"
            >
              <div class="mb-2">
                <img
                  v-if="tem.name === 'CentOS7_CyberPanel'"
                  src="@/assets/apps/app_cyberpanel.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'CentOS7_cPanel'"
                  src="@/assets/apps/app_cpanel.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'CentOS7_Plesk'"
                  src="@/assets/apps/app_plesk.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'App_WordPress'"
                  src="@/assets/apps/app_wordpress.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'GitLab CE - KVM'"
                  src="@/assets/apps/app_gitlab.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2" v-if="tem.name">
                  {{
                    tem.name.split("_").length > 1
                      ? tem.name.split("_")[1]
                      : tem.name
                  }}
                </div>
                <div class="text-sm text-darkBlueText mb-2" v-if="tem.group">
                  {{ tem.group.split(" | ")[0] }}
                </div>
                <div class="" v-if="tem.group">
                  <div
                    class="text-sm text-darkBlueText inline-block"
                    v-for="(gn, index) in tem.group.split(' | ')"
                    :key="index"
                  >
                    <span class="mr-1" v-if="index !== 0"
                      >{{ gn
                      }}<span
                        v-if="
                          tem.group.split(' | ').length > 2 &&
                            index !== tem.group.split(' | ').length - 1
                        "
                        >,</span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_python.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Python</div>
                <div class="text-sm text-darkBlueText">
                  Django | Flask | Celery
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_ruby.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Ruby</div>
                <div class="text-sm text-darkBlueText">
                  Rails | Sidekiq worker
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mt-4 mb-4">
                <img
                  src="@/assets/icon/ic_elixir.png"
                  class="w-24 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Elixir</div>
                <div class="text-sm text-darkBlueText">
                  Phoenix | Elixir Cluster
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_go.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Go</div>
                <div class="text-sm text-darkBlueText">Gin | Beego | Pgweb</div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_rust.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Rust</div>
                <div class="text-sm text-darkBlueText">
                  Rocket | Actix | GraphQl
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_php.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">PHP</div>
                <div class="text-sm text-darkBlueText">Laravel</div>
              </div>
            </div>
          </div> -->
        </div>
        <div
          class="grid grid-cols-1 lg:grid-cols-4 gap-4"
          v-if="filterApps && filterApps.length !== 0"
        >
          <!-- <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_node.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Node</div>
                <div class="text-sm text-darkBlueText">
                  Express | Hapi | Puppeeteer
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_docker.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Docker</div>
                <div class="text-sm text-darkBlueText">Redis | MySQL</div>
              </div>
            </div>
          </div> -->
          <div
            class="col-span-1 cursor-pointer"
            @click="$router.push('/create-instance?apps=' + tem.id)"
            v-for="tem in filterApps"
            :key="tem.id"
          >
            <div
              class="p-6 bg-grayForm rounded-lg hover:bg-orange2 hover:text-orange border border-white2 hover:border-orange"
            >
              <div class="mb-2">
                <img
                  v-if="tem.name === 'CentOS7_CyberPanel'"
                  src="@/assets/apps/app_cyberpanel.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'CentOS7_cPanel'"
                  src="@/assets/apps/app_cpanel.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'CentOS7_Plesk'"
                  src="@/assets/apps/app_plesk.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'App_WordPress'"
                  src="@/assets/apps/app_wordpress.png"
                  class="w-16 mx-auto"
                  alt=""
                />
                <img
                  v-if="tem.name === 'GitLab CE - KVM'"
                  src="@/assets/apps/app_gitlab.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2" v-if="tem.name">
                  {{
                    tem.name.split("_").length > 1
                      ? tem.name.split("_")[1]
                      : tem.name
                  }}
                </div>
                <div class="text-sm text-darkBlueText mb-2" v-if="tem.group">
                  {{ tem.group.split(" | ")[0] }}
                </div>
                <div class="" v-if="tem.group">
                  <div
                    class="text-sm text-darkBlueText inline-block"
                    v-for="(gn, index) in tem.group.split(' | ')"
                    :key="index"
                  >
                    <span class="mr-1" v-if="index !== 0"
                      >{{ gn
                      }}<span
                        v-if="
                          tem.group.split(' | ').length > 2 &&
                            index !== tem.group.split(' | ').length - 1
                        "
                        >,</span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_python.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Python</div>
                <div class="text-sm text-darkBlueText">
                  Django | Flask | Celery
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_ruby.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Ruby</div>
                <div class="text-sm text-darkBlueText">
                  Rails | Sidekiq worker
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mt-4 mb-4">
                <img
                  src="@/assets/icon/ic_elixir.png"
                  class="w-24 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Elixir</div>
                <div class="text-sm text-darkBlueText">
                  Phoenix | Elixir Cluster
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_go.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Go</div>
                <div class="text-sm text-darkBlueText">Gin | Beego | Pgweb</div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_rust.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">Rust</div>
                <div class="text-sm text-darkBlueText">
                  Rocket | Actix | GraphQl
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 p-4 border rounded-lg border-white2">
            <div class="p-10 bg-grayForm rounded-lg">
              <div class="mb-2">
                <img
                  src="@/assets/icon/ic_php.png"
                  class="w-16 mx-auto"
                  alt=""
                />
              </div>
              <div class="text-center">
                <div class="font-whitney-semibold mb-2">PHP</div>
                <div class="text-sm text-darkBlueText">Laravel</div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- no data -->
        <!-- <div class="flex flex-col flex-1 items-center justify-center">
          <div class="text-4xl text-darkBlueText font-nunito font-bold">
            You don't have any active products
          </div>
          <div class="">
            <img src="@/assets/img/img_nodata.png" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";

import { mapState } from "vuex";
export default {
  name: "Volume",
  components: {
    sidebar,
    navbar
  },
  created() {
    document.title = "Nevacloud | Apps";

    this.loadTemplate();
  },
  computed: {
    ...mapState(["minimize"]),
    template() {
      return this.$store.state.template.template;
    }
  },
  watch: {
    searchApps(e) {
      if (e) {
        this.filterApps = this.template.filter(res => {
          return res.name.toLowerCase().includes(e.toLowerCase());
        });
      } else {
        this.filterApps = null;
      }
    }
  },
  data: function() {
    return {
      searchApps: null,
      filterApps: null
    };
  },
  mounted: function() {
    // if (this.$cookies.isKey("apps_expired")) {
    //   let expired = new Date(this.$cookies.get("apps_expired"));
    //   let now = new Date();
    //   if (now >= expired) {
    //     this.$cookies.remove("apps_expired");
    //     this.$store.commit("zone/resetZoneList");
    //     this.$store.commit("template/resetTemplate");
    //     this.loadTemplate();
    //   }
    // }
  },

  methods: {
    loadTemplate() {
      let now = new Date();
      if (
        this.$cookies.isKey("apps_expired") &&
        this.template &&
        Object.keys(this.template).length !== 0
      ) {
        let expired = new Date(this.$cookies.get("apps_expired"));
        if (now <= expired) {
          // console.log("sini", this.template);
          return;
        } else {
          console.log("masuk");
          this.$cookies.remove("apps_expired");
        }
      }
      this.$store.commit("zone/resetZoneList");
      this.$store.commit("template/resetTemplate");

      this.g_loading_create("Loading Zone");
      this.$store.dispatch("zone/getZone").then(res => {
        this.g_loading_destroy();
        if (res.status === 200) {
          this.$store.commit("zone/changeZone", {
            short_name: res.data[0].short_name
          });
          this.$store.commit("deployVM/changeZone", res.data[0].short_name);
          this.$store.commit("template/updateTemplateOption", "app");
          this.getTemplate();
        } else {
          this.g_notif_create(false, "Failed", res.error.message);
        }
      });
    },
    getTemplate() {
      this.g_loading_create("Loading Apps");
      this.$store.dispatch("template/getTemplate").then(res => {
        this.g_loading_destroy();
        if (res.status !== 200) {
          this.g_notif_create(false, "Failed", res.error.message);
        } else {
          let dt = new Date();
          dt.setHours(dt.getHours() + 2);
          this.$cookies.set("apps_expired", dt);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
