<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10" @click="checkCloseDetail()">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Announcements
            </h1>
          </div>
          <!-- <div class="">
            <div
              class="text-darkBlueText py-2 px-3 bg-grayForm rounded-lg flex items-center"
            >
              <div class="w-6 mr-4">
                <img src="@/assets/icon/ic_search.png" alt="" />
              </div>
              <input
                type="text"
                class="w-full bg-grayForm placeholder-darkBlueText outline-none"
                placeholder="Search"
              />
            </div>
          </div> -->
        </div>

        <div class="mb-2 last:mb-0" v-for="dat in announcements" :key="dat.id">
          <div class="text-sm mb-1">
            {{ formatDate(dat.date) }} {{ formatTime(dat.date) }}
          </div>
          <div class="p-4 bg-grayForm rounded-xl flex items-center">
            <div class="flex-none mr-3">
              <div
                class="w-10 h-10 p-2 flex items-center justify-center bg-white rounded-full"
              >
                <img src="@/assets/logo/icon.png" alt="" />
              </div>
            </div>
            <div class="flex-1">
              <div class="font-nunito font-bold text-orange">
                {{ dat.title }}
              </div>
              <div class="" v-html="dat.body"></div>
            </div>
          </div>
        </div>

        <!-- no data -->
        <!-- <div
          class="flex flex-col flex-1 items-center justify-center"
          
        >
          <div class="text-2xl text-darkBlueText font-nunito font-bold mb-16">
            You don't have any active products
          </div>
          <div class="mb-16">
            <img
              src="@/assets/img/no_volume.png"
              class="w-2/3 mx-auto"
              alt=""
            />
          </div>
         
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "Volume",
  components: {
    sidebar,
    navbar
  },
  created() {
    document.title = "Nevacloud | Announcements";
  },
  computed: {
    ...mapState(["announcements", "minimize"])
  },

  data: function() {
    return {};
  },
  mounted: function() {
    this.getAnnouncements();
  },
  methods: {
    ...mapActions(["getAnnouncements"])
  }
};
</script>

<style lang="scss" scoped></style>
