<template>
  <div class="">
    <div class="text-center" v-if="Object.keys(template).length === 0">
      Loading
    </div>
    <div
      class="flex items-center justify-center text-sm mb-6"
      v-if="Object.keys(template).length !== 0"
    >
      <div
        class="py-2 px-3 cursor-pointer"
        @click="selectTemplateOption('default')"
        :class="
          templateOption === 'default'
            ? 'bg-grayForm text-black rounded-lg'
            : ''
        "
      >
        Distribution
      </div>
      <!-- <div
                  class="py-2 px-3 cursor-pointer"
                  @click="selectTemplateOption('backup')"
                  :class="
                    templateOption.backup
                      ? 'bg-grayForm text-black rounded-lg'
                      : ''
                  "
                >
                  Backup
                </div> -->
      <div
        class="py-2 px-3 cursor-pointer"
        @click="selectTemplateOption('app')"
        :class="
          templateOption === 'app' ? 'bg-grayForm text-black rounded-lg' : ''
        "
      >
        Apps
      </div>
    </div>

    <!-- Distribution -->
    <div class="grid grid-cols-3 gap-3" v-if="templateOption === 'default'">
      <div
        class="p-3 border rounded-lg"
        v-for="(value, key) in template"
        :key="key"
        :class="value.version ? 'border-orange' : 'border-white2'"
      >
        <div class="flex p-3 rounded-lg items-center bg-grayForm mb-2">
          <div class="w-10 mr-4 flex-none">
            <img
              src="@/assets/icon/ic_freebsd.png"
              alt=""
              class="h-10 mx-auto"
              v-if="key === 'freebsd'"
            />
            <img
              src="@/assets/icon/ic_opensuse.png"
              alt=""
              class="h-10 mx-auto"
              v-if="key === 'opensuse'"
            />
            <img
              src="@/assets/icon/ic_ubuntu.png"
              alt=""
              class="h-10 mx-auto"
              v-if="key === 'ubuntu'"
            />
            <img
              src="@/assets/icon/ic_fedora.png"
              alt=""
              class="-10 mx-auto"
              v-if="key === 'fedora'"
            />
            <img
              src="@/assets/icon/ic_debian.png"
              alt=""
              class="h-10 mx-auto"
              v-if="key === 'debian'"
            />
            <img
              src="@/assets/icon/ic_centos.png"
              alt=""
              class="h-10 mx-auto"
              v-if="key === 'centos'"
            />
            <img
              src="@/assets/icon/ic_rocky_linux.png"
              alt=""
              class="h-10 mx-auto"
              v-if="key === 'rocky linux'"
            />
            <img
              src="@/assets/icon/ic_almalinux.png"
              alt=""
              class="h-10 mx-auto"
              v-if="key === 'almalinux'"
            />
          </div>
          <div class="overflow-hidden">
            <div class="font-whitney-semibold text-black truncate">
              {{ key.toUpperCase() }}
            </div>

            <!-- <div class="text-sm text-darkBlueText">
                        Last Version : 18.0.4
                      </div> -->
          </div>
        </div>
        <div
          class="p-3 rounded-lg items-center border border-white2 flex justify-between text-sm relative"
          @click="openTemplate(value)"
        >
          <div class="text-black" v-if="value.version">
            {{ value.version }}
          </div>
          <div class="text-black" v-else>Select Version</div>
          <div class="text-darkBlueText">
            <i class="fas fa-chevron-down"></i>
          </div>
          <div
            class="absolute right-0 left-0 text-center shadow-md"
            style="top: 50px; z-index: 100"
            v-if="value.active"
          >
            <div class="p-2 rounded-lg border border-grayForm bg-white">
              <div
                v-for="data in value.data"
                :key="data.id"
                @click="selectTemplate(data)"
                class="text-black p-2 rounded-lg text-sm cursor-pointer hover:text-orange border-b border-grayForm last:border-none"
              >
                {{ data.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- backup -->
    <div
      class=""
      v-if="templateOption === 'backup' && images && images.length !== 0"
    >
      <div
        class="grid grid-cols-10 px-4 py-2 items-center rounded-lg cursor-pointer mb-2 last:mb-0"
        v-for="image in images"
        :key="image.id"
        @click="pickImage(image)"
        :class="{
          'border border-white2': !image.active,
          'border border-orange': image.active
        }"
      >
        <div class="col-span-1 flex items-center">
          <div
            class="w-4 h-4 rounded-md border-2 flex items-center justify-center"
            :class="{
              'border-orange bg-orange': image.active,
              'border-grayb': !image.active
            }"
          ></div>
          <div class="ml-2 w-10 h-10 bg-grayForm rounded-md p-2">
            <img src="@/assets/icon/ic_fedora.png" class="w-full" alt="" />
          </div>
        </div>
        <div class="col-span-3">{{ image.name }}</div>
        <div class="col-span-3 text-darkBlueText text-sm">
          {{ image.group }} Ver. 20.04
        </div>
        <div class="col-span-3 text-right text-darkBlueText text-sm">
          Created on 23/09/2020
        </div>
      </div>
    </div>

    <div
      class="text-center"
      v-if="templateOption === 'backup' && images && images.length === 0"
    >
      You don't have backup images
    </div>

    <!-- apps -->

    <div
      class="grid grid-cols-1 lg:grid-cols-5 gap-2 mb-4"
      v-if="templateOption === 'app' && template && template.length !== 0"
    >
      <div
        class="col-span-1 cursor-pointer"
        :class="tem.active ? 'border-orange' : 'border-white2'"
        @click="selectApp(tem.id)"
        v-for="tem in template"
        :key="tem.id"
      >
        <div
          class="p-6 bg-grayForm rounded-lg hover:bg-orange2 hover:text-orange border border-white2 hover:border-orange"
          :class="tem.active ? 'text-orange bg-orange2' : 'bg-grayForm'"
        >
          <div class="mb-2">
            <img
              v-if="tem.name === 'CentOS7_CyberPanel'"
              src="@/assets/apps/app_cyberpanel.png"
              class="w-16 mx-auto"
              alt=""
            />
            <img
              v-else-if="tem.name === 'CentOS7_cPanel'"
              src="@/assets/apps/app_cpanel.png"
              class="w-16 mx-auto"
              alt=""
            />
            <img
              v-else-if="tem.name === 'CentOS7_Plesk'"
              src="@/assets/apps/app_plesk.png"
              class="w-16 mx-auto"
              alt=""
            />
            <img
              v-else-if="tem.name === 'App_WordPress'"
              src="@/assets/apps/app_wordpress.png"
              class="w-16 mx-auto"
              alt=""
            />
            <img
              v-else-if="tem.name === 'App - GitLab CE'"
              src="@/assets/apps/app_gitlab.png"
              class="w-16 mx-auto"
              alt=""
            />
            <img
              v-else
              src="@/assets/icon/ic_static.png"
              class="w-16 mx-auto"
              alt=""
            />
          </div>
          <div class="text-center">
            <div class="font-whitney-semibold mb-2 text-sm" v-if="tem.name">
              {{
                tem.name.split("_").length > 1
                  ? tem.name.split("_")[1]
                  : tem.name.split("App - ")[1]
              }}
            </div>
            <div class="text-sm text-darkBlueText">
              {{ tem.group.split(" | ")[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="p-2 border border-white2 rounded-lg"
      v-if="templateOption === 'app' && selectedTemplate"
    >
      <div class="p-4" v-for="(value, key) in selectedTemplate.form" :key="key">
        <div class="grid grid-cols-3 gap-4 items-center">
          <div class="col-span-1">{{ value.label }}</div>
          <div class="col-span-2 p-2 border border-white2 rounded-lg">
            <input
              :type="value.type"
              class="w-full outline-none"
              v-model="value.value"
              :required="value.required"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-center"
      v-if="templateOption === 'app' && template && template.length === 0"
    >
      Loading
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    deployParams() {
      return this.$store.state.deployVM.deployParams;
    },
    images() {
      return this.$store.state.images;
    },
    template() {
      return this.$store.state.template.template;
    },
    templateOption() {
      return this.$store.state.template.templateOption;
    },
    selectedTemplate() {
      return this.$store.state.template.selectedTemplate;
    }
  },
  methods: {
    selectTemplateOption(data) {
      this.deployParams.hostname = null;
      this.$store.commit("deployVM/resetDeployTemplate", data);
      this.$store.commit("template/updateTemplateOption", data);
      this.$store.dispatch("template/getTemplate");
      this.$store.commit("template/resetSelectedTemplate");

      // console.log("deploy param", this.deployParams);
    },
    selectTemplate(data) {
      this.$store.commit("template/changeTemplate", { data });
    },
    openTemplate(value) {
      this.$store.commit("template/openTemplate", value);
    },
    selectApp(data) {
      this.$store.commit("template/selectApp", data);
    }
  }
};
</script>

<style></style>
