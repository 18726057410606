import axios from "axios";
import Vue from "vue";

export default {
  state: {
    department: null,
    ticketDetail: null,
    tickets: null,
    ticket_pagination: {
      count: 0,
      currentPage: 1,
      links: [],
      perPage: 10,
      total: 0,
      totalPages: 1
    }
  },
  actions: {
    async getDepartment({ commit }) {
      const url = process.env.VUE_APP_SERVICE_URL + "support/department";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("setDepartment", response.data);
        return response.data;
      } catch (err) {
        console.log(err);
        return err.response.data;
      }
    },
    async getTicketList({ commit }, data) {
      let url = process.env.VUE_APP_SERVICE_URL + "support/ticket";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      // var params = new FormData();
      if (data) {
        url += "?page=" + data;
      } else {
        url += "?page=" + 1;
      }
      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("setTickets", response.data);
        return response.data;
      } catch (err) {
        console.log(err);
        return err.response.data;
      }
    },
    async getTicketDetail({ commit }, data) {
      let url = process.env.VUE_APP_SERVICE_URL + "support/ticket/" + data;
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      // var params = new FormData();

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("setTicketDetail", response.data);
        return response.data;
      } catch (err) {
        console.log(err);
        return err.response.data;
      }
    },
    async openTicket(_, data) {
      const url = process.env.VUE_APP_SERVICE_URL + "support/ticket";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      const body = new FormData();
      body.append("department_id", data.department_id);
      body.append("subject", data.subject);
      body.append("message", data.message);
      //   body.append("attachment[0]", data.files[0]);
      if (data.files) {
        let x = 0;
        data.files.forEach(file => {
          body.append("attachment[" + x + "]", file);
          x++;
        });
      }

      try {
        const response = await axios({
          method: "post",
          url: url,
          data: body,
          credentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "application/json"
          }
        });
        return response.data;
      } catch (err) {
        console.log(err);
        return err.response.data;
      }
    },
    async replyTicket(_, data) {
      const url =
        process.env.VUE_APP_SERVICE_URL +
        "support/ticket/" +
        data.id +
        "/reply";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      const body = new FormData();
      body.append("message", data.message);
      //   body.append("attachment[0]", data.files[0]);
      if (data.files) {
        let x = 0;
        data.files.forEach(file => {
          body.append("attachment[" + x + "]", file);
          x++;
        });
      }

      try {
        const response = await axios({
          method: "post",
          url: url,
          data: body,
          credentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "application/json"
          }
        });
        return response.data;
      } catch (err) {
        console.log(err);
        return err.response.data;
      }
    }
  },
  mutations: {
    setDepartment(state, data) {
      state.department = data.data;
    },
    clearTickets(state) {
      state.tickets = null;
    },
    clearTicketDetail(state) {
      state.ticketDetail = null;
    },
    setTickets(state, data) {
      state.tickets = data.data;
      state.ticket_pagination = data.pagination;
    },
    setTicketDetail(state, data) {
      state.ticketDetail = data.data;
    }
  },

  namespaced: true
};
