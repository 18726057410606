<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10">
        <h1 class="text-3xl font-nunito font-bold text-black mb-10">
          Networks
        </h1>
        <div
          class="bg-white p-8 shadow-md rounded-lg mb-20"
          v-if="networks && networks.length != 0"
        >
          <!-- head -->
          <div
            class="grid grid-cols-10 gap-4 text-darkBlueText border-b border-white2 py-6"
          >
            <div class="col-span-3">IPv4</div>
            <div class="col-span-3">IPv6</div>
            <div class="col-span-3">Virtual Machine</div>
            <div class=""></div>
          </div>

          <!-- content -->
          <div class="">
            <div
              class="py-4 border-b border-white2"
              v-for="net in networks"
              :key="net.id"
            >
              <div class="grid grid-cols-10 gap-4 items-center text-black">
                <div class="col-span-3 truncate">
                  {{ net.ipv4_address }}
                </div>
                <div class="col-span-3 truncate">
                  {{ net.ipv6_address ? net.ipv6_address : null }}
                </div>
                <div
                  class="col-span-3 truncate hover:text-orange cursor-pointer"
                  @click="
                    $router.push(
                      '/dashboard/productdetails?id=' + net.virtualMachine.id
                    )
                  "
                >
                  {{ net.virtualMachine.hostname }}
                </div>

                <div class="">
                  <div
                    class="inline-block text-red text-sm border border-red px-2 py-1 rounded-full cursor-pointer"
                    @click="
                      (confirmation.active = true),
                        (confirmation.data = net.id),
                        (confirmation.textConfirmation =
                          'Are you sure you want to delete ?')
                    "
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- no data -->

        <div
          class="flex flex-col flex-1 items-center justify-center"
          v-if="networks && networks.length === 0"
        >
          <div class="mt-6 mb-16">
            <img
              src="@/assets/img/no_network.png"
              class="w-2/3 mx-auto"
              alt=""
            />
          </div>
          <div class="text-2xl text-darkBlueText font-nunito font-bold mb-16">
            You don't have any IP addresses
          </div>
        </div>
      </div>
    </div>
    <confirmation
      v-if="confirmation.active"
      @accept="deleteIP()"
      @dismiss="(confirmation.active = false), (confirmation.data = null)"
      :data="confirmation.data"
      :textConfirmation="confirmation.textConfirmation"
    />
    <!-- <Notification
      v-if="notif.show"
      @dismiss="notif.show = false"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    /> -->
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import confirmation from "@/components/confirmation.vue";
// import Notification from "@/components/Notification.vue";
import { mapState } from "vuex";
export default {
  name: "Images",
  components: {
    sidebar,
    navbar,
    confirmation
    // Notification
  },
  created() {
    document.title = "Nevacloud | Networks";
  },
  computed: {
    ...mapState(["minimize"]),
    networks() {
      return this.$store.state.networks.networks;
    }
  },
  data: function() {
    return {
      confirmation: {
        active: false,
        data: null,
        textConfirmation: null
      },
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      }
    };
  },
  mounted: function() {
    this.getListUserIP();
  },
  methods: {
    getListUserIP() {
      this.g_loading_create("Loading");
      this.$store.dispatch("networks/getUserListIP").then(() => {
        this.g_loading_destroy();
      });
    },
    deleteIP() {
      this.g_loading_create("Loading");
      this.$store
        .dispatch("detachIp", this.confirmation.data)
        .then(response => {
          this.g_loading_destroy();
          this.confirmation.active = false;
          this.confirmation.data = null;
          if (response.status === 200) {
            this.g_notif_create(true, "Success", "IP has been deleted");
            this.getListUserIP();
          } else {
            this.g_notif_create(false, "Failed", response.error.message);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
