<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div
        class="flex-1 pt-4 px-10 flex flex-col"
        @click="depMenu ? (depMenu = false) : ''"
      >
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Open Ticket
            </h1>
          </div>
        </div>
        <div class="">
          <form @submit.prevent="confirmation_active = true">
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-2">
                <div class="text-slate text-md mb-2">Subject</div>
                <input
                  type="text"
                  v-model="form.subject"
                  class="w-full mb-4 outline-none border border-gray p-4 rounded-lg focus:border-blue"
                  placeholder="Subject"
                  required
                />
              </div>
              <div class="col-span-1">
                <div class="text-slate text-md mb-2">
                  Department
                  <span class="text-xs text-red" v-if="depError"
                    >*Required</span
                  >
                </div>
                <div class="relative">
                  <input
                    @click.stop="depMenu = true"
                    @focus="depMenu = true"
                    type="text"
                    v-model="form.department_name"
                    class="w-full mb-4 outline-none border p-4 rounded-lg focus:border-blue cursor-pointer"
                    :class="depError ? 'border-red' : 'border-gray'"
                    placeholder="Department"
                    readonly
                    required
                  />
                  <div
                    class="absolute top-0 left-0 right-0 border border-gray p-4 rounded-lg bg-white mt-16 z-10"
                    v-if="department && depMenu"
                  >
                    <div
                      class="py-2 border-b text-black text-opacity-70 last:border-none border-gray hover:text-orange cursor-pointer"
                      v-for="(dep, index) in department"
                      :key="index"
                      @click="
                        (form.department_id = dep.id),
                          (form.department_name = dep.name)
                      "
                    >
                      {{ dep.name }}
                    </div>
                    <!-- <div class="py-2 text-black text-opacity-70">cek</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-6">
              <div class="text-slate text-md mb-2">
                Message
                <span class="text-xs text-red" v-if="mError">*Required</span>
              </div>
              <vue-editor
                :editorToolbar="customToolbar"
                v-model="form.message"
                class=""
              ></vue-editor>
            </div>

            <div class="mb-6">
              <div class="text-slate text-md mb-2">Attachment</div>
              <div class="w-full p-4 rounded-lg border border-gray">
                <div
                  class="bg-gray py-1 px-2 rounded-lg inline-block mr-2"
                  v-for="(file, index) in form.files"
                  :key="index"
                >
                  <div class="mr-2 inline-block">{{ file.name }}</div>
                  <div
                    class="inline-block text-black cursor-pointer text-opacity-80"
                    @click="spliceFile(index)"
                  >
                    x
                  </div>
                </div>
                <button
                  class="bg-grayb py-1 px-2 rounded-lg"
                  @click.prevent="chooseFile()"
                >
                  Choose File
                </button>
                <input
                  type="file"
                  @change="onFileChange"
                  multiple="multiple"
                  hidden
                  id="fileTicket"
                />
              </div>
            </div>

            <div class="text-right">
              <button
                @click="cancelCreate()"
                class="px-10 py-3 bg-gray mr-6 text-white rounded-lg"
              >
                Cancel
              </button>
              <button
                class="px-10 py-3 bg-gray mr-6 text-white rounded-lg"
                v-if="sendButton"
              >
                Submit
              </button>
              <button
                type="submit"
                class="px-10 py-3 bg-orange text-white rounded-lg"
                v-else
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <confirmation
      v-if="confirmation_active"
      @accept="submitTicket()"
      @dismiss="confirmation_active = false"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import { VueEditor } from "vue2-editor";
import confirmation from "@/components/confirmation.vue";

import { mapState } from "vuex";
export default {
  name: "Images",
  components: {
    sidebar,
    navbar,
    VueEditor,
    confirmation
  },
  created() {
    document.title = "Nevacloud | Open Ticket";
    // if (!this.networks) {
    //   this.g_loading_create("Loading");
    // }
    // this.getVM().then(() => {
    //   this.g_loading_destroy();
    // });
  },
  computed: {
    ...mapState({
      networks: "networks",
      minimize: "minimize"
    }),
    department() {
      return this.$store.state.support.department;
    }
  },
  data: function() {
    return {
      confirmation_active: false,
      depError: false,
      mError: false,
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      },
      show: false,
      files: null,
      form: {
        subject: null,
        department_name: null,
        department_id: null,
        message: null,
        files: []
      },
      sendButton: false,
      depMenu: false,
      customToolbar: [
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
        // [
        //   { align: "" },
        //   { align: "center" },
        //   { align: "right" },
        //   { align: "justify" },
        // ],
        // [{ color: [] }],
      ]
    };
  },
  mounted() {
    this.getDepartment();
  },
  methods: {
    onFileChange(e) {
      if (!e.target.files.length) return;
      e.target.files.forEach(file => {
        this.form.files.push(file);
      });
    },
    chooseFile() {
      document.getElementById("fileTicket").click();
    },
    spliceFile(index) {
      this.form.files.splice(index, 1);
    },
    cancelCreate() {
      this.$router.go(-1);
    },
    getDepartment() {
      this.$store.dispatch("support/getDepartment");
    },
    submitTicket() {
      this.confirmation_active = false;
      this.depError = false;
      this.mError = false;
      if (!this.form.department_id) {
        this.depError = true;
        return;
      }
      if (!this.form.message) {
        this.mError = true;
        return;
      }
      let message = this.turndownMarkdown(this.form.message);

      let newForm = {
        subject: this.form.subject,
        department_name: this.form.department_name,
        department_id: this.form.department_id,
        message: message,
        files: this.form.files
      };
      this.sendButton = true;
      this.g_loading_create("Loading");
      this.$store.dispatch("support/openTicket", newForm).then(res => {
        this.g_loading_destroy();
        this.sendButton = false;
        if (res.status === 200) {
          this.g_notif_create(true, "Succes", "Ticket has been created");
          this.$router.push("/support");
        } else {
          console.log(res);
          this.g_notif_create(false, "Failed", "Something went wrong");
        }
      });
    }
  }
};
</script>

<style>
.ql-toolbar.ql-snow {
  border-color: #dbdfe6 !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.ql-container.ql-snow {
  border-color: #dbdfe6 !important;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
</style>
