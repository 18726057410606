var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"fixed inset-0 bg-black bg-opacity-75",staticStyle:{"z-index":"20"},on:{"click":function($event){return _vm.dismiss()}}},[_c('div',{staticClass:"container m-auto max-w-3xl border border-white-100 mt-16 bg-white rounded-lg form-container",class:{ 'will-dismiss': _vm.will_dismiss },on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"py-4 px-4"},[_c('div',{staticClass:"text-right text-xl text-gray cursor-pointer",on:{"click":function($event){return _vm.dismiss()}}},[_c('i',{staticClass:"fas fa-times"})]),_c('h1',{staticClass:"text-3xl text-slate"},[_vm._v("New SSH Key")]),_c('p',{staticClass:"text-dark-gray mt-4"},[_vm._v(" Copy your public SSH key and paste it in the space below ")]),_c('form',{staticClass:"mt-4",on:{"submit":_vm.addssh}},[_c('div',{staticClass:"border p-4 rounded-lg",class:{
              'border-orange': _vm.ssh_key.active,
              'border-red': _vm.ssh_key.message && !_vm.ssh_key.active,
              'border-gray': !_vm.ssh_key.active
            }},[(_vm.ssh_key.message)?_c('span',{staticClass:"text-red text-xs"},[_vm._v(_vm._s(_vm.ssh_key.message))]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ssh_key.data),expression:"ssh_key.data"}],staticClass:"w-full outline-none",staticStyle:{"resize":"none"},attrs:{"name":"","id":"","rows":"10","placeholder":"SSH Key Content"},domProps:{"value":(_vm.ssh_key.data)},on:{"focus":function($event){_vm.ssh_key.active = true},"blur":function($event){_vm.ssh_key.active = false},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ssh_key, "data", $event.target.value)}}})]),_c('div',{staticClass:"border p-4 mt-2 rounded-lg",class:{
              'border-orange': _vm.name.active,
              'border-red': _vm.name.message && !_vm.name.active,
              'border-gray': !_vm.name.active
            }},[(_vm.name.message)?_c('span',{staticClass:"text-red text-xs"},[_vm._v(_vm._s(_vm.name.message))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name.data),expression:"name.data"}],staticClass:"outline-none w-full",attrs:{"type":"text","placeholder":"Name"},domProps:{"value":(_vm.name.data)},on:{"focus":function($event){_vm.name.active = true},"blur":function($event){_vm.name.active = false},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.name, "data", $event.target.value)}}})]),_c('button',{staticClass:"w-full p-4 bg-orange text-white mt-2 rounded-lg",attrs:{"type":"submit"}},[_vm._v(" Add SSH Key ")])])])])]),(_vm.notif.show)?_c('Notification',{attrs:{"header":_vm.notif.header,"message":_vm.notif.message,"active":_vm.notif.active},on:{"dismiss":function($event){return _vm.closeNotif()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }