var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed top-0 left-0 bottom-0 border-r-2 border-white2 bg-white2 z-10",class:_vm.minimize ? '' : 'sidebar'},[(!_vm.minimize)?_c('div',{staticClass:"mb-4 py-2 px-4 logo-container flex justify-between items-center"},[_vm._m(0),_c('i',{staticClass:"fas fa-chevron-left text-darkBlueText cursor-pointer",on:{"click":_vm.changeMinimize}})]):_vm._e(),(_vm.minimize)?_c('div',{staticClass:"px-4 py-4 mb-4 logo-container flex items-center justify-center"},[_c('div',{staticClass:"w-5 cursor-pointer",on:{"click":_vm.changeMinimize}},[_c('img',{staticClass:"w-full",attrs:{"src":require("@/assets/icon/ic_hamburger_sidenav.png"),"alt":""}})])]):_vm._e(),_c('div',{},[_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"h-50px flex items-center p-3 mx-auto rounded-lg",class:[
            _vm.minimize ? 'w-50px justify-center' : '',
            _vm.current_page.path.startsWith('/dashboard') ||
            _vm.current_page.path === '/create-instance'
              ? 'bg-orange text-white'
              : 'bg-white2 text-darkBlueText'
          ]},[_c('div',{staticClass:"w-6 h-6 p-1 flex items-center justify-center bg-white rounded-full"},[_c('img',{attrs:{"src":require("@/assets/logo/icon.png"),"alt":""}})]),_c('div',{class:_vm.minimize ? 'hidden' : 'block ml-2 flex-1'},[_vm._v(" Instance ")])])])],1),_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":"/apps"}},[_c('div',{staticClass:"h-50px flex items-center p-3 mx-auto rounded-lg",class:[
            _vm.minimize ? 'w-50px justify-center' : '',
            _vm.current_page.path.startsWith('/apps') ||
            _vm.current_page.path === '/apps'
              ? 'bg-orange text-white'
              : 'bg-white2 text-darkBlueText'
          ]},[_c('div',{staticClass:"w-5"},[(
                _vm.current_page.path.startsWith('/apps') ||
                  _vm.current_page.path == '/apps'
              )?_c('img',{attrs:{"src":require("@/assets/icon/ic_app.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icon/ic_app_f.png"),"alt":""}})]),_c('div',{class:_vm.minimize ? 'hidden' : 'block ml-3 flex-1'},[_vm._v(" Apps ")])])])],1),_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":"/ssh-keys"}},[_c('div',{staticClass:"h-50px flex items-center p-3 mx-auto rounded-lg",class:[
            _vm.minimize ? 'w-50px justify-center' : '',
            _vm.current_page.path.startsWith('/ssh-keys') ||
            _vm.current_page.path === '/ssh-keys'
              ? 'bg-orange text-white'
              : 'bg-white2 text-darkBlueText'
          ]},[_c('div',{staticClass:"w-5"},[(_vm.current_page.path == '/ssh-keys')?_c('img',{attrs:{"src":require("@/assets/icon/ic_ssh.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icon/ic_ssh_f.png"),"alt":""}})]),_c('div',{class:_vm.minimize ? 'hidden' : 'block ml-3 flex-1'},[_vm._v(" SSH Keys ")])])])],1),_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":"/volume"}},[_c('div',{staticClass:"h-50px flex items-center p-3 mx-auto rounded-lg",class:[
            _vm.minimize ? 'w-50px justify-center' : '',
            _vm.current_page.path.startsWith('/volume') ||
            _vm.current_page.path === '/volume'
              ? 'bg-orange text-white'
              : 'bg-white2 text-darkBlueText'
          ]},[_c('div',{staticClass:"w-5"},[(_vm.current_page.path == '/volume')?_c('img',{attrs:{"src":require("@/assets/icon/ic_volume.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icon/ic_volume_f.png"),"alt":""}})]),_c('div',{class:_vm.minimize ? 'hidden' : 'block ml-3 flex-1'},[_vm._v(" Volume ")])])])],1),_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":"/networks"}},[_c('div',{staticClass:"h-50px flex items-center p-3 mx-auto rounded-lg",class:[
            _vm.minimize ? 'w-50px justify-center' : '',
            _vm.current_page.path.startsWith('/networks') ||
            _vm.current_page.path === '/networks'
              ? 'bg-orange text-white'
              : 'bg-white2 text-darkBlueText'
          ]},[_c('div',{staticClass:"w-5"},[(_vm.current_page.path == '/networks')?_c('img',{attrs:{"src":require("@/assets/icon/ic_network.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icon/ic_network_f.png"),"alt":""}})]),_c('div',{class:_vm.minimize ? 'hidden' : 'block ml-3 flex-1'},[_vm._v(" Networks ")])])])],1),_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":"/billing"}},[_c('div',{staticClass:"h-50px flex items-center p-3 mx-auto rounded-lg",class:[
            _vm.minimize ? 'w-50px justify-center' : '',
            _vm.current_page.path.startsWith('/billing') ||
            _vm.current_page.path === '/billing'
              ? 'bg-orange text-white'
              : 'bg-white2 text-darkBlueText'
          ]},[_c('div',{staticClass:"w-5"},[(_vm.current_page.path == '/billing')?_c('img',{attrs:{"src":require("@/assets/icon/ic_billing.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icon/ic_billing_f.png"),"alt":""}})]),_c('div',{class:_vm.minimize ? 'hidden' : 'block ml-3 flex-1'},[_vm._v(" Billing ")])])])],1),_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":"/support"}},[_c('div',{staticClass:"h-50px flex items-center p-3 mx-auto rounded-lg",class:[
            _vm.minimize ? 'w-50px justify-center' : '',
            _vm.current_page.path.startsWith('/support') ||
            _vm.current_page.path === '/support'
              ? 'bg-orange text-white'
              : 'bg-white2 text-darkBlueText'
          ]},[_c('div',{staticClass:"w-5"},[(_vm.current_page.path.startsWith('/support'))?_c('img',{attrs:{"src":require("@/assets/icon/ic_support.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icon/ic_support_f.png"),"alt":""}})]),_c('div',{class:_vm.minimize ? 'hidden' : 'block ml-3 flex-1'},[_vm._v(" Support ")])])])],1)]),_c('div',{staticClass:"px-4 mt-20"},[_c('div',{staticClass:"px-3 py-4 flex bg-white rounded-lg shadow-md items-center cursor-pointer",class:_vm.minimize ? 'flex-col' : 'justify-between',on:{"mouseover":function($event){_vm.logout_ic = true},"mouseleave":function($event){_vm.logout_ic = false},"click":function($event){_vm.logout(), _vm.$router.push('/login')}}},[_c('div',{staticClass:"w-5 cursor-pointer"},[(!_vm.logout_ic)?_c('img',{staticClass:"w-full",attrs:{"src":require("@/assets/icon/ic_logout_f.png"),"alt":""}}):_c('img',{staticClass:"w-full",attrs:{"src":require("@/assets/icon/ic_logout.png"),"alt":""}})]),_c('div',{class:{
          hidden: _vm.minimize,
          'ml-3 flex-1': !_vm.minimize,
          'text-darkBlueText': !_vm.logout_ic,
          'text-orange': _vm.logout_ic
        }},[_vm._v(" Logout ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"7rem"},attrs:{"src":require("@/assets/logo/logo.png"),"alt":""}})])}]

export { render, staticRenderFns }