<template>
  <div
    class="navbar py-4 px-10 bg-white z-10"
    :class="minimize ? 'minmar' : 'mar'"
  >
    <div class="flex items-center justify-between">
      <div class="">
        <div>
          <div class="flex items-center">
            <div class="w-10 mr-4">
              <img src="@/assets/icon/ic_wallet.png" alt="" />
            </div>
            <div class="mr-4" v-if="billingData">
              <div class="text-sm text-darkBlueText">My balance</div>
              <div
                class="text-orange font-whitney-bold"
                v-if="billingData.credits"
              >
                IDR {{ formatRupiah(billingData.credits.toString()) }}
              </div>
              <div class="text-sm" v-else>0</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="relative z-10">
          <button
            class="flex text-orange items-center border border-orange px-3 py-2 rounded-lg btn-hover"
            @click="create = !create"
          >
            <div class="mr-2">Create</div>
            <div class="text-xs" v-if="create">
              <i class="fas fa-chevron-up"></i>
            </div>
            <div class="text-xs" v-if="!create">
              <i class="fas fa-chevron-down"></i>
            </div>
          </button>
          <div
            v-if="create"
            class="absolute right-0 rounded-lg shadow-md p-4 pb-8 bg-white z-20"
            style="top: 52px; width: 400px"
          >
            <div class="my-4 font-nunito font-bold text-xl">Create New</div>
            <div class="">
              <router-link to="/create-instance">
                <div
                  class="grid grid-cols-12 gap-3 p-3 border border-white2 rounded-lg items-center justify-center mb-1 hover:bg-white2"
                >
                  <div class="col-span-1">
                    <img
                      src="@/assets/icon/logo3.png"
                      class="w-5 mx-auto"
                      alt=""
                    />
                  </div>
                  <div class="col-span-11 text-black text-sm">
                    <div class="">Instance</div>
                    <div class="">Create new virtual machine</div>
                  </div>
                </div>
              </router-link>

              <!-- create volume -->
              <!-- <div
                class="grid grid-cols-12 gap-3 p-3 border border-white2 rounded-lg items-center justify-center mb-1 hover:bg-white2"
              >
                <div class="col-span-1">
                  <img
                    src="@/assets/icon/ic_volume_f.png"
                    class="w-5 mx-auto"
                    alt=""
                  />
                </div>
                <div class="col-span-11 text-black text-sm">
                  <div class="">Volume</div>
                  <div class="">Create a space for your virtual machine</div>
                </div>
              </div> -->

              <!-- create apps -->
              <!-- <div
                class="grid grid-cols-12 gap-3 p-3 border border-white2 rounded-lg items-center justify-center mb-1 hover:bg-white2"
              >
                <div class="col-span-1">
                  <img
                    src="@/assets/icon/ic_app_f.png"
                    class="w-5 mx-auto"
                    alt=""
                  />
                </div>
                <div class="col-span-11 text-black text-sm">
                  <div class="">Apps</div>
                  <div class="">One click to deploy your apps</div>
                </div>
              </div> -->

              <!-- create domain -->
              <!-- <div
                class="grid grid-cols-12 gap-3 p-3 border border-white2 rounded-lg items-center justify-center mb-1 hover:bg-white2"
              >
                <div class="col-span-1">
                  <img
                    src="@/assets/icon/ic_domain_f.png"
                    class="w-5 mx-auto"
                    alt=""
                  />
                </div>
                <div class="col-span-11 text-black text-sm">
                  <div class="">Domain</div>
                  <div class="">Link your domain</div>
                </div>
              </div> -->

              <!-- create network -->
              <!-- <div
                class="grid grid-cols-12 gap-3 p-3 border border-white2 rounded-lg items-center justify-center mb-4 hover:bg-white2"
              >
                <div class="col-span-1">
                  <img
                    src="@/assets/icon/ic_network_f.png"
                    class="w-5 mx-auto"
                    alt=""
                  />
                </div>
                <div class="col-span-11 text-black text-sm">
                  <div class="">Network</div>
                  <div class="">Create a new IP</div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <!-- notificattin -->
        <div
          class="px-6 cursor-pointer"
          @click="$router.push('/announcements')"
        >
          <div class="relative">
            <div class="w-10 p-2">
              <img src="@/assets/icon/ic_notif.png" alt="" />
            </div>
            <!-- <div
              class="w-5 h-5 absolute rounded-full bg-red text-white flex items-center justify-center"
              style="font-size: 10px; top: 0; right: -6px"
            >
              10+
            </div> -->
          </div>
        </div>

        <div class="" v-if="userdata">
          <router-link to="/profile" class="flex items-center ">
            <div
              class="w-10 h-10 rounded-full bg-red flex items-center justify-center"
            >
              <!-- <div class="text-white" v-if="userdata.fullname !== null">
                {{ userdata.fullname.slice(0, 2).toUpperCase() }}
              </div> -->
              <v-gravatar
                v-if="userdata.email"
                :email="userdata.email"
                class="w-full rounded-full mx-auto"
                default-img="retro"
              />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Navbar",
  props: {
    btn_text: null
  },
  computed: {
    ...mapState(["userdata", "billingTotalCharge", "billingData", "minimize"])
  },
  mounted: function() {
    this.getBalance();
  },
  data: function() {
    return {
      create: false
    };
  },
  methods: {
    ...mapActions(["getBalance"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.mar {
  margin-left: 200px;
}
.minmar {
  margin-left: 84px;
}
.float-card {
  width: 200px;
  left: 43px;
  top: 50px;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(91, 103, 123, 0.05);
}
.triangle-icon {
  right: 45%;
  // left: 0;
  top: -25px;
  font-size: 30px;
}
</style>
