import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import qs from "query-string";
import support from "./support";
import plan from "./plan";
import zone from "./zone";
import deployVM from "./deployVM";
import template from "./template";
import invoice from "./invoice";
import networks from "./networks";
// import mixin from "@/mixins/index";

Vue.use(Vuex, axios, qs);

export default new Vuex.Store({
  state: {
    minimize: false,
    url: process.env.VUE_APP_SERVICE_URL,
    loading: {
      active: false,
      message: null,
      dismiss: false
    },
    announcements: null,
    loadingFull: false,
    userdata: null,
    vmList: null,
    vm_pagination: {
      currentPage: 1,
      totalPages: null,
      totalData: [],
      showTotal: 5
    },
    vm_detail: null,
    panel: [
      "Graph",
      "Access",
      "Root Password",
      "Resize",
      "Reinstall",
      "Volume",
      "Network",
      // 'Images',
      "History"
    ],
    // panel: [
    //   "Graph",
    //   "Access",
    //   "Root Password",
    //   "Resize",
    //   "Reinstall",
    //   "History",
    // ],
    payment: {
      method: null,
      name: null
    },
    vm_network: [],
    panelState: "Graph",
    register: {
      name: {
        active: false,
        message: null,
        data: null
      },
      email: {
        active: false,
        message: null,
        data: null
      },
      password: {
        active: false,
        message: null,
        data: null
      },
      repassword: {
        active: false,
        message: null,
        data: null
      },
      lname: {
        active: false,
        message: null,
        data: null
      },
      checked: {
        active: false,
        message: null
      }
    },
    loginData: {
      password: {
        active: false,
        message: null,
        data: null
      },
      email: {
        active: false,
        message: null,
        data: null
      }
    },

    sshList: null,
    images: null,
    notification: {
      show: false,
      header: null,
      message: null,
      active: false
    },
    postMeData: {
      firstname: null,
      lastname: null,
      email: null,
      phone_number: null,
      address: null,
      companyname: null,
      npwp: null,
      taxType: null
    },

    chart: {
      cpu: null,
      disk: [
        { name: "Read", data: null },
        { name: "Write", data: null }
      ],
      bandwidth: [
        { name: "Received", data: null },
        { name: "Send", data: null }
      ]
    },
    vnc: null,
    vncPassword: null,
    vmLogs: null,
    billingTotalCharge: 300000,
    billingLogs: [
      {
        id: 321,
        date: "2020-08-30",
        description: "Deposit from Paypal",
        amount: 100000
      },
      {
        id: 322,
        date: "2020-08-30",
        description: "Create New VM",
        amount: 100000
      },
      {
        id: 323,
        date: "2020-09-01",
        description: "Deposti From Paypal",
        amount: 400000
      },
      {
        id: 324,
        date: "2020-09-01",
        description: "Create New VM",
        amount: 200000
      }
    ],
    billingData: null,
    billingCharges: [
      {
        id: 12312,
        vm_name: "VM number 1",
        vm_monthly_charge: 100000,
        date: "2020-08-30"
      },
      {
        id: 12313,
        vm_name: "VM number 2",
        vm_monthly_charge: 200000,
        date: "2020-09-30"
      }
    ],
    volume: {
      active: false
      // persistent: false,
      // data: [
      //   {
      //     id: 1,
      //     active: false,
      //     price_monthly: 1000,
      //     price_hourly: 100,
      //     size: 1,
      //   },
      //   {
      //     id: 2,
      //     active: false,
      //     price_monthly: 5000,
      //     price_hourly: 100,
      //     size: 5,
      //   },
      //   {
      //     id: 3,
      //     active: false,
      //     price_monthly: 8000,
      //     price_hourly: 100,
      //     size: 8,
      //   },
      //   {
      //     id: 4,
      //     active: false,
      //     price_monthly: 10000,
      //     price_hourly: 100,
      //     size: 10,
      //   },
      //   {
      //     id: 5,
      //     active: false,
      //     price_monthly: 20000,
      //     price_hourly: 100,
      //     size: 20,
      //   },
      //   {
      //     id: 6,
      //     active: false,
      //     price_monthly: 50000,
      //     price_hourly: 100,
      //     size: 50,
      //   },
      // ],
    },
    volumeData: null,

    IpTypeOption: {
      active: false,
      data: ["ipv4", "ipv6", "ipv4v6"]
    }
  },
  actions: {
    async login({ state, commit }) {
      const url = state.url + "auth/login";

      var params = new FormData();
      params.append("email", state.loginData.email.data);
      params.append("password", state.loginData.password.data);

      try {
        await axios.get(process.env.VUE_APP_CSRF + "sanctum/csrf-cookie");
        const response = await axios.post(url, params, { credentials: true });
        Vue.$cookies.set("token", response.data.token);
        // window.localStorage.setItem(
        //   "token",
        //   JSON.stringify(response.data.token)
        // );

        commit("loginReset");
        return response.data;
      } catch (err) {
        if (err.response) {
          return err.response.data;
        } else {
          return err;
        }
      }
    },
    async registerUser({ state, commit }) {
      const url = state.url + "auth/register";

      var params = new FormData();
      params.append("first_name", state.register.name.data);
      params.append("last_name", state.register.lname.data);
      params.append("email", state.register.email.data);
      params.append("password", state.register.password.data);
      params.append("password_confirmation", state.register.repassword.data);
      // params.append("phone_number", state.register.phone.data);

      try {
        // await axios.get(process.env.VUE_APP_CSRF + "sanctum/csrf-cookie");
        const response = await axios.post(url, params, {
          credentials: true
        });
        commit("registerReset");
        return response.data;
      } catch (err) {
        return err.response;
      }
    },
    async forgot({ state }, data) {
      const url = state.url + "auth/forgot-password";
      var params = new FormData();
      params.append("email", data.email);
      params.append("h-captcha-response", data.captcha);

      try {
        const response = await axios.post(url, params, {
          credentials: true
        });
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response.data;
      }
    },
    async resetpassword({ state }, data) {
      const url = state.url + "auth/reset-password/" + data.token;
      var params = new FormData();
      params.append("email", data.email);
      params.append("password", data.password);
      params.append("confirm_password", data.confirm_password);

      return new Promise((resolve, reject) => {
        axios
          .post(url, params, { credentials: true })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
    },
    async resetPasswordLogin({ state }, data) {
      const url = state.url + "me/reset-password";
      const token = Vue.$cookies.get("token");
      var params = new FormData();
      params.append("old_password", data);

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response.data;
      }
    },
    async resend() {
      const url = this.state.url + "auth/resend-link";
      const token = Vue.$cookies.get("token");

      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            credentials: true,
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(response => {
            resolve(response);
          })
          .catch(function(err) {
            reject(err.response.data);
          });
      });
    },
    async getme({ state, commit }) {
      const url = state.url + "me";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("changeMe", response.data);
        // dispatch("gravatar", response.data.data.md5_email);
        return response.data;
      } catch (err) {
        // console.log(err);
        return err.response.data;
      }
    },
    async getAnnouncements({ state, commit }) {
      const url = state.url + "support/announcements";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("changeAnnouncements", response.data);
        return response.data;
      } catch (err) {
        // console.log(err);
        return err.response.data;
      }
    },
    //need handle Gravatar
    async gravatar({ state }, data) {
      console.log(state.postMeData);
      console.log("gravatar", data);
      const url = "https://www.gravatar.com/" + data + ".json";

      try {
        const response = await axios.get(url, {
          credentials: true
        });
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    },
    async postMe({ state }) {
      const url = state.url + "me";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      // var params = new FormData();
      // params.append("firstname", state.postMeData.firstname);
      // params.append("lastname", state.postMeData.lastname);

      // params.append("phonenumber", state.postMeData.phone_number);
      // params.append("address1", state.postMeData.address);
      // params.append("companyname", state.postMeData.companyname);
      // params.append("tax_id", state.postMeData.npwp);
      // params.append("tax_type", state.postMeData.taxType);

      const reqBody = {
        firstname: state.postMeData.firstname,
        lastname: state.postMeData.lastname,
        phonenumber: state.postMeData.phone_number,
        address1: state.postMeData.address,
        companyname: state.postMeData.companyname,
        tax_id: state.postMeData.npwp,
        tax_type: state.postMeData.taxType
      };

      try {
        const response = await axios.put(url, qs.stringify(reqBody), {
          credentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        });
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response.data;
      }
    },

    async getSSH({ state, commit }) {
      const url = state.url + "sshkey";

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      await axios
        .get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          commit("updateSSH", response.data.data);
        })
        .catch(err => {
          // console.log("err", err.response.data);
          return err.response;
        });
    },
    async getVM({ state, commit }) {
      const url = state.url + "vm";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("updateVM", response.data.data);
      } catch (err) {
        // console.log(err.response.data);
        return err.response;
      }
    },
    async getVNC({ state, commit }, data) {
      if (Vue.$cookies.isKey("vnc_url")) {
        Vue.$cookies.remove("vnc_url");
      }
      let id = null;
      let url = state.url + "vm/";
      if (typeof data === "string") {
        id = data;
        url += id + "/vnc";
      } else {
        let split = data.id.split("?");
        id = split[0];
        url += id + "/vnc?" + split[1];
      }

      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        Vue.$cookies.set(
          "vnc_url",
          response.data.data.url,
          null,
          null,
          process.env.VUE_APP_DOMAIN_NAME,
          true,
          "None"
        );

        commit("updateVNC", response.data);
        return response.data;
      } catch (err) {
        if (Vue.$cookies.isKey("vnc_url")) {
          Vue.$cookies.remove("vnc_url");
        }
        return err.response;
      }
    },
    async getVNCPassword({ state, commit }, data) {
      let url = state.url + "vm/";
      let id = null;

      // if (typeof data === "string") {
      //   url += data + "/vnc-password";
      // } else {
      //   url += data.id + "/vnc-password";
      // }

      if (typeof data === "string") {
        id = data;
        url += id + "/vnc-password";
      } else {
        let split = data.id.split("?");
        id = split[0];
        url += id + "/vnc-password?" + split[1];
      }

      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });

        commit("updateVNCPassword", response.data);
        return response.data;
      } catch (err) {
        return err.response;
      }
    },
    async getMetrics({ state, commit }, data) {
      let id = null;
      let url = state.url + "vm/";
      if (typeof data === "string") {
        id = data;
        url += id + "/metrics";
      } else {
        let split = data.id.split("?");
        id = split[0];
        url += id + "/metrics?" + split[1];
      }

      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });

        commit("updateMetrics", response.data);

        return response.data;
      } catch (err) {
        // mixin.methods.g_notif_create(
        //   false,
        //   "Failed get metrics data",
        //   err.response.data.message
        // );
        commit("updateMetrics", false);
        return err.response.data;
      }
    },
    async getImages({ state, commit }) {
      const url = state.url + "image";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("updateImages", response.data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response;
      }
    },
    async resetPasswordVM({ state }, data) {
      let id = null;
      let url = state.url + "vm/";
      if (typeof data.id === "string") {
        id = data.id;
        url += id + "/password";
      } else {
        let split = data.id.id.split("?");
        id = split[0];
        url += id + "/password?" + split[1];
      }
      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      var params = new FormData();
      params.append("password", data.vm_password);

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: header
        });
        return response.data;
      } catch (err) {
        return err.response;
      }
    },
    async logout({ state }) {
      const url = state.url + "auth/logout";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.post(url, {
          credentials: true,
          Authorization: `Bearer ${token}`
        });
        return response.data;
      } catch (err) {
        return err.response;
      }
    },
    async resizeVM({ state }, data) {
      let id = null;
      let url = state.url + "vm/";
      if (typeof data.id === "string") {
        id = data.id;

        data.calc
          ? (url += id + "/resize?calconly=true")
          : (url += id + "/resize");
      } else {
        let split = data.id.split("?");
        id = split[0];

        data.calc
          ? (url += id + "/resize?calconly=true&&" + split[1])
          : (url += id + "/resize?" + split[1]);
      }

      var params = new FormData();
      params.append("plan_id", data.plan_id);

      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: header
        });
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    },

    async backupImageVM({ state }, data) {
      const url = state.url + "vm/" + data.id + "/backup";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      var params = new FormData();
      params.append("name", data.name);

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });

        return response.data;
      } catch (err) {
        return err.response.data;
      }
    },
    async addssh({ state }, data) {
      const url = state.url + "sshkey";

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      var params = new FormData();
      params.append("name", data.name);
      params.append("ssh_key", data.ssh);

      return new Promise((resolve, reject) => {
        axios
          .post(url, params, {
            credentials: true,
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
    },
    async putSSH({ state }, data) {
      const url = state.url + "sshkey/" + data.id;

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      var params = new FormData();
      params.append("sshkey", data.ssh);

      return new Promise((resolve, reject) => {
        axios
          .put(url, params, {
            credentials: true,
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
    },

    async startVM({ state, dispatch }, data) {
      let id = null;
      let url = state.url + "vm/";
      if (typeof data === "string") {
        id = data;
        url += id + "/resume";
      } else {
        let split = data.id.split("?");
        id = split[0];
        url += id + "/resume?" + split[1];
      }
      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        dispatch("detailVM", data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response;
      }
    },
    async restartVM({ state, dispatch }, data) {
      let id = null;
      let url = state.url + "vm/";
      if (typeof data === "string") {
        id = data;
        url += id + "/reboot";
      } else {
        let split = data.id.split("?");
        id = split[0];
        url += id + "/reboot?" + split[1];
      }
      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        dispatch("detailVM", data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response;
      }
    },
    async stopVM({ state, dispatch }, data) {
      let id = null;
      let url = state.url + "vm/";
      if (typeof data === "string") {
        id = data;
        url += id + "/poweroff";
      } else {
        let split = data.id.split("?");
        id = split[0];
        url += id + "/poweroff?" + split[1];
      }
      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        dispatch("detailVM", data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response;
      }
    },
    async destroyVM({ state, commit }, data) {
      let id = null;
      if (typeof data === "string") {
        id = data;
      } else {
        id = data.id;
      }
      const url = state.url + "vm/" + id;
      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        await axios.delete(url, {
          credentials: true,
          headers: header
        });
        commit("deleteVM", id);
      } catch (err) {
        // console.log(err.response.data);
        return err.response;
      }
    },
    async deletessh({ state }, data) {
      const url = state.url + "sshkey/" + data.sshkey;

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      return new Promise((resolve, reject) => {
        axios
          .delete(url, {
            credentials: true,
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
    },
    async detailVM({ state, commit }, data) {
      let url = state.url + "vm/";
      if (typeof data === "string") {
        url += data;
      } else {
        url += data.id;
      }
      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        commit("changeVM", response.data);
        return response.data;
      } catch (err) {
        commit("changeVM", err);
        // console.log(err.response);
        return err.response;
      }
    },
    async getVMLogs({ state, commit }, data) {
      let id = null;
      let url = state.url + "vm/";
      if (typeof data === "string") {
        id = data;
        url += id + "/logs";
      } else {
        let split = data.id.split("?");
        id = split[0];
        url += id + "/logs?" + split[1];
      }

      let token = null;
      if (!data.token) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data.token);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        commit("changeVMLogs", response.data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response;
      }
    },
    async deleteImages({ state, commit }, data) {
      const url = state.url + "image/" + data.id;

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.delete(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("changeImages", response.data);
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    },
    async getBalance({ state, commit }) {
      const url = state.url + "me/credits";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("changeBalance", response.data);
        return response.data;
      } catch (err) {
        commit("changeBalance", err.response);
        return err.response.data;
      }
    },
    async postBalance({ state }, data) {
      const url = state.url + "me/credits";

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      var params = new FormData();
      params.append("amount", data);
      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        // commit("changeBalance", response.data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response.data;
      }
    },

    async getVolume({ state, commit }) {
      const url = state.url + "volume";

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        commit("changeVolumeData", response.data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response.data;
      }
    },
    async createVolume({ state }, data) {
      const url = state.url + "volume";
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      var params = new FormData();
      params.append("vm_id", data.vmId);
      params.append("volume_name", data.volumeName);
      params.append("volume_size", data.size);

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        // console.log(response.data);
        return response.data;
      } catch (err) {
        // console.log(err.response.data);
        return err.response.data;
      }
    },
    async detachVolume({ state }, data) {
      const url = state.url + "volume/" + data + "/detach";

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios({
          method: "post",
          url: url,
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    },
    async attachVolume({ state }, data) {
      const url = state.url + "volume/" + data.volumeId + "/attach";

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      var params = new FormData();
      params.append("vm_id", data.vmId);

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    },
    async deleteVolume({ state }, data) {
      const url = state.url + "volume/" + data;

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");

      try {
        const response = await axios({
          method: "delete",
          url: url,
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    },
    async detachIp({ state }, data) {
      const url = state.url + "ip-address/" + data;

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      // var params = new FormData();
      // params.append("vm_uuid", data.vmId);

      try {
        const response = await axios({
          method: "delete",
          url: url,
          // params: params,
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    },
    async attachIp({ state }, data) {
      const url = state.url + "vm/" + data.vmId + "/attach-nic";

      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      const token = Vue.$cookies.get("token");
      var params = new FormData();
      params.append("ip_address_type", data.ip_address_type);

      try {
        const response = await axios.post(url, params, {
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    }
  },
  mutations: {
    updateVM: (state, data) => {
      // data = [
      //   {
      //     id: "618092d3-0368-4d2e-b453-3dd9ee2ff07a",
      //     hostname: "4pOO.yk.Ubuntu.18.04",
      //     status: "Waiting Payment",
      //     type: "default",
      //     suspended: false,
      //     has_provision: false,
      //     created_at: "2022-01-18 09:44:07",
      //     available_quota_ip_address: 3,
      //     vm_token: "e69f8af3d3a259ec4679092ddbfd4cde0bc8c775",
      //     order_id: "960",
      //     order_invoice: "1925",
      //     details: {
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       public_domain: null,
      //       zone: {
      //         name: "Yogyakarta",
      //         flag: "id",
      //         short_name: "yk",
      //       },
      //       template: {
      //         name: "Ubuntu 18.04",
      //         group: "ubuntu",
      //         label: "default_cloudora",
      //       },
      //     },
      //     plan: {
      //       id: "1335ab24-8952-4eed-96ce-39bbde533362",
      //       name: "Cloudora 1GB",
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       price_hourly: 150,
      //       price_monthly: 100000,
      //       taxed: true,
      //       type: "standard",
      //       quota_ip_address: 3,
      //       max_volume_size: 50,
      //     },
      //     ip_address: [],
      //   },
      //   {
      //     id: "e5a6ad27-5066-4303-860a-b53d3edaa2cb",
      //     hostname: "NNE0.yk.CentOS.8",
      //     status: "poweroff",
      //     type: "default",
      //     suspended: false,
      //     has_provision: true,
      //     created_at: "2021-12-30 14:25:16",
      //     available_quota_ip_address: 3,
      //     vm_token: "0921ece7cae533518e774d5e24e0fbb6f24e153a",
      //     details: {
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       public_domain: "nne0ykcentos8-b53d3edaa2cb.cloudora-app.com",
      //       zone: {
      //         name: "Yogyakarta",
      //         flag: "id",
      //         short_name: "yk",
      //       },
      //       template: {
      //         name: "CentOS 8",
      //         group: "centos",
      //         label: "default_cloudora",
      //       },
      //     },
      //     plan: {
      //       id: "1335ab24-8952-4eed-96ce-39bbde533362",
      //       name: "Cloudora 1GB",
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       price_hourly: 150,
      //       price_monthly: 100000,
      //       taxed: true,
      //       type: "standard",
      //       quota_ip_address: 3,
      //       max_volume_size: 50,
      //     },
      //     ip_address: [
      //       {
      //         id: "03a17e3e-0855-45ea-97f2-029475c45e11",
      //         name: "default_public",
      //         ipv4_address: "103.126.226.74",
      //         ipv6_address: "2001:df7:5300:5::2",
      //         type: "ipv4v6",
      //         mac_address: "02:00:67:7e:e2:4a",
      //       },
      //       {
      //         id: "a597b0ae-bb8e-48ab-8b14-7701f5ef9254",
      //         name: "default_private",
      //         ipv4_address: "192.168.3.56",
      //         ipv6_address: null,
      //         type: "ipv4",
      //         mac_address: "02:00:c0:a8:03:38",
      //       },
      //     ],
      //   },
      //   {
      //     id: "f248de3d-71ec-4c94-b486-e070f5c29a4e",
      //     hostname: "TVHG.yk.Ubuntu.20.04",
      //     status: "running",
      //     type: "default",
      //     suspended: false,
      //     has_provision: true,
      //     created_at: "2021-12-28 11:24:01",
      //     available_quota_ip_address: 3,
      //     vm_token: "197211db8b5317df876085e4f533798c0b1a4582",
      //     details: {
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       public_domain: "tvhgykubuntu2004-e070f5c29a4e.cloudora-app.com",
      //       zone: {
      //         name: "Yogyakarta",
      //         flag: "id",
      //         short_name: "yk",
      //       },
      //       template: {
      //         name: "Ubuntu 20.04",
      //         group: "ubuntu",
      //         label: "default_cloudora",
      //       },
      //     },
      //     plan: {
      //       id: "1335ab24-8952-4eed-96ce-39bbde533362",
      //       name: "Cloudora 1GB",
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       price_hourly: 150,
      //       price_monthly: 100000,
      //       taxed: true,
      //       type: "standard",
      //       quota_ip_address: 3,
      //       max_volume_size: 50,
      //     },
      //     ip_address: [
      //       {
      //         id: "3ed9c151-9692-4774-bebd-5d1d221f3784",
      //         name: "default_public",
      //         ipv4_address: "103.126.226.115",
      //         ipv6_address: "2001:df7:5300:5::d",
      //         type: "ipv4v6",
      //         mac_address: "02:00:67:7e:e2:73",
      //       },
      //       {
      //         id: "8ff7e9e7-f9a1-4375-b914-b8c06b26863b",
      //         name: "default_private",
      //         ipv4_address: "192.168.3.53",
      //         ipv6_address: null,
      //         type: "ipv4",
      //         mac_address: "02:00:c0:a8:03:35",
      //       },
      //     ],
      //   },
      //   {
      //     id: "abb4f345-2912-4ec3-878f-8e8cd1baffc9",
      //     hostname: "NSnY.yk.Ubuntu.14.04",
      //     status: "running",
      //     type: "default",
      //     suspended: false,
      //     has_provision: true,
      //     created_at: "2021-12-27 19:43:56",
      //     available_quota_ip_address: 3,
      //     vm_token: "d30af3ffe4ec801e9181922e787e17c1a900dfdd",
      //     details: {
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       public_domain: "nsnyykubuntu1404-8e8cd1baffc9.cloudora-app.com",
      //       zone: {
      //         name: "Yogyakarta",
      //         flag: "id",
      //         short_name: "yk",
      //       },
      //       template: {
      //         name: "Ubuntu 14.04",
      //         group: "ubuntu",
      //         label: "default_cloudora",
      //       },
      //     },
      //     plan: {
      //       id: "1335ab24-8952-4eed-96ce-39bbde533362",
      //       name: "Cloudora 1GB",
      //       cpu: 1,
      //       vcpu: 1,
      //       memory: 1,
      //       disk_size: 10,
      //       price_hourly: 150,
      //       price_monthly: 100000,
      //       taxed: true,
      //       type: "standard",
      //       quota_ip_address: 3,
      //       max_volume_size: 50,
      //     },
      //     ip_address: [
      //       {
      //         id: "f50c8c10-e392-4177-8608-d995477f5b2f",
      //         name: "default_public",
      //         ipv4_address: "103.126.226.114",
      //         ipv6_address: "2001:df7:5300:5::c",
      //         type: "ipv4v6",
      //         mac_address: "02:00:67:7e:e2:72",
      //       },
      //       {
      //         id: "df1a58e1-f1cf-49ff-809e-84f2691d0fa1",
      //         name: "default_private",
      //         ipv4_address: "192.168.3.50",
      //         ipv6_address: null,
      //         type: "ipv4",
      //         mac_address: "02:00:c0:a8:03:32",
      //       },
      //     ],
      //   },
      // ];
      data.forEach(vmData => {
        vmData.menu = false;
        vmData.extend = false;
      });
      var vm_pagination = state.vm_pagination;
      vm_pagination.totalPages = Math.ceil(
        data.length / vm_pagination.showTotal
      );
      vm_pagination.totalData = data;
      var vmListData = vm_pagination.totalData.slice(
        (vm_pagination.currentPage - 1) * vm_pagination.showTotal,
        vm_pagination.currentPage * vm_pagination.showTotal
      );
      state.vmList = vmListData;
    },
    changeAnnouncements: (state, data) => {
      Vue.set(state, "announcements", data.data);
    },
    changeMinimize: state => {
      Vue.set(state, "minimize", !state.minimize);
    },
    changeVM: (state, data) => {
      state.vm_detail = data.data;
      // state.vm_detail = {
      //   id: '9b49fd06-424d-4d89-8eed-23aba223d5c2',
      //   hostname: 'S9U5.yk.Ubuntu.20.04',
      //   status: 'running',
      //   type: 'default',
      //   suspended: false,
      //   created_at: '2021-11-09 00:06:36',
      //   available_quota_ip_address: 3,
      //   vm_token: '23d91a16de08c2030d83af7307bd54ed5e1c618b',
      //   details: {
      //     cpu: 1,
      //     vcpu: 1,
      //     memory: 1,
      //     disk_size: 10,
      //     public_domain: 's9u5ykubuntu2004-23aba223d5c2.cloudora-app.com',
      //     zone: {
      //       name: 'Yogyakarta',
      //       flag: 'id',
      //       short_name: 'yk',
      //     },
      //     template: {
      //       name: 'Ubuntu 20.04',
      //       group: 'ubuntu',
      //       label: 'default_cloudora',
      //     },
      //   },
      //   plan: {
      //     id: '1335ab24-8952-4eed-96ce-39bbde533362',
      //     name: 'Cloudora 1GB',
      //     cpu: 1,
      //     vcpu: 1,
      //     memory: 1,
      //     disk_size: 10,
      //     price_hourly: 150,
      //     price_monthly: 100000,
      //     taxed: true,
      //     type: 'standard',
      //     quota_ip_address: 3,
      //     max_volume_size: 50,
      //   },
      //   ip_address: [
      //     {
      //       id: '5f4b35a9-00e3-4da1-ac42-5f67b06a608d',
      //       name: 'default_public',
      //       ipv4_address: '103.126.226.208',
      //       ipv6_address: '2001:df7:5300:5::10',
      //       type: 'ipv4v6',
      //       mac_address: '02:00:67:7e:e2:d0',
      //     },
      //     {
      //       id: '0dbe7677-222e-4f12-a22d-6b473d286909',
      //       name: 'default_private',
      //       ipv4_address: '192.168.3.60',
      //       ipv6_address: null,
      //       type: 'ipv4',
      //       mac_address: '02:00:c0:a8:03:3c',
      //     },
      //   ],
      //   volume: [
      //     {
      //       id: '84f3a5b1-810b-435c-90ee-45a045f2f931',
      //       name: 'Cek Create Volume',
      //       size: 20,
      //       zone: 'yk',
      //       target: 'sdb',
      //       persistent: true,
      //       created_at: '2021-11-25 15:33:55',
      //     },
      //   ],
      // };
      // console.log("ini vm detail", state.vm_detail);
      let id = data.data.id;
      // let id = '2b2687cb-516c-4fe8-8e5a-c145f60e8c31';
      if (state.vm_pagination.totalData.length > 0) {
        const vm = state.vm_pagination.totalData.findIndex(
          val => val.id === id
        );
        if (data.data.status === "done" && vm != -1) {
          state.vm_pagination.totalData.splice(vm, 1);
          var vmListData = state.vm_pagination.totalData.slice(
            (state.vm_pagination.currentPage - 1) *
              state.vm_pagination.showTotal,
            state.vm_pagination.currentPage * state.vm_pagination.showTotal
          );
          state.vmList = vmListData;
        } else {
          if (data.data.status && vm != -1) {
            state.vm_pagination.totalData[vm].status = data.data.status;
            state.vm_pagination.totalData[vm].details = data.data.details;
            state.vm_pagination.totalData[vm].ip_address = JSON.parse(
              JSON.stringify(data.data.ip_address)
            );
          }
        }
      }

      state.vm_pagination.totalData.forEach(vm => {
        if (vm.ip_address && vm.ip_address.length !== 0) {
          state.vm_network.push(vm);
        }
      });
    },
    deleteVM: (state, data) => {
      const vm = state.vm_pagination.totalData.findIndex(
        val => val.id === data
      );
      state.vm_pagination.totalData.splice(vm, 1);
      var vmListData = state.vm_pagination.totalData.slice(
        (state.vm_pagination.currentPage - 1) * state.vm_pagination.showTotal,
        state.vm_pagination.currentPage * state.vm_pagination.showTotal
      );
      state.vmList = vmListData;
    },
    updateSSH: (state, data) => {
      data.forEach(data => {
        data.active = false;
        data.menu = false;
      });
      state.sshList = data;
    },
    changeSSH: (state, data) => {
      state.sshList.forEach(ssh => {
        if (ssh.id == data.id) {
          if (!ssh.active) {
            ssh.active = true;
          } else {
            ssh.active = false;
          }
        }
      });
    },

    pickImage: (state, data) => {
      state.images.forEach(image => {
        if (image.id === data.id) {
          if (!data.active) {
            image.active = true;
            state.deployParams.template_id = data.id;
          }
        } else {
          image.active = false;
        }
      });
      for (var key in state.template) {
        state.template[key].version = null;
        state.template[key].active = false;
      }
    },

    resetDeployParams: state => {
      state.deployParams = {
        template_id: null,
        plan_id: null,
        zone: null,
        sshKey: [],
        vm_password: null,
        hostname: null,
        volume: 0,
        volume_persistent: 0,
        volume_size: 0,
        ip_address: 0,
        ip_address_size: 0,
        ip_address_type: null,
        template_type: "default"
      };
      state.template = {};
      state.images = null;
      state.zone.zoneList = null;
      state.plan = null;
      state.sshList = null;
      state.volume.active = false;
      state.addIp = false;
      state.IpTypeOption.active = false;
    },
    resetZoneList: state => {
      state.template = {};
      state.vm_detail = null;
      state.images = null;
      state.plan = null;
      state.sshList = null;
      state.deployParams.hostname = null;
      state.deployParams.vm_password = null;
      state.deployParams.volume = 0;
      state.deployParams.volume_persistent = 0;
      state.deployParams.volume_size = 0;
      state.deployParams.ip_address = 0;
      state.deployParams.ip_address_size = 0;
      state.deployParams.ip_address_type = null;
      state.deployParams.template_type = "default";
      state.volume.active = false;
      state.planOption = {
        gp: true,
        mo: false,
        dc: false
      };
      state.templateOption = {
        default: true,
        backup: false,
        app: false
      };
      state.addIp = false;
      state.IpTypeOption.active = false;
    },

    registerReset: state => {
      state.register = {
        name: {
          active: false,
          message: null,
          data: null
        },
        email: {
          active: false,
          message: null,
          data: null
        },
        password: {
          active: false,
          message: null,
          data: null
        },
        repassword: {
          active: false,
          message: null,
          data: null
        },
        lname: {
          active: false,
          message: null,
          data: null
        },
        checked: {
          active: false,
          message: false
        }
      };
    },
    loginReset: state => {
      state.loginData = {
        password: {
          active: false,
          message: null,
          data: null
        },
        email: {
          active: false,
          message: null,
          data: null
        }
      };
    },
    resetVMDetail: state => {
      state.vm_detail = null;
    },
    changePanelState: (state, data) => {
      state.panelState = data;
    },
    changePanelContent: (state, data) => {
      data.forEach(data => {
        state.panel = state.panel.filter(e => e !== data);
      });
    },
    updateImages: (state, data) => {
      // state.images = data.data;
      data.data.forEach(value => {
        value.active = false;
        let nameInclude = value.name.includes("_");
        if (nameInclude) {
          let nameSeparator = value.name.indexOf("_");
          value.name = value.name.slice(0, nameSeparator);
        }
      });
      Vue.set(state, "images", data.data);
      // console.log(state.images);
    },
    changeImages: (state, data) => {
      const imageIndex = state.images.findIndex(val => val.id === data.id);
      state.images.splice(imageIndex, 1);
    },
    changeMe: (state, data) => {
      state.userdata = data.data;
      // console.log(state.userdata);
      if (data.data) {
        // state.postMeData = {
        //   name: data.data.fullname,
        //   email: data.data.email,
        //   phone_number: data.data.phonenumber,
        //   address: data.data.address1,
        //   company: data.data.companyname,
        //   npwp: data.data.tax_id,
        //   taxType: data.data.account_type,
        // };
        state.postMeData = {
          firstname: data.data.firstname,
          lastname: data.data.lastname,
          email: data.data.email,
          phone_number: data.data.phonenumber,
          address: data.data.address1,
          companyname: data.data.companyname,
          npwp: data.data.tax_id,
          taxType: data.data.customfields1
        };
      } else {
        state.postMeData.name = data.data.name;
        state.postMeData.email = data.data.email;
      }
    },
    updateMetrics: (state, data) => {
      Vue.set(state.chart, "cpu", {});
      Vue.set(state.chart.disk[0], "data", {});
      Vue.set(state.chart.disk[1], "data", {});
      Vue.set(state.chart.bandwidth[0], "data", {});
      Vue.set(state.chart.bandwidth[1], "data", {});
      // data = {
      //   data: {
      //     "2021-04-01 11:01:09": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:02:10": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:02:16": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:03:25": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:04:40": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:05:41": {
      //       cpu: 12,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:06:56": {
      //       cpu: 10,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:07:10": {
      //       cpu: 6,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:08:11": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:09:27": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //     "2021-04-01 11:10:41": {
      //       cpu: 1,
      //       diskrdbytes: 208188820,
      //       diskrdiops: 21883,
      //       diskwrbytes: 46602240,
      //       diskwriops: 4610,
      //       memory: 515784,
      //       netrx: 116077768,
      //       nettx: 4992200,
      //     },
      //   },
      // };

      if (data) {
        // let keysLength = Object.keys(data.data).length / 2;
        // let keysInt = ~~keysLength;

        // let reverseKey = Object.keys(data.data)
        //   .slice()
        //   .reverse();
        let keys = Object.keys(data.data);
        // console.log("ini reverser", reverseKey);

        if (keys.length !== 0) {
          for (let i = 0; i < keys.length; i++) {
            var newKey = keys[i];
            // if (Object.keys(state.chart.cpu).length !== 8) {
            state.chart.cpu[newKey] = data.data[keys[i]].cpu;
            // }
            // if (Object.keys(state.chart.disk[0].data).length !== 8) {
            state.chart.disk[0].data[newKey] =
              data.data[keys[i]].diskrdbytes / 100000000;
            // }
            // if (Object.keys(state.chart.disk[1].data).length !== 8) {
            state.chart.disk[1].data[newKey] =
              data.data[keys[i]].diskwrbytes / 100000000;
            // }
            // if (Object.keys(state.chart.bandwidth[0].data).length !== 8) {
            state.chart.bandwidth[0].data[newKey] =
              data.data[keys[i]].netrx / 100000000;
            // }
            // if (Object.keys(state.chart.bandwidth[1].data).length !== 8) {
            state.chart.bandwidth[1].data[newKey] =
              data.data[keys[i]].nettx / 100000000;
            // }
          }
        }
      }
      // console.log("Data chart", state.chart);
    },
    resetMetrics: state => {
      Vue.set(state.chart, "cpu", null);
      Vue.set(state.chart.disk[0], "data", null);
      Vue.set(state.chart.disk[1], "data", null);
      Vue.set(state.chart.bandwidth[0], "data", null);
      Vue.set(state.chart.bandwidth[1], "data", null);
    },
    updateVNC: (state, data) => {
      Vue.set(state, "vnc", data.data);
    },
    updateVNCPassword: (state, data) => {
      Vue.set(state, "vncPassword", data.data.password);
    },
    resetVNC: state => {
      Vue.set(state, "vnc", null);
    },
    resetVNCPassword: state => {
      Vue.set(state, "vncPassword", null);
    },
    resetImages: state => {
      Vue.set(state, "images", null);
    },
    changeLoadingFull: (state, data) => {
      Vue.set(state, "loadingFull", data);
    },
    changeVMLogs: (state, data) => {
      Vue.set(state, "vmLogs", data.data);
    },
    clearVMLogs: state => {
      Vue.set(state, "vmLogs", null);
    },
    changeBalance: (state, data) => {
      Vue.set(state, "billingData", data.data);
    },
    changeLoginData: (state, data) => {
      Vue.set(state.loginData.email, "data", data.email);
      Vue.set(state.loginData.password, "data", data.password);
    },
    changeVolumeData: (state, data) => {
      // let ndata = [
      //   {
      //     id: "7e78b00f-2476-4d3a-bfb4-64ed06005f06",
      //     name: "di Ubuntu20",
      //     size: 20,
      //     zone: "yk",
      //     target: null,
      //     persistent: true,
      //     created_at: "2021-10-28 11:47:00",
      //     virtualMachine: null,
      //   },
      //   {
      //     id: "89c0be32-25a9-48a1-bf44-3321681bb909",
      //     name: "cobain volume",
      //     size: 20,
      //     zone: "yk",
      //     target: "sdc",
      //     persistent: true,
      //     created_at: "2021-10-28 11:07:32",
      //     virtualMachine: {
      //       id: "23dc9219-cc57-4cef-833e-fcdacc05f689",
      //       hostname: "dasdasdadasd",
      //       status: "done",
      //       type: "default",
      //       suspended: false,
      //       created_at: "2021-10-14 16:18:42",
      //       available_quota_ip_address: 3,
      //       vm_token: "4fe475ce9ee1a0d56a59f413fb5118bc31c1c1dd",
      //       details: {
      //         cpu: 1,
      //         vcpu: 1,
      //         memory: 1,
      //         disk_size: 10,
      //         public_domain: "dasdasdadasd-fcdacc05f689.cloudora-app.com",
      //         zone: {
      //           name: "Yogyakarta",
      //           flag: "id",
      //           short_name: "yk",
      //         },
      //         template: {
      //           name: "Ubuntu 14.04",
      //           group: "ubuntu",
      //           label: "default_cloudora",
      //         },
      //       },
      //       plan: {
      //         id: "1335ab24-8952-4eed-96ce-39bbde533362",
      //         name: "Cloudora 1GB",
      //         cpu: 1,
      //         vcpu: 1,
      //         memory: 1,
      //         disk_size: 10,
      //         price_hourly: 150,
      //         price_monthly: 100000,
      //         taxed: true,
      //         type: "standard",
      //         quota_ip_address: 3,
      //         max_volume_size: 50,
      //       },
      //     },
      //   },
      //   {
      //     id: "c7521ae0-64bd-4768-bc22-a01d3f6669b0",
      //     name: "gjvvjhvjhvh",
      //     size: 40,
      //     zone: "yk",
      //     target: null,
      //     persistent: true,
      //     created_at: "2021-10-25 16:55:50",
      //     virtualMachine: null,
      //   },
      // ];
      data.data.forEach(volume => {
        volume.menu = false;
      });
      // ndata.forEach((volume) => {
      //   volume.menu = false;
      // });
      // console.log(data);
      Vue.set(state, "volumeData", data.data);
      // Vue.set(state, "volumeData", ndata);
    },
    changeVolume: (state, data) => {
      Vue.set(state.deployParams, "volume_size", data);
      // state.volume.data.forEach((volume) => {
      //   if (volume.id === data.data.id) {
      //     volume.active = true;
      //     Vue.set(state.deployParams, "volume_size", data.data.size);
      //   } else {
      //     volume.active = false;
      //   }
      // });
    },
    switchVolume: state => {
      Vue.set(state.volume, "active", !state.volume.active);
    },
    resetVolume: state => {
      Vue.set(state.volume, "active", false);
    },
    resetPayment: state => {
      Vue.set(state.payment, "method", null);
      Vue.set(state.payment, "name", null);
    },
    changePayment: (state, data) => {
      Vue.set(state.payment, "method", data.method);
      Vue.set(state.payment, "name", data.name);
    },

    switchPersistent: state => {
      Vue.set(state.volume, "persistent", !state.volume.persistent);
      if (state.volume.persistent) {
        Vue.set(state.deployParams, "volume_persistent", 1);
      } else {
        Vue.set(state.deployParams, "volume_persistent", 0);
      }
    }
  },
  modules: {
    support: support,
    plan: plan,
    zone: zone,
    deployVM: deployVM,
    template: template,
    invoice: invoice,
    networks: networks
  }
});
