<template>
  <div
    class="bg-black bg-opacity-50 fixed inset-0"
    style="z-index: 30"
    @click="dismiss()"
  >
    <div class="container max-w-xl m-auto" @click.stop="">
      <div
        class="mx-2 mt-56 py-8 px-6 rounded-md"
        :class="{ 'bg-green': active, 'bg-red': !active }"
      >
        <div class="flex items-start text-white">
          <div class="pb-4 pr-8 text-3xl">
            <i
              :class="{
                'fas fa-check': active,
                'far fa-times-circle': !active
              }"
            ></i>
          </div>
          <div class="flex flex-col flex-1">
            <div class="text-3xl">{{ header }}</div>
            <p>{{ message }}</p>
          </div>
          <div class="text-right p-1 cursor-pointer ml-2" @click="dismiss()">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    message: null,
    header: null,
    active: null
  },
  methods: {
    dismiss: function() {
      this.$emit("dismiss");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
