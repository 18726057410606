<template>
  <div class="">
    <div class="grid grid-cols-2 items-center justify-center">
      <div
        class="flex justify-center min-h-screen text-white bg-orange items-center"
      >
        <div class="w-64">
          <img src="@/assets/icon/logo_white.png" alt="" class="w-full" />
        </div>
        <!-- <div class="flex font-bold relative">
          <h1 class="text-2xl font-nunito">cloudora</h1>
          <span
            class="absolute top-0 font-nunito"
            style="font-size: 8px; right: -6px"
            >TM</span
          >
        </div> -->
      </div>
      <div class="min-h-screen">
        <div
          class="max-w-sm min-h-screen mx-auto flex items-center justify-center"
        >
          <div class="w-full">
            <ErrorMessage
              v-if="em"
              message="The Given Data is Invalid"
              @dismiss="em = false"
            />
            <h1 class="text-2xl mb-6 font-nunito font-bold">Forgot Password</h1>
            <form @submit.prevent="request">
              <div class="my-6">
                <div
                  class="bg-grayForm p-3 rounded-md mb-2 border"
                  :class="{
                    'border-orange': email.active,
                    'border-grayForm': !email.active,
                    'border-red': email.message && email.active
                  }"
                >
                  <div class="flex">
                    <div class="mr-2">
                      <img
                        src="@/assets/icon/email_orange@2x.png"
                        alt=""
                        class="w-full"
                      />
                    </div>
                    <input
                      type="email"
                      v-model="email.data"
                      @focus="email.active = true"
                      @blur="email.active = false"
                      class="w-full text-sm placeholder-grayText bg-grayForm outline-none"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>
                <span v-if="email.message" class="text-red text-xs">{{
                  email.message
                }}</span>
              </div>

              <vue-hcaptcha
                size="invisible"
                :sitekey="hcaptchaKey"
                theme="dark"
                @verify="verifyCaptcha"
                @closed="g_loading_destroy()"
                ref="invisibleHcaptcha"
              ></vue-hcaptcha>

              <button
                class="h-captcha w-full py-3 text-white bg-orange rounded-md font-whitney-bold mb-4"
                type="submit"
              >
                Send
              </button>
              <div class="text-sm text-darkBlueText text-center">
                <span class="">
                  Back to
                  <router-link to="/login" class="text-orange"
                    >Login</router-link
                  >
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Notification
      v-if="notif"
      @dismiss="closeNotif()"
      header="Send"
      message="We just sent you an email. Check out your inbox"
      active="true"
    />
  </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Notification from "@/components/Notification.vue";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
export default {
  name: "Home",
  components: {
    ErrorMessage,
    Notification,
    VueHcaptcha
  },
  created() {
    document.title = "Nevacloud | Forgot Password";
  },
  data: function() {
    return {
      email: {
        active: false,
        message: null,
        error: null
      },
      notif: false,
      em: false,
      hcaptchaKey: process.env.VUE_APP_HCAPTCHA_SITEKEY
    };
  },
  mounted: function() {},
  methods: {
    request() {
      this.email.message = null;
      this.g_loading_create("Loading");
      this.$refs.invisibleHcaptcha.execute();
      // this.$store
      //   .dispatch("forgot", {
      //     email: this.email.data,
      //   })
      //   .then(() => {
      //     this.g_loading_destroy();
      //     this.notif = true;
      //   })
      //   .catch((err) => {
      //     this.em = true;
      //     this.g_loading_destroy();
      //     console.log(err);
      //   });
    },
    closeNotif() {
      this.notif = false;
      this.$router.push("/login");
    },
    verifyCaptcha(response) {
      this.$store
        .dispatch("forgot", {
          email: this.email.data,
          captcha: response
        })
        .then(res => {
          this.g_loading_destroy();

          if (res.status === 200) {
            this.notif = true;
          } else {
            this.g_notif_create(false, "Failed", res.error.message);
          }
        });
    }
  }
};
</script>

<style scoped>
iframe {
  margin: 0 auto !important;
}
</style>
