<template>
  <div class>
    <sidebar />
    <div class="flex flex-col" :class="minimize ? 'min-content' : 'content'">
      <navbar />
      <div class="flex-1 pt-4 px-10">
        <div class="flex items-center justify-between mb-10">
          <div class="flex items-center">
            <h1 class="text-3xl font-nunito font-bold text-black mr-4">
              Images
            </h1>
          </div>
          <!-- <div class="">
            <div
              class="text-darkBlueText py-2 px-3 bg-grayForm rounded-lg flex items-center"
            >
              <div class="w-6 mr-4">
                <img src="@/assets/icon/ic_search.png" alt="" />
              </div>
              <input
                type="text"
                class="w-full bg-grayForm placeholder-darkBlueText outline-none"
                placeholder="Search"
              />
            </div>
          </div> -->
        </div>

        <div
          class="bg-white p-8 shadow-md rounded-lg mb-20"
          v-if="images && images.length != 0"
        >
          <!-- head -->
          <div
            class="grid grid-cols-12 gap-4 text-darkBlueText border-b border-white2 py-6"
          >
            <div class="col-span-3">Image Name</div>
            <div class="col-span-2">Region</div>
            <div class="col-span-2">VM Count</div>
            <div class="col-span-2">Size</div>
            <div class="col-span-2">Status</div>
            <div class=""></div>
          </div>

          <!-- content -->
          <div class="">
            <div
              class="py-4 border-b border-white2"
              v-for="image in images"
              :key="image.id"
            >
              <div class="grid grid-cols-12 gap-4 items-center text-black">
                <div class="col-span-3 truncate">
                  {{ image.name }}
                </div>
                <div class="col-span-2 truncate" v-if="image.region == 'yk'">
                  Yogyakarta
                </div>
                <div class="col-span-2 truncate" v-if="image.region == 'id'">
                  Indonesia
                </div>
                <div class="col-span-2">
                  {{ image.vm_count }}
                </div>

                <div class="col-span-2">{{ image.size / 10240 }} GB</div>
                <div class="col-span-2">
                  <div class="inline-block">
                    <div
                      class="flex py-2 px-3 rounded-full items-center"
                      :class="
                        image.status === 'ready' ? 'bg-greenBg' : 'bg-grayForm'
                      "
                    >
                      <div
                        class="w-2 h-2 rounded-full bg-green mr-2"
                        v-if="image.status === 'ready'"
                      ></div>
                      <div class="text-sm">{{ image.status }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-span-1">
                  <div
                    class="w-6 cursor-pointer"
                    @click="(confirmation_active = true), (imageData = image)"
                  >
                    <img src="@/assets/icon/ic_delete_f.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- no data -->
        <div class="flex flex-col flex-1 items-center justify-center">
          <div class="mt-6 mb-16">
            <img src="@/assets/img/no_image.png" class="w-2/3 mx-auto" alt="" />
          </div>
          <div class="text-2xl text-darkBlueText font-nunito font-bold mb-16">
            Coming Soon
          </div>
        </div>
      </div>
    </div>
    <confirmation
      v-if="confirmation_active"
      @accept="deleteImage()"
      @dismiss="(confirmation_active = false), (id_delete = null)"
    />
    <Notification
      v-if="notif.show"
      @dismiss="notif.show = false"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    />
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import navbar from "@/components/navbar.vue";
import confirmation from "@/components/confirmation.vue";
import Notification from "@/components/Notification.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Images",
  components: {
    sidebar,
    navbar,
    confirmation,
    Notification
  },
  created() {
    document.title = "Nevacloud | Images";
    // this.$store.commit("resetImages");
    // if (!this.images) {
    //   this.g_loading_create("Loading");
    // }
    // this.getImages().then(() => {
    //   this.g_loading_destroy();
    // });
  },
  computed: {
    ...mapState(["images", "minimize"])
  },
  data: function() {
    return {
      confirmation_active: false,
      imageData: null,
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      }
    };
  },
  mounted: function() {},
  methods: {
    ...mapActions(["getImages"]),
    deleteImage() {
      if (!this.imageData) {
        return;
      }
      this.g_loading_create("Loading");
      this.$store.dispatch("deleteImages", this.imageData).then(response => {
        if (response.status === 200) {
          this.confirmation_active = false;
          this.imageData = null;

          this.notif = {
            active: true,
            message: "Delete Image Succes",
            show: true,
            header: "Succes!"
          };
          this.g_loading_destroy();
        } else {
          this.confirmation_active = false;
          this.imageData = null;
          this.g_loading_destroy();
          this.notif = {
            active: false,
            message: "Delete Image Failed",
            show: true,
            header: "Something Went Wrong"
          };
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
