<template>
  <div class>
    <div class="fixed inset-0 bg-gray bg-opacity-75" style="z-index: 20">
      <div
        class="container m-auto max-w-3xl border border-white-100 mt-16 bg-white form-container"
        :class="{ 'will-dismiss': will_dismiss }"
      >
        <div class="py-8 px-4">
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
          <h1 class="text-3xl text-slate mb-4">Add New IP</h1>
          <div class="relative mb-32">
            <div
              class="border flex items-center justify-between border-gray px-2 py-2 cursor-pointer"
              @click="IpTypeOption.active = !IpTypeOption.active"
            >
              <div class="" v-if="ipType">
                {{ ipType }}
              </div>
              <div class="" v-else>
                Choose type
                <span class="text-red text-xs" v-if="tMessage">{{
                  tMessage
                }}</span>
              </div>
              <i
                v-if="IpTypeOption.active === false"
                class="fas fa-chevron-down"
              ></i>
              <i
                v-if="IpTypeOption.active === true"
                class="fas fa-chevron-up"
              ></i>
            </div>
            <div
              v-if="IpTypeOption.active"
              class="absolute border bg-white border-gray right-0 left-0 mt-1"
            >
              <div
                class="p-2 text-slate hover:text-white hover:bg-blue cursor-pointer"
                v-for="listIp in IpTypeOption.data"
                :key="listIp"
                @click="
                  (ipType = listIp),
                    (IpTypeOption.active = !IpTypeOption.active)
                "
              >
                {{ listIp }}
              </div>
            </div>
          </div>
          <button
            @click.prevent="addIp()"
            class="w-full py-2 bg-blue text-white"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "attachIP",
  components: {},
  computed: {
    ...mapState(["IpTypeOption"])
  },
  props: {
    will_dismiss: {
      type: Boolean,
      default: false
    },
    vmId: null
  },
  created() {},
  data: function() {
    return {
      ipType: null,
      tMessage: null
    };
  },
  mounted: function() {
    this.ipType = null;
  },
  methods: {
    attachVolume() {
      if (!this.vmUUID) {
        alert("Please Choose Virtual Machine");
        return;
      }

      this.g_loading_create("Loading");
      this.$store
        .dispatch("attachVolume", {
          vmId: this.vmUUID,
          volumeId: this.volumeData.volumeId
        })
        .then(response => {
          this.g_loading_destroy();
          if (response.status === 200) {
            this.g_notif_create(true, "Success", "Volume has been attached");
            this.succesClose();
          } else {
            this.g_notif_create(false, "Failed", response.error.message);
          }
        });
      //
    },
    addIp() {
      this.tMessage = null;
      if (!this.ipType) {
        this.tMessage = "Please Choose IP Type";
        return;
      }
      this.g_loading_create("Loading");
      this.$store
        .dispatch("attachIp", { vmId: this.vmId, ip_address_type: this.ipType })
        .then(response => {
          this.g_loading_destroy();
          if (response.status === 200) {
            this.g_notif_create(true, "Success", "New IP has been attached");
            this.succesClose();
          } else {
            this.g_notif_create(false, "Failed", response.error.message);
          }
        });
    },
    succesClose() {
      this.$emit("dismiss");
      this.$emit("get");
    },
    dismiss() {
      this.$emit("dismiss");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
