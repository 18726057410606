import axios from "axios";
import Vue from "vue";

export default {
  state: {
    zoneList: null
  },
  actions: {
    async getZone({ commit }, data) {
      const url = process.env.VUE_APP_SERVICE_URL + "zone";
      let token = null;
      if (!data) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        commit("updateZone", response.data.data);
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    }
  },
  mutations: {
    updateZone: (state, data) => {
      data.forEach(zoneData => {
        zoneData.active = false;
      });
      state.zoneList = data;
    },
    changeZone: (state, data) => {
      state.zoneList.forEach(zone => {
        if (zone.short_name === data.short_name) {
          zone.active = true;
        } else {
          zone.active = false;
        }
      });
    },
    resetZoneList: state => {
      Vue.set(state, "zoneList", null);
    }
  },

  namespaced: true
};
