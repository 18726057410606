<template>
  <div class>
    <div
      class="fixed inset-0 bg-black bg-opacity-75"
      style="z-index: 20"
      @click="dismiss()"
    >
      <div
        class="container m-auto max-w-3xl border border-white-100 mt-16 bg-white rounded-lg form-container"
        :class="{ 'will-dismiss': will_dismiss }"
        @click.stop=""
      >
        <div class="py-4 px-4">
          <div
            class="text-right text-xl text-gray cursor-pointer"
            @click="dismiss()"
          >
            <i class="fas fa-times"></i>
          </div>
          <h1 class="text-3xl text-slate">New SSH Key</h1>
          <p class="text-dark-gray mt-4">
            Copy your public SSH key and paste it in the space below
          </p>
          <form @submit="addssh" class="mt-4">
            <div
              class="border p-4 rounded-lg"
              :class="{
                'border-orange': ssh_key.active,
                'border-red': ssh_key.message && !ssh_key.active,
                'border-gray': !ssh_key.active
              }"
            >
              <span class="text-red text-xs" v-if="ssh_key.message">{{
                ssh_key.message
              }}</span>
              <textarea
                name
                id
                class="w-full outline-none"
                style="resize: none"
                rows="10"
                placeholder="SSH Key Content"
                v-model="ssh_key.data"
                @focus="ssh_key.active = true"
                @blur="ssh_key.active = false"
              ></textarea>
            </div>
            <div
              class="border p-4 mt-2 rounded-lg"
              :class="{
                'border-orange': name.active,
                'border-red': name.message && !name.active,
                'border-gray': !name.active
              }"
            >
              <span class="text-red text-xs" v-if="name.message">{{
                name.message
              }}</span>
              <input
                type="text"
                v-model="name.data"
                @focus="name.active = true"
                @blur="name.active = false"
                class="outline-none w-full"
                placeholder="Name"
              />
            </div>
            <button
              class="w-full p-4 bg-orange text-white mt-2 rounded-lg"
              type="submit"
            >
              Add SSH Key
            </button>
          </form>
        </div>
      </div>
    </div>
    <Notification
      v-if="notif.show"
      @dismiss="closeNotif()"
      :header="notif.header"
      :message="notif.message"
      :active="notif.active"
    />
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
export default {
  name: "AddSSH",
  components: {
    Notification
  },
  props: {},
  data: function() {
    return {
      will_dismiss: false,
      ssh_key: {
        active: false,
        message: null,
        data: null
      },
      name: {
        active: false,
        message: null,
        data: null
      },
      notif: {
        active: false,
        message: null,
        show: null,
        header: null
      }
    };
  },
  methods: {
    addssh(ev) {
      ev.preventDefault();
      this.ssh_key.message = null;
      this.name.message = null;
      // eslint-disable-next-line
      const sshChecker = "ssh-rsa AAAA[0-9A-Za-z+]+[=]{0,3}([^@]+@[^@]+)?";
      if (!this.ssh_key.data) {
        this.ssh_key.message = "SSH key can't be empty";
        return;
      }
      if (!this.ssh_key.data.match(sshChecker)) {
        this.ssh_key.message = "SSH Format Doesnt Support";
        return;
      }
      if (!this.name.data) {
        this.name.message = "Name can't be empty";
        return;
      }

      this.g_loading_create("Loading");
      this.$store
        .dispatch("addssh", {
          name: this.name.data,
          ssh: this.ssh_key.data
        })
        .then(response => {
          // eslint-disable-next-line
          var data = response.data;
          this.notif = {
            active: true,
            message: "Your SSH Key has been registered",
            header: "Success",
            show: true
          };
          this.g_loading_destroy();
        })
        .catch(err => {
          this.notif = {
            active: false,
            message: "Something wrong",
            header: "Upss!",
            show: true
          };
          this.g_loading_destroy();
          console.log(err);
        });
    },
    dismiss() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("dismiss");
      }, 300);
    },
    succesClose() {
      this.will_dismiss = true;
      setTimeout(() => {
        this.$emit("succes");
      }, 300);
    },
    closeNotif() {
      this.notif.show = false;
      this.succesClose();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
