import axios from "axios";
import Vue from "vue";

export default {
  state: {
    networks: null
  },
  actions: {
    async getUserListIP({ commit }, data) {
      // console.log("masuk 2");
      const url = process.env.VUE_APP_SERVICE_URL + "ip-address";
      let token = null;
      if (!data) {
        if (!Vue.$cookies.isKey("token")) {
          return;
        }
        token = Vue.$cookies.get("token");
      } else {
        token = JSON.parse(data);
      }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };

      try {
        const response = await axios.get(url, {
          credentials: true,
          headers: header
        });
        commit("updateNetworks", response.data);
        return response.data;
      } catch (err) {
        return err.response.data;
      }
    }
  },
  mutations: {
    updateNetworks: (state, data) => {
      Vue.set(state, "networks", data.data);
      // console.log("state.networks", state.networks);
    }
  },

  namespaced: true
};
