import axios from "axios";
import Vue from "vue";

export default {
  state: {
    invoices: null,
    invoiceDetail: null,
    orders: null
  },
  actions: {
    async getInvoiceDetail({ commit }, data) {
      // const urlBase = process.env.VUE_APP_SERVICE_URL;
      console.log("ini data inv", data);
      let urlAdd;
      let params;
      if (data.query) {
        urlAdd = "invoice/" + data.id;

        params = {
          payment_channel: data.query
        };
      } else if (data.id && !data.query) {
        urlAdd = "invoice/" + data.id;
      } else {
        urlAdd = "invoice/" + data;
      }

      let token = null;
      // if (!data) {
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      token = Vue.$cookies.get("token");
      // } else {
      //   token = JSON.parse(data);
      // }
      try {
        const response = await axios({
          method: "get",
          url: process.env.VUE_APP_SERVICE_URL + urlAdd,
          params: params,
          credentials: true,
          headers: { Authorization: `Bearer ${token}` }
        });

        commit("updateInvoiceDetail", response.data);
        return response.data;
      } catch (err) {
        console.log(err.response.data);
      }
    },
    async getInvoices({ commit }, data) {
      const url = process.env.VUE_APP_SERVICE_URL + "invoice";

      let token = null;
      // if (!data) {
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      token = Vue.$cookies.get("token");
      // } else {
      //   token = JSON.parse(data);
      // }
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      let page;
      if (data) {
        page = data;
      } else {
        page = 1;
      }
      try {
        const response = await axios.get(url, {
          params: {
            page: page
          },
          credentials: true,
          headers: header
        });
        commit("updateInvoices", response.data);
        return response.data;
      } catch (err) {
        console.log(err.response.data);
      }
    },
    async getOrders({ commit }) {
      const url = process.env.VUE_APP_SERVICE_URL + "order";

      let token = null;
      if (!Vue.$cookies.isKey("token")) {
        return;
      }
      token = Vue.$cookies.get("token");

      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      // let page;
      // if (data) {
      //   page = data;
      // } else {
      //   page = 1;
      // }
      try {
        const response = await axios.get(url, {
          // params: {
          //   page: page,
          // },
          credentials: true,
          headers: header
        });
        commit("updateOrders", response.data);
        return response.data;
      } catch (err) {
        console.log(err.response.data);
      }
    }
  },
  mutations: {
    updateInvoiceDetail: (state, data) => {
      Vue.set(state, "invoiceDetail", data);
    },
    updateInvoices: (state, data) => {
      Vue.set(state, "invoices", data);
    },
    updateOrders: (state, data) => {
      Vue.set(state, "orders", data.data);
    }
  },

  namespaced: true
};
