import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import global from "@/mixins/index";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import VueCarousel from "vue-carousel";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Gravatar from "vue-gravatar";
import VueClipboard from "vue-clipboard2";
import VueCookies from "vue-cookies";
import VueMarkdown from "vue-markdown";

Vue.use(VueCookies);
// Vue.use(TurndownService);

Vue.use(VueClipboard);

Vue.component("v-gravatar", Gravatar);
Vue.component("VueSlider", VueSlider);
Vue.component("VueMarkdown", VueMarkdown);

Vue.use(VueCarousel);
// import { VueReCaptcha } from "vue-recaptcha-v3";

import "./assets/styles/app.css";

Vue.config.productionTip = false;

Vue.mixin(global);
Vue.use(Chartkick.use(Chart));
// Vue.use(VueReCaptcha, { siteKey: "6LcXcs8ZAAAAAKpQ8gJR1vWjyrvMs78Er8ebTvb4" });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
