<template>
  <div
    class="bg-black bg-opacity-75 fixed inset-0"
    style="z-index: 10"
    v-if="inputVal"
    @click="dismiss()"
  >
    <div
      class="container max-w-lg m-auto form-container"
      :class="{ 'will-dismiss': will_dismiss }"
    >
      <div class="mx-2 mt-56 py-8 px-6 rounded-md border border-gray bg-white">
        <div class="">
          <div class="text-orange font-nunito text-xl mb-4 font-bold">
            Console Password
          </div>
          <div class="mb-2">
            This is your uniquely generated password to secure your console
            connection
          </div>
          <div class="mb-4">
            <div class="flex">
              <div class="py-2 px-2 truncate bg-grayForm rounded-l-lg flex-1">
                {{ pwd }}
              </div>
              <div
                class="w-10 bg-dangerForm rounded-r-md flex items-center justify-center cursor-pointer relative"
                @click.stop="copySucces()"
              >
                <img
                  src="@/assets/icon/ic_copy.png"
                  class="w-5"
                  alt=""
                  v-clipboard:copy="pwd"
                />
                <div
                  v-if="clip"
                  class="absolute -top-12 px-4 py-2 text-black rounded-lg bg-gray text-sm"
                >
                  Copied
                </div>
              </div>
            </div>
          </div>

          <div class>
            <button
              v-if="!onSubmit"
              @click.stop="$emit('accept')"
              class="px-8 py-2 border border-orange btn-hover text-orange w-full rounded-md font-semibold mr-4"
            >
              Launch Console
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirmation",
  props: {
    pwd: {
      type: String,
      default: "dnkajndkjn12312"
    },
    value: null
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        val ? (this.will_dismiss = true) : (this.will_dismiss = false);
      }
    }
  },
  data() {
    return {
      onSubmit: false,
      clip: false,
      will_dismiss: false
    };
  },
  methods: {
    copySucces() {
      this.clip = true;
      setTimeout(() => {
        this.clip = false;
      }, 500);
    },
    dismiss() {
      this.$emit("input", false);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-container {
  animation: fadeSlideIn 0.3s ease-in;
  opacity: 1;
  transition: all 0.3s;
}
.will-dismiss {
  opacity: 0;
  transform: translateY(10%);
}
@keyframes fadeSlideIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
