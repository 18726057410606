<template>
  <div class="">
    <div class="grid grid-cols-2 items-center justify-center">
      <div
        class="flex justify-center min-h-screen text-white bg-orange items-center"
      >
        <div class="w-64">
          <img src="@/assets/icon/logo_white.png" alt="" class="w-full" />
        </div>
      </div>
      <div class="min-h-screen">
        <div
          class="max-w-sm min-h-screen mx-auto flex items-center justify-center"
        >
          <div class="w-full">
            <ErrorMessage
              class="mb-4"
              v-if="em"
              :message="em_message"
              @dismiss="em = false"
            />
            <h1 class="text-2xl mb-6 font-nunito font-bold">Register</h1>
            <form @submit.prevent="checkdata" class="mb-6">
              <!-- First Name -->
              <div class="mb-2">
                <div
                  class="bg-grayForm p-3 rounded-md border"
                  :class="{
                    'border-orange': register.name.active,
                    'border-grayForm': !register.name.active,
                    'border-red': register.name.message && !register.name.active
                  }"
                >
                  <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-1 text-orange text-lg text-center">
                      <i class="far fa-user"></i>
                    </div>
                    <input
                      type="name"
                      v-model="register.name.data"
                      @focus="register.name.active = true"
                      @blur="register.name.active = false"
                      class="w-full col-span-11 text-sm placeholder-grayText bg-grayForm outline-none"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div
                  v-if="register.name.message"
                  class="text-red text-right text-xs"
                >
                  {{ register.name.message }}
                </div>
              </div>

              <!-- Last Name -->
              <div class="mb-4">
                <div
                  class="bg-grayForm p-3 rounded-md border"
                  :class="{
                    'border-orange': register.lname.active,
                    'border-grayForm': !register.lname.active,
                    'border-red':
                      register.lname.message && !register.lname.active
                  }"
                >
                  <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-1 text-center text-orange text-lg">
                      <i class="far fa-user"></i>
                    </div>
                    <input
                      type="text"
                      @focus="register.lname.active = true"
                      @blur="register.lname.active = false"
                      v-model="register.lname.data"
                      class="w-full col-span-11 text-sm placeholder-grayText bg-grayForm outline-none"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div
                  v-if="register.lname.message"
                  class="text-red text-right text-xs"
                >
                  {{ register.lname.message }}
                </div>
              </div>

              <!-- email -->
              <div class="mb-2">
                <div
                  class="bg-grayForm p-3 rounded-md border"
                  :class="{
                    'border-orange': register.email.active,
                    'border-grayForm': !register.email.active,
                    'border-red':
                      register.email.message && !register.email.active
                  }"
                >
                  <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-1 text-orange text-lg text-center">
                      <i class="far fa-envelope"></i>
                    </div>
                    <input
                      type="email"
                      v-model="register.email.data"
                      @focus="register.email.active = true"
                      @blur="register.email.active = false"
                      class="w-full col-span-11 text-sm placeholder-grayText bg-grayForm outline-none"
                      placeholder="Email Address"
                    />
                  </div>
                </div>
                <div
                  v-if="register.email.message"
                  class="text-red text-right text-xs"
                >
                  {{ register.email.message }}
                </div>
              </div>

              <!-- password -->
              <div class="mb-2">
                <div
                  class="bg-grayForm p-3 rounded-md border"
                  :class="{
                    'border-orange': register.password.active,
                    'border-grayForm': !register.password.active,
                    'border-red':
                      register.password.message && !register.password.active
                  }"
                >
                  <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-1 flex items-center justify-center">
                      <img
                        src="@/assets/icon/lock_orange@2x.png"
                        alt=""
                        class="w-full"
                      />
                    </div>
                    <input
                      type="Password"
                      @focus="register.password.active = true"
                      @blur="register.password.active = false"
                      v-model="register.password.data"
                      class="w-full col-span-11 text-sm placeholder-grayText bg-grayForm outline-none"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div
                  v-if="register.password.message"
                  class="text-red text-right text-xs"
                >
                  {{ register.password.message }}
                </div>
              </div>

              <!-- re-password -->
              <div class="mb-2">
                <div
                  class="bg-grayForm p-3 rounded-md border"
                  :class="{
                    'border-orange': register.repassword.active,
                    'border-grayForm': !register.repassword.active,
                    'border-red':
                      register.repassword.message && !register.repassword.active
                  }"
                >
                  <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-1 flex items-center justify-center">
                      <img
                        src="@/assets/icon/lock_orange@2x.png"
                        alt=""
                        class="w-full"
                      />
                    </div>
                    <input
                      type="Password"
                      @focus="register.repassword.active = true"
                      @blur="register.repassword.active = false"
                      v-model="register.repassword.data"
                      class="w-full col-span-11 text-sm placeholder-grayText bg-grayForm outline-none"
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
                <div
                  v-if="register.repassword.message"
                  class="text-red text-right text-xs"
                >
                  {{ register.repassword.message }}
                </div>
              </div>

              <div class="mb-10">
                <div class="flex text-sm items-center text-darkBlueText">
                  <input
                    type="checkbox"
                    class="mr-4"
                    v-model="register.checked.active"
                  />
                  <span class="mr-1">I Agree the </span>
                  <span class="text-orange">terms and conditions</span>
                </div>
                <div v-if="register.checked.message" class="text-red text-xs">
                  {{ register.checked.message }}
                </div>
              </div>

              <button
                type="submit"
                class="w-full py-3 text-white bg-orange rounded-md font-whitney-bold"
              >
                Register
              </button>
            </form>
            <div class="text-sm text-darkBlueText text-center">
              Already have an account?
              <span class="text-orange"
                ><router-link to="/login">Login</router-link></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Notification
      v-if="notif"
      @dismiss="closeNotif()"
      header="Thankyou!"
      message="We just sent you a confirmation email. Check out your inbox"
      active="true"
    />
  </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Notification from "@/components/Notification.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    ErrorMessage,
    Notification
  },
  created() {
    document.title = "Nevacloud | Register";
  },
  computed: {
    ...mapState({
      register: "register"
    })
  },
  data: function() {
    return {
      notif: false,
      em: false,
      em_message: "Connection Error"
    };
  },
  mounted: function() {},
  methods: {
    ...mapActions(["registerUser"]),
    checkdata() {
      this.register.email.message = null;
      this.register.name.message = null;
      this.register.password.message = null;
      this.register.repassword.message = null;
      this.register.checked.message = false;
      this.register.lname.message = false;

      if (!this.register.name.data) {
        this.register.name.message = "Name is Required";
        return;
      }
      var matches = this.register.name.data.match(/\d+/g);
      if (matches != null) {
        this.register.name.message = "Please insert name format";
        return;
      }

      if (!this.register.lname.data) {
        this.register.lname.message = "Last Name Required";
        return;
      }

      let lnameChecker = this.register.lname.data.match(/\d+/g);

      if (lnameChecker != null) {
        this.register.lname.message = "Please insert name format";
        return;
      }
      // eslint-disable-next-line
      const emailChecker = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.register.email.data) {
        this.register.email.message = "Email address is Required";
        return;
      }
      if (!this.register.email.data.match(emailChecker)) {
        this.register.email.message = "Email address is in wrong format";
        return;
      }
      if (!this.register.password.data) {
        this.register.password.message = "Password is Required";
        return;
      }
      if (!this.register.repassword.data) {
        this.register.repassword.message = "Confirm Password is Required";
        return;
      }
      if (this.register.repassword.data != this.register.password.data) {
        this.register.repassword.message = "Password doesn't match";
        return;
      }

      if (!this.register.checked.active) {
        this.register.checked.message = "Required";
        return;
      }
      this.g_loading_create("Loading");
      this.registerUser().then(response => {
        this.g_loading_destroy();
        if (response.status === 200) {
          this.notif = true;
        } else if (response.data.error) {
          if (response.data.error.fields) {
            if (response.data.error.fields.password) {
              this.register.password.message =
                response.data.error.fields.password[0];
            }
          } else {
            this.em = true;
            this.em_message = response.data.error.message;
          }
        } else {
          this.em = true;
          this.em_message = "Connection Error";
        }
      });
    },
    closeNotif() {
      this.notif = false;
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss" scoped></style>
